<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple  @click="$router.push('/building')">
					<q-item-section avatar>
						<q-icon name="apartment" />
					</q-item-section>

					<q-item-section>
						<div>	Building management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple  @click="$router.push('/buildingconfig')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Building Config management</div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple  @click="$router.push('/electricityusage')">
					<q-item-section avatar>
						<q-icon name="power" />
					</q-item-section>

					<q-item-section>
					<div>	Electricity Usage Management </div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple  @click="$router.push('/steamandheat')">
					<q-item-section avatar>
						<q-icon name="local_fire_department" />
					</q-item-section>

					<q-item-section>
						<div>	Steam and Heat Usage Management </div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>
		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Building Config Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>This section is to config your building energy source. Here you will define what power source is used for a specific building. </div>
							<div style="margin-top:10px;">If a building has never changed its power source it can be configured like following:</div>
							<div style="margin-left:15px;">
								<div>Building config name: Administration</div>	
								<div>Building config name: Administration</div>	
								<div>Energy source config: Power company A</div>
								<div>Power source: Electricity grid</div>
							</div>
							<div style="margin-top:10px;">If a building has changed its power source or supply, it can be configured like following:</div>
							<div style="margin-left:15px;">
								<div>Building config name: Administration – Before 2023</div>	
								<div>Building: Owen Hall</div>	
								<div>Energy source config: Power company A</div>
								<div>Power source: Electricity grid</div>
							</div>
							<div style="margin-top:10px;">Then create another configuration:</div>
							<div style="margin-left:15px;">
								<div>Building config name: Administration – Gas heating</div>	
								<div>Building: Owen Hall</div>	
								<div>Energy source config: Power company A</div>
								<div>Power source: Heat and steam</div>
							</div>
							<div style="margin-top:10px;">Note: It is important to select "Market Supplier Available" when inputting your data. This is crucial for Scope 2 electricity dual reporting, which includes both a location-based and a market-based method. Dual reporting is required if a market supplier is accessible in your area, regardless of whether you use their services or not.</div>
							<div style="margin-top:10px;">Additionally, if a building utilizes renewable energy, this portion of energy consumption should not be included in this section, as it will not be accounted for in the GHG inventory management calculations. Instead, renewable energy usage should be documented separately to report GHG reduction practices.</div>
							<div style="margin-top:10px;">Default emission factors, based on the local power grid, are initially used in the calculations. However, these values can be adjusted to reflect the specific emission factors of your own electricity supplier, allowing for more accurate reporting.</div>
							<div style="margin-top:10px;">After entering the details for a new item, make sure to click "Save" to record the new information.</div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertBuildingConfig()">Add Building Config</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
							<vxe-button @click="saveProgress()">SAVE</vxe-button>
						</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						header-align="center"
						:auto-resize="true"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column type="checkbox" width="60"></vxe-column>
						<vxe-column field="buildingprofile_name" title="Building Config Name">
							<template #default="{ row }">
								<vxe-input v-model="row.buildingprofile_name" type="text" transfer placeholder="Enter Config Name"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="building" title="Building">
							<template #default="{ row }">
							<vxe-select v-model="row.building" transfer placeholder="Please select" @change="getfactorbypower(row)">
								<!-- <vxe-option  :key="1" value="Electricity grid" label="Electricity grid"></vxe-option> -->
								<vxe-option v-for="item in building_option" :key="item.name" :value="item.name" :label="item.name"></vxe-option>
							</vxe-select>
							</template>
						</vxe-column>
						<vxe-column field="energysource_config" title="Energy Source Config">
							<template #default="{ row }">
								<vxe-input v-model="row.energysource_config" type="text" transfer placeholder="Enter Energy Source Config"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="powersource_config" title="Power source">
							<template #default="{ row }">
							<vxe-select v-model="row.powersource_config" transfer placeholder="Please select" @change="getfactorbypower(row)">
								<vxe-option  :key="1" value="Electricity grid" label="Electricity grid"></vxe-option>
								<vxe-option  :key="2" value="Market energy" label="Market energy"></vxe-option>
								<vxe-option  :key="3" value="Heat and steam" label="Heat and steam"></vxe-option>
								<vxe-option  :key="4" value="Self generation" label="Self generation"></vxe-option>
							</vxe-select>
							</template>
						</vxe-column>
						<!-- <vxe-column field="emission_factor" title="Emission factor">
							<template #default="{ row }">
								<vxe-input v-model="row.emission_factor" type="number" placeholder="Enter Emission Factor"></vxe-input>
							</template>
						</vxe-column> -->
						<vxe-colgroup title="Emission factor (lb/MWh)">
							<vxe-column field="factor_co2" title="CO2">
								<template #default="{ row }">
									<vxe-input v-model="row.factor_co2" type="number" placeholder=""></vxe-input>
								</template>
							</vxe-column>
							<vxe-column field="factor_ch4" title="CH4">
								<template #default="{ row }">
									<vxe-input v-model="row.factor_ch4" type="number" placeholder=""></vxe-input>
								</template>
							</vxe-column>
							<vxe-column field="factor_n2o" title="N2O">
								<template #default="{ row }">
									<vxe-input v-model="row.factor_n2o" type="number" placeholder=""></vxe-input>
								</template>
							</vxe-column>
						</vxe-colgroup>
						<vxe-column field="market_supplier" title="Market supplier available">
							<template #default="{ row }">
								<vxe-select v-model="row.market_supplier" transfer placeholder="Please select" :disabled="row.supplierdisable">
									<div  v-if="row.powersource_config==='Electricity grid'">
									<vxe-option  :key="1" value="yes" label="yes"></vxe-option>
									</div>
									<vxe-option  :key="2" value="no" label="no"></vxe-option>
								</vxe-select>
							</template>
						</vxe-column>
					</vxe-table>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import USAZipcode from "@/components/formdata/zipcode.json";
import AllConuntryStates from "@/components/formdata/allcountries.json";
import stepSource from "../carbondata/sourcetype.json";
import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			buildingprofile_name:'',
			building: '',
			energysource_config: '',
			powersource_config: '',
			emission_factor: '',
			market_supplier:''
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const AllCityStates = ref()
		const rowNum = ref();
		// const ConvertValue = ref("")	
		const building_option = []
		const countryChangeEvent = (row) => {
				// Update State list and iso3
				AllConuntryStates.map((item) => {
					// console.log(row.location_county)
					// console.log(item.name)
					if (row.location_county === item.name) {
						console.log("Found selected country from all countries json");
						row.iso3 = item.iso3;
						if (!item.states.length) {
							console.log(
								"No status or province in country:" +
									item.name +
									" Using country as state"
							);
							const tmpState = {
								name: item.name,
								state_code: item.iso3,
							};
							row.state = tmpState;
							row.allStates.push(tmpState);
						} else {
							// row.state = Object.create(item.states[0]);
							// console.log(item.states)
							row.location_city = ''
							AllCityStates.value = item.states//Object.create(item.states);
							// console.log(AllCityStates)
							return
						}
					}
				});
		}
		
		// const cityChangeEvent = (row) => {
		// 	var source = AllConuntryStates.filter((x) => x.name === row.location_county);
		// 	var source1 = source[0].states.filter((x) => x.name === row.location_city);
		// 	row.location = source[0].iso3 + "_" + source1[0].state_code
		// }
		
		// function cityBycode(code){
		// 	// console.log("cityBycode")
		// 	var ncode = code.split('_')
		// 	var source = AllConuntryStates.filter((x) => x.iso3 === ncode[0]);
		// 	// console.log(source)
		// 	if(source.length !== 0){
		// 		temp_country = source[0].name
		// 		if(source[0].states.length !== 0){
		// 			var source1 = source[0].states.filter((x) => x.state_code === ncode[1]);
		// 			temp_city = source1[0].name
		// 		}
		// 	}
		// }
				
		function getFuelType(FuelTypeStr){
			if (FuelTypeStr == null) {
				console.error("selected val is null");
			} else {
				var source = stepSource.filter((x) => x.value === FuelTypeStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", FuelTypeStr);
					return null;
				}
			}
		}
		function getCategory(categoryStr) {
			if (categoryStr == null || categoryStr == "" ) {
				console.error("selected val is null");
			} else {
				// console.log(categoryStr)
				// console.log(stepSource)
				var source = stepSource[0].children.filter((x) => x.label === categoryStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].suboptions);
					return source[0].suboptions;
				} else {
					console.error("Failed to find the step", categoryStr);
					return null;
				}
			}
		}

		const insertBuildingConfig = async (row) => {
			const $table = xTable.value

			if ($table) {
				const record = {
					buildingprofile_name:'',
					building: '',
					energysource_config: '',
					powersource_config: '',
					emission_factor: '',
					market_supplier: ''
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'buildingprofile_name')
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				// console.log("$table")
				// const selectRecords = $table.getCheckboxRecords()
				// console.log(selectRecords)
				$table.removeCheckboxRow()
				// itemsValue()
				saveProgress()
			}
		}
		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {

				var	source = stepFactor.filter((x) => x.name === Ftype);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}
		function getFactorbyzipcode(zipcode) {
			// let temp_Factor;
			if (zipcode === "") {
				console.error("selected val is null");
				return null;
			} else {
				var	zipcodesource = USAZipcode.filter((x) => x.zipcode === zipcode.toString());
				console.log(USAZipcode)
				console.log(zipcode)
				console.log(zipcodesource)
				if (zipcodesource.length >= 1) {
					console.log(zipcodesource)
					var	source = stepFactor.filter((x) => x.name_nano === zipcodesource[0].subregion);
					if (source.length >= 1) {
						return source[0];
					} else {
						console.error("Failed to find the step", zipcode);
						return null;
					}
				}
			}
		}
		function getBuilding(building){
			if (building == null) {
				console.error("selected val is null");
			} else {
				// console.log("building",building,building_option)
				var source = building_option.filter((x) => x.name === building);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", building);
					return null;
				}
			}
		}
		function getfactorbypower(row)
		{
			const tempFactor = ref()
			if(row.building === '' || row.powersource_config === '') return
			row.supplierdisable = false
			if(row.powersource_config != "Electricity grid"){
				row.supplierdisable = true
				row.market_supplier = "no"
			} 
			if(row.powersource_config === "Electricity grid")
			{
				
				const building = getBuilding(row.building);
				tempFactor.value = getFactorbyzipcode(building.zipcode)
				console.log(tempFactor.value)
				console.log(building.zipcode)
				row.factor_co2 = tempFactor.value.value[0].co2
				row.factor_ch4 = tempFactor.value.value[1].ch4
				row.factor_n2o = tempFactor.value.value[2].n2o

			}
			else if(row.powersource_config === "Heat and steam")
			{
				tempFactor.value = getFactor("Natural Gas Fuel")
				row.factor_co2 = tempFactor.value.value[0].co2
				row.factor_ch4 = tempFactor.value.value[1].ch4
				row.factor_n2o = tempFactor.value.value[2].n2o
			}
		}
		async function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			// console.log("Saving data for user: " + userid + store.state.template)
			// if(xTable.value.getTableData().visibleData === null) return
			// const quipment = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
			// const returnUser = await getItemsData()
			// // console.log("returnUser",returnUser.data.items)
			// if(returnUser.data.items === undefined) return
			// const olditems = JSON.parse(JSON.stringify(returnUser.data.items))
			// // console.log("quipment",quipment)
			// // console.log("olditems",olditems)
			// const newitems=reactive([])
			// for(let i=0;i<olditems.length;i++){
			// 	let rst = 0;
			// 	for(let j=0;j<quipment.length;j++)
			// 	{
			// 		if(quipment[j].name === olditems[i].equipment)
			// 		{
			// 			calculate(olditems[i])
			// 			rst = 1;
			// 			break;
			// 		}
			// 	}
			// 	if(rst)
			// 	{
			// 		if(typeof(olditems[i].operation) !== "undefined") {
			// 			const table_arry=reactive([])
			// 			table_arry.push({
			// 				operation: olditems[i].operation,
			// 				process: olditems[i].process,
			// 				equipment: olditems[i].equipment,
			// 				co2e: olditems[i].co2e,
			// 				co2: olditems[i].co2,
			// 				ch4: olditems[i].ch4,
			// 				n2o: olditems[i].n2o,
			// 				timeperiod:{
			// 					starttime: olditems[i].timeperiod.starttime,
			// 					endtime: olditems[i].timeperiod.endtime
			// 				},
			// 				amount: olditems[i].amount,
			// 				unit: olditems[i].unit,
			// 				datatype: olditems[i].datatype,
			// 				note: olditems[i].note
			// 			})					
			// 			newitems.push(table_arry[0])				
			// 		}
			// 	}
			// }
			const headers = {};
			const body = {
				buildingprofile: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData)),
			};

			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorBuildingProfileByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		async function getBuildingData() {
            const usercode = JSON.stringify(user.value, null, 2);
            const userid = JSON.parse(usercode)["sub"];
            setTimeout(()=>{
                // console.log("setTimeout")
                if(loading.value === true)
                {
                    //router.push('/home')
                }
                
            },10000)
            let response = await axios.get(
                "https://testdynimosazure.azurewebsites.net/api/getCalculatorBuildingByUser?id=" + userid,
                {
                    headers: {},
                }
            );
            if (response) {
                return response;
            }
            
            return null;
        }

		onMounted(async () => {
			const returnUser = await userHasData();
			const tempebuilding = await getBuildingData();
			loading.value = false
			// console.log(tempebuilding.data.building)
			if(tempebuilding.data.building === undefined) return
            for(let i=0;i<tempebuilding.data.building.length;i++)
            {
				if(tempebuilding.data.building[i].name != "")
				{
					building_option.push(tempebuilding.data.building[i])
				}
            }
			
			if(returnUser.data.buildingprofile === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.buildingprofile))
			for(let i=0;i<databuf.length;i++){
				if(typeof(databuf[i].buildingprofile_name) !== "undefined") {
					const table_arry=reactive([])
					var temp_supplierdisable = false
					if(databuf[i].powersource_config != "Electricity grid"){
						temp_supplierdisable = true
					}
			
					table_arry.push({
						buildingprofile_name: databuf[i].buildingprofile_name,
						building: databuf[i].building,
						energysource_config: databuf[i].energysource_config,
						powersource_config: databuf[i].powersource_config,
						emission_factor: databuf[i].emission_factor,
						factor_co2: databuf[i].factor_co2,
						factor_ch4: databuf[i].factor_ch4,
						factor_n2o: databuf[i].factor_n2o,
						market_supplier: databuf[i].market_supplier,
						supplierdisable: temp_supplierdisable
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			building_option,
			loading,
			tableData,
			formRules,
			miniState: ref(true),
			AllConuntryStates,
			countryChangeEvent,
			// cityChangeEvent,
			getFuelType,
			getCategory,
			getfactorbypower,
			removeSelectRowEvent,
			insertBuildingConfig,
			saveProgress,
			AllCityStates,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>
.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>