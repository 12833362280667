<template>
	<div id="chart">
		<apexchart
			type="bar"
			height="350"
			:options="chartOptions"
			:series="series"
		></apexchart>
	</div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
	components: {
		apexchart: VueApexCharts,
	},
	props: ["ourcompnay", "economy", "environment", "social"],
	setup(props) {
		const ourcompnayexpected = 5 - props.ourcompnay;
		const economyexpected = 5 - props.economy;
		const environmentexpected = 12 - props.environment;
		const socialexpected = 14 - props.social;
		const series = [
			{
				name: "Completed",
				data: [
					{
						x: "Our Company",
						y: ourcompnayexpected,
						goals: [
							{
								name: "Expected",
								value: 5,
								strokeWidth: 2,
								strokeColor: "#775DD0",
							},
						],
					},
					{
						x: "Economy",
						y: economyexpected,
						goals: [
							{
								name: "Expected",
								value: 5,
								strokeWidth: 2,
								strokeColor: "#775DD0",
							},
						],
					},
					{
						x: "Environment",
						y: environmentexpected,
						goals: [
							{
								name: "Expected",
								value: 12,
								strokeWidth: 2,
								strokeColor: "#775DD0",
							},
						],
					},
					{
						x: "Social Responsibility",
						y: socialexpected,
						goals: [
							{
								name: "Expected",
								value: 14,
								strokeWidth: 2,
								strokeColor: "#775DD0",
							},
						],
					},
				],
			},
		];
		const chartOptions = {
			chart: {
				height: 350,
				type: "bar",
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			colors: ["#00E396"],
			dataLabels: {
				formatter: function (val, opt) {
					const goals =
						opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;

					if (goals && goals.length) {
						return `${val} / ${goals[0].value}`;
					}
					return val;
				},
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ["Completed", "Expected"],
				markers: {
					fillColors: ["#00E396", "#775DD0"],
				},
			},

			title: {
				text: "Completed v.s. Expected",
				align: "center",
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
					fontSize: "20px",
					fontWeight: "bold",
					fontFamily: "Nunito",
					color: "#263238",
				},
			},
		};

		return {
			series,
			chartOptions,
		};
	},
};
</script>
