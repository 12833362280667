<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" class="text-black">
						<b> ENVIRONMENTAL IMPACT</b>
					</q-tab>
					<q-tab name="social" label="SOCIAL IMPACT" />
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-6">
							<div class="title text-capitalize">Wastes and treatment</div>
							<div class="text-content">
								{{ wastetreatment }}
							</div>
						</div>
						<div class="col-5">
							<div class="title text-capitalize">
								Material Reduction Commitment
							</div>
							<div class="text-content">
								{{ materialreduction }}
							</div>
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-4 testrow bg-green-1">
							<div class="title text-capitalize">Materials Usage</div>

							<q-list padding>
								<q-item
									dense
									v-for="obj in dataArrayMaterial"
									:key="obj.operation"
								>
									<q-item-section side>
										<q-icon name="arrow_right" color="black" />
									</q-item-section>
									<q-item-section>
										<q-item-label>
											{{ obj.meterial }} is used in the product of
											{{ obj.operation }}
										</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>
							<q-table
								title="Materials Usage Table (Update to reasonable title)"
								:rows="rowsMaterial"
								:columns="columnsMaterial"
								row-key="name"
							/>
						</div>
						<div class="col-4 testrow">
							<div class="title text-capitalize">Recycling Materials Usage</div>
							<div class="text-content">
								{{ recycleratio }}% of our used materials are recyclable:
							</div>

							<q-list padding>
								<q-item dense v-for="m in recyclematerial" :key="m">
									<q-item-section side>
										<q-icon name="arrow_right" color="black" />
									</q-item-section>
									<q-item-section>
										<q-item-label>
											{{ m }}
										</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>

							<q-table
								title="Waste Usage Table (Update to reasonable title)"
								:rows="rowsWaste"
								:columns="columnsWaste"
								row-key="name"
							/>
						</div>
						<div class="col-3">
							<q-img :src="wastetreatmentimg" style="fit" />
							<p></p>
							<q-img :src="materialreductionimg" style="fit" />
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
import SlideFooter from "./components/DefaultFooter.vue";

const columnsMaterial = [
	{
		name: "name",
		required: true,
		label: "Material",
		align: "left",
		field: (row) => row.name,
		format: (val) => `${val}`,
		sortable: true,
	},
	{
		name: "amount",
		align: "center",
		label: "Amount",
		field: "amount",
		sortable: true,
	},
	{ name: "unit", label: "Unit", field: "unit", sortable: true },
];
const columnsWaste = [
	{
		name: "name",
		required: true,
		label: "Waste",
		align: "left",
		field: (row) => row.name,
		format: (val) => `${val}`,
		sortable: true,
	},
	{
		name: "amount",
		align: "center",
		label: "Amount",
		field: "amount",
		sortable: true,
	},
	{ name: "unit", label: "Unit", field: "unit", sortable: true },
	{ name: "treatment", label: "Treatment", field: "treatment" },
];
export default {
	name: "EnviromentMaterial",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;

		let wastetreatment =
			store.state[template][template + "_environment_wastetreatment"].ans;
		let wastetreatmentimg =
			store.state[template][template + "_environment_wastetreatment"].img;
		if (!wastetreatmentimg) {
			wastetreatmentimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677932704/DYNIMOS%20DEFAULT%20PICS/pexels-cottonbro-studio-3951625_ej0xhk.jpg";
		}

		const materialreduction =
			store.state[template][template + "_environment_materialreduction"].ans;

		let materialreductionimg =
			store.state[template][template + "_environment_materialreduction"].img;
		if (!materialreductionimg) {
			materialreductionimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677932701/DYNIMOS%20DEFAULT%20PICS/pexels-mali-maeder-802221_x6imhz.jpg";
		}

		var materialtable =
			store.state[template][template + "_environment_materialtable"].ans;
		const dataArrayMaterial = JSON.parse(JSON.stringify(materialtable));
		var wastetable =
			store.state[template][template + "_environment_wastetable"].ans;
		const dataArrayWaste = JSON.parse(JSON.stringify(wastetable));

		var rowsMaterial = [];
		let recyclecnt = 0;
		let recyclematerial = [];
		for (let i = 0; i < dataArrayMaterial.length; i++) {
			let obj = dataArrayMaterial[i];
			let cur = {
				name: obj.meterial,
				amount: obj.amount,
				unit: obj.unit,
			};
			if (obj.recyclability.toUpperCase() === "YES") {
				recyclecnt = recyclecnt + 1;
				recyclematerial.push(obj.meterial);
			}
			rowsMaterial.push(cur);
		}

		const recycleratio = Math.floor(
			(recyclecnt / dataArrayMaterial.length) * 100
		);

		var rowsWaste = [];
		for (let i = 0; i < dataArrayWaste.length; i++) {
			let obj = dataArrayWaste[i];
			let cur = {
				name: obj.waste,
				amount: obj.amount,
				unit: obj.unit,
				treatment: obj.treatment,
			};
			rowsWaste.push(cur);
		}

		return {
			wastetreatment,
			materialreduction,
			wastetreatmentimg,
			materialreductionimg,
			dataArrayMaterial,
			columnsWaste,
			columnsMaterial,
			rowsMaterial,
			rowsWaste,
			recycleratio,
			recyclematerial,
		};
	},
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.testrow {
	padding: 10px 15px;
	background: rgba(86, 61, 124, 0.15);
	border: 1px solid rgba(86, 61, 124, 0.2);
}
.row {
	margin-top: 1rem;
}
.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
