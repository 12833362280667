<template>
  <canvas :id="id" class="chart-canvas" :height="height"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "BarChart",
  props: {
    id: {
      type: String,
      default: "bar-chart",
    },
    title: {
      type: String,
      default: "Bar Chart",
    },
    height: {
      type: [String, Number],
      default: "300",
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Array,
        required: true,
        label: String,
        data: Array,
      },
    },
  },
  mounted() {
    // Bar chart
    var chart = document.getElementById(this.id).getContext("2d");

    // var gradientStroke2 = chart.createLinearGradient(0, 230, 0, 50);

    // gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
    // gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
    // gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    if (this.chart.datasets.length === 4) {
      new Chart(chart, {
        type: "bar",
        data: {
          labels: this.chart.labels,
          datasets: [
            {
                label: this.chart.datasets[0].label+"(kg)",
                tension: 0.4,
                // weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "rgba(61, 252, 64, 0.5)",
                borderColor: "#cb0c9f",
                // backgroundColor: gradientStroke2,
                data: this.chart.datasets[0].data,
                fill: false,
                maxBarThickness: 35,
              },{
                label: this.chart.datasets[1].label+"(kg)",
                tension: 0.4,
                // weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "rgba(59, 239, 252, 0.5)",
                borderColor: "#3A416F",
                // borderWidth: 3,
                // backgroundColor: gradientStroke2,
                data: this.chart.datasets[1].data,
                fill: false,
                maxBarThickness: 35,
              },
              {
                label: this.chart.datasets[2].label+"(kg)",
                tension: 0.4,
                // weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "rgba(59, 117, 252, 0.5)",
                borderColor: "#17c1e8",
                // borderWidth: 3,
                // backgroundColor: gradientStroke2,
                data: this.chart.datasets[2].data,
                fill: false,
                maxBarThickness: 35,
              },
              {
                label: this.chart.datasets[3].label+"(kg)",
                tension: 0.4,
                // weight: 5,
                borderWidth: 0,
                borderRadius: 4,
                backgroundColor: "rgba(229, 59, 252, 0.5)",
                borderColor: "#2dce89",
                // borderWidth: 3,
                // backgroundColor: gradientStroke2,
                data: this.chart.datasets[3].data,
                fill: false,
                maxBarThickness: 35,
              },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 0,
          barPercentage: 1.6,
          legend: {
            display: false,
          },

          scales: {
            y: {
              // stacked: true,
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                zeroLineColor: "#dee2e6",
                drawBorder: false,
                drawTicks: false,
                lineWidth: 1,
                zeroLineWidth: 1,
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              ticks: {
                beginAtZero: true,
                padding: 10,
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
              },
              // title: {
              //   display: true,
              //   text: 'kg',
              //   align: 'end',             
              //   font: {
              //     family: 'Open Sans',
              //     size: 13,
              //     color: '#8898aa',
              //   },
              // }

              // grid: {
              //   drawBorder: false,
              //   display: true,
              //   drawOnChartArea: true,
              //   drawTicks: false,
              //   borderDash: [5, 5],
              // },
              // ticks: {
              //   display: true,
              //   padding: 10,
              //   color: "#9ca2b7",
              // },
            },
            x: {
              stacked: true,
              gridLines: {
                drawBorder: false,
                color: "transparent",
                zeroLineColor: "transparent",
              },
              ticks: {
                fontSize: 13,
                fontColor: "#8898aa",
                fontFamily: "Open Sans",
              },
            },
          },
          layout: {
            padding: 0,
          },
        },
      });
    }
  },
};
</script>
