<template>
	<q-header elevated class="glossy">
		<Toolbar />
	</q-header>

	<div class="row content-2 justify-center">
		<div class="col-12 big-title text-center">
			Textile Life Cycle Carbon Footprint Assessment
		</div>
		<div class="col-10 detail">
			The assessment tool provides an estimate of the environmental impact of
			everyday clothing items. By using the information on your apparel product
			tag, including material composition, you can easily input the necessary
			details to generate an estimate of the carbon footprint of your garment.
		</div>
	</div>
	<div class="c-form-container">
		<div class="title text-left">1.Please enter product information.</div>
		<div class="form">
			<q-form autofocus class="q-gutter-md">
				<q-input
					outlined
					v-model.trim="name"
					type="text"
					clearable="true"
					label="Product Name"
					maxlength="50"
					lazy-rules
				/>
				<q-select
					outlined
					v-model="country"
					name="country"
					label="Country Of Origin"
					:options="countryoptions"
					emit-value
					map-options
					lazy-rules
					:rules="[(val) => (val !== null && val !== '') || '']"
				/>
				<q-input
					outlined
					v-model.number="wegith"
					type="number"
					label="Total Weight"
					lazy-rules
					:rules="[
						(val) =>
							(val && wegith > 0) ||
							'Please enter the total weight of the product',
					]"
				/>
			</q-form>
		</div>
		<div class="form">
			<q-form name="units" lazy-rules>
				<div align="right">
					<span>
						Unit:
						<q-radio v-model="units" val="0" label="oz" value="0" />
						<q-radio v-model="units" val="1" label="g" value="1" />
					</span>
				</div>
			</q-form>
		</div>
	</div>
	<div class="c-form-container">
		<div class="title text-left">
			2.Select all the materials in the product and enter the proportion of each
			material.
		</div>
		<div class="table">
			<q-table
				title="Material"
				:rows="rows"
				:columns="columns"
				row-key="name"
				:visible-columns="visibleColumns"
				hide-bottom
			>
				<template v-slot:top>
					<img
						style="height: 50px; width: 50px"
						src="https://i.ibb.co/Cb7KGVX/logo.png"
					/>
					<q-space />
					<q-select
						outlined
						label="Selection of materials"
						v-model="visibleColumns"
						multiple
						dense
						options-dense
						emit-value
						map-options
						hide-selected
						:options="columns"
						option-value="name"
						options-cover
						style="width: 200px"
					/>
				</template>
				<template v-slot:body="props">
					<q-tr>
						<q-td key="name" align="left">
							<span>Proportion(%)</span>
						</q-td>
						<q-td key="Polyester" :props="props">
							<q-input
								filled
								v-model.number="PolyesterValue"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Nylon" :props="props">
							<q-input
								filled
								v-model.number="NylonValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Acrylic" :props="props">
							<q-input
								filled
								v-model.number="AcrylicValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Polyproplene" :props="props">
							<q-input
								filled
								v-model.number="PolypropleneValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Polyurethane" :props="props">
							<q-input
								filled
								v-model.number="PolyurethaneValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Acetonitrile" :props="props">
							<q-input
								filled
								v-model.number="AcetonitrileValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Linen" :props="props">
							<q-input
								filled
								v-model.number="LinenValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Elastane" :props="props">
							<q-input
								filled
								v-model.number="ElastaneValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Cotton_conventional" :props="props">
							<q-input
								filled
								v-model.number="CottonConventionalValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Cotton_organic" :props="props">
							<q-input
								filled
								v-model.number="CottonOrganicValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Hemp" :props="props">
							<q-input
								filled
								v-model.number="HempValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Cellulosic" :props="props">
							<q-input
								filled
								v-model.number="CellulosicValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Wool" :props="props">
							<q-input
								filled
								v-model.number="WoolValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Cashmere" :props="props">
							<q-input
								filled
								v-model.number="CashmereValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
						<q-td key="Silk" :props="props">
							<q-input
								filled
								v-model.number="SilkValue"
								:dense="dense"
								autofocus="true"
								type="number"
								:rules="[
									(val) =>
										(val > 0 && TotalMaterialProportion <= 100) ||
										'proportion > 0,total proportion <= 100',
								]"
							/>
						</q-td>
					</q-tr>
				</template>
			</q-table>
		</div>
	</div>
	<div class="c-form-container" v-if="calculate > 0">
		<div class="title text-left">
			3.Total carbon footprint:<span style="font-weight: bold">{{
				calculate
			}}</span>
			kg CO2e per item,subdivide as shown below:
		</div>
		<div class="chart">
			<apexchart
				type="pie"
				width="600"
				:options="chartOptions"
				:series="series"
				align="center"
			/>
		</div>

		<q-btn
			push
			color="white"
			text-color="primary"
			@click="resetParameters"
			label="Reset"
		/>
		<div class="detail text-center">
			<router-link to="/contact">Contact Us</router-link> for our calculation
			methodology
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
import VueApexCharts from "vue3-apexcharts";
import { computed, ref } from "vue";
const series = ref([]);

export default {
	name: "CO2Calculator",
	components: {
		apexchart: VueApexCharts,
		Toolbar,
	},

	setup() {
		const name = ref("");
		const country = ref("");
		const wegith = ref("");
		const units = ref("0");
		const visibleColumns = ref([]);

		const PolyesterValue = ref(0);
		const NylonValue = ref(0);
		const AcrylicValue = ref(0);
		const PolypropleneValue = ref(0);
		const PolyurethaneValue = ref(0);
		const AcetonitrileValue = ref(0);
		const LinenValue = ref(0);
		const ElastaneValue = ref(0);
		const CottonConventionalValue = ref(0);
		const CottonOrganicValue = ref(0);
		const HempValue = ref(0);
		const CellulosicValue = ref(0);
		const WoolValue = ref(0);
		const CashmereValue = ref(0);
		const SilkValue = ref(0);

		const columns = [
			{
				name: "name",
				required: true,
				label: "Materials",
				align: "left",
				disable: true,
			},
			{
				name: "Polyester",
				align: "center",
				label: "Polyester",
				field: "Polyester",
			},
			{
				name: "Nylon",
				align: "center",
				label: "Nylon",
				field: "Nylon",
			},
			{
				name: "Acrylic",
				align: "center",
				label: "Acrylic",
				field: "Acrylic",
			},
			{
				name: "Polyproplene",
				align: "center",
				label: "Polyproplene",
				field: "Polyproplene",
			},
			{
				name: "Polyurethane",
				align: "center",
				label: "Polyurethane",
				field: "Polyurethane",
			},
			{
				name: "Acetonitrile",
				align: "center",
				label: "Acetonitrile",
				field: "Acetonitrile",
			},
			{
				name: "Linen",
				align: "center",
				label: "Linen",
				field: "Linen",
			},
			{
				name: "Elastane",
				align: "center",
				label: "Elastane",
				field: "Elastane",
			},
			{
				name: "Cotton_conventional",
				align: "center",
				label: "Cotton_conventional",
				field: "Cotton_conventional",
			},
			{
				name: "Cotton_organic",
				align: "center",
				label: "Cotton_organic",
				field: "Cotton_organic",
			},
			{
				name: "Hemp",
				align: "center",
				label: "Hemp",
				field: "Hemp",
			},
			{
				name: "Cellulosic",
				align: "center",
				label: "Cellulosic",
				field: "Cellulosic",
			},
			{
				name: "Wool",
				align: "center",
				label: "Wool",
				field: "Wool",
			},
			{
				name: "Cashmere",
				align: "center",
				label: "Cashmere",
				field: "Cashmere",
			},
			{
				name: "Silk",
				align: "center",
				label: "Silk",
				field: "Silk",
			},
		];
		const rows = [
			{
				name: "Proportion",
				Polyester: 0,
				Nylon: 0,
				Acrylic: 0,
				Polyproplene: 0,
				polyurethane: 0,
				Acetonitrile: 0,
				Linen: 0,
				Elastane: 0,
				Cotton_conventional: 0,
				Cotton_organic: 0,
				Hemp: 0,
				Cellulosic: 0,
				Wool: 0,
				Cashmere: 0,
				Silk: 0,
			},
		];
		function resetParameters() {
			country.value = null;
			wegith.value = null;
			units.value = "0";
			visibleColumns.value = [];
			PolyesterValue.value = 0;
			NylonValue.value = 0;
			AcrylicValue.value = 0;
			PolypropleneValue.value = 0;
			PolyurethaneValue.value = 0;
			AcetonitrileValue.value = 0;
			LinenValue.value = 0;
			ElastaneValue.value = 0;
			CottonConventionalValue.value = 0;
			CottonOrganicValue.value = 0;
			HempValue.value = 0;
			CellulosicValue.value = 0;
			WoolValue.value = 0;
			CashmereValue.value = 0;
			SilkValue.value = 0;
		}
		const chartOptions = {
			chart: {
				width: 480,
				type: "pie",
			},
			labels: ["Material", "Production", "Distribution", "Use", "End of life"],
			legend: {
				show: true,
				showForSingleSeries: false,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: "bottom",
				horizontalAlign: "center",
				fontSize: "18px",
				fontFamily: "Helvetica, Arial",
				fontWeight: 400,
				itemMargin: {
					horizontal: 15,
					vertical: 0,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			title: {
				text: "Carbon footprint of each stage",
				align: "center",
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
					fontSize: "20px",
					fontWeight: "bold",
					fontFamily: "Nunito",
					color: "#263238",
				},
			},
			dataLabels: {
				// enabled: true,
				// enabledOnSeries: undefined,
				// textAnchor: "middle",
				// distributed: false,
				// offsetX: 0,
				// offsetY: 0,
				// style: {
				// 	fontSize: "14px",
				// 	fontFamily: "Helvetica, Arial, sans-serif",
				// 	fontWeight: "bold",
				// 	colors: undefined,
				// },
				// background: {
				// 	enabled: true,
				// 	foreColor: "#fff",
				// 	padding: 4,
				// 	borderRadius: 2,
				// 	borderWidth: 1,
				// 	borderColor: "#fff",
				// 	opacity: 0.9,
				// 	dropShadow: {
				// 		enabled: false,
				// 		top: 1,
				// 		left: 1,
				// 		blur: 1,
				// 		color: "#000",
				// 		opacity: 0.45,
				// 	},
				// },
				// dropShadow: {
				// 	enabled: false,
				// 	top: 1,
				// 	left: 1,
				// 	blur: 1,
				// 	color: "#000",
				// 	opacity: 0.45,
				// },
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, val.toFixed(2) + "%"];
				},
			},
		};

		const TotalMaterialProportion = computed(() => {
			var totalproportion = 0;
			totalproportion =
				PolyesterValue.value +
				NylonValue.value +
				AcrylicValue.value +
				PolypropleneValue.value +
				PolyurethaneValue.value +
				AcetonitrileValue.value +
				LinenValue.value +
				ElastaneValue.value +
				CottonConventionalValue.value +
				CottonOrganicValue.value +
				HempValue.value +
				CellulosicValue.value +
				WoolValue.value +
				CashmereValue.value +
				SilkValue.value;
			return totalproportion;
		});

		function optionparamreset() {
			if (!visibleColumns.value.includes("Polyester")) {
				PolyesterValue.value = 0;
			}
			if (!visibleColumns.value.includes("Nylon")) {
				NylonValue.value = 0;
			}
			if (!visibleColumns.value.includes("Acrylic")) {
				AcrylicValue.value = 0;
			}
			if (!visibleColumns.value.includes("Polyproplene")) {
				PolypropleneValue.value = 0;
			}
			if (!visibleColumns.value.includes("Polyurethane")) {
				PolyurethaneValue.value = 0;
			}
			if (!visibleColumns.value.includes("Acetonitrile")) {
				AcetonitrileValue.value = 0;
			}
			if (!visibleColumns.value.includes("Linen")) {
				LinenValue.value = 0;
			}
			if (!visibleColumns.value.includes("Elastane")) {
				ElastaneValue.value = 0;
			}
			if (!visibleColumns.value.includes("Cotton_conventional")) {
				CottonConventionalValue.value = 0;
			}
			if (!visibleColumns.value.includes("Cotton_organic")) {
				CottonOrganicValue.value = 0;
			}
			if (!visibleColumns.value.includes("Hemp")) {
				HempValue.value = 0;
			}
			if (!visibleColumns.value.includes("Cellulosic")) {
				CellulosicValue.value = 0;
			}
			if (!visibleColumns.value.includes("Wool")) {
				WoolValue.value = 0;
			}
			if (!visibleColumns.value.includes("Cashmere")) {
				CashmereValue.value = 0;
			}
			if (!visibleColumns.value.includes("Silk")) {
				SilkValue.value = 0;
			}
		}

		let calculate = computed(() => {
			var temp_data = 0;
			var TotalCarbonEmission = 0,
				MaterialCarbonEmission = 0,
				ProductionCarbonEmission = 0,
				DistributionCarbonEmission = 0,
				UseCarbonEmission = 0,
				EndoflifeCarbonEmission = 0;
			var RegionRatios = [0.59, 1, 1.02, 1.23, 1.02];
			var RegionMaterialRatios = [1, 1, 1, 1, 1];
			var RegionProductionRatios = [1.5, 3.42, 3.33, 4.2, 3.33];
			var RegionDistributionRatios = [0.06, 0.07, 0.11, 0.14, 0.11];
			var RegionUseCarbonEmissionRatios = [0.34, 0.33, 0.32, 0.32, 0.32];
			var RegionndoflifeRatios = [0.05, 0.03, 0.03, 0.02, 0.03];
			optionparamreset();
			temp_data =
				((RegionRatios[country.value] *
					wegith.value *
					(units.value * 1 + (1 - units.value) * 28.35)) /
					1000) *
				(0.127 * PolyesterValue.value +
					0.1849 * NylonValue.value +
					0.1784 * AcrylicValue.value +
					0.0985 * PolypropleneValue.value +
					0.0484 * PolyurethaneValue.value +
					0.0304 * AcetonitrileValue.value +
					0.045 * LinenValue.value +
					0.18 * ElastaneValue.value +
					0.0815 * CottonConventionalValue.value +
					0.08 * CottonOrganicValue.value +
					0.0425 * HempValue.value +
					0.024 * CellulosicValue.value +
					0.1045 * WoolValue.value +
					0.13 * CashmereValue.value +
					0.0763 * SilkValue.value);

			MaterialCarbonEmission =
				(RegionMaterialRatios[country.value] *
					temp_data *
					((wegith.value * (units.value * 1 + (1 - units.value) * 28.35)) /
						1000)) /
				0.15;
			ProductionCarbonEmission =
				RegionProductionRatios[country.value] * temp_data;
			DistributionCarbonEmission =
				RegionDistributionRatios[country.value] * temp_data;
			UseCarbonEmission =
				RegionUseCarbonEmissionRatios[country.value] * temp_data;
			EndoflifeCarbonEmission = RegionndoflifeRatios[country.value] * temp_data;
			series.value = [
				MaterialCarbonEmission,
				ProductionCarbonEmission,
				DistributionCarbonEmission,
				UseCarbonEmission,
				EndoflifeCarbonEmission,
			];
			TotalCarbonEmission =
				MaterialCarbonEmission +
				ProductionCarbonEmission +
				DistributionCarbonEmission +
				UseCarbonEmission +
				EndoflifeCarbonEmission;
			console.log("----------log start---------");
			console.log(TotalMaterialProportion.value);
			console.log("----------log end---------");
			return TotalCarbonEmission.toFixed(2);
		});

		return {
			name,
			wegith,
			visibleColumns,
			columns,
			rows: ref(rows),
			units,
			resetParameters,
			series,
			chartOptions,
			country,
			TotalMaterialProportion,
			PolyesterValue,
			calculate,
			NylonValue,
			AcrylicValue,
			PolypropleneValue,
			PolyurethaneValue,
			AcetonitrileValue,
			LinenValue,
			ElastaneValue,
			CottonConventionalValue,
			CottonOrganicValue,
			HempValue,
			CellulosicValue,
			WoolValue,
			CashmereValue,
			SilkValue,
			countryoptions: [
				{
					value: 0,
					label: "France",
				},
				{
					value: 1,
					label: "Europe",
				},
				{
					value: 2,
					label: "Turkey",
				},
				{
					value: 3,
					label: "China",
				},
				{
					value: 4,
					label: "Rest of the World",
				},
			],
		};
	},
	// mounted(){
	// 	console.log(country.value);
	// }
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");

.content-2 {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 50px 8vw;
	.big-title {
		font-size: 3vw;
		font-weight: 400;
		font-family: "Abel";
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 15px;
		margin-bottom: 10px;
	}
	.detail {
		margin-top: 20px;
		font-size: 20px;
		font-weight: 400;
		font-family: "Abel", sans-serif;
		box-sizing: border-box;
		// width: 55%;
	}
}

.c-form-container {
	display: block;
	margin: -3em auto;
	margin-top: 20px;
	text-align: center;
	border-radius: 5px;
	background-color: #ffffff;
	padding: 20px;
	width: 50%;
	.title {
		font-size: 2vw;
		font-weight: 400;
		font-family: "Abel";
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.form {
		margin-top: 20px;
		margin-bottom: 20px;
		// border-top: 1px solid rgb(231, 231, 233);
	}
	.table {
		margin-top: 20px;
		margin-bottom: 35px;
		border-top: 1px solid rgb(231, 231, 233);
	}
	.chart {
		margin-top: 20px;
		margin-bottom: 30px;
		box-sizing: border-box;
		border-top: 1px solid rgb(231, 231, 233);
		color: #6e6d7a;
	}
	.detail {
		margin-top: 20px;
		margin-bottom: 40px;
		font-size: 20px;
		font-weight: 400;
		font-family: "Abel", sans-serif;
		box-sizing: border-box;
	}
}
</style>
