<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="container">
		<!-- <q-parallax class="banner">
			<template v-slot:media> -->
		<div>
			<img
				class="banner"
				src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1680324529/DYNIMOS%20DEFAULT%20PICS/homepage-v3_veoxop.png"
			/>
		</div>
		<!-- </template>
		</q-parallax> -->
		<div class="row content-intro">
			<div class="col-12 big-title text-capitalize text-center">
				Dynimos, Your All-in-One Sustainability Hub
			</div>
			<div class="col-12 detail text-center">
				Dynimos aims to simplify the process to manage, measure, report carbon
				emissions, and identify realistic sustainability strategies through our
				scientific life-cycle assessments (LCAs) and AI approach. Our mission is
				helping businesses communicate their sustainability efforts effectively
				to stakeholders.
			</div>

			<div class="col-6 text-center">
				<q-btn
					flat
					icon="arrow_forward"
					class="detail-btn"
					label="Learn more about our products"
					@click="$router.push('/products')"
				/>
			</div>

			<div class="col-6 text-center">
				<q-btn
					flat
					icon="arrow_forward"
					class="detail-btn"
					label="Learn more about our services"
					@click="$router.push('/services')"
				/>
			</div>
		</div>

		<q-separator />
		<div class="row content-2 bg-grey-2">
			<div class="col-12 text-left">
				<q-btn
					class="detail-btn"
					color="light-green-6"
					label="Dynimos Report"
					@click="$router.push('/products')"
				/>

				<p></p>
			</div>
			<div class="col-12 big-title text-left">
				Invest in customizing your sustainability / ESG profile with Dynimos
			</div>
			<div class="col-12 detail text-left">
				A profile from Dynimos will feature your unique commitments to social,
				economic, cultural, and environmental sustainability
			</div>
			<div class="col-12 text-h6 text-left">
				<div class="detail">
					Do you want to present your unique commitments and responsible
					impacts? We can help you write your story and best explain how you
					address the challenges of sustainability. And it is easy for you.
				</div>
			</div>

			<div class="col-12 right">
				<div class="item">
					<div class="template">
						<img
							src="https://s3.bmp.ovh/imgs/2022/09/04/52765a3aee2b5af8.png"
							alt=""
						/>
					</div>
					<div class="title">Step 1. Pick your template</div>
					<div class="detail">
						We have customizable templates designed to fit the unique features
						of your business.
					</div>
				</div>
				<div class="item">
					<div class="follow">
						<img
							src="https://s3.bmp.ovh/imgs/2022/09/04/c5a9a34e45de6a15.png"
							alt=""
						/>
					</div>
					<div class="title">Step 2. Follow our step-by-step guidance</div>
					<div class="detail">
						Use our guidelines and focus on one task at a time.
					</div>
				</div>
				<div class="item">
					<div class="information">
						<img
							src="https://s3.bmp.ovh/imgs/2022/09/04/d218b1c5eaf42a19.png"
							alt=""
						/>
					</div>
					<div class="title">Step 3. Enter your information</div>
					<div class="detail">
						Choose from example answers we provide, or enter answers of your
						own.
					</div>
				</div>
			</div>

			<div class="col-12 text-center">
				<q-btn
					outline
					flat
					class="detail-btn"
					style="color: #ff0080"
					icon="arrow_forward"
					label="Click To Learn More"
					@click="$router.push('/products')"
				/>
			</div>
		</div>

		<q-separator />

		<div class="row content-2">
			<div class="col-12 text-left">
				<q-btn
					class="detail-btn"
					color="light-green-6"
					label="Dynimos Carbon Accouting"
					@click="$router.push('/calculatermain')"
				/>
				<p></p>
			</div>
			<div class="col-12 big-title text-left">Calculate Carbon Emission</div>
			<div class="col-12 detail">
				We provide a data management platform tailored to trace, calculate, and
				report your business's carbon emissions. With the digital toolsets of
				Dynimos Carbon Accounting, the job of calculating these emissions
				becomes intuitive, automated, and easy for businesses of all types.
			</div>

			<div class="col-4 justify-center" >
				<q-img
					src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690377289/1_gyackf.png"
					fit
					
				/>
			</div>
			<div class="col-4 justify-center" >
				<q-img
					src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690376999/2_nzop1w.png"
					fit
					
				/>
			</div>
			<div class="col-4 justify-center" >
				<q-img
					src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690376999/3_hxbxqm.png"
					fit
					
				/>
			</div>
			<div class="col-12 text-center q-pt-lg">
				<q-btn
					outline
					flat
					class="detail-btn"
					style="color: #ff0080"
					icon="arrow_forward"
					label="Click To Learn More"
					@click="$router.push('/calculatermain')"
				/>
			</div>
		</div>

		<div class="row content-2 bg-grey-1">
			<div class="col-12 text-left">
				<q-btn
					class="detail-btn"
					color="light-green-6"
					label="Dynimos calulator"
					@click="$router.push('/products')"
				/>
				<p></p>
			</div>
			<div class="col-12 big-title text-left">Calculate Carbon Footprint</div>
			<div class="col-12 detail">
				Here we provide calculators to help you estimate the carbon footprint of
				products, operations, and services. Dynimos calculators aim to assist
				you in understanding how material composition and country of origin can
				affect the carbon footprint of products.
			</div>

			<div class="col-6 q-pt-lg">
				<q-img
					src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679784196/DYNIMOS%20DEFAULT%20PICS/pagepics/cal-1_vboodo.png"
					fit
				/>
			</div>
			<div class="col-6 q-pt-lg">
				<q-img
					src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679784761/DYNIMOS%20DEFAULT%20PICS/pagepics/cal-3_aqa5b0.png"
					fit
				/>
			</div>
			<div class="col-12 text-center q-pt-lg">
				<q-btn
					outline
					flat
					class="detail-btn"
					style="color: #ff0080"
					icon="arrow_forward"
					label="Click To Learn More"
					@click="$router.push('/products')"
				/>
			</div>
		</div>

		<q-separator />

		<div class="row content-2">
			<div class="col-12 text-left">
				<q-btn
					class="detail-btn"
					color="light-green-6"
					label="Dynimos Service"
					@click="$router.push('/services')"
				/>
				<p></p>
			</div>
			<div class="col-12 big-title text-center">
				Manage GHG Impact with Dynimos
			</div>
			<div class="col-12 detail text-center">
				We offer free 30 mins consulting service for our first-time users!
			</div>

			<div class="col-12 right">
				<div class="item">
					<q-parallax>
						<template v-slot:media>
							<img
								src="https://images.pexels.com/photos/1423600/pexels-photo-1423600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
							/>
						</template>
						<div class="title1 text-white text-center">
							Life cycle assessment and Environmental Product Disclaimer
						</div>
					</q-parallax>
				</div>
				<div class="item">
					<q-parallax>
						<template v-slot:media>
							<img
								src="https://images.pexels.com/photos/9175725/pexels-photo-9175725.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
							/>
						</template>
						<div class="title1 text-white text-center">
							GHG Inventory Establishment and Development
						</div>
					</q-parallax>
				</div>
				<div class="item">
					<q-parallax>
						<template v-slot:media>
							<img
								src="https://images.pexels.com/photos/7165473/pexels-photo-7165473.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
							/>
						</template>
						<div class="title1 text-white text-center">
							GHG Reduction Projects
						</div>
					</q-parallax>
				</div>
				<div class="item">
					<q-parallax>
						<template v-slot:media>
							<img
								src="https://images.pexels.com/photos/673648/pexels-photo-673648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
							/>
						</template>
						<div class="title1 text-white text-center">
							Customized Sustainability Report
						</div>
					</q-parallax>
				</div>
			</div>
			<div class="col-12 text-center">
				<q-btn
					outline
					flat
					class="detail-btn"
					style="color: #ff0080"
					icon="arrow_forward"
					label="Click To Learn More"
					@click="$router.push('/services')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
// import { onMounted } from "vue";
import { useQuasar } from "quasar";
export default {
	name: "HomePage",
	components: {
		Toolbar,
	},
	setup() {
		const $q = useQuasar();
		$q.notify.setDefaults({
			position: "bottom",
			timeout: 12000,
			textColor: "white",
		});

		// function showNotif() {
		// 	$q.notify({
		// 		progress: true,
		// 		message:
		// 			'<strong style="font-size:1.2vw">🚀Exclusive Limited-Time Offer!🚀</strong><br><p style="font-size:1vw">Discover Dynimos Carbon Accounting and ESG Reporting Software with <strong>NO COST</strong> until November 9th, 2023</p> ',
		// 		html: true,
		// 		// icon: "rocket_launch",
		// 		multiLine: true,
		// 		color: "green-4",
		// 		actions: [
		// 			{
		// 				icon: "close",
		// 				color: "white",
		// 				round: true,
		// 				handler: () => {
		// 					/* ... */
		// 				},
		// 			},
		// 		],
		// 	});
		// }

		// onMounted(() => {
		// 	showNotif();
		// });

		return {
			mailUrl: `mailto:support@dynimos.com?body=Hello, my friends,%0A%0AWe are glad you are here! %0APlease feel free to say hello and let us know if you are interested in telling your own sustainability stories. We would love to schedule a demo appointment with you. We suggest you return for our official product launch soon and bring your friends.%0A%0ADYNIMOS team `,
		};
	},

	methods: {
		track () {
		this.$gtag.query('event', 'page_view', {
			page_title: 'Home page',
			page_path:'/home'
		})
		}
	}
	// methods: {
	// jumpPrice() {
	// 	this.$router.push({ path: "/user/price" });
	// },
	// },
};
</script>
<style lang="less" scoped>
.leftButton {
	border-radius: 20px;
}
</style>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 1200px) {
	.container {
		div {
			font-family: Calibri;
		}
		.banner {
			height: 100%;
			width: 100%;
		}
		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 28px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.left {
				.big {
					font-size: 2.5vw;
					font-weight: 700;
					font-family: "Roboto", sans-serif;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
					line-height: 2.9vw;
				}

				.small {
					margin-top: 20px;
					font-size: 20px;
					font-weight: 400;
					font-family: "Abel", sans-serif;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				img {
					width: 40vw;
					border-radius: 50px;
				}
			}
		}

		.content-intro {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.big-title {
				font-size: 3.7vw;
				font-weight: 700;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-bottom: 30px;
			}
			.big-title2 {
				font-size: 2vw;
				font-weight: 700;
				font-family: "Abel";
				color: #686868;
				box-sizing: border-box;
			}

			.detail {
				margin-top: 20px;
				font-size: 22px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
		}

		.content-2 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 50px 8vw;
			.big-title {
				font-size: 28px;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.big-title2 {
				font-size: 2vw;
				font-weight: 400;
				font-family: "Abel";
				color: #070707;
				box-sizing: border-box;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 20px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
			}
			.left {
				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 80px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 20px;
						font-weight: 700;
						font-family: "Abel";
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}
					.title1 {
						font-size: 24px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}
					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (min-width: 480px) and (max-width: 1200px) {
	.container {
		.banner {
			height: 100%;
			width: 100%;
		}
		.content-intro {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 15px;

			.big-title {
				font-size: 2.7vw;
				font-weight: 700;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.big-title2 {
				font-size: 2vw;
				font-weight: 700;
				font-family: "Abel";
				color: #686868;
				box-sizing: border-box;
			}

			.detail {
				margin-top: 20px;
				font-size: 16px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-btn {
				font-size: 14px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 40px;
			}
		}
		.content-2 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 50px 8vw;
			.big-title {
				font-size: 24px;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.big-title2 {
				font-size: 2vw;
				font-weight: 400;
				font-family: "Abel";
				color: #070707;
				box-sizing: border-box;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 20px;
			}
			.detail {
				margin-top: 16px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
			}
			.left {
				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 80px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 20px;
						font-weight: 700;
						font-family: "Abel";
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}
					.title1 {
						font-size: 24px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}
					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
	.with-bg {
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
	.content-2 {
		// display: flex;
		// flex-direction: column;
		// justify-content: space-between;
		// box-sizing: border-box;
		// padding: 50px 8vw;
		// margin-top: 30px;
		box-sizing: border-box;
		padding: 20px 8vw;
		margin-top: 10px;
		.detail-btn {
			font-size: 20px;
			font-weight: 500;
			font-family: "Abel", sans-serif;
			color: rgb(108, 154, 208);
			margin-top: 10px;
		}
		.big-title {
			font-size: 24px;
			font-weight: 400;
			font-family: "Abel";
			color: #0d0c22;
			box-sizing: border-box;
			margin-bottom: 10px;
		}
		.detail {
			margin-top: 10px;
			font-size: 18px;
			font-weight: 400;
			font-family: "Abel", sans-serif;
			box-sizing: border-box;
		}
		.left {
			margin-bottom: 30px;

			.title {
				font-size: 24px;
				font-weight: 700;
				width: 100% !important;
				color: rgb(13, 12, 34);
			}

			.detail {
				font-size: 18px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 50px;
				width: 100% !important;
				color: #3d3d4e;
			}
		}

		.right {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			// .item {
			// 	width: 30vw !important;

			// 	.detail {
			// 		font-size: 16px;
			// 		font-weight: 600;
			// 		width: 20vw !important;
			// 		height: 100px;
			// 	}
			// }
			.item {
				width: 20vw;
				margin-top: 2vw;
				margin-bottom: 1.5vw;

				.template {
					height: 66px;

					img {
						height: 60px;
					}
				}

				.follow {
					height: 66px;

					img {
						height: 55px;
					}
				}

				.information {
					height: 66px;

					img {
						height: 66px;
					}
				}

				.title {
					font-size: 14px;
					font-weight: 700;
					font-family: "Abel";
					height: 80px;
					margin-top: 20px;
					width: 15vw;
				}

				.detail {
					font-size: 12px;
					font-weight: 600;
				}

				.learnmore {
					color: rgb(238, 115, 165);
					font-size: 16px;
					font-weight: 600;
					margin-top: 30px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding-right: 50px;
					cursor: pointer;

					img {
						width: 30px;
						height: 30px;
					}
				}
				.title1 {
					font-size: 14px;
					font-weight: 400;
					width: 18vw;
					height: 100px;
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (max-width: 480px) {
	.container {
		div {
			font-family: Calibri;
		}
		.banner {
			height: 100%;
			width: 100%;
		}
		.content-intro {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 5px;

			.big-title {
				font-size: 18px;
				font-weight: 700;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-bottom: 5px;
			}
			.big-title2 {
				font-size: 2vw;
				font-weight: 700;
				font-family: "Abel";
				color: #686868;
				box-sizing: border-box;
			}

			.detail {
				margin-top: 20px;
				font-size: 15px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-btn {
				font-size: 12px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 20px;
			}
		}
		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 18px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;

			.left {
				.big {
					font-size: 6vw;
					font-weight: 700;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
				}

				.small {
					margin-top: 10px;
					font-size: 18px;
					font-weight: 400;
					font-weight: normal;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				margin-top: 20px;

				img {
					width: 100%;
					border-radius: 50px;
				}
			}
		}

		.content-2 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;
			.detail-btn {
				font-size: 14px;
				font-weight: 500;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 10px;
			}
			.big-title {
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.detail {
				margin-top: 5px;
				font-size: 15px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.left {
				.title {
					font-size: 6vw;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				// display: flex;
				// justify-content: space-between;
				box-sizing: border-box;

				.item {
					margin-top: 5px;

					img {
						width: 50px;
					}

					.title {
						font-size: 20px;
						font-weight: 700;
					}
					.title1 {
						font-size: 24px;
						font-weight: 400;
						width: 60vw;
						height: 100px;
					}
					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}
					.detail {
						font-size: 16px;
						font-weight: 400;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
		}

		.content-3 {
			box-sizing: border-box;
			padding: 50px 8vw;

			.left {
				.left-title {
					font-size: 21px;
					font-weight: 800;
					margin-top: 20px;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
				}
			}

			.right {
				.title {
					text-align: center;
					font-size: 30px;
					font-weight: 500;
					color: rgb(79, 113, 190);
					margin-top: 30px;
				}

				.imglist {
					.item {
						display: flex;
						align-items: center;
						flex-direction: column;
						margin-top: 20px;

						img {
							width: 80vw;
						}

						.item-title {
							font-size: 25px;
							font-weight: 500;
							margin-top: 20px;
							margin-bottom: 20px;
						}

						.item-detail {
							font-size: 16px;
							font-weight: 500;
							width: 100%;
						}
					}
				}
			}
		}

		.content-4 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 30px;

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					white-space: nowrap;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					height: 50px;
					font-size: 18px;
					font-weight: 600;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				box-sizing: border-box;
				margin-top: 50px;

				.item {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					margin-top: 20px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 40px;
						margin-top: 20px;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
		}

		.footer {
			padding: 20px 10px;
			box-sizing: border-box;
			border-top: 1px solid rgb(231, 231, 233);
			color: #6e6d7a;
		}
	}
	.with-bg {
		border-radius: 5px;
		height: 50px;
		font-size: 18px;
		font-weight: 600;
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
}
</style>
