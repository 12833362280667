<template>
	<q-card flat bordered class="my-card">
		<q-card-section>
			<div class="text-h6">Economy</div>
		</q-card-section>
		<q-separator inset />

		<q-card-section>
			The economy section discloses your company's critical responsible business
			activities and performances in the reporting period. This section aims to
			demonstrate the economic impacts of your company on customers,
			stakeholders, and/or local communities.<br />
			You will be asked to provide the following information:
			<li>
				Responsible business practices during the reporting period that
				generated economic impacts.
			</li>
			<li>Business opportunities your company has created for others.</li>
			<li>Business practices for building strong partnerships.</li>
			<li>
				A feature story or responsible business highlight to demonstrate your
				remarkable performance during the reporting period.
			</li>
			Uploading good-quality pictures in our designated sections will make your
			report more appealing to readers and stakeholders. <br />
			Examples and templates in this section demonstrate how the content can be
			presented and how it can be a starting point where you edit the example
			based on your company's situation. Whenever possible, please use your
			content and avoid adopting the examples directly.
		</q-card-section>
	</q-card>
</template>
<script>
export default {
	name: "EconomyPage",
};
</script>
