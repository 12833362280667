<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="big_title"  v-if="MediumData() !== null">
		<!-- <span>{{ MediumData().feed.title }}</span> -->
    <span>{{ "Dynimos Blogs" }}</span>
	</div>
	<div class="justify-center" style="width: 60%;margin: 0 auto;" v-if="MediumData() !== null">
    <div class="row justify-center col-12" style="justify-content: space-around;">
      <div 
        class="card"
        v-for="(item,index) in MediumData().items"
        :key="index"
      >
      <!-- p-0 mx-3 mt-n4 position-relative  -->
        <div class="card-header"> 
          <img :src="require('@/assets/blog/Fashion.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-if="item.pubDate==='2023-10-06 01:36:35'">
          <img :src="require('@/assets/blog/2024-11-14 011520.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-11-14 01:15:20'">
          <img :src="require('@/assets/blog/2024-10-09 155808.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-10-09 15:58:08'">
          <img :src="require('@/assets/blog/2024-09-11 204523.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-09-11 20:45:23'">
          <img :src="require('@/assets/blog/2024-06-20 154154.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-06-20 15:41:54'">
          <img :src="require('@/assets/blog/2024-02-28 202348.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-02-28 20:23:48'">
          <img :src="require('@/assets/blog/2024-02-26 120356.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-02-26 12:03:56'">
          <img :src="require('@/assets/blog/2024-02-16 122124.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-02-16 12:21:24'">
          <img :src="require('@/assets/blog/2024-02-12 195229.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-02-12 19:52:29'">
          <img :src="require('@/assets/blog/2024-01-31 003644.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-01-31 00:36:44'">
          <img :src="require('@/assets/blog/unique.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-01-22 20:00:55'">
          <img :src="require('@/assets/blog/Cap-and-Trade.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.pubDate==='2024-01-15 13:51:14'">
          <img :src="require('@/assets/blog/chipmunk.jpg')" alt="" style="max-width: 100%;min-height:300hv" v-else-if="item.title==='Dynimos’ partnership with Chipmunk Robotics'">
          <img :src="require('@/assets/blog/roboro.jpg')" alt="" style="width: 100%;" v-else-if="item.title==='When fashion industry meets Dynimos: our partnership with Roboro'">
          <img :src="require('@/assets/blog/'+ index%9 +'.jpg')" alt="" style="width: 100%;" v-else>
        </div>
        <div class="card-body">
          <div
            class="card-title"
            @click="Blog_Detail('BlogDetailPage', item)"
          >
            {{item.title}}
          </div>
          <div class="author align-items-center">
            <div class="name ps-2">
              <span>{{ item.categories[0] }}</span>
              <div class="stats">
                <small>{{ item.pubDate }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
// import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import BLOGFILE from "../../public/dynimosmedia.json"
// import { writeFile } from 'fs-extra';

export default {
	name: "BlogPage",
	components: {
		Toolbar,
	},
	
	data() {
		let rssData = ref();
		var rss_json;
    const router = useRouter();
    //https://medium.com/feed/@dynimosmedia
    // const response = axios.get("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40dynimosmedia")
		// 		.then(response =>{
		// 		rssData.value = response.data;
		// 	})
		function MediumData() {
			// let response = axios.get("https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40dynimosmedia")
			// 	.then(response =>{
			// 	rssData.value = response.data;
			// })
			if (BLOGFILE !== '') {
        try {
          // rss_json = JSON.parse(JSON.stringify(rssData.value));
          // console.log("BLOGFILE",BLOGFILE)
          rss_json = JSON.parse(JSON.stringify(BLOGFILE));
          // writeFile('blog.json', JSON.stringify(rssData.value));
        } catch {
          rss_json = null
        }
				return rss_json;
			}
			return null;
		}	

    function Blog_Detail(pagepath,item_d) {
      // console.log(JSON.stringify(item_d))
      // console.log(item_d.guid)
      // window.open(item_d.guid, '_blank');
			router.push({
				name: pagepath,
				params: {
					context: item_d
				},
				state:{
					k: JSON.stringify(item_d),//item_d,//
				},
			});
		}
		return {
			rssData,
			rss_json,
			MediumData,
      Blog_Detail,
		};
	},
	methods: {
		
	}
};
</script>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 1200px) {
div {
  font-family: "Abel";
}
.big_title{
  text-align: center;
  font-size: 3vw;
  font-weight: 700;
  margin: 2% 2%;
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bs-card-inner-border-radius: 0.75rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;

  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 5%;
  max-width: 25%;
  margin: 20px 20px;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.card[data-animation=true] .card-header {
  transform: translate3d(0, 0, 0);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card:hover[data-animation=true] .card-header {
  transform: translate3d(0, -50px, 0);
}
.card .card-header {
  padding: 1.2rem;
}
.card .card-body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding: 1.2rem;
}
.card.card-plain {
  background-color: transparent;
  box-shadow: none;
}
.card .card-footer {
  padding: 1.5rem;
  background-color: transparent;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  font-weight: bolder;
  font-size: 0.8vw;
  color: #000000;
  cursor:pointer;
  text-decoration: none;
}
.card-title:hover{
  // font-weight: bolder;
  // font-size: 0.8vw;
  // margin-bottom: 20px;
  color: #1A73E8;
}
.author {
  display: flex;
  margin-top: 10px;
}
.author .name > span {
  line-height: 1.571;
  font-weight: 600;
  font-size: 0.875rem;
  color: #001181;
}
.author .stats {
  font-size: 0.875rem;
  font-weight: 400;
}
.card.card-background .card-body {
  color: #fff;
  position: relative;
  z-index: 2;
}
.card.card-background .card-body .author .name span,
.card.card-background .card-body .author .name .stats {
  color: #fff;
}
}
@media (min-width: 600px) and (max-width: 1200px) {
div {
  font-family: "Abel";
}
.big_title{
  text-align: center;
  font-size: 3vw;
  font-weight: 700;
  margin: 2% 2%;
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bs-card-inner-border-radius: 0.75rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;

  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 5%;
  max-width: 48%;
  margin: 5px 2px;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.card[data-animation=true] .card-header {
  transform: translate3d(0, 0, 0);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card:hover[data-animation=true] .card-header {
  transform: translate3d(0, -50px, 0);
}
.card .card-header {
  padding: 1.2rem;
}
.card .card-body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding: 1.2rem;
}
.card.card-plain {
  background-color: transparent;
  box-shadow: none;
}
.card .card-footer {
  padding: 1.5rem;
  background-color: transparent;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  font-weight: bolder;
  font-size: 0.8vw;
  color: #000000;
  cursor:pointer;
  text-decoration: none;
}
.card-title:hover{
  // font-weight: bolder;
  // font-size: 0.8vw;
  // margin-bottom: 20px;
  color: #1A73E8;
}
.author {
  display: flex;
  margin-top: 10px;
}
.author .name > span {
  line-height: 1.571;
  font-weight: 600;
  font-size: 0.875rem;
  color: #001181;
}
.author .stats {
  font-size: 0.875rem;
  font-weight: 400;
}
.card.card-background .card-body {
  color: #fff;
  position: relative;
  z-index: 2;
}
.card.card-background .card-body .author .name span,
.card.card-background .card-body .author .name .stats {
  color: #fff;
}
}
@media (max-width: 600px) {
div {
  font-family: "Abel";
}
.big_title{
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 2% 2%;
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bs-card-inner-border-radius: 0.75rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;

  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 5%;
  max-width: 100%;
  margin: 5px 2px;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.card[data-animation=true] .card-header {
  transform: translate3d(0, 0, 0);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card:hover[data-animation=true] .card-header {
  transform: translate3d(0, -50px, 0);
}
.card .card-header {
  padding: 1.2rem;
}
.card .card-body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding: 1.2rem;
}
.card.card-plain {
  background-color: transparent;
  box-shadow: none;
}
.card .card-footer {
  padding: 1.5rem;
  background-color: transparent;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  font-weight: bolder;
  font-size: 0.8vw;
  color: #000000;
  cursor:pointer;
  text-decoration: none;
}
.card-title:hover{
  // font-weight: bolder;
  // font-size: 0.8vw;
  // margin-bottom: 20px;
  color: #1A73E8;
}
.author {
  display: flex;
  margin-top: 10px;
}
.author .name > span {
  line-height: 1.571;
  font-weight: 600;
  font-size: 0.875rem;
  color: #001181;
}
.author .stats {
  font-size: 0.875rem;
  font-weight: 400;
}
.card.card-background .card-body {
  color: #fff;
  position: relative;
  z-index: 2;
}
.card.card-background .card-body .author .name span,
.card.card-background .card-body .author .name .stats {
  color: #fff;
}
}
</style>




