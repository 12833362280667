<!-- https://www.vuescript.com/vue-component-image-crop-upload/ -->
<template>
	<div id="app">
		<p>{{ label }}</p>

		<q-btn color="teal" label="Upload Picture" @click="toggleShow" />
		<my-upload
			field="img"
			@crop-success="cropSuccess"
			@crop-upload-success="cropUploadSuccess"
			@crop-upload-fail="cropUploadFail"
			v-model="show"
			:width="width"
			:height="height"
			url=""
			:noCircle="noCircle"
			:langType="langType"
			img-format="png"
		></my-upload>
		<br />
		<div class="q-gutter-md row items-start q-pt-lg">
			<q-img
				v-if="imgDataUrl"
				:src="imgDataUrl"
				:ratio="1"
				class="rounded-borders"
				:width="widthpx"
				:height="heightpx"
			>
				<div class="absolute-top text-center">Preview</div>
			</q-img>
		</div>
	</div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import myUpload from "vue-image-crop-upload";

export default {
	name: "ImgUploader",

	props: ["step", "label", "ratio"],

	components: {
		"my-upload": myUpload,
	},

	setup(props) {
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));
		console.log(props);
		const width =
			parseFloat(props.ratio.substr(0, props.ratio.indexOf("x"))) * 100;
		console.log(width);
		const height =
			parseFloat(
				props.ratio.substr(props.ratio.indexOf("x") + 1, props.ratio.len)
			) * 100;

		let show = ref(false);

		let imgDataUrl = ref(store.state[curTemplate][curStep].img);
		return {
			langType: {
				type: String,
				default: "en",
			},
			// disable circle image
			noCircle: {
				type: Boolean,
				default: false,
			},
			show,
			imgDataUrl,
			width,
			height,
			widthpx: width + "px",
			heightpx: height + "px",
			allowImgFormat: {
				type: Array,
				default: () => ["gif", "jpg", "png"],
			},

			toggleShow() {
				show.value = !show.value;
			},
			/**
			 * crop success
			 *
			 * [param] imgDataUrl
			 * [param] field
			 */
			cropSuccess(myimgDataUrl) {
				console.log("-------- crop success --------");
				imgDataUrl.value = myimgDataUrl;
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "img",
					value: myimgDataUrl,
				});
			},
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field) {
				console.log("-------- upload success --------");
				console.log(jsonData);
				console.log("field: " + field);
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field) {
				console.log("-------- upload fail --------");
				console.log(status);
				console.log("field: " + field);
			},
		};
	},
};
</script>
