const default_retail_states = {
    retail_ourcompany_intro: {
        ans: "",
        img: "",
    },
    retail_ourcompany_mission: {
        ans: "",
    },
    retail_ourcompany_stakeholders: {
        ans: "",
        img: "",
    },
    // retail_ourcompany_suppliers: {
    //     ans: "",
    //     img: "",
    // },
    retail_ourcompany_commitment: {
        ans: "",
        img: "",
    },
    retail_ourcompany_featurestory: {
        ans: "",
        img: "",
    },
    retail_economy_activities: {
        ans: "",
        img: "",
    },
    retail_economy_opportunities: {
        ans: "",
        img: "",
    },
    retail_economy_practices: {
        ans: "",
        img: "",
    },
    retail_economy_featurestory: {
        ans: "",
        img: "",
    },
    retail_economy_highlights: {
        ans: "",
        img: "",
    },
    retail_environment_strategy: {
        ans: "",
    },
    retail_environment_suppliers: {
        ans: "",
    },
    retail_environment_carbon: {
        ans: "",
        img: "",
    },
    retail_environment_energyreduction: {
        ans: "",
    },
    retail_environment_wastewater: {
        ans: "",
    },
    retail_environment_waterreduction: {
        ans: "",
    },
    retail_environment_wastetreatment: {
        ans: "",
        img: "",
    },
    retail_environment_materialreduction: {
        ans: "",
        img: "",
    },
    retail_social_diversity: {
        ans: "",
        img: "",
    },
    retail_social_employees_education: {
        ans: "",
        img: "",
    },
    retail_social_employees_growth: {
        ans: "",
    },
    retail_social_featurestory_diversity: {
        ans: "",
    },
    retail_social_featurestory_training: {
        ans: "",
    },
    retail_social_community: {
        ans: "",
    },
    retail_social_culturalheritage: {
        ans: "",
    },
    retail_social_featurestory_community: {
        ans: "",
        img: "",
    },
    retail_social_selection: {
        ans: "",
    },
    retail_social_featurestory_commitment: {
        ans: "",
    },
    retail_social_safetymeasures: {
        ans: "",
    },
    retail_social_safety: {
        ans: "",
    },
    retail_social_wellbeing: {
        ans: "",
    },
    retail_social_featurestory_safety: {
        ans: "",
        img: "",
    },
    // Table data starts
    retail_environment_energytable: {
        ans: [],
    },
    retail_environment_watertable: {
        ans: [],
    },
    retail_environment_materialtable: {
        ans: [],
    },
    retail_environment_wastetable: {
        ans: [],
    },
    // Table data ends
};
export default default_retail_states;