<template>
	<q-card flat bordered class="my-card">
		<q-card-section>
			<div class="text-h6">Environment</div>
		</q-card-section>
		<q-separator inset />

		<q-card-section>
			The environment section discloses how business activities and operations
			impact the environment. We provide step-by-step assistance on reporting
			the <b>greenhouse gas(GHG), water, material, and waste </b> impacts, so
			only minimum preparation is needed from you. You will be asked to provide
			the following information:
			<br />
			You will be asked to provide the following information:
			<li>Strategies and commitment to environment practices.</li>
			<li>Supplier selection considering environmental criteria .</li>
			<li>Energy use, water use, and material consumption.</li>
			<li>Waste generation and treatment.</li>
			In some sections, we will need your quantitative data from documents such
			as energy and water bills.<br />
			Examples and templates in this section demonstrate how the content can be
			presented and how it can be a starting point where you edit the example
			based on your company's situation. Whenever possible, please use your
			content and avoid adopting the examples directly.
		</q-card-section>
	</q-card>
</template>
<script>
export default {
	name: "EnvironmentPage",
};
</script>
