const defaultstationarystate = {
    
        "timeperiod": {
                "starttime": 0,
                "endtime": 0
        },
        "location": "",
        "fuel": {
                "category":"",
                "type":"",
                "unit":"",
                "Amount":0,
                "datatype":"",
                "Note":""
        },
        "operation": ""
    
    // Table data ends
};
export default defaultstationarystate;