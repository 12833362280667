<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	
	<div class="container-1">
		<div class="item1" style="border-radius:10px;background-color:rgb(241, 241, 241);">
			<div style="justify-content: center;">
				<img class="photo" src="@/assets/img/anime3.png" alt="Profile Photo" />
				<div style="display: flex;justify-content: center;font-weight:600">
					<div style="width: 150px;text-align:center;">{{formData.FirstName}}{{' '}}{{formData.LastName}}</div>
				</div>
			</div>
		</div>
		<div class="item2" style="border-radius:10px;background-color:rgb(241, 241, 241);">
			<div style="margin-top:5%;margin-left:5%;">
				<div style="display: flex;text-align: left;padding-top:10px">
					<div  style="display: flex;width: 45%;align-items: center;">
						<div style="font-weight:600">First name: </div>
						<div style="padding-left:5px">{{formData.FirstName}}</div>
						<!-- <input class="borderless-input" v-model="formData.FirstName" @blur="saveInputValue"> -->
					</div>
					<div  style="display: flex;width: 50%;align-items: center;">
						<div style="font-weight:600">Last name: </div>
						<div style="padding-left:5px">{{formData.LastName}}</div>
						<!-- <input class="borderless-input" v-model="formData.LastName" @blur="saveInputValue"> -->
					</div>
				</div>
				<div style="display: flex;text-align: left;padding-top:15px">
					<div  style="display: flex;width: 45%;align-items: center;">
						<div style="font-weight:600">Occupation: </div>
						<div style="padding-left:5px">{{formData.Occupation}}</div>
						<!-- <input class="borderless-input" style="width: 100%;" v-model="formData.Occupation" @blur="saveInputValue"> -->
					</div>
					<div  style="display: flex;width: 50%;align-items: center;">
						<div style="font-weight:600">Company Name: </div>
						<div style="padding-left:5px">{{formData.CompanyName}}</div>
						<!-- <input class="borderless-input" v-model="formData.CompanyName" @blur="saveInputValue"> -->
					</div>
				</div>
				<div style="display: flex;text-align: left;padding-top:15px">
					<div  style="display: flex;width: 45%;align-items: center;">
						<div style="font-weight:600">Country: </div>
						<div style="padding-left:5px">{{formData.Country}}</div>
						<!-- <input class="borderless-input" style="width: 100%;" v-model="formData.Country" @blur="saveInputValue"> -->
					</div>
					<div  style="display: flex;width: 50%;align-items: center;">
						<div style="font-weight:600;">Email: </div>
						<div style="padding-left:5px">{{formData.Email}}</div>
						<!-- <input class="borderless-input" style="width: 100%;" v-model="formData.Email" @blur="saveInputValue"> -->
					</div>
				</div>
				<div style="display: flex;text-align: left;padding-top:15px"> 
					<div  style="display: flex;width: 45%;align-items: center;">
						<div style="font-weight:600">Phone: </div>
						<div style="padding-left:5px">{{formData.Phone}}</div>
						<!-- <input class="borderless-input" style="width: 100%;" v-model="formData.Phone" @blur="saveInputValue"> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container-2">
		<!-- <div class="item3" style="border-radius:10px;background-color:rgb(241, 241, 241);">
			<div style="font-size:25px;padding:20px;text-align: left;">Stats</div>
			<div style="margin-left: 20px;font-weight:600">
				<div style="text-align: left;margin:5px">Overall Emission</div>
				<div style="text-align: left;margin:5px">Scope 1 Emission</div>
				<div style="text-align: left;margin:5px">Scope 2 Emission</div>
				<div style="text-align: left;margin:5px">Scope 3 Emission</div>
				<div style="text-align: left;margin:5px">Top Category Emission</div>
			</div>
		</div> -->
		<div class="item4" style="border-radius:10px;background-color:rgb(241, 241, 241);">
			<div style="font-size:25px;padding:10px">Asset Manangment and Measurement</div>
			<q-btn style="width:60%;margin:5px" color="primary" label="Equipment" @click="$router.push('/equipment')"/>
			<q-btn style="width:60%;margin:5px" color="primary" label="Vehicle and Motor Equipment" @click="$router.push('/vehicleprofile')"/>
			<q-btn style="width:60%;margin:5px" color="primary" label="Building" @click="$router.push('/building')"/>
			<q-btn style="width:60%;margin:5px" color="primary" label="Waste" @click="$router.push('/wasteinventory')"/>
			<q-btn style="width:60%;margin:5px" color="primary" label="Purchase Goods and Services" @click="$router.push('/goodsServices')"/>
			<q-btn style="width:60%;margin:5px" color="primary" label="Product" @click="$router.push('/product')"/>
		</div>
	</div>
	<br>
	<br>
	<br>
</template>

<script>
import { ref , onMounted} from 'vue'
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
import axios from "axios";
import ToolBar from "@/components/tools/HomeToolBar.vue";

export default {
	components: {
		ToolBar,
	},
	setup () {
		const { user } = useAuth0()
		const router = useRouter();
		const usercode = JSON.stringify(user.value, null, 2);
		const userid = JSON.parse(usercode)["sub"];
		const collapseStatus = ref(true)

		const formData = ref({
			id: userid,
			userId: userid,
			FirstName: '',
			LastName: '',
			Occupation: '',
			CompanyName: '',
			CompanySize: '',
			Country: '',
			Email: '',
			Phone: ''
		})

		function saveInputValue()
		{
			// console.log("----------")
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			const headers = {};
			const body = {
				form:formData.value
			};
			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postUserDataByUser?id=" +
					userid,
					body,
				{ headers }
			);
		}

		async function userHasData() {
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getUserDataByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		onMounted(async () => {
			const returnUser = await userHasData();

			if(returnUser.data.items === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.items))
			// console.log("databuf",databuf.id)
			if(databuf.submitFlag != true){
				router.push('/Settings')
			}
			if(databuf.id){
				formData.value.id = databuf.id
				formData.value.userId = databuf.userId
				formData.value.FirstName = databuf.FirstName
				formData.value.LastName = databuf.LastName
				formData.value.Occupation = databuf.Occupation
				formData.value.CompanyName = databuf.CompanyName
				formData.value.CompanySize = databuf.CompanySize
				formData.value.Country = databuf.Country
				formData.value.Email = databuf.Email
				formData.value.Phone = databuf.Phone
			}
			// console.log(formData)
		});

		return { 
			collapseStatus,
			formData,
			saveInputValue
		}

	},
}
</script>

<style  lang="less" scoped>
.borderless-input {
	border: none;
	background-color:rgb(241, 241, 241);
	outline: none; /* 可选：移除输入框的默认轮廓样式 */
	padding: 5px; /* 可选：添加一些内边距以改善外观 */
	width: 40%; /* 可选：设置输入框宽度 */
  }
.container-1 {
	display: flex;
	justify-content: center; /* 水平居中 */
	padding-top: 5%;
}
.container-2 {
	display: flex;
	justify-content: center; /* 水平居中 */
	padding-top: 2%;
}

.item1 {
	width: 15%;
	height:200px;
	margin-right: 1%;
	text-align:center
}
.item2 {
	width: 35%;
	height:200px;
	text-align:center
}
.photo {
	margin-top: 20px;
	height: 120px;
	width: 100px;
	border-radius: 10px;

}
.item3 {
	width: 15%;
	height:300px;
	margin-right: 10px;
	text-align:center
}
.item4 {
	width: 51%;
	height:400px;
	text-align:center
}
</style>
