<template>
	<q-card flat bordered class="my-card">
		<q-card-section>
			<div class="text-h6">Social</div>
		</q-card-section>
		<q-separator inset />

		<q-card-section>
			Social responsibility disclosures constitute a significant part of your
			report. The goal is to present how your company impacts society, including
			employees, customers, and communities.:
			<br />
			We invite you to share your stories regarding the following topics:
			<li>Diversity, equity, and inclusion policies and performances.</li>
			<li>Your support of employee's professional growth and employability.</li>
			<li>Actions and activities of giving back to the community.</li>
			<li>Criteria for selecting suppliers.</li>
			<li>Safety, wellness, and health in your workplace.</li>
			We will guide you where good-quality pictures and feature stories are
			needed to highlight your company's responsible behaviors better.
			<br />
			Examples and templates in this section demonstrate how the content can be
			presented and how it can be a starting point where you edit the example
			based on your company's situation. Whenever possible, please use your
			content and avoid adopting the examples directly.
		</q-card-section>
	</q-card>
</template>
<script>
export default {
	name: "SocialPage",
};
</script>
