<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="container">
		<div class="row content-2 justify-center">
			<div class="col-12 text-h5 text-center">Overview Of Our Methodology</div>
			<div class="col-12 detail text-center">
				Below is a concise overview of the methodology applied to calculate the
				carbon footprint within the carbon calculator and sustainability
				reporting tool
			</div>

			<div class="col-12 detail-2 text-center shadow-2">
				Carbon emission = Material/energy amount * Carbon emission factor of the
				specific material/energy <br />
				<br />Total carbon emission: E = ∑ (Ai * Ei) for i = 1 to n
			</div>

			<div class="col-12 detail text-center">
				The emission factors utilized by our company have been carefully
				developed based on rigorous scientific research from multiple reputable
				sources such as ecoinvent and the Environmental Protection Agency (EPA)
				of various countries. While it is possible that these values may differ
				from those used by other organizations or databases, they are generally
				consistent with the emission factors used in rigorous scientific
				assessments. We take great care in selecting and utilizing reliable and
				relevant data to ensure that our calculations accurately reflect the
				environmental impact of our operations.<br />
				Method for modeling recycling: Simple cut-off To obtain the emission
				factors used in our calculations and/or get a detailed understanding of
				the methodology, please do not hesitate to contact us. Here is a
				screenshot displaying a section of the carbon emission factors used to
				calculate electricity consumption within the methodology.
			</div>
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
export default {
	name: "HomePage",
	components: {
		Toolbar,
	},
};
</script>
<style lang="less" scoped>
.leftButton {
	border-radius: 20px;
}
</style>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 480px) {
	.container {
		.content-2 {
			display: flex;
			// justify-content: space-between;
			box-sizing: border-box;
			padding: 50px 8vw;

			.title {
				font-size: 40px;
				font-weight: 600;
				font-family: "Roboto", sans-serif;
				height: 80px;
				margin-top: 20px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-2 {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 600;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.left {
				.title {
					font-size: 25px;
					font-weight: 700;
					// width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 80px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (min-width: 480px) and (max-width: 1200px) {
	.with-bg {
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
	.content-2 {
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		box-sizing: border-box;
		padding: 50px 8vw;
		margin-top: 30px;

		.left {
			margin-bottom: 30px;

			.title {
				font-size: 24px;
				font-weight: 700;
				width: 100% !important;
				color: rgb(13, 12, 34);
			}

			.detail {
				font-size: 18px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 50px;
				width: 100% !important;
				color: #3d3d4e;
			}
		}

		.right {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.item {
				width: 30vw !important;

				.detail {
					font-size: 16px;
					font-weight: 600;
					width: 20vw !important;
					height: 100px;
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (max-width: 480px) {
	.container {
		div {
			font-family: Calibri;
		}

		.content-2 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;
			.title {
				font-size: 21px;
				font-weight: 700;
				margin-top: 20px;
			}

			.left {
				.title {
					font-size: 6vw;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				// display: flex;
				// justify-content: space-between;
				box-sizing: border-box;

				.item {
					margin-top: 50px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						margin-top: 20px;
					}

					.detail {
						margin-top: 20px;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}
	}
	.with-bg {
		border-radius: 5px;
		height: 50px;
		font-size: 18px;
		font-weight: 600;
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
}
</style>
