<template>
	<div id="chart">
		<apexchart
			type="pie"
			width="480"
			:options="chartOptions"
			:series="series"
		></apexchart>
	</div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import { useStore } from "vuex";

export default {
	components: {
		apexchart: VueApexCharts,
	},
	setup() {
		const store = useStore();
		const template = store.state.template;
		var storedata =
			store.state[template][template+"_environment_watertable"].ans;
		const dataArray = JSON.parse(JSON.stringify(storedata));
		var myseries = [];
		var myunits = [];
		var mylabels = [];
		for (let i = 0; i < dataArray.length; i++) {
			let obj = dataArray[i];
			// console.log(obj);
			mylabels.push(obj.operation);
			myunits.push(obj.unit);
			// Transform Liter to Gallon
			var rawamount = parseFloat(obj.amount);
			if (obj.unit.toUpperCase() === "GALLON") {
				myseries.push(rawamount);
			} else {
				myseries.push(rawamount * 0.264172);
			}
		}
		const series = myseries;

		const chartOptions = {
			chart: {
				width: 380,
				type: "pie",
			},
			labels: mylabels,
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],

			title: {
				text: "Water Usage Pie Chart",
				align: "center",
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
					fontSize: "20px",
					fontWeight: "bold",
					fontFamily: "Nunito",
					color: "#263238",
				},
			},
		};
		return {
			series,
			chartOptions,
			dataArray,
		};
	},
};
</script>
