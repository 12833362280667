const default_facctory_states = {
    factory_ourcompany_intro: {
        ans: "",
        img: "",
    },
    factory_ourcompany_suppliers: {
        ans: "",
        img: "",
    },
    factory_ourcompany_stakeholders: {
        ans: "",
        img: "",
    },
    factory_ourcompany_commitment: {
        ans: "",
    },
    factory_ourcompany_featurestory: {
        ans: "",
        img: "",
    },
    factory_economy_activities: {
        ans: "",
        img: "",
    },
    factory_economy_opportunities: {
        ans: "",
        img: "",
    },
    factory_economy_practices: {
        ans: "",
        img: "",
    },
    factory_economy_featurestory: {
        ans: "",
        img: "",
    },
    factory_economy_highlights: {
        ans: "",
        img: "",
    },
    factory_environment_strategy: {
        ans: "",
    },
    factory_environment_suppliers: {
        ans: "",
        img: "",
    },
    factory_environment_carbon: {
        ans: "",
        img: "",
    },
    factory_environment_energyreduction: {
        ans: "",
        img: "",
    },
    factory_environment_wastewater: {
        ans: "",
        img: "",
    },
    factory_environment_waterreduction: {
        ans: "",
    },
    factory_environment_wastetreatment: {
        ans: "",
        img: "",
    },
    factory_environment_materialreduction: {
        ans: "",
        img: "",
    },
    factory_social_diversity: {
        ans: "",
        img: "",
    },
    factory_social_employees: {
        ans: "",
    },
    factory_social_compliance: {
        ans: "",
    },
    factory_social_featurestory_diversity: {
        ans: "",
    },
    factory_social_featurestory_training: {
        ans: "",
    },
    factory_social_community: {
        ans: "",
    },
    factory_social_culturalheritage: {
        ans: "",
    },
    factory_social_featurestory_community: {
        ans: "",
    },
    factory_social_partner: {
        ans: "",
    },
    factory_social_featurestory_commitment: {
        ans: "",
    },
    factory_social_safetymeasures: {
        ans: "",
    },
    factory_social_safety: {
        ans: "",
    },
    factory_social_wellbeing: {
        ans: "",
    },
    factory_social_featurestory_safety: {
        ans: "",
        img: "",
    },
    // Table data starts
    factory_environment_energytable: {
        ans: [],
    },
    factory_environment_watertable: {
        ans: [],
    },
    factory_environment_materialtable: {
        ans: [],
    },
    factory_environment_wastetable: {
        ans: [],
    },
    // Table data ends
};
export default default_facctory_states;