<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="q-pa-md q-gutter-md">
		<q-parallax>
			<template v-slot:media>
				<img
					src="https://images.pexels.com/photos/247676/pexels-photo-247676.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
				/>
			</template>
			<template v-slot:content="scope">
				<div
					class="row justify-center"
					:style="{
						// opacity: 0.45 + (1 - scope.percentScrolled) * 0.55,
						top: scope.percentScrolled * 60 + '%',
						left: 0,
						right: 0,
					}"
				>
					<div class="col-8">
						<div class="text-h4 text-white text-center">
							Schedule Your Free Consultation Today!
						</div>
						<div class="text-body3 text-white text-center">
							We're excited to offer new customers a free 30-minute consultation. 
							To help us prepare and serve you effectively, please pick a topic and 
							provide a short description of what you'd like us to know before our meeting. Thank you!
							<p></p>
						</div>
					</div>
				</div>

				<q-btn
					push
					color="white"
					text-color="black"
					label="Click to schedule"
					@click="openInNewTab"
				/>
			</template>
		</q-parallax>
		<q-separator />
		<div class="row justify-center">
			<div class="col-12">
				<h5 class="text-center">Contact Dynimos</h5>
				<div class="form-container">
					<q-form autofocus @submit="onSubmit" class="q-gutter-md">
						<q-input
							outlined
							ref="nameRef"
							v-model="name"
							type="name"
							label="Your Name"
							counter
							maxlength="10"
							lazy-rules
							:rules="nameRules"
						/>
						<q-input
							outlined
							ref="emailRef"
							v-model="email"
							type="email"
							label="Your Email"
							lazy-rules
							:rules="emailRules"
						/>
						<q-select
							outlined
							v-model="subject"
							:options="options"
							label="Subject"
						/>
						<q-input
							ref="messageRef"
							v-model="message"
							placeholder="Message to Dynimos. (To make our conversation more efficient please provide a brief background of your industry and the products, and describe the specific questions or topics you would like to discuss during our consultation.)"
							filled
							type="textarea"
							lazy-rules
							:rules="messageRules"
						/>
						<q-btn
							style="background: #6c9ad0; color: white"
							icon-right="send"
							type="submit"
							label="Send"
						/>
					</q-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";

import emailjs from "@emailjs/browser";
import { useQuasar } from "quasar";
import { ref } from "vue";

export default {
	name: "ContactForm",
	// the instance state
	components: {
		Toolbar,
	},
	setup() {
		const $q = useQuasar();

		const name = ref();
		const nameRef = ref(null);

		const email = ref();
		const emailRef = ref(null);

		const subject = ref();

		const message = ref();
		const messageRef = ref(null);

		function isValidEmail(val) {
			const emailPattern =
				/^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
			return emailPattern.test(val) || "Invalid email";
		}
		function openInNewTab() {
			window.open("https://calendly.com/dynimos/30min", "_blank", "noreferrer");
		}

		return {
			subject,
			name,
			nameRef,
			nameRules: [(val) => (val && val.length > 0) || "Please type your name"],
			email,
			emailRef,
			emailRules: [
				(val) => !!val || "Please input a valid email",
				isValidEmail,
			],
			options: [
				"Questions regarding greenhouse gas(GHG) calculation ",
				"Questions about ESG profile generation ",
				"Questions about life cycle assessment(LCA)",
				"Questions about GHG inventory establishment and development ",
				"Questions about GHG emission reduction and neutralization projects",
				"Questions about customization of your sustainability reports",
				"Others",
			],
			message,
			messageRef,
			messageRules: [
				(val) => (val && val.length > 0) || "Please type your message",
			],
			isValidEmail,
			openInNewTab,

			onSubmit() {
				// Add send email function
				// emailjs.sendForm("service_kvdgvl1", "template_a8qv4a4", "test").then(
				// 	(result) => {
				// 		console.log("SUCCESS!", result.text);
				// 	},
				// 	(error) => {
				// 		console.log("FAILED...", error.text);
				// 	}
				// );
				try {
					var templateParams = {
						from_name: name.value,
						from_email: email.value,
						message: message.value,
						subject: subject.value,
					};
					emailjs
						.send(
							"service_kvdgvl1",
							"template_a8qv4a4",
							templateParams,
							"iSyOVs__c2cQxNjve"
						)
						.then(
							function (response) {
								console.log("SUCCESS!", response.status, response.text);

								name.value = null;
								email.value = null;
								subject.value = null;
								message.value = null;

								nameRef.value.resetValidation();
								emailRef.value.resetValidation();
								messageRef.value.resetValidation();

								$q.notify({
									color: "primary",
									textColor: "white",
									message:
										"We have received your email. Our staff will get back to you shortly",
								});
							},
							function (error) {
								console.log("FAILED...", error);
							}
						);
				} catch (error) {
					console.log({ error });
				}
			},
		};
	},
};
</script>

<style lang="less" scoped>
@media (min-width: 1200px) {
* {
	box-sizing: border-box;
}
.form-container {
	display: block;
	margin: auto;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #ffffff;
	padding: 20px;
	width: 50%;
}

q-input {
	float: left;
}

q-input[type="name"],
[type="email"],
q-input[type="textarea"] {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

q-btn[type="submit"] {
	background-color: #4caf50;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

q-btn[type="submit"]:hover {
	background-color: #45a049;
}
}
</style>

<style lang="less" scoped>
@media (min-width: 480px) and (max-width: 1200px) {
* {
	box-sizing: border-box;
}
.form-container {
	display: block;
	margin: auto;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #ffffff;
	padding: 20px;
	width: 70%;
}

q-input {
	float: left;
}

q-input[type="name"],
[type="email"],
q-input[type="textarea"] {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

q-btn[type="submit"] {
	background-color: #4caf50;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

q-btn[type="submit"]:hover {
	background-color: #45a049;
}
}
</style>

<style lang="less" scoped>
@media (max-width: 480px) {
* {
	box-sizing: border-box;
}
.form-container {
	display: block;
	margin: auto;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #ffffff;
	padding: 10px;
	width: 90%;
}

q-input {
	float: left;
}

q-input[type="name"],
[type="email"],
q-input[type="textarea"] {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

q-btn[type="submit"] {
	background-color: #4caf50;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

q-btn[type="submit"]:hover {
	background-color: #45a049;
}
}
</style>


