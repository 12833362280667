<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" label="ENVIRONMENTAL IMPACT" />
					<q-tab name="social" class="text-black">
						<b>SOCIAL IMPACT</b>
					</q-tab>
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-sm-7">
							<div class="row justify-evenly">

								<div class="col-sm-6 paddingrow">
									<div class="title text-capitalize">Give Back To Community</div>
									<div class="text-content">
										{{ community }}
									</div>
								</div>
								<div class="col-sm-6 paddingrow bg-light-blue-1">
									<div class="title text-capitalize">Cultural Heritage</div>
									<div class="text-content">
										{{ culturalheritage }}
									</div>
								</div>
							</div>
							<div class="row justify-evenly">
								<div class="col-sm-6 paddingrow bg-pink-1">
									<div class="title text-capitalize">Feature Story</div>
									<div class="text-content">
										{{ featurestorycommunity }}
									</div>
								</div>

								<div class="col-sm-6 paddingrow">
									<div class="title text-capitalize">Feature Story</div>
									<div class="text-content">
										{{ featurestorycommitment }}
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-12 col-sm-12 testrow">
									<div class="title text-capitalize">Partnerships</div>
									<div class="text-content">
										{{ partner }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-4 justify-center">
							<div class="row">
								<div class="col-12 col-sm-12 pic">
									<q-img :src="featurestorycommunityimg" style="fit" />
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-sm-12 pic">
									<q-img :src="featurestorycommitmentimg" style="fit" />
								</div>
							</div>
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from "vuex";
// import HeaderTab from "./components/HeaderTab.vue";
import SlideFooter from "./components/DefaultFooter.vue";

export default {
	name: "CommunityPage",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;
		let partner = ref("");

		const community = store.state[template][template+"_social_community"].ans;
		const culturalheritage = store.state[template][template+"_social_culturalheritage"].ans;
		if(template !== "retail"){
			partner = store.state[template][template+"_social_partner"].ans;
		}else{
			partner = store.state[template][template+"_social_selection"].ans;
		}


		const featurestorycommunity =
			store.state[template][template+"_social_featurestory_community"].ans;
		let featurestorycommunityimg =
			store.state[template][template+"_social_featurestory_community"].img;
			console.log("...................................");
			console.log(featurestorycommunityimg);
		if (!featurestorycommunityimg) {
			featurestorycommunityimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202724/DYNIMOS%20DEFAULT%20PICS/COMMUNITY_SERVICE_mffjpy.jpg";
		}
		const featurestorycommitment =
			store.state[template][template+"_social_featurestory_commitment"].ans;
		let featurestorycommitmentimg =
			store.state[template][template+"_social_featurestory_commitment"].img;
		if (!featurestorycommitmentimg) {
			featurestorycommitmentimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/c_crop,g_east,h_1067,w_1140/v1677202692/DYNIMOS%20DEFAULT%20PICS/pexels-photo-6646918_zz3mts.webp";
		}

		return {
			template,
			community,
			culturalheritage,
			partner,
			featurestorycommunity,
			featurestorycommunityimg,
			featurestorycommitment,
			featurestorycommitmentimg,
		};
	},
};
</script>

<style lang="less" scoped>
.testrow {
	margin-top: 20px;
	padding: 10px 15px;
	border: 5px solid rgba(86, 61, 124, 0.2);
}

.paddingrow {
	padding: 10px 15px;
}
.testrow3 {
	padding: 10px 15px;
	border: 1px solid rgba(86, 61, 124, 0.2);
}

.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
.pic {
	padding: 10px 15px 10px 10px;
}
</style>
