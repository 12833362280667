<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<div style="display: flex;overflow: auto;">
		<div style="position: fixed;align-items: center;margin-left:10px;margin-top:12px;width:12%;height:80vh;border-radius:10px;background-color:rgb(61, 56, 56);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
			<div style="width:80%;font-size:1.6vw;margin-left:7%;color: aliceblue;">
				<div class="box" style="margin-top:30%;" @click="$router.push('/dashboardmain')">Dashboards</div>
				<div>
					<div class="box" @click="$router.push('/dashboardequipment')">Equipment</div>
					<div class="box" @click="$router.push('/dashboardvehicle')">Vehicle</div>
					<div class="box" style="border-radius:10px;background-color:rgba(0, 255, 0, 0.552);" @click="$router.push('/dashboardbuilding')">Building</div>
					<!-- <div class="box" @click="$router.push('/dashboardequipment')">Waste</div> -->
				</div>
			</div>
		</div>
		<div style="position: static;margin-left:16%;margin-top:12px;width:82%;">
			<div style="font-size:3.0vw;padding-bottom:2%">Building</div>
			<div style="width: 80%;padding:10px;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
				<div>
					<div style="font-size:large;margin-bottom:10px">Dropdown button to select time range start -> end, all charts in this dashboard are based on the time range selected</div>
					<div class="p-3 pb-0 card-header">
						<div class="demo-date-picker">
							<div class="block">
								<el-date-picker
									v-model="startTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="Start date"
									transfer
									:size="small"
									@input="isdisplay=false"
									@change="dateRules"
									style="width:120px;"
									/>
								<el-date-picker
									v-model="endTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="End date"
									transfer
									:size="small"
									@input="isdisplay=false"
									@change="dateRules"
									style="width:120px;margin-left:20px"
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6" style="margin: 20px auto;">
				<div class="card z-index-2">
					<div class="p-3 card-body" :key="refresh_indexs" style="border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);" v-if="isdisplay===true"> 
						<div class="chart" style="width: 90%;padding-left:2%">
						<bar-chart
							id="bar-chart"
							:chart="chartdata"
						/>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isdisplay===true">
				
				<div  style="margin-top:10px; border-radius:10px; background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);" >
					<div style="font-size: medium;font-weight:600;padding:20px">Electricity</div>
					<div style="display:flex;justify-content: space-between;">
						<div class="card z-index-2" style="width: 48%;">
							<div class="p-3 card-body" style="width: 90%;padding-top:5%;padding-left:5%">
								<div class="chart">
									<apexchart
										id="pie1"
										type="pie"
										:options="electricity_chart"
										:series="electricity_data"
										align="center"
									/>
								</div>
							</div>
						</div>
						<div class="card z-index-2" style="width: 48%;">
							<div class="p-3 card-body" style="width: 90%;padding-top:8%;padding-left:5%">
								<div style="display: flex;justify-content: space-between;width:50%;margin:0 auto">
									<div><input type="checkbox" name="CO2e" @click="ele_displaychart3('CO2e')">CO2e(kg)</div>
									<div><input type="checkbox" name="CO2" @click="ele_displaychart3('CO2')">CO2(kg)</div>
									<div><input type="checkbox" name="CH4" @click="ele_displaychart3('CH4')">CH4(kg)</div>
									<div><input type="checkbox" name="N2O" @click="ele_displaychart3('N2O')">N2O(kg)</div>
								</div> 
								<div class="chart"  v-if="chart3_refresh===true">
								<bar-chart-horizontal
									id="bar-chart-horizontal1"
									:chart="{
										labels: display_ele_chart3_title,//['CO2e', 'CO2', 'CH4', 'N2O'],
										datasets: {
											data: display_ele_Value, //[12,14,15,15],
										}, 
									}"
								/>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div  style="margin-top:10px;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
					<div style="font-size: medium;font-weight:600;padding:20px">Steam and Heat</div>
					<div style="display:flex;justify-content: space-between;">
						<div class="card z-index-2" style="width: 48%;">
							<div class="p-3 card-body" style="width: 90%;padding-top:5%;padding-left:5%">
								<div class="chart">
									<apexchart
										id="pie2"
										type="pie"
										:options="steamandheat_chart"
										:series="steamandheat_data"
										align="center"
									/>
								</div>
							</div>
						</div>
						<div class="card z-index-2" style="width: 48%;">
							<div class="p-3 card-body" style="width: 90%;padding-top:8%;padding-left:5%">
								<div style="display: flex;justify-content: space-between;width:50%;margin:0 auto">
									<div><input type="checkbox" name="CO2e" @click="sh_displaychart3('CO2e')">CO2e(kg)</div>
									<div><input type="checkbox" name="CO2" @click="sh_displaychart3('CO2')">CO2(kg)</div>
									<div><input type="checkbox" name="CH4" @click="sh_displaychart3('CH4')">CH4(kg)</div>
									<div><input type="checkbox" name="N2O" @click="sh_displaychart3('N2O')">N2O(kg)</div>
								</div> 
								<div class="chart"  v-if="chart3_refresh===true">
								<bar-chart-horizontal
									id="bar-chart-horizontal2"
									:chart="{
										labels: display_sh_chart3_title,//['CO2e', 'CO2', 'CH4', 'N2O'],
										datasets: {
											data: display_sh_Value, //[12,14,15,15],
										}, 
									}"
								/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br>
			<br>
			<br>
		</div>
	</div>

</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
// import { useQuasar } from "quasar";
// import {  VxeTableInstance } from 'vxe-table'
import axios from "axios";
// import AllConuntryStates from "@/components/formdata/allcountries.json";
// import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
import BarChart from "./components/BarChart.vue";
import BarChartHorizontal from "./components/BarChartHorizontal.vue";
import VueApexCharts from "vue3-apexcharts";
import reactive1 from "./reactive.js"

export default {
	components: {
		ToolBar,
		BarChart,
		BarChartHorizontal,
		apexchart: VueApexCharts,
	},
	setup () {
		// const xTable = ref(VxeTableInstance)
		const tableData = []
		const ConvertValue = ref("")
		var kg_CO2e = [],
			kg_CO2 = [],
			g_CH4 = [],
			g_N2O = [];
		const { user } = useAuth0()

		const startTime = ref()
		const endTime = ref()

		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
		const currentDay = String(today.getDate()).padStart(2, '0');
		endTime.value  = `${currentYear}-${currentMonth}-${currentDay}`;

		const oneYearAgo = new Date();
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
		const oneYearAgoYear = oneYearAgo.getFullYear();
		const oneYearAgoMonth = String(oneYearAgo.getMonth() + 1).padStart(2, '0');
		const oneYearAgoDay = String(oneYearAgo.getDate()).padStart(2, '0');
		startTime.value = `${oneYearAgoYear}-${oneYearAgoMonth}-${oneYearAgoDay}`;

		const electricity_data = reactive([])
		const electricity_option = reactive([])
		const steamandheat_data = reactive([])
		const steamandheat_option = reactive([])
		var all_chart3_title = reactive1(['CO2e', 'CO2', 'CH4', 'N2O'])//['CO2e', 'CO2', 'CH4', 'N2O'],
		var electricity_allValue = reactive1([0,0,0,0])
		var steamandheat_allValue = reactive1([0,0,0,0])
		var display_ele_chart3_title = reactive1([])//['CO2e', 'CO2', 'CH4', 'N2O'],
		var display_ele_Value = reactive1([])
		var display_sh_chart3_title = reactive1([])//['CO2e', 'CO2', 'CH4', 'N2O'],
		var display_sh_Value = reactive1([])
		const electricity_chart = {
			chart: {
				width: 480,
				type: "pie",
			},
			labels: electricity_option,//["Operation1", "Operation2", "Operation3", "Operation4", "Operation5"],
			legend: {
				show: true,
				showForSingleSeries: false,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: "top",
				horizontalAlign: "center",
				fontSize: "12px",
				fontFamily: "Helvetica, Arial",
				fontWeight: 400,
				itemMargin: {
					horizontal: 15,
					vertical: 0,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, val.toFixed(2) + "%"];
				},
			},
		}
		const steamandheat_chart = {
			chart: {
				width: 480,
				type: "pie",
			},
			labels: steamandheat_option,//["Operation1", "Operation2", "Operation3", "Operation4", "Operation5"],
			legend: {
				show: true,
				showForSingleSeries: false,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: "top",
				horizontalAlign: "center",
				fontSize: "12px",
				fontFamily: "Helvetica, Arial",
				fontWeight: 400,
				itemMargin: {
					horizontal: 15,
					vertical: 0,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, val.toFixed(2) + "%"];
				},
			},
		}
		const rowNum = ref()
		// var temp_city,temp_country
		var isDisabled = false;
		var isdisplay = ref(false)
		var chart3_refresh = ref(false)
		const refresh_indexs = ref(0)
		let CO2e_value = reactive1([])
		let CO2_value = reactive1([])
		let CH4_value = reactive1([])
		let N2O_value = reactive1([])
		let charts1_title = reactive1([])//'五', '六', '七', '八', '九', '十'
		const charts1_data = reactive1([
							{
								label: 'CH4',
								data: CH4_value,
							},{
								label: 'N2O',
								data: N2O_value,
							},{
								label: 'CO2',
								data: CO2_value,
							},{
								label: 'CO2e',
								data: CO2e_value,
							}
							])

		const chartdata = reactive1({
			labels: charts1_title,//['16-20', '21-25', '26-30', '31-36', '36-42', '42+'],
			datasets: charts1_data
		})	

		const ele_co2e_selected = ref(false)
		const ele_co2_selected = ref(false)
		const ele_ch4_selected = ref(false)
		const ele_n2o_selected = ref(false)
		const sh_co2e_selected = ref(false)
		const sh_co2_selected = ref(false)
		const sh_ch4_selected = ref(false)
		const sh_n2o_selected = ref(false)
		var buildingconfigData = ([])

		function getBuildingName(buildingcondfigname) {
			// let temp_Factor;
			if (buildingcondfigname === "") {
				console.error("selected val is null");
				return null;
			} else {
				var	source = buildingconfigData.filter((x) => x.buildingprofile_name === buildingcondfigname);
				if (source.length >= 1) {
					return source[0].building;
				} else {
					console.error("Failed to find the step", buildingcondfigname);
					return null;
				}
			}
		}

		async function getBuildingConfigData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorBuildingProfileByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}

		async function getElectricityData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"electricity"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		async function getSteamandHeatData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"steamandheat"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		
		onMounted(async () => {
			const temp_buildingconfigData = await getBuildingConfigData();
			const electricityData = await getElectricityData();
			const steamandHeatData = await getSteamandHeatData();

			buildingconfigData = JSON.parse(JSON.stringify(temp_buildingconfigData.data.buildingprofile))

			if(electricityData.data.operatingData != undefined)
			{
				const databuf1= JSON.parse(JSON.stringify(electricityData.data.operatingData))
				for(let i=0;i<databuf1.length;i++)
				{
					const buildingname = getBuildingName(databuf1[i].buildingprofile_name)
					if(buildingname !== null) {
						const table_arry=reactive([])
						table_arry.push({
							type: "electricity",
							buildingname: buildingname,
							timeperiod:{
								starttime: databuf1[i].timeperiod.starttime,
								endtime: databuf1[i].timeperiod.endtime
							},
							co2e: databuf1[i].co2e,
							co2: databuf1[i].co2,
							ch4: databuf1[i].ch4,
							n2o: databuf1[i].n2o,
						})							
						rowNum.value += 1 
						tableData.push(table_arry[0])
					}
				}
			}
			if(steamandHeatData.data.operatingData != undefined)
			{
				const databuf2 = JSON.parse(JSON.stringify(steamandHeatData.data.operatingData))
				for(let i=0;i<databuf2.length;i++)
				{
					// buildingname = buildingconfigData.filter((x) => x.buildingprofile_name === databuf2[i].buildingprofile_name);
					const buildingname = getBuildingName(databuf2[i].buildingprofile_name)
					if(buildingname !== null) {
						const table_arry=reactive([])
						table_arry.push({
							type: "steamandheat",
							buildingname: buildingname,
							timeperiod:{
								starttime: databuf2[i].timeperiod.starttime,
								endtime: databuf2[i].timeperiod.endtime
							},
							co2e: databuf2[i].co2e,
							co2: databuf2[i].co2,
							ch4: databuf2[i].ch4,
							n2o: databuf2[i].n2o,
						})							
						rowNum.value += 1 
						tableData.push(table_arry[0])
					}
					
				}
			}
			charts_calc(startTime.value ,endTime.value)
			// console.log("tableData",tableData)
		});

		function charts_calc(start,end){
			if(start < end){
				if(isDisabled === false){
					CO2e_value.length = 0
					CO2_value.length = 0
					CH4_value.length = 0
					N2O_value.length = 0
					charts1_title.length = 0
					let i=0,j=0,k=0,m=0
					let month_len = 0
					var xTabledata = tableData//reactive()
					isdisplay.value = true
					chart3_refresh.value = true
					isDisabled = true
					electricity_option.length = 0
					electricity_data.length = 0
					steamandheat_option.length = 0
					steamandheat_data.length = 0
					// console.log("------------>charts_calc start")
					let input_start_year = parseInt(start.substring(0,4))
					let input_start_month = parseInt(start.substring(5,7))
					let input_end_year = parseInt(end.substring(0,4))
					let input_end_month = parseInt(end.substring(5,7))
					for(i=0;i<xTabledata.length;i++)
					{
						let include_operation = 0

						let table_start_year = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(0,4))
						let table_start_month = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(5,7))
						let table_end_year = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(0,4))
						let table_end_month = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(5,7))
						
						if(table_end_year > table_start_year)
						{
							month_len = (table_end_year - table_start_year - 1)*12 + (12 + table_end_month - table_start_month) + 1    //防止小数月份减大数月份
						}else{
							month_len = (table_end_year - table_start_year) * 12 + (table_end_month - table_start_month) + 1
						}
						
						// console.log("month_len=%d",month_len)
						if(isNaN(month_len)){
							return
						}
						
						for(j=0;j<month_len;j++)
						{
							let comp_yeas = parseInt(table_start_year)
							let comp_month = parseInt(table_start_month+j)
							if(comp_month>12) {
								comp_month -= 12
								comp_yeas +=1
							}
							if((comp_yeas > input_start_year && comp_yeas < input_end_year)||
								(comp_yeas === input_start_year && comp_yeas < input_end_year && comp_month >= input_start_month)||
								(comp_yeas === input_start_year && comp_yeas === input_end_year && comp_month >= input_start_month && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas > input_start_year && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas === input_start_year && comp_month >= input_start_month && comp_month <= input_end_month))
							{			
								include_operation = 1	
								var comp_date = comp_yeas.toString() + "/" + comp_month.toString()	
								let index = charts1_title.length
								let min_time_flag = 0		
								for(k=0;k<charts1_title.length;k++){
									let title_year = parseInt(charts1_title[k].substring(0,4))
									let title_month = parseInt(charts1_title[k].substring(5,7))
									if(charts1_title[k] === comp_date)  
									{
										// console.log("------->comp_date",comp_date)
										CO2e_value[k] += parseInt(xTabledata[i].co2e/month_len)
										CO2_value[k] += parseInt(xTabledata[i].co2/month_len)
										CH4_value[k] += parseInt(xTabledata[i].ch4/1000/month_len)
										N2O_value[k] += parseInt(xTabledata[i].n2o/1000/month_len)
										break
									}else if((comp_yeas<title_year || (comp_yeas===title_year && comp_month<=title_month)) && min_time_flag===0){
										index = k
										min_time_flag = 1
										break
									}
								} 
								if(k===index){
									CO2e_value.splice(index,0,parseInt(xTabledata[i].co2e/month_len))
									CO2_value.splice(index,0,parseInt(xTabledata[i].co2/month_len))
									CH4_value.splice(index,0,parseInt(xTabledata[i].ch4/1000/month_len))
									N2O_value.splice(index,0,parseInt(xTabledata[i].n2o/1000/month_len))
									charts1_title.splice(index,0,comp_date)			
								}
								if(xTabledata[i].type === "electricity")
								{
									electricity_allValue[0] += parseInt(xTabledata[i].co2e)
									electricity_allValue[1] += parseInt(xTabledata[i].co2)
									electricity_allValue[2] += parseInt(xTabledata[i].ch4*25)
									electricity_allValue[3] += parseInt(xTabledata[i].n2o*298)
								}else if(xTabledata[i].type === "steamandheat"){
									steamandheat_allValue[0] += parseInt(xTabledata[i].co2e)
									steamandheat_allValue[1] += parseInt(xTabledata[i].co2)
									steamandheat_allValue[2] += parseInt(xTabledata[i].ch4*25)
									steamandheat_allValue[3] += parseInt(xTabledata[i].n2o*298)
								}
							}
						}
						if(include_operation)
						{
							if(xTabledata[i].type === "electricity")
							{
								// console.log(electricity_option.length)
								if(electricity_option.length===0){
									
									electricity_option.push(xTabledata[i].buildingname)
									electricity_data[0] = 1;
								}else{
									for(m=0;m<electricity_option.length;m++)
									{
										if(xTabledata[i].buildingname === electricity_option[m])
										{
											electricity_data[m] += 1 
											break
										}
									}
									if(m===electricity_option.length){
										electricity_option.push(xTabledata[i].buildingname)
										electricity_data[m] = 1
									}
								}
							}
							else if(xTabledata[i].type === "steamandheat"){
								if(steamandheat_option.length===0){
									steamandheat_option.push(xTabledata[i].buildingname)
									steamandheat_data[0] = 1;
								}else{
									for(m=0;m<steamandheat_option.length;m++)
									{
										if(xTabledata[i].buildingname === steamandheat_option[m])
										{
											steamandheat_data[m] += 1 
											break
										}
									}
									if(m===steamandheat_option.length){
										steamandheat_option.push(xTabledata[i].buildingname)
										steamandheat_data[m] = 1
									}
								}
							}
						}
					}
					refresh_indexs.value ++
					if(ele_co2e_selected.value === false)
					{
						display_ele_chart3_title.push(all_chart3_title[0])
						display_ele_Value.push(electricity_allValue[0])
					}
					if(ele_co2_selected.value === false)
					{
						display_ele_chart3_title.push(all_chart3_title[1])
						display_ele_Value.push(electricity_allValue[1])
					}
					if(ele_ch4_selected.value === false)
					{
						display_ele_chart3_title.push(all_chart3_title[2])
						display_ele_Value.push(electricity_allValue[2])
					}
					if(ele_n2o_selected.value === false)
					{
						display_ele_chart3_title.push(all_chart3_title[3])
						display_ele_Value.push(electricity_allValue[3])
					}
					if(sh_co2e_selected.value === false)
					{
						display_sh_chart3_title.push(all_chart3_title[0])
						display_sh_Value.push(steamandheat_allValue[0])
					}
					if(sh_co2_selected.value === false)
					{
						display_sh_chart3_title.push(all_chart3_title[1])
						display_sh_Value.push(steamandheat_allValue[1])
					}
					if(sh_ch4_selected.value === false)
					{
						display_sh_chart3_title.push(all_chart3_title[2])
						display_sh_Value.push(steamandheat_allValue[2])
					}
					if(sh_n2o_selected.value === false)
					{
						display_sh_chart3_title.push(all_chart3_title[3])
						display_sh_Value.push(steamandheat_allValue[3])
					}
					setTimeout(()=>{
						isDisabled = false
					},200)
				}			
			}
		}
		
		function ele_displaychart3(value){
			chart3_refresh.value = false
			// console.log("ele_displaychart3",value)
			// console.log("isdisplay",isdisplay)
			
			if(value === 'CO2e')
			{
				if(ele_co2e_selected.value === true) ele_co2e_selected.value = false
				else                             ele_co2e_selected.value = true
			}
			else if(value === 'CO2')
			{
				if(ele_co2_selected.value === true) ele_co2_selected.value = false
				else                            ele_co2_selected.value = true
			}
			else if(value === 'CH4')
			{
				if(ele_ch4_selected.value === true) ele_ch4_selected.value = false
				else                            ele_ch4_selected.value = true
			}
			else if(value === 'N2O')
			{
				if(ele_n2o_selected.value === true) ele_n2o_selected.value = false
				else                            ele_n2o_selected.value = true
			}
			display_ele_chart3_title.length = 0
			display_ele_Value.length = 0
			if(ele_co2e_selected.value === false)
			{
				display_ele_chart3_title.push(all_chart3_title[0])
				display_ele_Value.push(electricity_allValue[0])
			}
			if(ele_co2_selected.value === false)
			{
				display_ele_chart3_title.push(all_chart3_title[1])
				display_ele_Value.push(electricity_allValue[1])
			}
			if(ele_ch4_selected.value === false)
			{
				display_ele_chart3_title.push(all_chart3_title[2])
				display_ele_Value.push(electricity_allValue[2])
			}
			if(ele_n2o_selected.value === false)
			{
				display_ele_chart3_title.push(all_chart3_title[3])
				display_ele_Value.push(electricity_allValue[3])
			}
			// isdisplay.value = true
			setTimeout(()=>{
				// console.log("setTimeout")
				chart3_refresh.value = true
			},0.5)
			
		}
		function sh_displaychart3(value){
			chart3_refresh.value = false
			
			if(value === 'CO2e')
			{
				if(sh_co2e_selected.value === true) sh_co2e_selected.value = false
				else                             sh_co2e_selected.value = true
			}
			else if(value === 'CO2')
			{
				if(sh_co2_selected.value === true) sh_co2_selected.value = false
				else                            sh_co2_selected.value = true
			}
			else if(value === 'CH4')
			{
				if(sh_ch4_selected.value === true) sh_ch4_selected.value = false
				else                            sh_ch4_selected.value = true
			}
			else if(value === 'N2O')
			{
				if(sh_n2o_selected.value === true) sh_n2o_selected.value = false
				else                            sh_n2o_selected.value = true
			}
			display_sh_chart3_title.length = 0
			display_sh_Value.length = 0
			if(sh_co2e_selected.value === false)
			{
				display_sh_chart3_title.push(all_chart3_title[0])
				display_sh_Value.push(steamandheat_allValue[0])
			}
			if(sh_co2_selected.value === false)
			{
				display_sh_chart3_title.push(all_chart3_title[1])
				display_sh_Value.push(steamandheat_allValue[1])
			}
			if(sh_ch4_selected.value === false)
			{
				display_sh_chart3_title.push(all_chart3_title[2])
				display_sh_Value.push(steamandheat_allValue[2])
			}
			if(sh_n2o_selected.value === false)
			{
				display_sh_chart3_title.push(all_chart3_title[3])
				display_sh_Value.push(steamandheat_allValue[3])
			}
			// isdisplay.value = true
			setTimeout(()=>{
				// console.log("setTimeout")
				chart3_refresh.value = true
			},0.5)
			
		}
		return { 
			tableData,
			kg_CO2e,
			kg_CO2,
			g_CH4,
			g_N2O,
			ConvertValue,
			miniState: ref(true),
			startTime,
			endTime,
			electricity_data,
			steamandheat_data,
			electricity_chart,	
			steamandheat_chart,
			CO2e_value,
			CO2_value,
			CH4_value,
			N2O_value,
			charts1_title,
			charts1_data,
			chartdata,
			charts_calc,
			refresh_indexs,
			all_chart3_title,
			electricity_allValue,
			steamandheat_allValue,
			display_ele_chart3_title,
			display_ele_Value,
			display_sh_chart3_title,
			display_sh_Value,
			isdisplay,
			chart3_refresh,
			ele_co2e_selected,
			ele_co2_selected,
			ele_ch4_selected,
			ele_n2o_selected,
			sh_co2e_selected,
			sh_co2_selected,
			sh_ch4_selected,
			sh_n2o_selected,
			ele_displaychart3,
			sh_displaychart3
		}
	},

	methods: {
		dateRules(){
			// console.log(this.startTime,this.endTime)
			if(this.startTime > this.endTime) {

				this.$q.notify({
					color: "red-5",
					textColor: "white",
					icon: "warning",
					message: "Start date must be earlier than end date",
				});
				
			}else{
				this.charts_calc(this.startTime,this.endTime);
			}
		},
	},
	

}
</script>

<style scoped>
.box {
	display: flex;
	height: 40px;
	font-size:1.4vw;
	padding-left:7%;
	align-items: center;
	margin-top: 3px;
	border-radius:10px;
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */
  }

  .box:hover {
	
    box-shadow: 0px 0px 10px rgba(214, 213, 213, 0.678); /* 鼠标移入时添加阴影效果 */
  }
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: left;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
.delete-line {
	text-decoration: line-through;
  }
</style>
