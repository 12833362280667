<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/building')">
					<q-item-section avatar>
						<q-icon name="apartment" />
					</q-item-section>

					<q-item-section>
						<div>	Building management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/buildingconfig')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Building Config management</div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="$router.push('/electricityusage')">
					<q-item-section avatar>
						<q-icon name="power" />
					</q-item-section>

					<q-item-section>
					<div>	Electricity Usage Management </div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="$router.push('/steamandheat')">
					<q-item-section avatar>
						<q-icon name="local_fire_department" />
					</q-item-section>

					<q-item-section>
						<div>	Steam and Heat Usage Management </div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>
		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Steam and Heat Usage Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>This section is designed to compile a detailed inventory of gas (by mmBtu) for each building configuration you defined in “Building Configuration Management”. Please record the usage of gas for a specified period.</div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertSteamandHeatUsage()">Add Steam and Heat Usage</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
							<!-- <vxe-button @click="saveProgress()">SAVE</vxe-button> -->
						</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						header-align="center"
						:auto-resize="true"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column type="checkbox" width="60"></vxe-column>
						<vxe-column field="buildingprofile_name" title="Building Config ">
							<template #default="{ row }">
								<vxe-select v-model="row.buildingprofile_name" transfer placeholder="Please select">
									<vxe-option v-for="item in buildingprofile_option" :key="item.buildingprofile_name" :value="item.buildingprofile_name" :label="item.buildingprofile_name"></vxe-option>
								</vxe-select>
							</template>
						</vxe-column>
						<vxe-column field="timeperiod" title="Time Period" :span="12" :edit-render="{}">
							<template #default="{ row }">
								<span v-if="row.timeperiod.starttime !=='' || row.timeperiod.endtime !==''">{{ row.timeperiod.starttime }}{{ "-" }}{{ row.timeperiod.endtime }}</span>
							</template>
							<template #edit="{ row }">
								<div class="demo-date-picker">
									<div class="block">
										<el-date-picker
											v-model="row.timeperiod.starttime"
											type="date"
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
											placeholder="Start date"
											:size="small"
											style="width:50%"
											/>
										<el-date-picker
											v-model="row.timeperiod.endtime"
											type="date"
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
											placeholder="End date"
											:size="small"
											style="width:50%"
											/>
									</div>
								</div>
							</template>
						</vxe-column>
						<vxe-column field="amount" title="Amount(mmBtu)" :span="12" :item-render="{}">
							<template #default="{ row }">
							<vxe-input v-model="row.amount" type="number" placeholder="Enter Amount" @change="caclfunc(row)"></vxe-input>
							</template>
						</vxe-column>
						<vxe-colgroup title="GHG Emissions">
								<vxe-column field="co2" title="CO2 (kg)">
									<template #default="{ row }">
										<div>{{row.co2}}</div>
									</template>
								</vxe-column>
								<vxe-column field="ch4" title="CH4 (g)">
									<template #default="{ row }">
										<div>{{row.ch4}}</div>
									</template>
								</vxe-column>
								<vxe-column field="n2o" title="N2O (g)">
									<template #default="{ row }">
										<div>{{row.n2o}}</div>
									</template>
								</vxe-column>
								<vxe-column field="co2e" title="CO2e (kg)">
									<template #default="{ row }">
										<div>{{row.co2e}}</div>
									</template>
								</vxe-column>
						</vxe-colgroup>
					</vxe-table>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import AllConuntryStates from "@/components/formdata/allcountries.json";
import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
import stepFactor from "../carbondata/factortype.json"

import Guid from 'guid'

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const AllCityStates = ref()
		const rowNum = ref();
		// var temp_city,temp_country
		// const ConvertValue = ref("")	
		const buildingprofile_option = []

		const countryChangeEvent = (row) => {
				// Update State list and iso3
				AllConuntryStates.map((item) => {
					// console.log(row.location_county)
					// console.log(item.name)
					if (row.location_county === item.name) {
						console.log("Found selected country from all countries json");
						row.iso3 = item.iso3;
						if (!item.states.length) {
							console.log(
								"No status or province in country:" +
									item.name +
									" Using country as state"
							);
							const tmpState = {
								name: item.name,
								state_code: item.iso3,
							};
							row.state = tmpState;
							row.allStates.push(tmpState);
						} else {
							// row.state = Object.create(item.states[0]);
							// console.log(item.states)
							row.location_city = ''
							AllCityStates.value = item.states//Object.create(item.states);
							// console.log(AllCityStates)
							return
						}
					}
				});
		}
		
		const cityChangeEvent = (row) => {
			var source = AllConuntryStates.filter((x) => x.name === row.location_county);
			var source1 = source[0].states.filter((x) => x.name === row.location_city);
			row.location = source[0].iso3 + "_" + source1[0].state_code
		}
		
		// function cityBycode(code){
		// 	// console.log("cityBycode")
		// 	var ncode = code.split('_')
		// 	var source = AllConuntryStates.filter((x) => x.iso3 === ncode[0]);
		// 	// console.log(source)
		// 	if(source.length !== 0){
		// 		temp_country = source[0].name
		// 		if(source[0].states.length !== 0){
		// 			var source1 = source[0].states.filter((x) => x.state_code === ncode[1]);
		// 			temp_city = source1[0].name
		// 		}
		// 	}
		// }
				
		function getFuelType(FuelTypeStr){
			if (FuelTypeStr == null) {
				console.error("selected val is null");
			} else {
				var source = stepSource.filter((x) => x.value === FuelTypeStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", FuelTypeStr);
					return null;
				}
			}
		}
		function getCategory(categoryStr) {
			if (categoryStr == null || categoryStr == "" ) {
				console.error("selected val is null");
			} else {
				// console.log(categoryStr)
				// console.log(stepSource)
				var source = stepSource[0].children.filter((x) => x.label === categoryStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].suboptions);
					return source[0].suboptions;
				} else {
					console.error("Failed to find the step", categoryStr);
					return null;
				}
			}
		}
		// function getBuildingConfig(buildingconfig){
		// 	if (buildingconfig == null) {
		// 		console.error("selected val is null");
		// 	} else {
		// 		var source = buildingprofile_option.filter((x) => x.buildingprofile_name === buildingconfig);
		// 		if (source.length >= 1) {
		// 			// console.log(source.length );
		// 			// console.log(source[0]);
		// 			return source[0];
		// 		} else {
		// 			console.error("Failed to find the step", buildingconfig);
		// 			return null;
		// 		}
		// 	}
		// }

		const insertSteamandHeatUsage = async (row) => {
			// console.log("insertEvent",row)
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			const $table = xTable.value
			const get_Guid = Guid.raw()
			if ($table) {
				const record = {
					transactionId: get_Guid,
					id: get_Guid,
					userId: userid,
					transactionType: "steamandheat",
					scope: 0x22,
					buildingprofile_name:'',
					timeperiod: {
						starttime: '',
						endtime: ''
					},
					amount: '',
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'buildingprofile_name')

				const headers = {};
				const body = {
					operatingData: record
				};
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
						record.transactionId,
						body,
					{ headers }
				);

				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		function remove_rowData(id)
		{
			const headers = {};
			let response = axios.delete(
				"https://testdynimosazure.azurewebsites.net/api/deleteCalculatorOperatingByGuid?id=" +
				id,
				{ headers }
			);
			return response
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				const selectRecords = $table.getCheckboxRecords()
				for(let i=0;i<selectRecords.length;i++)
				{
					if (remove_rowData(selectRecords[i].id)) 
					{
						$table.removeCheckboxRow()
					}
				}
				// itemsValue()
				// saveProgress()
			}
		}

		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {

				var	source = stepFactor.filter((x) => x.name === Ftype);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}
		// function getHHV(fueltype_t) {
		// 	return parseFloat(getFactor(fueltype_t).value[0].hhv);
		// }
		// function calculate(data){
		// 	var temp_data = 0;
		// 	var cov = 1;
		// 	var co2 = 0,
		// 		ch4 = 0,
		// 		n2o = 0; 
		// 	temp_data = getFactor(data.equipment);

		// 	if (temp_data === null) {
		// 		console.log("-------->error");
		// 		return 0;
		// 	} else {
		// 		if (data.unit === "2") {
		// 			cov = (ConvertValue.value > 0 ? ConvertValue.value : getHHV(data.fuelcategory) );
		// 		}

		// 		co2 = parseFloat(temp_data.value[1].co2);
		// 		ch4 = parseFloat(temp_data.value[2].ch4);
		// 		n2o = parseFloat(temp_data.value[3].n2o);

		// 		data.co2e = (cov * data.amount * (co2 + ch4 * 25/1000  + n2o * 298/1000)).toFixed(2);
		// 		data.co2 = (cov * data.amount * co2).toFixed(2);
		// 		data.ch4 = (cov * data.amount * ch4).toFixed(2);
		// 		data.n2o = (cov * data.amount * n2o).toFixed(2);
		// 	}
		// 	return data.co2e+data.co2+data.ch4+data.n2o;//kg_CO2e.toFixed(2);
		// }

		// async function getItemsData() {
		// 	const usercode = JSON.stringify(user.value, null, 2);
		// 	const userid = JSON.parse(usercode)["sub"];
		// 	let response = await axios.get(
		// 		"https://testdynimosazure.azurewebsites.net/api/getCalculatorDataByUser?id=" + userid,
		// 		{
		// 			headers: {},
		// 		}
		// 	);
		// 	if (response) {
		// 		return response;
		// 	}
			
		// 	return null;
		// }

		function caclfunc(row)
		{
			var co2 = 0,
				ch4 = 0,
				n2o = 0
			if(row.buildingprofile_name != "")
			{
				// const buildingprofile = getBuildingConfig(row.buildingprofile_name)
				const tempFactor = getFactor("Natural Gas Fuel")
				// console.log("test...................",buildingprofile,tempFactor)
				co2 = parseFloat(tempFactor.value[0].co2);
				ch4 = parseFloat(tempFactor.value[1].ch4);
				n2o = parseFloat(tempFactor.value[2].n2o);
				// console.log(co2)
				// console.log(ch4)
				// console.log(n2o)
				row.co2e = (row.amount * (co2 + ch4*25/1000 + n2o*298/1000)).toFixed(2);
				row.co2 = (row.amount * co2).toFixed(2);
				row.ch4 = (row.amount * ch4).toFixed(2);
				row.n2o = (row.amount * n2o).toFixed(2);
				row.scope = 0x22
				const headers = {};
				const body = {
					operatingData: row,
				};
				console.log(row)
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
					row.id,
						body,
					{ headers }
				);
				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
				// saveProgress()
			}

		}

		async function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			const headers = {};
			const body = {
				steamheat_items: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
			};

			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"steamandheat"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		async function getBuildingProfileData() {
            const usercode = JSON.stringify(user.value, null, 2);
            const userid = JSON.parse(usercode)["sub"];
            setTimeout(()=>{
                // console.log("setTimeout")
                if(loading.value === true)
                {
                    //router.push('/home')
                }
                
            },10000)
            let response = await axios.get(
                "https://testdynimosazure.azurewebsites.net/api/getCalculatorBuildingProfileByUser?id=" + userid,
                {
                    headers: {},
                }
            );
            if (response) {
                return response;
            }
            
            return null;
        }
		onMounted(async () => {
			const returnUser = await userHasData();
			const tempebuildingprofile = await getBuildingProfileData();
			loading.value = false
			if(tempebuildingprofile.data.buildingprofile === undefined) return
			for(let i=0;i<tempebuildingprofile.data.buildingprofile.length;i++)
            {
				if(tempebuildingprofile.data.buildingprofile[i].powersource_config == "Heat and steam")
				{
					buildingprofile_option.push(tempebuildingprofile.data.buildingprofile[i])
				}
            }
			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			for(let i=0;i<databuf.length;i++){
				// if(typeof(databuf[i].buildingprofile_name) !== "undefined") 
				{
					const table_arry=reactive([])
					// cityBycode(databuf[i].location)
					table_arry.push({
						transactionId: databuf[i].transactionId,
						id: databuf[i].id,
						userId: databuf[i].userId,
						transactionType: databuf[i].transactionType,
						scope: databuf[i].scope,
						buildingprofile_name: databuf[i].buildingprofile_name,
						timeperiod:{
							starttime: databuf[i].timeperiod.starttime,
							endtime: databuf[i].timeperiod.endtime
						},
						amount: databuf[i].amount,
						co2e: databuf[i].co2e,
						co2: databuf[i].co2,
						ch4: databuf[i].ch4,
						n2o: databuf[i].n2o
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			loading,
			buildingprofile_option,
			tableData,
			formRules,
			miniState: ref(true),
			AllConuntryStates,
			countryChangeEvent,
			cityChangeEvent,
			getFuelType,
			getCategory,
			caclfunc,
			removeSelectRowEvent,
			insertSteamandHeatUsage,
			saveProgress,
			AllCityStates,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>
.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>