<template>
	<q-header elevated class="glossy">
		<AppToolbar />
	</q-header>
	<div>
		<q-splitter v-model="splitterModel" style="height: 1000px">
			<template v-slot:before>
				<div class="q-pa-md">
					<div>
						<div class="q-gutter-sm text-center">
							<q-btn
								style="background: #6c9ad0; color: black"
								@click="showStatus"
								label="Progress Dashboard"
							/>
						</div>
					</div>
					<div>
						<q-tree
							:nodes="steptree"
							node-key="value"
							label-key="label"
							selected-color="primary"
							v-model:selected="selected"
							default-expand-all
						/>
					</div>
				</div>
			</template>

			<template v-slot:after>
				<q-tab-panels
					v-model="selected"
					animated
					transition-prev="jump-up"
					transition-next="jump-up"
				>
					<q-tab-panel name="show-status-tab">
						<FormStatus />
					</q-tab-panel>
					<!-- first level tab start -->
					<q-tab-panel name="general-ourcompany">
						<OurCompany />
					</q-tab-panel>
					<q-tab-panel name="general-economy">
						<Economy />
					</q-tab-panel>
					<q-tab-panel name="general-environment">
						<Environment />
					</q-tab-panel>
					<q-tab-panel name="general-social">
						<Social />
					</q-tab-panel>
					<!-- first level tab end -->

					<q-tab-panel
						v-for="(tab, index) in multiSubAnswersWithChipTabs"
						:key="index"
						:name="tab"
					>
						<BasicStepWithChip
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							:sampleanswers="getSampleAnswers(selected)"
							:charlimit="getCharactersCount(selected)"
							:step="tab"
						/>
					</q-tab-panel>

					<q-tab-panel
						v-for="(tab, index) in singleAnswerTabs"
						:key="index"
						:name="tab"
					>
						<BasicStepSingleAnswer
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							:sampleanswer="getSampleAnswers(selected)"
							:picturelabel="getPicLabel(selected)"
							:pictureratio="getPicRatio(selected)"
							:charlimit="getCharactersCount(selected)"
							:step="tab"
						/>
					</q-tab-panel>

					<q-tab-panel
						v-for="(tab, index) in multiAnswerTabs"
						:key="index"
						:name="tab"
					>
						<BasicStepMultiAnswer
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							:sampleanswers="getSampleAnswers(selected)"
							:picturelabel="getPicLabel(selected)"
							:pictureratio="getPicRatio(selected)"
							:charlimit="getCharactersCount(selected)"
							:step="tab"
						/>
					</q-tab-panel>

					<q-tab-panel
						v-for="(tab, index) in multiSubAnswersTabs"
						:key="index"
						:name="tab"
					>
						<BasicStepSubAnswer
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							:sampleanswers="getSampleAnswers(selected)"
							:samplesubanswers="getSamplesubanswers(selected)"
							:picturelabel="getPicLabel(selected)"
							:pictureratio="getPicRatio(selected)"
							:charlimit="getCharactersCount(selected)"
							:step="tab"
						/>
					</q-tab-panel>

					<!-- All tables start -->
					<q-tab-panel name="general-environment-energytable">
						<EnergyUsageTable
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							step="general-environment-energytable"
						/>
					</q-tab-panel>
					<q-tab-panel name="general-environment-watertable">
						<WaterUsageTable
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							step="general-environment-watertable"
						/>
					</q-tab-panel>
					<q-tab-panel name="general-environment-materialtable">
						<MaterialUsageTable
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							step="general-environment-materialtable"
						/>
					</q-tab-panel>
					<q-tab-panel name="general-environment-wastetable">
						<WasteAmountTable
							:label="getLabel(selected)"
							:question="getQuestion(selected)"
							:guildline="getGuildline(selected)"
							step="general-environment-wastetable"
						/>
					</q-tab-panel>
					<!-- All tables end -->
				</q-tab-panels>
			</template>
		</q-splitter>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import stepNode from "./formdata/generalstepnode.json";
import stepQuestions from "./formdata/generalquestions.json";
import BasicStepMultiAnswer from "./formsteps/BasicStepMultiAnswerTemplate.vue";
import BasicStepSubAnswer from "./formsteps/BasicStepMultiAnswerWithSubTemplate.vue";
import BasicStepSingleAnswer from "./formsteps/BasicStepSingleAnswerTempalte.vue";
import BasicStepWithChip from "./formsteps/BasicStepAnswerWithChipsTemplate.vue";
import EnergyUsageTable from "./formsteps/EnergyUsageTable.vue";
import WaterUsageTable from "./formsteps/WaterUsageTable.vue";
import MaterialUsageTable from "./formsteps/MaterialUsageTable.vue";
import WasteAmountTable from "./formsteps/WasteAmountTable.vue";
import OurCompany from "./formsteps/OurCompany.vue";
import Economy from "./formsteps/Economy.vue";
import Environment from "./formsteps/Environment.vue";
import Social from "./formsteps/Social.vue";
import AppToolbar from "@/components/tools/AppToolBar.vue";
import FormStatus from "./formsteps/FormStatus.vue";
import axios from "axios";

export default {
	name: "GeneralForm",

	components: {
		AppToolbar,
		BasicStepMultiAnswer,
		BasicStepSubAnswer,
		BasicStepSingleAnswer,
		BasicStepWithChip,
		EnergyUsageTable,
		WaterUsageTable,
		MaterialUsageTable,
		WasteAmountTable,
		OurCompany,
		Economy,
		Environment,
		Social,
		FormStatus,
	},

	setup() {
		const store = useStore();
		const { user } = useAuth0();
		// Upate value here to debug each page
		const selected = ref("general-ourcompany-intro");
		const multiAnswerTabs = [
			"general-ourcompany-intro",
			"general-ourcompany-stakeholders",
			"general-ourcompany-suppliers",
			"general-ourcompany-featurestory",

			"general-economy-opportunities",
			"general-economy-practices",
			"general-economy-featurestory",
			"general-economy-highlights",
			"general-economy-activities",

			"general-environment-suppliers",
			"general-environment-carbon",
			"general-environment-energyreduction",
			"general-environment-wastewater",
			"general-environment-wastetreatment",
			"general-environment-materialreduction",
			"general-social-diversity",
			"general-social-employees",
			"general-social-compliance",
			"general-social-featurestory-diversity",
			"general-social-featurestory-training",
			"general-social-community",
			"general-social-culturalheritage",
			"general-social-featurestory-community",
			"general-social-partner",
			"general-social-featurestory-commitment",
			"general-social-safetymeasures",
			"general-social-safety",
			"general-social-wellbeing",
			"general-social-featurestory-commitment",
			"general-social-featurestory-safety",
		];

		const singleAnswerTabs = [
					
		];

		const multiSubAnswersTabs = [
			"general-environment-waterreduction",
			"general-environment-strategy",
		];

		const multiSubAnswersWithChipTabs = ["general-ourcompany-commitment"];

		function getStep(val) {
			if (val == null) {
				console.error("selected val is null");
			} else {
				var question = stepQuestions.filter((x) => x.value === val);
				if (question.length >= 1) {
					return question[0];
				} else {
					console.error("Failed to find the step", val);
					return null;
				}
			}
		}

		function showStatus() {
			selected.value = "show-status-tab";
		}

		function getQuestion(val) {
			return getStep(val).question;
		}

		function getGuildline(val) {
			return getStep(val).guildline;
		}

		function getPicLabel(val) {
			return getStep(val).picturelabel;
		}
		function getPicRatio(val) {
			return getStep(val).pictureratio;
		}

		function getSampleAnswers(val) {
			return getStep(val).sampleanswers;
		}

		function getLabel(val) {
			return getStep(val).label;
		}

		function getSamplesubanswers(val) {
			return getStep(val).samplesubanswers;
		}

		function getCharactersCount(val) {
			var limit = getStep(val).charactercnt;
			if (limit) {
				return parseInt(limit);
			}
			return 500;
		}

		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				// "https://formanswer.azurewebsites.net/api/getFormByUser?id=" + userid,
				"https://testdynimosazure.azurewebsites.net/api/getFormByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return true;
			}
			return null;
		}

		onMounted(async () => {
			const returnUser = await userHasData();
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			if (returnUser) {
				store.dispatch("loadItems", {
					template: "general",
					userid: userid,
				});
			} else {
				store.commit("updateSingleState", {
					label: "template",
					value: "general",
				});
			}
		});
		// onMounted(() => {
		// 	store.commit("updateSingleState", {
		// 		label: "template",
		// 		value: "general",
		// 	});
		// });

		return {
			splitterModel: ref(20),
			steptree: stepNode,
			selected,
			introAnswers: ref(null),
			getQuestion,
			getGuildline,
			getPicLabel,
			getPicRatio,
			getSampleAnswers,
			getSamplesubanswers,
			getLabel,
			getCharactersCount,
			multiAnswerTabs,
			singleAnswerTabs,
			multiSubAnswersTabs,
			multiSubAnswersWithChipTabs,
			showStatus,
		};
	},
};
</script>
