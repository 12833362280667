<template>
	<div class="header">
		<q-toolbar>
			<!-- <q-toolbar class="bg-primary text-white glossy"> -->
			<div class="right">
				<div class="logo">
					<q-btn
						flat
						round
						dense
						size="xl"
						@click="$router.push('/')"
						aria-label="Menu"
						icon="img:https://i.ibb.co/Cb7KGVX/logo.png"
					/>
					<q-btn
						class="logo-text"
						dense
						flat
						label="DYNIMOS"
						@click="$router.push('/')"
					/>
				</div>
			</div>
			<q-space />
			<q-btn flat class="menu" @click="$router.push('/about')" label="About" />
			<q-btn
				flat
				class="menu"
				@click="$router.push('/services')"
				label="Our Services"
			/>
			<q-btn
				flat
				class="menu"
				@click="$router.push('/products')"
				label="Our Products"
			/>
			<!-- <template  v-if="isAuthenticated"> -->
			<q-btn-dropdown flat class="menu" label="Tools">
				<q-list>
					<q-item clickable v-close-popup v-if="isAuthenticated">
						<q-item-section>
							<q-item-label @click="$router.push('/esg')">
								Dynimos ESG Report Generator
							</q-item-label>
						</q-item-section>
					</q-item>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/calculatermain')">
								Dynimos Carbon Accounting
							</q-item-label>
						</q-item-section>
					</q-item>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/calculater')">
								Textile Life Cycle Assessment
							</q-item-label>
						</q-item-section>
					</q-item>
				</q-list>
			</q-btn-dropdown>
			<!-- </template> -->
			<q-btn flat class="menu" @click="$router.push('/blog')" label="BLOG" />
			<q-btn
				flat
				class="menu"
				label="Contact Us"
				@click="$router.push('/contact')"
			/>
			<template v-if="!isAuthenticated">
				<LoginButton />
			</template>

			<q-btn-dropdown flat class="menu-fold" label="Menu" :icon="pag">
				<q-list>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/about')">
								ABOUT
							</q-item-label>
						</q-item-section>
					</q-item>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/services')">
								OUR SERVICES
							</q-item-label>
						</q-item-section>
					</q-item>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/products')">
								OUR PRODUCTS
							</q-item-label>
						</q-item-section>
					</q-item>
					<!-- <template  v-if="isAuthenticated"> -->
					<q-btn-dropdown flat class="menu" label="Tools">
						<q-list>
							<q-item clickable v-close-popup v-if="isAuthenticated">
								<q-item-section>
									<q-item-label @click="$router.push('/esg')">
										Dynimos ESG Report Generater
									</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup>
								<q-item-section>
									<q-item-label @click="$router.push('/calculatermain')">
										Dynimos Carbon Accounting
									</q-item-label>
								</q-item-section>
							</q-item>
							<q-item clickable v-close-popup>
								<q-item-section>
									<q-item-label @click="$router.push('/calculater')">
										Textile Life Cycle Assessment
									</q-item-label>
								</q-item-section>
							</q-item>
						</q-list>
					</q-btn-dropdown>
					<!-- </template> -->
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/BLOG')"> BLOG </q-item-label>
						</q-item-section>
					</q-item>
					<q-item clickable v-close-popup>
						<q-item-section>
							<q-item-label @click="$router.push('/contact')">
								CONTACTUS
							</q-item-label>
						</q-item-section>
					</q-item>
					<template v-if="!isAuthenticated">
						<LoginButton />
					</template>

					<!-- <template v-if="isAuthenticated">
						<div @click="dropdown_handle()" style="margin: 0 20px;">
							<div class="photo">
								<img src="@/assets/img/anime3.png" alt="Profile Photo" />
							</div>
						</div>
						<div v-if="dropdown_flag===true" class="dropdown-menu" >
							<ul style="min-width:7.5rem;list-style-type: none;padding-inline-start: 0px">
								<li class="nav-link" >
									<div class="dropdown-item dropdown-item-backgroung" @click="$router.push('/profile')">Profile</div>
								</li>
								<li class="nav-link" >
									<div  class="dropdown-item dropdown-item-backgroung">Settings</div>
								</li>
								<li class="nav-link" style="padding-left: 0px;border-top: 1px solid #d1d1d1;"></li>
								<li class="nav-link" >
									<div class="dropdown-item dropdown-item-backgroung" @click="handleLogout">Log Out</div>
								</li>
							</ul>
						</div>					
					</template> -->
				</q-list>
			</q-btn-dropdown>

			<template v-if="isAuthenticated">
				<div @click="dropdown_handle()" style="margin: 0 20px;">
					<div class="photo">
						<img src="@/assets/img/anime3.png" alt="Profile Photo" />
					</div>
				</div>
				<div v-if="dropdown_flag===true" class="dropdown-menu">
					<ul style="min-width:7.5rem;list-style-type: none;padding-inline-start: 0px">
						<li class="nav-link" >
							<div class="dropdown-item dropdown-item-backgroung" @click="$router.push('/profile')">Profile</div>
						</li>
						<li class="nav-link" >
							<div class="dropdown-item dropdown-item-backgroung" @click="$router.push('/dashboardmain')">Dashboards</div>
						</li>
						<li class="nav-link" >
							<div  class="dropdown-item dropdown-item-backgroung" @click="$router.push('/settings')">Settings</div>
						</li>
						<li class="nav-link" style="padding-left: 0px;border-top: 1px solid #d1d1d1;"></li>
						<li class="nav-link" >
							<div class="dropdown-item dropdown-item-backgroung" @click="handleLogout">Log Out</div>
						</li>
					</ul>
				</div>
			</template>
		</q-toolbar>
	</div>
</template>

<script>
import { ref } from 'vue'
import LoginButton from "@/buttons/login-button.vue";
// import LogoutButton from "@/buttons/logout-button.vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
	components: {
		LoginButton,
		// LogoutButton,
	},
	
	setup() {
		const dropdown_flag = ref(false)
		const { isAuthenticated } = useAuth0();
		const { logout } = useAuth0();

		const handleLogout = () =>logout({
			returnTo: window.location.origin,
		});
		function dropdown_handle()
		{
			if(dropdown_flag.value === true) dropdown_flag.value = false
			else                       		 dropdown_flag.value = true
		}
		return {
			isAuthenticated,
			handleLogout,
			dropdown_handle,
			dropdown_flag,
		};
	},
};
</script>

<style lang="less" scoped>
@media (min-width: 1200px) {
	.photo {
		display: inline-block;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		vertical-align: middle;
		overflow: hidden;
		img{
			width: 100%;
		}
	}
	.dropdown-menu{
		position: absolute;
		top: 100%;
		right: 0;
		float:right;
		z-index:1000;
		font-size: 1rem; // Redeclare because nesting can cause inheritance issues
		color:  #525f7f;
		text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
		list-style: none;
		background-color: #f6f5f5;
		background-clip: padding-box;
		border: 0 solid rgba(#000, .15);
		border-radius: 10px;
	}
	.nav-link {
		text-align: left;
		padding-right: 0;
		margin: 10px 0;
		list-style-type: none;
	}
		.dropdown-item {
			display: block;
			padding-left: 20px;
			min-width: 5rem; // For `<button>`s
			clear: both;
			font-weight: 400;
			color: #212529;
			text-align: inherit; // For `<button>`s
			white-space: nowrap; // prevent links from randomly breaking onto new lines
			border: 0; // For `<button>`s
		}
		.dropdown-item-backgroung:hover {
			background-color: #ffffff;
		}

		.dropdown-item-backgroung {
			background-color: transparent;
		}

	.navbar-nav {
		display: flex;
		flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		.nav-link {
			padding-right: 0;
			padding-left: 0;
		}

		.dropdown-menu {
			position: static;
			float: none;
		}
	}

	.header {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 10px 10px;
		justify-content: space-between;
		background-color: rgb(253, 253, 255);
		border-bottom: 1px solid rgb(231, 231, 233);

		.right {
			display: flex;
			align-items: center;
		}

		.left {
			margin-right: 50px;
		}

		.title {
			margin-left: 3vw;
			font-size: 16px;
			font-weight: 500;
			color: #6e6d7a;
		}
		.menu {
			margin-left: 1vw;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-family: Arial;
			color: #333336;
		}
		.menu-fold {
			display: none;
			margin-left: 1vw;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-family: Arial;
			color: #333336;
		}
		.sign {
			font-weight: 400;
			font-size: 18px;
			color: #6e6d7a;
		}

		.logo {
			display: flex;
			align-items: center;

			.logo-img {
				width: 30px;
			}

			.logo-text {
				margin-left: 10px;
				font-size: 28px;
				font-weight: 900;
				color: rgb(108, 154, 208);
			}
		}
	}
}
</style>
<style lang="less" scoped>
@media (max-width: 1200px) {
	.photo {
		display: inline-block;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		vertical-align: middle;
		overflow: hidden;
		img{
			width: 100%;
		}
	}
	.dropdown-menu{
		position: absolute;
		top: 100%;
		right: 0;
		float:right;
		z-index:1000;
		font-size: 1rem; // Redeclare because nesting can cause inheritance issues
		color:  #525f7f;
		text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
		list-style: none;
		background-color: #f6f5f5;
		background-clip: padding-box;
		border: 0 solid rgba(#000, .15);
		border-radius: 10px;
	}
	.nav-link {
		text-align: left;
		padding-right: 0;
		margin: 10px 0;
		list-style-type: none;
	}
		.dropdown-item {
			display: block;
			padding-left: 20px;
			min-width: 5rem; // For `<button>`s
			clear: both;
			font-weight: 400;
			color: #212529;
			text-align: inherit; // For `<button>`s
			white-space: nowrap; // prevent links from randomly breaking onto new lines
			border: 0; // For `<button>`s
		}
		.dropdown-item-backgroung:hover {
			background-color: #ffffff;
		}

		.dropdown-item-backgroung {
			background-color: transparent;
		}

	.navbar-nav {
	display: flex;
		flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		.nav-link {
			padding-right: 0;
			padding-left: 0;
		}

		.dropdown-menu {
			position: static;
			float: none;
		}
	}


	.header {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 10px 10px;
		justify-content: space-between;
		background-color: rgb(253, 253, 255);
		border-bottom: 1px solid rgb(231, 231, 233);

		.right {
			display: flex;
			align-items: center;
		}

		.left {
			margin-right: 50px;
		}

		.title {
			margin-left: 3vw;
			font-size: 16px;
			font-weight: 500;
			color: #6e6d7a;
		}
		.menu {
			display: none;
			margin-left: 1vw;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-family: Arial;
			color: #333336;
		}
		.menu-fold {
			display: flex;
			margin-left: 1vw;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-family: Arial;
			color: #333336;
		}
		.sign {
			font-weight: 400;
			font-size: 18px;
			color: #6e6d7a;
		}

		.logo {
			display: flex;
			align-items: center;

			.logo-img {
				width: 10px;
			}

			.logo-text {
				margin-left: 10px;
				font-size: 20px;
				font-weight: 900;
				color: rgb(108, 154, 208);
			}
		}
		.option {
			display: flex;
			margin-left: 1vw;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-family: Arial;
			color: #333336;
		}
	}
}
</style>
