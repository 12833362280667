<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" class="text-black">
						<b> ENVIRONMENTAL IMPACT</b>
					</q-tab>
					<q-tab name="social" label="SOCIAL IMPACT" />
				</q-tabs>
			</q-header>

			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-6">
							<div class="title text-capitalize">Strategy</div>
							<div class="text-content">
								{{ ourapproach }}
							</div>
							<div class="testrow">
								<div class="title text-capitalize">Carbon Footprint</div>
								<div class="text-content">
									{{ carbinfootprint }}
								</div>
							</div>
							<div class="title text-capitalize">Supplier Selection Criteria</div>
							<div class="text-content">
								{{ envcri }}
							</div>
						</div>
						<div class="col-5">
							<q-img :src="carbinfootprintimg" style="fit" />
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-3">
							<ColChart />
						</div>
						<div class="col-3">
							<q-table :rows="myrows" :columns="columns" row-key="name" />
						</div>
						<div class="col-5 testrow bg-light-blue-1">
							<div class="title text-capitalize">Energy Reduction</div>
							<div class="text-content">
								{{ energy }}
							</div>
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
import ColChart from "@/components/charts/ColChart.vue";
// import HeaderTab from "./components/HeaderTab.vue";
import SlideFooter from "./components/DefaultFooter.vue";

const columns = [
	{
		name: "name",
		required: true,
		label: "Operation",
		align: "left",
		field: (row) => row.name,
		format: (val) => `${val}`,
		sortable: true,
	},
	{
		name: "amount",
		align: "center",
		label: "Cost",
		field: "amount",
		sortable: true,
	},
	{ name: "unit", label: "Unit", field: "unit", sortable: true },
];

export default {
	name: "EnviromentEngery",
	components: { ColChart, SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;

		const ourapproach =
			store.state[template][template+"_environment_strategy"].ans;
		const carbinfootprint =
			store.state[template][template+"_environment_carbon"].ans;
		let carbinfootprintimg =
			store.state[template][template+"_environment_carbon"].img;
		if (!carbinfootprintimg) {
			carbinfootprintimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/c_crop,h_1067,w_1107/v1677202687/DYNIMOS%20DEFAULT%20PICS/ENERGY_USAGE_bsfqe2.jpg";
		}
		const envcri = store.state[template][template+"_environment_suppliers"].ans;
		const energy =
			store.state[template][template+"_environment_energyreduction"].ans;

		var storedata =
			store.state[template][template+"_environment_energytable"].ans;
		const dataArray = JSON.parse(JSON.stringify(storedata));

		var myrows = [];
		for (let i = 0; i < dataArray.length; i++) {
			let obj = dataArray[i];
			let cur = {
				name: obj.operation,
				amount: obj.amount,
				unit: obj.unit,
			};
			myrows.push(cur);
		}

		return {
			ourapproach,
			carbinfootprint,
			carbinfootprintimg,
			envcri,
			energy,
			columns,
			myrows,
		};
	},
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.testrow {
	padding: 10px 15px;
	background: rgba(86, 61, 124, 0.15);
	border: 1px solid rgba(86, 61, 124, 0.2);
}
.row {
	margin-top: 1rem;
}
.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
