<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<div style="display: flex;overflow: auto;">
		<div style="position: fixed;align-items: center;margin-left:10px;margin-top:12px;width:12%;height:80vh;border-radius:10px;background-color:rgb(61, 56, 56);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
			<div style="width:80%;font-size:1.6vw;margin-left:7%;color: aliceblue;">
				<div class="box" style="margin-top:30%;" @click="$router.push('/dashboardmain')">Dashboards</div>
				<div>
					<div class="box" @click="$router.push('/dashboardequipment')">Equipment</div>
					<div class="box" style="border-radius:10px;background-color:rgba(0, 255, 0, 0.552);" @click="$router.push('/dashboardvehicle')">Vehicle</div>
					<div class="box" @click="$router.push('/dashboardbuilding')">Building</div>
					<!-- <div class="box" @click="$router.push('/dashboardequipment')">Waste</div> -->
				</div>
			</div>
		</div>
		<div style="position: static;margin-left:14%;margin-top:12px;width:84%;">
			<div style="font-size:3.0vw;padding-bottom:2%">Vehicle</div>
			<div style="width: 80%;padding:10px;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
				<div>
					<div style="font-size:large;margin-bottom:10px">Dropdown button to select time range start -> end, all charts in this dashboard are based on the time range selected</div>
					<div class="p-3 pb-0 card-header">
						<div class="demo-date-picker">
							<div class="block">
								<el-date-picker
									v-model="startTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="Start date"
									transfer
									:size="small"
									@input="isdisplay=false"
									@change="dateRules"
									style="width:120px;"
									/>
								<el-date-picker
									v-model="endTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="End date"
									transfer
									:size="small"
									@input="isdisplay=false"
									@change="dateRules"
									style="width:120px;margin-left:20px"
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6" style="margin: 20px auto;">
				<div class="card z-index-2">

					<div class="p-3 card-body" :key="refresh_indexs" style="border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);" v-if="isdisplay===true"> 
						<div class="chart" style="width: 90%;padding-left:2%">
						<bar-chart
							id="bar-chart"
							:chart="chartdata"
						/>
						</div>
					</div>
				</div>
			</div>

			<div  style="display:flex;justify-content: space-between;margin-top:10px;" v-if="isdisplay===true">
				<div class="card z-index-2" style="width: 48%;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
					<div class="p-3 card-body" style="width: 90%;padding-top:5%;padding-left:5%">
						<div class="chart">
							<apexchart
								type="pie"
								:options="chartOptions"
								:series="series"
								align="center"
							/>
						</div>
					</div>
				</div>
				<div class="card z-index-2" style="width: 48%;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
					<div class="p-3 card-body" style="width: 90%;padding-top:8%;padding-left:5%">
						<div style="display: flex;justify-content: space-between;width:50%;margin:0 auto">
							<div><input type="checkbox" name="CO2e" @click="displaychart3('CO2e')">CO2e(kg)</div>
							<div><input type="checkbox" name="CO2" @click="displaychart3('CO2')">CO2(kg)</div>
							<div><input type="checkbox" name="CH4" @click="displaychart3('CH4')">CH4(kg)</div>
							<div><input type="checkbox" name="N2O" @click="displaychart3('N2O')">N2O(kg)</div>
						</div> 
						<div class="chart"  v-if="chart3_refresh===true">
						<bar-chart-horizontal
							:chart="{
								labels: display_chart3_title,//['CO2e', 'CO2', 'CH4', 'N2O'],
								datasets: {
									data: display_Value, //[12,14,15,15],
								}, 
							}"
						/>
						</div>
					</div>
				</div>
			</div>
			<br>
			<br>
			<br>
		</div>
	</div>

</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
// import { useQuasar } from "quasar";
// import {  VxeTableInstance } from 'vxe-table'
import axios from "axios";
// import AllConuntryStates from "@/components/formdata/allcountries.json";
// import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
import BarChart from "./components/BarChart.vue";
import BarChartHorizontal from "./components/BarChartHorizontal.vue";
import VueApexCharts from "vue3-apexcharts";
import reactive1 from "./reactive.js"

export default {
	components: {
		ToolBar,
		BarChart,
		BarChartHorizontal,
		apexchart: VueApexCharts,
	},
	setup () {
		// const xTable = ref(VxeTableInstance)
		const tableData = []
		const ConvertValue = ref("")
		// const store = useStore()
		const { user } = useAuth0()
		// const $q = useQuasar()
		// var watch_first = true
		const startTime = ref()
		const endTime = ref()

		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
		const currentDay = String(today.getDate()).padStart(2, '0');
		endTime.value  = `${currentYear}-${currentMonth}-${currentDay}`;

		const oneYearAgo = new Date();
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
		const oneYearAgoYear = oneYearAgo.getFullYear();
		const oneYearAgoMonth = String(oneYearAgo.getMonth() + 1).padStart(2, '0');
		const oneYearAgoDay = String(oneYearAgo.getDate()).padStart(2, '0');
		startTime.value = `${oneYearAgoYear}-${oneYearAgoMonth}-${oneYearAgoDay}`;

		const series = reactive([])
		const optionbuf = reactive([])
		var all_chart3_title = reactive1(['CO2e', 'CO2', 'CH4', 'N2O'])//['CO2e', 'CO2', 'CH4', 'N2O'],
		var allValue = reactive1([0,0,0,0])
		var display_chart3_title = reactive1([])//['CO2e', 'CO2', 'CH4', 'N2O'],
		var display_Value = reactive1([])
		const chartOptions = {
			chart: {
				width: 480,
				type: "pie",
			},
			labels: optionbuf,//["Operation1", "Operation2", "Operation3", "Operation4", "Operation5"],
			legend: {
				show: true,
				showForSingleSeries: false,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: "top",
				horizontalAlign: "center",
				fontSize: "12px",
				fontFamily: "Helvetica, Arial",
				fontWeight: 400,
				itemMargin: {
					horizontal: 15,
					vertical: 0,
				},
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, val.toFixed(2) + "%"];
				},
			},
		}
		const rowNum = ref()
		// var temp_city,temp_country
		var isDisabled = false;
		var isdisplay = ref(false)
		var chart3_refresh = ref(false)
		const refresh_indexs = ref(0)
		let CO2e_value = reactive1([])
		let CO2_value = reactive1([])
		let CH4_value = reactive1([])
		let N2O_value = reactive1([])
		let charts1_title = reactive1([])//'五', '六', '七', '八', '九', '十'
		const charts1_data = reactive1([
							{
								label: 'CH4',
								data: CH4_value,
							},{
								label: 'N2O',
								data: N2O_value,
							},{
								label: 'CO2',
								data: CO2_value,
							},{
								label: 'CO2e',
								data: CO2e_value,
							}
							])

		const chartdata = reactive1({
			labels: charts1_title,//['16-20', '21-25', '26-30', '31-36', '36-42', '42+'],
			datasets: charts1_data
		})	

		const co2e_selected = ref(false)
		const co2_selected = ref(false)
		const ch4_selected = ref(false)
		const n2o_selected = ref(false)

		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"vehicleusage"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		onMounted(async () => {
			const returnUser = await userHasData();

			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			for(let i=0;i<databuf.length;i++)
			{
				if(typeof(databuf[i].vehicle) !== "undefined") {
					// console.log(databuf[i].vehicle)
					const table_arry=reactive([])
					table_arry.push({
						transactionId: databuf[i].transactionId,
						id: databuf[i].id,
						userId: databuf[i].userId,
						transactionType: databuf[i].transactionType,
						scope: databuf[i].scope,
						vehicle: databuf[i].vehicle,
						timeperiod:{
							starttime: databuf[i].timeperiod.starttime,
							endtime: databuf[i].timeperiod.endtime
						},
						calculationtype: databuf[i].calculationtype,
						amount: databuf[i].amount,
						unit: databuf[i].unit,
						co2e: databuf[i].co2e,
						co2: databuf[i].co2,
						ch4: databuf[i].ch4,
						n2o: databuf[i].n2o
					})							
					rowNum.value += 1 
					tableData.push(table_arry[0])
				}
			}
			charts_calc(startTime.value ,endTime.value)
			// console.log("tableData",tableData)
		});

		function charts_calc(start,end){
			if(start < end){
				if(isDisabled === false){
					CO2e_value.length = 0
					CO2_value.length = 0
					CH4_value.length = 0
					N2O_value.length = 0
					charts1_title.length = 0
					let i=0,j=0,k=0,m=0
					let month_len = 0
					var xTabledata = tableData//reactive()
					isdisplay.value = true
					chart3_refresh.value = true
					isDisabled = true
					optionbuf.length = 0
					series.length = 0
					// console.log("------------>charts_calc start")
					let input_start_year = parseInt(start.substring(0,4))
					let input_start_month = parseInt(start.substring(5,7))
					let input_end_year = parseInt(end.substring(0,4))
					let input_end_month = parseInt(end.substring(5,7))
					for(i=0;i<xTabledata.length;i++)
					{
						let include_operation = 0

						let table_start_year = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(0,4))
						let table_start_month = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(5,7))
						let table_end_year = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(0,4))
						let table_end_month = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(5,7))
						
						// let month_len = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(0,7)) - parseInt(xTabledata[i].timeperiod.starttime.toString().substring(0,7)) + 1
						if(table_end_year > table_start_year)
						{
							month_len = (table_end_year - table_start_year - 1)*12 + (12 + table_end_month - table_start_month) + 1    //防止小数月份减大数月份
						}else{
							month_len = (table_end_year - table_start_year) * 12 + (table_end_month - table_start_month) + 1
						}
						
						// console.log("month_len=%d",month_len)
						if(isNaN(month_len)){
							return
						}
						
						for(j=0;j<month_len;j++)
						{
							let comp_yeas = parseInt(table_start_year)
							let comp_month = parseInt(table_start_month+j)
							if(comp_month>12) {
								comp_month -= 12
								comp_yeas +=1
							}
							if((comp_yeas > input_start_year && comp_yeas < input_end_year)||
								(comp_yeas === input_start_year && comp_yeas < input_end_year && comp_month >= input_start_month)||
								(comp_yeas === input_start_year && comp_yeas === input_end_year && comp_month >= input_start_month && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas > input_start_year && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas === input_start_year && comp_month >= input_start_month && comp_month <= input_end_month))
							{			
								include_operation = 1	
								var comp_date = comp_yeas.toString() + "/" + comp_month.toString()	
								let index = charts1_title.length
								let min_time_flag = 0		
								for(k=0;k<charts1_title.length;k++){
									let title_year = parseInt(charts1_title[k].substring(0,4))
									let title_month = parseInt(charts1_title[k].substring(5,7))
									if(charts1_title[k] === comp_date)  
									{
										// console.log("------->comp_date",comp_date)
										CO2e_value[k] += parseInt(xTabledata[i].co2e/month_len)
										CO2_value[k] += parseInt(xTabledata[i].co2/month_len)
										CH4_value[k] += parseInt(xTabledata[i].ch4/1000/month_len)
										N2O_value[k] += parseInt(xTabledata[i].n2o/1000/month_len)
										break
									}else if((comp_yeas<title_year || (comp_yeas===title_year && comp_month<=title_month)) && min_time_flag===0){
										index = k
										min_time_flag = 1
										break
									}
									
								} 
								if(k===index){
									CO2e_value.splice(index,0,parseInt(xTabledata[i].co2e/month_len))
									CO2_value.splice(index,0,parseInt(xTabledata[i].co2/month_len))
									CH4_value.splice(index,0,parseInt(xTabledata[i].ch4/1000/month_len))
									N2O_value.splice(index,0,parseInt(xTabledata[i].n2o/1000/month_len))
									charts1_title.splice(index,0,comp_date)			
								}

								allValue[0] += parseInt(xTabledata[i].co2e)
								allValue[1] += parseInt(xTabledata[i].co2)
								allValue[2] += parseInt(xTabledata[i].ch4*25)
								allValue[3] += parseInt(xTabledata[i].n2o*298)
                                
							}
						}
						if(include_operation)
						{
							if(i===0){
								optionbuf.push(xTabledata[i].vehicle)
								series[0] = 1;
							}else{
								for(m=0;m<optionbuf.length;m++)
								{
									if(xTabledata[i].vehicle === optionbuf[m])
									{
										series[m] += 1 
										break
									}
								}
								if(m===optionbuf.length){
									optionbuf.push(xTabledata[i].vehicle)
									series[m] = 1
								}
							}
							// console.log("optionbuf",optionbuf,series)
						}
						
					}
					refresh_indexs.value ++
					// console.log("------------>charts_calc end")
					if(co2e_selected.value === false)
					{
						display_chart3_title.push(all_chart3_title[0])
						display_Value.push(allValue[0])
					}
					if(co2_selected.value === false)
					{
						display_chart3_title.push(all_chart3_title[1])
						display_Value.push(allValue[1])
					}
					if(ch4_selected.value === false)
					{
						display_chart3_title.push(all_chart3_title[2])
						display_Value.push(allValue[2])
					}
					if(n2o_selected.value === false)
					{
						display_chart3_title.push(all_chart3_title[3])
						display_Value.push(allValue[3])
					}
					
								// console.log(CO2e_value)
								// console.log(CO2_value)
								// console.log(CH4_value)
								// console.log(N2O_value)
								// console.log(display_chart3_title,display_Value)

					setTimeout(()=>{
						// console.log("setTimeout")
						isDisabled = false
					},1000)
				}			
			}
		}
		
		function displaychart3(value){
			chart3_refresh.value = false
			// console.log("displaychart3",value)
			// console.log("isdisplay",isdisplay)
			
			if(value === 'CO2e')
			{
				if(co2e_selected.value === true) co2e_selected.value = false
				else                             co2e_selected.value = true
			}
			else if(value === 'CO2')
			{
				if(co2_selected.value === true) co2_selected.value = false
				else                            co2_selected.value = true
			}
			else if(value === 'CH4')
			{
				if(ch4_selected.value === true) ch4_selected.value = false
				else                            ch4_selected.value = true
			}
			else if(value === 'N2O')
			{
				if(n2o_selected.value === true) n2o_selected.value = false
				else                            n2o_selected.value = true
			}
			display_chart3_title.length = 0
			display_Value.length = 0
			if(co2e_selected.value === false)
			{
				display_chart3_title.push(all_chart3_title[0])
				display_Value.push(allValue[0])
			}
			if(co2_selected.value === false)
			{
				display_chart3_title.push(all_chart3_title[1])
				display_Value.push(allValue[1])
			}
			if(ch4_selected.value === false)
			{
				display_chart3_title.push(all_chart3_title[2])
				display_Value.push(allValue[2])
			}
			if(n2o_selected.value === false)
			{
				display_chart3_title.push(all_chart3_title[3])
				display_Value.push(allValue[3])
			}
			// isdisplay.value = true
			setTimeout(()=>{
				// console.log("setTimeout")
				chart3_refresh.value = true
			},0.5)
			
		}
		return { 
			tableData,
			ConvertValue,
			miniState: ref(true),
			startTime,
			endTime,
			series,
			chartOptions,	
			CO2e_value,
			CO2_value,
			CH4_value,
			N2O_value,
			charts1_title,
			charts1_data,
			chartdata,
			charts_calc,
			refresh_indexs,
			all_chart3_title,
			allValue,
			display_chart3_title,
			display_Value,
			isdisplay,
			chart3_refresh,
			co2e_selected,
			co2_selected,
			ch4_selected,
			n2o_selected,
			displaychart3
		}
	},

	methods: {
		dateRules(){
			// console.log(this.startTime,this.endTime)
			if(this.startTime > this.endTime) {

				this.$q.notify({
					color: "red-5",
					textColor: "white",
					icon: "warning",
					message: "Start date must be earlier than end date",
				});
				
			}else{
				this.charts_calc(this.startTime,this.endTime);
			}
		},
	},
	

}
</script>

<style scoped>
.box {
	display: flex;
	height: 40px;
	font-size:1.4vw;
	padding-left:7%;
	align-items: center;
	margin-top: 3px;
	border-radius:10px;
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */
  }

  .box:hover {
	
    box-shadow: 0px 0px 10px rgba(214, 213, 213, 0.678); /* 鼠标移入时添加阴影效果 */
  }
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: left;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
.delete-line {
	text-decoration: line-through;
  }
</style>
