<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/equipment')">
					<q-item-section avatar>
						<q-icon name="account_tree" />
					</q-item-section>

					<q-item-section>
						<div>	Equipment management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/inventory')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Inventory management</div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="$router.push('/methodology')">
					<q-item-section avatar>
						<q-icon name="description" />
					</q-item-section>

					<q-item-section>
						<div>	Methodology </div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>

		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Equipment Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>In this section, you will create profiles for each piece of fuel-consuming equipment, an essential aspect of Scope 1 emissions reporting. The equipment profiles established here will integrate into the inventory management system. Please ensure you only include equipment that operates on the following fuels: coal and coke, other solid fuels, solid biomass fuels, natural gas, other gaseous fuels, gaseous biomass fuels, petroleum products, liquid biomass fuels, and kraft pulping liquor derived from wood processing. </div>
							<div style="margin-top: 10px;">It is important to remember that this section excludes equipment powered by electricity.</div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertEquipment()">Add Equipment</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
							<vxe-button @click="saveProgress()">SAVE</vxe-button>
						</template>
					</vxe-toolbar>
					<vxe-table
					border
					:loading="loading"
					:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
					show-overflow
					ref="xTable"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:column-config="{resizable: true}"
					:row-config="{isHover: true}"
					:data="tableData"
					empty-text="Empty Table"
					align="center"
					header-align="center"
					:auto-resize="true"
				>
					<vxe-column type="seq" width="60"></vxe-column>
					<vxe-column type="checkbox" width="60"></vxe-column>
					<vxe-column field="name" title="Name">
						<template #default="{ row }">
							<!-- <span>{{ row.name }}</span> -->
							<vxe-input v-model="row.name" type="text" transfer placeholder="Enter Name"></vxe-input>
						</template>

					</vxe-column>
					<vxe-column field="location" title="Location">
						<template #default="{ row }">
							<div style="display: Flex;">
								<vxe-select
									v-model="row.location_county"
									@change="countryChangeEvent(row)"
									transfer
									placeholder="Select Country"
									style="width:50%"
								>
									<vxe-option
										v-for="item in AllConuntryStates"
										:key="item.iso3"
										:value="item.name"
										:label="item.name"
									>
									</vxe-option>		
								</vxe-select>
								<vxe-select
									v-if="row.location_county"
									v-model="row.location_city"
									@change="cityChangeEvent(row)"
									transfer
									placeholder="Select City"
									style="width:50%"
								>
									<vxe-option
										v-for="item in AllCityStates"
										:key="item.iso3"
										:value="item.name"
										:label="item.name"
									>
									</vxe-option>		
								</vxe-select>
								<vxe-select
									v-else
									placeholder="Select City"
									style="width:50%"
								>
									<vxe-option>null</vxe-option>		
								</vxe-select>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="cost" title="Cost">
						<template #default="{ row }">
							<vxe-input v-model="row.cost" type="number" placeholder="Enter Cost"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="fueltype" title="Fuel type">
						<template #default="{ row }">
						<vxe-select v-model="row.fueltype" transfer placeholder="Please select" @change="row.fuelcategory=''">
							<vxe-option v-for="item in getFuelType('SCOPE1-STATIONARY').children" :key="item.value" :value="item.label" :label="item.label"></vxe-option>
						</vxe-select>
						</template>
					</vxe-column>
					<vxe-column field="fuelcategory" title="Fuel category">
						<template #default="{ row }">
							<vxe-select v-model="row.fuelcategory" transfer  placeholder="Please select" v-if="row.fueltype">
								<vxe-option v-for="item in getCategory(row.fueltype)" :key="item.fueltype" :value="item.fueltype" :label="item.fueltype" ></vxe-option>
							</vxe-select>
							<vxe-select  placeholder="Please select" v-else>
								<vxe-option> null</vxe-option>
							</vxe-select>
						</template>	
					</vxe-column>
				</vxe-table>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import AllConuntryStates from "@/components/formdata/allcountries.json";
import stepSource from "../carbondata/sourcetype.json";
import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			name:'',
			fueltype: '',
			fuelcategory: '',
			cost: '',
			location: ''
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const AllCityStates = ref()
		const rowNum = ref();
		var temp_city,temp_country
		const ConvertValue = ref("")	

		const countryChangeEvent = (row) => {
				// Update State list and iso3
				AllConuntryStates.map((item) => {
					// console.log(row.location_county)
					// console.log(item.name)
					if (row.location_county === item.name) {
						// console.log("Found selected country from all countries json");
						row.iso3 = item.iso3;
						if (!item.states.length) {
							console.log(
								"No status or province in country:" +
									item.name +
									" Using country as state"
							);
							const tmpState = {
								name: item.name,
								state_code: item.iso3,
							};
							row.state = tmpState;
							row.allStates.push(tmpState);
						} else {
							// row.state = Object.create(item.states[0]);
							// console.log(item.states)
							row.location_city = ''
							AllCityStates.value = item.states//Object.create(item.states);
							// console.log(AllCityStates)
							return
						}
					}
				});
		}
		
		const cityChangeEvent = (row) => {
			var source = AllConuntryStates.filter((x) => x.name === row.location_county);
			var source1 = source[0].states.filter((x) => x.name === row.location_city);
			row.location = source[0].iso3 + "_" + source1[0].state_code
		}
		
		function cityBycode(code){
			// console.log("cityBycode")
			var ncode = code.split('_')
			var source = AllConuntryStates.filter((x) => x.iso3 === ncode[0]);
			// console.log(source)
			if(source.length !== 0){
				temp_country = source[0].name
				if(source[0].states.length !== 0){
					var source1 = source[0].states.filter((x) => x.state_code === ncode[1]);
					temp_city = source1[0].name
				}
			}
		}
				
		function getFuelType(FuelTypeStr){
			if (FuelTypeStr == null) {
				console.error("selected val is null");
			} else {
				var source = stepSource.filter((x) => x.value === FuelTypeStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", FuelTypeStr);
					return null;
				}
			}
		}
		function getCategory(categoryStr) {
			if (categoryStr == null || categoryStr == "" ) {
				console.error("selected val is null");
			} else {
				// console.log(categoryStr)
				// console.log(stepSource)
				var source = stepSource[0].children.filter((x) => x.label === categoryStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].suboptions);
					return source[0].suboptions;
				} else {
					console.error("Failed to find the step", categoryStr);
					return null;
				}
			}
		}

		const insertEquipment = async (row) => {
			// console.log("insertEvent",row)
			const $table = xTable.value
			if ($table) {
				const record = {
					name:'',
					fueltype: '',
					fuelcategory: '',
					cost: '',
					location: ''
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'operation')
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				// console.log("$table")
				// const selectRecords = $table.getCheckboxRecords()
				// console.log(selectRecords)
				$table.removeCheckboxRow()
				// itemsValue()
				saveProgress()
			}
		}

		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {
				const quipment = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
				var equipment_source = quipment.filter((x) => x.name === Ftype);
				// console.log(equipment_source)
				// temp_Factor = Ftype;
				var	source = stepFactor.filter((x) => x.name === equipment_source[0].fuelcategory);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}
		function getHHV(fueltype_t) {
			return parseFloat(getFactor(fueltype_t).value[0].hhv);
		}
		function calculate(data){
			var temp_data = 0;
			var cov = 1;
			var co2 = 0,
				ch4 = 0,
				n2o = 0; 
			temp_data = getFactor(data.equipment);

			if (temp_data === null) {
				console.log("-------->error");
				return 0;
			} else {
				if (data.unit === "2") {
					cov = (ConvertValue.value > 0 ? ConvertValue.value : getHHV(data.fuelcategory) );
				}

				co2 = parseFloat(temp_data.value[1].co2);
				ch4 = parseFloat(temp_data.value[2].ch4);
				n2o = parseFloat(temp_data.value[3].n2o);

				data.co2e = (cov * data.amount * (co2 + ch4 * 25/1000  + n2o * 298/1000)).toFixed(2);
				data.co2 = (cov * data.amount * co2).toFixed(2);
				data.ch4 = (cov * data.amount * ch4).toFixed(2);
				data.n2o = (cov * data.amount * n2o).toFixed(2);
			}
			return data.co2e+data.co2+data.ch4+data.n2o;//kg_CO2e.toFixed(2);
		}

		async function getItemsData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorDataByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}

		async function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			// console.log("Saving data for user: " + userid + store.state.template)
			// if(xTable.value.getTableData().visibleData === null) return
			const quipment = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
			// console.log("---------->")
			const returnUser = await getItemsData()
			// console.log("---------->111")
			// console.log("returnUser",returnUser.data.items)
			if(returnUser.data.items === undefined) return
			const olditems = JSON.parse(JSON.stringify(returnUser.data.items))
			// console.log("quipment",quipment)
			// console.log("olditems",olditems.length)
			const newitems=reactive([])
			for(let i=0;i<olditems.length;i++){
				let rst = 0;
				for(let j=0;j<quipment.length;j++)
				{
					if(quipment[j].name === olditems[i].equipment)
					{
						calculate(olditems[i])
						rst = 1;
						break;
					}
				}
				if(rst)
				{
					if(typeof(olditems[i].operation) !== "undefined") {
						const table_arry=reactive([])
						table_arry.push({
							operation: olditems[i].operation,
							process: olditems[i].process,
							equipment: olditems[i].equipment,
							co2e: olditems[i].co2e,
							co2: olditems[i].co2,
							ch4: olditems[i].ch4,
							n2o: olditems[i].n2o,
							timeperiod:{
								starttime: olditems[i].timeperiod.starttime,
								endtime: olditems[i].timeperiod.endtime
							},
							amount: olditems[i].amount,
							unit: olditems[i].unit,
							datatype: olditems[i].datatype,
							note: olditems[i].note
						})					
						newitems.push(table_arry[0])				
					}
				}
			}
			const headers = {};
			const body = {
				equipment: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData)),
				items: newitems
			};

			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		
		onMounted(async () => {
			const returnUser = await userHasData();
			loading.value = false
			if(returnUser.data.equipment === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.equipment))
			for(let i=0;i<databuf.length;i++){
				if(typeof(databuf[i].name) !== "undefined") {
					const table_arry=reactive([])
					cityBycode(databuf[i].location)
					table_arry.push({
						name: databuf[i].name,
						fueltype: databuf[i].fueltype,
						fuelcategory: databuf[i].fuelcategory,
						cost: databuf[i].cost,
						location: databuf[i].location,
						location_city: temp_city,
						location_county: temp_country,
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			loading,
			tableData,
			formRules,
			miniState: ref(true),
			AllConuntryStates,
			countryChangeEvent,
			cityChangeEvent,
			getFuelType,
			getCategory,
			removeSelectRowEvent,
			insertEquipment,
			saveProgress,
			AllCityStates,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>

.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>
