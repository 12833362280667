<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/vehicleprofile')">
					<q-item-section avatar>
						<q-icon name="apartment" />
					</q-item-section>

					<q-item-section>
						<div>	Vehicle Management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/vehiclecacl')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Vehicle Usage Management</div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>
		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Vehicle Usage Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>This section is designed to compile a detailed inventory of vehicle usage (by fuel amount or by mile) for each vehicle or motor equipment over specific periods.</div>
							<div style="margin-top: 15px;">Please record the usage of your vehicle or motor equipment for a specified period. To begin, click "Add Vehicle," then double-click the new row that appears. In the pop-up window, you can select the vehicle or motor equipment from the list created in the "Vehicle Management" section. Also, specify the calculation type (by mile or the amount of fuel) amount used, and the data source.</div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertVehicleUsage()">Add Vehicle</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
							<!-- <vxe-button @click="saveProgress()">SAVE</vxe-button> -->
						</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						@cell-dblclick="cellDBLClickEvent"
						header-align="center"
						:auto-resize="true"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column type="checkbox" width="60"></vxe-column>
						<vxe-column field="vehicle" title="Vehicle">
							<template #default="{ row }">
								<span>{{ row.vehicle }}</span>
							</template>
						</vxe-column>
						<vxe-column field="timeperiod" title="Time Period" :span="12" :edit-render="{}">
							<template #default="{ row }">
								<span v-if="row.timeperiod.starttime !==0 || row.timeperiod.endtime !==0">{{ row.timeperiod.starttime }}{{ "-" }}{{ row.timeperiod.endtime }}</span>
							</template>
						</vxe-column>
						<vxe-column field="calculationtype" title="Calculation type">
							<template #default="{ row }">
								<span>{{ row.calculationtype }}</span>
							</template>
						</vxe-column>
						<vxe-column field="amount" title="Usage Amount" :span="12" :item-render="{}">
							<template #default="{ row }">
								<span>{{ row.amount }}{{" "}}{{row.unit}}</span>							
							</template>
						</vxe-column>
						<!-- <vxe-column field="unit" title="Unit">
							<template #default="{ row }">
								<div>{{row.unit}}</div>
							</template>
						</vxe-column> -->
						<vxe-colgroup title="GHG Emissions">
								<vxe-column field="co2" title="CO2 (kg)">
									<template #default="{ row }">
										<div>{{row.co2}}</div>
									</template>
								</vxe-column>
								<vxe-column field="ch4" title="CH4 (g)">
									<template #default="{ row }">
										<div>{{row.ch4}}</div>
									</template>
								</vxe-column>
								<vxe-column field="n2o" title="N2O (g)">
									<template #default="{ row }">
										<div>{{row.n2o}}</div>
									</template>
								</vxe-column>
								<vxe-column field="co2e" title="CO2e (kg)">
									<template #default="{ row }">
										<div>{{row.co2e}}</div>
									</template>
								</vxe-column>
						</vxe-colgroup>
					</vxe-table>
					<vxe-modal v-model="showEdit" :title="selectRow ? 'EDIT&SAVE' : 'ADD&SAVE'" width="800" min-width="600" min-height="300" :loading="submitLoading" resize destroy-on-close>
						<template #default>
							<vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">
								<!-- <vxe-form-item :title="title_text" :titleAlign="center" :title-width="400" :span="24"></vxe-form-item> -->
								<vxe-form-item field="vehicle" title="Vehicle" :span="12" :edit-render="{}">
									<template #default="{ data }">
										<vxe-select v-model="data.vehicle" transfer placeholder="Please select" v-if="vehicle_option.length>0">
											<vxe-option v-for="item in vehicle_option" :key="item.license_plate" :value="item.license_plate" :label="item.license_plate"></vxe-option>
										</vxe-select>
										<vxe-select
											v-else
											placeholder="Please select"
										>
											<vxe-option>null</vxe-option>		 
										</vxe-select>										
									</template>
								</vxe-form-item>
								<vxe-form-item field="timeperiod" title="Time Period" :span="12" :edit-render="{}">
									<!-- <template #default="{ data }">
										<span v-if="data.timeperiod.starttime !=='' || data.timeperiod.endtime !==''">{{ data.timeperiod.starttime }}{{ "-" }}{{ data.timeperiod.endtime }}</span>
									</template> -->
									<template #default="{ data }">
										<div class="demo-date-picker">
											<div class="block">
												<el-date-picker
													v-model="data.timeperiod.starttime"
													type="date"
													format="YYYY/MM/DD"
													value-format="YYYY-MM-DD"
													placeholder="Start date"
													:size="small"
													style="width:50%"
													/>
												<el-date-picker
													v-model="data.timeperiod.endtime"
													type="date"
													format="YYYY/MM/DD"
													value-format="YYYY-MM-DD"
													placeholder="End date"
													:size="small"
													style="width:50%"
													/>
											</div>
										</div>
									</template>
								</vxe-form-item>
								<!-- <vxe-option  :key="3" value="Rail transport" label="Rail transport"></vxe-option> -->
								<vxe-form-item field="calculationtype" title="Calculation type" :span="12" :item-render="{}">
									<template #default="{ data }">
										<vxe-select v-model="data.calculationtype" transfer placeholder="Please select" @change="sureUnit(data)" @click="sure_option=get_vehicle_type(data)">
											<vxe-option  :key="1" value="Vehicle mile" label="Vehicle mile" 
											v-if="sure_option ==='Passenger Cars' || sure_option ==='Light-Duty Trucks' || sure_option ==='Medium-Duty Trucks' || sure_option ==='Heavy-Duty Trucks'"
											></vxe-option>
											<vxe-option  :key="2" value="Ton-mile" label="Ton-mile"
											v-if="sure_option ==='Medium-Duty Trucks' || sure_option ==='Heavy-Duty Trucks' || sure_option ==='Ships and Boats' || sure_option ==='Aircraft'"
											></vxe-option>
											
											<vxe-option  :key="4" value="Spend" label="Spend"
											v-if="sure_option ==='Light-Duty Trucks' || sure_option ==='Medium-Duty Trucks' || sure_option ==='Heavy-Duty Trucks' || sure_option ==='Ships and Boats' || sure_option ==='Aircraft'"
											></vxe-option>
											<vxe-option  :key="5" value="Vehicle fuel" label="Vehicle fuel"
											v-if="sure_option ==='Passenger Cars' || sure_option ==='Light-Duty Trucks' || sure_option ==='Medium-Duty Trucks' || sure_option ==='Heavy-Duty Trucks' || sure_option ==='Buses' || sure_option ==='Motorcycles'"
											></vxe-option>
										</vxe-select>
									</template>
								</vxe-form-item>

								<vxe-form-item field="amount" title="Amount" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-input v-model="data.amount" type="number" placeholder="Enter Amount"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item field="unit" title="Units" :span="12" :item-render="{}">
									<template #default="{ data }">
										<vxe-input disabled="true" placeholder="" v-model="data.unit"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item align="center" title-align="center" :span="24">
									<template #default="{ data }">
									<vxe-button type="submit" @click="SaveFunction(data)">Save</vxe-button>
									<vxe-button type="reset">Clear</vxe-button>
									</template>
								</vxe-form-item>
							</vxe-form>
						</template>
					</vxe-modal>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import AllConuntryStates from "@/components/formdata/allcountries.json";
import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
import stepFactor from "../carbondata/factortype.json"

import Guid from 'guid'

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			vehicle: '',
			timeperiod:
			{
				starttime: 0,
				endtime: 0,
			},
			calculationtype: '',
			amount: 0,
			unit: '',
			co2e: 0,
			co2: 0,
			ch4: 0,
			n2o: 0,
			
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const AllCityStates = ref()
		const rowNum = ref();
		// var temp_city,temp_country
		// const ConvertValue = ref("")	
		const vehicle_option = []
				
		function getFuelType(FuelTypeStr){
			if (FuelTypeStr == null) {
				console.error("selected val is null");
			} else {
				var source = stepSource.filter((x) => x.value === FuelTypeStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", FuelTypeStr);
					return null;
				}
			}
		}
		function getCategory(categoryStr) {
			if (categoryStr == null || categoryStr == "" ) {
				console.error("selected val is null");
			} else {
				// console.log(categoryStr)
				// console.log(stepSource)
				var source = stepSource[0].children.filter((x) => x.label === categoryStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].suboptions);
					return source[0].suboptions;
				} else {
					console.error("Failed to find the step", categoryStr);
					return null;
				}
			}
		}
		function getLicense_plate(license_plate){
			if (license_plate == null) {
				console.error("selected val is null");
			} else {
				console.log(license_plate)
				var source = vehicle_option.filter((x) => x.license_plate === license_plate);
				if (source.length >= 1) {
					// console.log(source.length );
					console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", license_plate);
					return null;
				}
			}
		}
		const insertVehicleUsage = async (row) => {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			const $table = xTable.value
			const get_Guid = Guid.raw()
			if ($table) {
				const record = {
					transactionId: get_Guid,
					id: get_Guid,
					userId: userid,
					transactionType: "vehicleusage",
					scope: 0x12,
					timeperiod: {
						starttime: '',
						endtime: ''
					},
					amount: '',
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'vehicle')
				const headers = {};
				const body = {
					operatingData: record
				};
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
						record.transactionId,
						body,
					{ headers }
				);

				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		function remove_rowData(id)
		{
			const headers = {};
			let response = axios.delete(
				"https://testdynimosazure.azurewebsites.net/api/deleteCalculatorOperatingByGuid?id=" +
				id,
				// 	body,
				{ headers }
			);
			return response
		}


		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				const selectRecords = $table.getCheckboxRecords()
				for(let i=0;i<selectRecords.length;i++)
				{
					if (remove_rowData(selectRecords[i].id)) 
					{
						$table.removeCheckboxRow()
					}
				}
				// itemsValue()
				// saveProgress()
			}
		}
		const editEvent = (row,rowIndex) => {
			Object.assign(formData, row)
			selectRow.value = row
			showEdit.value = true
			rowNum.value = rowIndex
			// console.log("rowNum.value")
			// console.log(rowNum.value)
		}
		const cellDBLClickEvent = ({ row ,rowIndex}) => {
			editEvent(row,rowIndex)
		}
		const submitEvent = () => {
			submitLoading.value = true
			// setTimeout(() => {
				const $table = xTable.value
				if ($table) {
				submitLoading.value = false
				showEdit.value = false
				if (selectRow.value) {
					// VXETable.modal.message({ content: 'Save successfully', status: 'success' })
					Object.assign(selectRow.value, formData)
					// console.log("submitEvent")
					// console.log(JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData)))
				} else {
					// VXETable.modal.message({ content: 'Successfully added', status: 'success' })
					$table.insert({ ...formData })
				}
				}
			// }, 500)
		}

		function sureUnit(row){
			if(row.calculationtype === "Vehicle mile")
			{
				row.unit = "Mile"
			}
			else if(row.calculationtype === "Vehicle fuel")
			{
				const temp_vehicle_config = getLicense_plate(row.vehicle)
				if(temp_vehicle_config.vehicle_type === "Ships and Boats" || temp_vehicle_config.vehicle_type === "Aircraft")
					row.unit = "Gallon"
				else
					row.unit = "Mile"
			}
			else if(row.calculationtype === "Ton-mile")
			{
				row.unit = "Mile"
			}
			else if(row.calculationtype === "Spend")
			{
				row.unit = "USD"
			}
		}

		function get_vehicle_type(row){
			if(row.vehicle != "")
			{
				var temp_vehicle_type
				var temp_truck 
				const temp_vehicle_config = getLicense_plate(row.vehicle)
				console.log(temp_vehicle_config.vehicle_type)
				if(temp_vehicle_config.vehicle_type == "Trucks")
				{
					if(temp_vehicle_config.gvwr<6.4)
					{
						temp_truck = "Light-Duty "
					}
					else if(temp_vehicle_config.gvwr<11.8)
					{
						temp_truck = "Medium-Duty "
					}
					else
					{
						temp_truck = "Heavy-Duty "
					}
					temp_vehicle_type = temp_truck + temp_vehicle_config.vehicle_type
				}else{
					temp_vehicle_type = temp_vehicle_config.vehicle_type
				}
				console.log(temp_vehicle_type)
				return temp_vehicle_type
			}
		}

		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {

				var	source = stepFactor.filter((x) => x.name === Ftype);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}

		function SaveFunction(data)
		{
			submitEvent()
			// console.log(data)
			caclfunc(data)
			// itemsValue()
			// setTimeout(()=>{
			// 	saveProgress()
			// },1000)
		}

		function caclfunc(row)
		{
			// var cov = 1;
			var CO2_MotorGasoline = 8.78,
				CO2_Diesel = 10.21,
				CO2_Methanol = 4.1,
				CO2_Ethanol = 5.75,
				CO2_CNG = 0.05444,
				CO2_LPG = 5.68,
				CO2_LNG = 4.50,
				CO2_Biodiesel = 9.45,
				CO2_ResidualFuelOil = 11.27,
				CO2_AviationGasoline = 8.31,
				CO2_JetFuel = 9.75;
				var temp_mpg = 0;
			var co2 = 0,
				ch4 = 0,
				n2o = 0,
				co2e = 0
			var temp_title
			var temp_truck 
			const temp_vehicle_config = getLicense_plate(row.vehicle)
			if(temp_vehicle_config.ownership === "Own")
			{
				row.scope = 0x12
			}else row.scope = 0x31
			if(temp_vehicle_config.vehicle_type === "Trucks")
			{
				if(temp_vehicle_config.gvwr<6.4)
				{
					temp_truck = "Light-Duty "
				}
				else if(temp_vehicle_config.gvwr<11.8)
				{
					temp_truck = "Medium-Duty "
				}
				else
				{
					temp_truck = "Heavy-Duty "
				}
				if(temp_vehicle_config.fule_type == "Gasoline" || temp_vehicle_config.fule_type == "Diesel"){
					temp_title = temp_truck + temp_vehicle_config.vehicle_type + " " + temp_vehicle_config.fule_type + " " + temp_vehicle_config.year
				}else{
					temp_title = temp_truck + temp_vehicle_config.vehicle_type + " " + temp_vehicle_config.fule_type
				}
			}else{
				if(temp_vehicle_config.fule_type == "Gasoline" || temp_vehicle_config.fule_type == "Diesel"){
					temp_title = temp_vehicle_config.vehicle_type + " " + temp_vehicle_config.fule_type + " " + temp_vehicle_config.year
				}else{
					temp_title = temp_vehicle_config.vehicle_type + " " + temp_vehicle_config.fule_type
				}
			}
			if(row.vehicle != "")
			{
				console.log("temp_title",temp_title)
				const temp_data = getFactor(temp_title)
console.log(temp_data)
				ch4 = row.amount * temp_data.value[0].ch4;
				n2o = row.amount * temp_data.value[1].n2o;

				if (row.unit === "Mile") {
					switch(temp_vehicle_config.fule_type)
					{
						case "Gasoline":
							temp_mpg = CO2_MotorGasoline;
						break;
						case "Diesel":
							temp_mpg = CO2_Diesel;
						break;
						case "Methanol":
							temp_mpg = CO2_Methanol;
						break;
						case "CNG":
							temp_mpg = CO2_CNG;
						break;
						case "Ethanol":
							temp_mpg = CO2_Ethanol;
						break;
						case "LPG":
							temp_mpg = CO2_LPG;
						break;
						case "LNG":
							temp_mpg = CO2_LNG;
						break;
						case "Biodiesel":
							temp_mpg = CO2_Biodiesel;
						break;
						default:
							temp_mpg = CO2_MotorGasoline;
						break;
					}
					// cov = ConvertValue.value > 0 ? ConvertValue.value : getMPG();
					console.log("------------")
					co2e = (ch4 * 25) / 1000 + (n2o * 298) / 1000 + temp_mpg * row.amount / temp_data.value[2].mpg;
					co2 = temp_mpg * row.amount / temp_data.value[2].mpg;
				}else if(row.unit === "Gallon"){
					if(temp_vehicle_config.fule_type === 'Diesel' || temp_vehicle_config.fule_type === 'Diesel Equipment' || temp_vehicle_config.fule_type === 'Diesel Off-Road Trucks')
					{
						temp_mpg = CO2_Diesel;
					}
					else if(temp_vehicle_config.fule_type === 'Residual Fuel Oil')
					{
						temp_mpg = CO2_ResidualFuelOil;
					}
					else if(temp_vehicle_config.fule_type === 'Aviation Gasoline')
					{
						temp_mpg = CO2_AviationGasoline;
					}
					else if(temp_vehicle_config.fule_type === 'LPG')
					{
						temp_mpg = CO2_LPG;
					}
					else if(temp_vehicle_config.fule_type === 'Jet Fuel')
					{
						temp_mpg = CO2_JetFuel;
					}
					else   //Gasoline、Gasoline (2 stroke)、Gasoline (4 stroke)、Gasoline Off-Road Trucks ...
					{
						temp_mpg = CO2_MotorGasoline;
					}
					co2 = temp_mpg * row.amount;
					co2e = (ch4 * 25) / 1000 + (n2o * 298) / 1000 + co2;
				}
				row.ch4 = ch4.toFixed(2)
				row.n2o = n2o.toFixed(2)
				row.co2 = co2.toFixed(2)
				row.co2e = co2e.toFixed(2)

				const headers = {};
				const body = {
					operatingData: row,
				};
				// console.log(row)
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
					row.id,
						body,
					{ headers }
				);
				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});

				// saveProgress()
			}
		}

		async function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			// console.log("Saving data for user: " + userid)
	
			const headers = {};
			const body = {
				vehicle_usage: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
			};

			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"vehicleusage"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		async function getLicense_plateData() {
            const usercode = JSON.stringify(user.value, null, 2);
            const userid = JSON.parse(usercode)["sub"];
            setTimeout(()=>{
                // console.log("setTimeout")
                if(loading.value === true)
                {
                    //router.push('/home')
                }
                
            },10000)
            let response = await axios.get(
                "https://testdynimosazure.azurewebsites.net/api/getCalculatorVehicleProfileByUser?id=" + userid,
                {
                    headers: {},
                }
            );
            if (response) {
                return response;
            }
            
            return null;
        }
		onMounted(async () => {
			const returnUser = await userHasData();
			const tempeLicense_plate = await getLicense_plateData();
			loading.value = false

			console.log(tempeLicense_plate.data)

			if(tempeLicense_plate.data.vehicle_config === undefined) return
			for(let i=0;i<tempeLicense_plate.data.vehicle_config.length;i++)
            {
				if(tempeLicense_plate.data.vehicle_config[i].license_plate != "")
				{
					vehicle_option.push(tempeLicense_plate.data.vehicle_config[i])
				}
            }

			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			for(let i=0;i<databuf.length;i++){
				// if(typeof(databuf[i].vehicle) !== "undefined") 
				{
					const table_arry=reactive([])
					// cityBycode(databuf[i].location)
					table_arry.push({
						transactionId: databuf[i].transactionId,
						id: databuf[i].id,
						userId: databuf[i].userId,
						transactionType: databuf[i].transactionType,
						scope: databuf[i].scope,
						vehicle: databuf[i].vehicle,
						timeperiod:{
							starttime: databuf[i].timeperiod.starttime,
							endtime: databuf[i].timeperiod.endtime
						},
						calculationtype: databuf[i].calculationtype,
						amount: databuf[i].amount,
						unit: databuf[i].unit,
						co2e: databuf[i].co2e,
						co2: databuf[i].co2,
						ch4: databuf[i].ch4,
						n2o: databuf[i].n2o
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			loading,
			editEvent,
			cellDBLClickEvent,
			submitEvent,
			vehicle_option,
			tableData,
			formRules,
			miniState: ref(true),
			AllConuntryStates,
			getLicense_plate,
			getFuelType,
			getCategory,
			caclfunc,
			SaveFunction,
			sureUnit,
			get_vehicle_type,
			removeSelectRowEvent,
			insertVehicleUsage,
			saveProgress,
			AllCityStates,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>

.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>