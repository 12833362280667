<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div>
	<p>{{ step }}</p>
	<p>{{ question }}</p>
	<p>{{ guildline }}</p> -->
	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>

	<q-form @submit="onSubmit" class="q-gutter-md">
		<q-input
			v-model="answer"
			filled
			clearable
			clear-icon="close"
			placeholder="Please write your own or add our tempalte from list"
			autogrow
			counter
			:maxlength="charlimit"
			:rules="[
				(val) =>
					val.length < charlimit ||
					'Please use maximum ' + charlimit + ' characters',
			]"
		/>
		<!-- sampleanswers list -->
		<q-list bordered padding v-for="(item, index) in items" :key="index">
			<q-item v-ripple>
				<q-item-section>
					<q-item-label>{{ item.message }}</q-item-label>
				</q-item-section>

				<q-item-section top side>
					<div class="text-grey-8 q-gutter-xs">
						<q-btn
							class="gt-xs"
							color="primary"
							@click="updatemsg(item.message)"
							round
							icon="add"
						/>
					</div>
				</q-item-section>
			</q-item>
		</q-list>

		<q-separator />
		<!-- expansible sampleanswers list -->
		<q-list bordered v-for="(subAnswer, index) in subAnswerInfo" :key="index">
			<q-expansion-item
				:label="subAnswer.label"
				header-class="bg-teal text-white"
				expand-icon-class="text-white"
			>
				<q-list bordered v-for="(answer, idx) in subAnswer.answers" :key="idx">
					<q-item>
						<q-item-section>
							<q-item-label>{{ answer }}</q-item-label>
						</q-item-section>

						<q-item-section top side>
							<div class="text-grey-8 q-gutter-xs">
								<q-btn
									class="gt-xs"
									color="primary"
									@click="updatemsg(answer)"
									dense
									round
									icon="add"
								/>
							</div>
						</q-item-section>
					</q-item>
				</q-list>
			</q-expansion-item>
		</q-list>

		<ImgUploader
			v-if="picturelabel"
			:step="step"
			:label="picturelabel"
			:ratio="pictureratio"
			class="q-mt-md q-pb-md"
		/>
		<!-- <q-btn color="secondary" type="submit" label="I'm done!" /> -->
	</q-form>
</template>

<script>
import { ref, toRef, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ImgUploader from "../tools/ImgUploader.vue";

export default {
	props: [
		"label",
		"question",
		"guildline",
		"sampleanswers",
		"samplesubanswers",
		"picturelabel",
		"pictureratio",
		"charlimit",
		"step",
	],
	components: { ImgUploader },

	setup(props) {
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));

		let answer = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set(value) {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: value,
				});
			},
		});

		let subAnswerInfo = toRef(props, "samplesubanswers");

		const items = ref(
			props.sampleanswers.map((q) => {
				return {
					message: q,
					active: false,
				};
			})
		);

		onMounted(() => {
			console.log("Mounted");
		});

		return {
			items,
			answer,
			subAnswerInfo,
			updatemsg(val) {
				// TODO: add rules here, i.e. val shall not be repeat added, size of selection
				if (!answer.value) {
					answer.value = val;
				} else {
					answer.value += "\n" + val;
				}
			},
			onSubmit() {
				console.log("clicked");
			},
		};
	},
};
</script>
