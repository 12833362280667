
{/* <script async src="https://www.googletagmanager.com/gtag/js?id=TAG_ID"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'TAG_ID');
  </script>  */}


import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';;
import quasarUserOptions from "./quasar-user-options";
import router from "./router";
import store from "./store";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
import { createAuth0 } from "@auth0/auth0-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "./style.css";
import VueGtag from "vue-gtag";
import VueGtm from 'vue-gtm';
// import i18n from './i18n'

import ElementPlus from 'element-plus'
// 引入 Element Plus 默认样式文件
import 'element-plus/theme-chalk/index.css'
//引入矢量图标样式
// import "./assets/iconfont/iconfont.css"
// import "./assets/iconfont/iconfont.js"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faUser, faIdBadge, faPenToSquare } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(fas,fab, faUser, faIdBadge, faPenToSquare, )


createApp(App)
    .use(Quasar, {
        plugins: {
            Notify,
            Dialog,
            Loading,
        },
        config: {
            notify: {
                /* look at QuasarConfOptions from the API card */
            },
        },
        quasarUserOptions,
    })
    .use(
        createAuth0({
            domain: "dev-okbpp40gh1flyhwe.us.auth0.com",
            client_id: "zmAJ6KwFnLPfOc9hM7pkXDIDT80GdxIm",
            redirect_uri: window.location.origin,
        })
    )
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(VueAxios, axios)
    .use(router)
    .use(store)
    // .use(i18n)
    .use(ElementPlus)
    .use(VXETable)
    .use(VueGtag, {
        config: { id: "G-QWQFL563KW" },  //G-TQLV0E6E0P  G-QWQFL563KW
        appName: "Dynimos",
        pageTrackerScreenviewEnabled: true
    })
    .use(VueGtm, {
        // container id
        id: 'GTM-K767RJL5',
        // enable track or not (optional)
        enabled: process.env.NODE_ENV === 'production',
        // display console logs debugs or not (optional)
        debug: false 
    })
    .mount("#app");