<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="container">
		<q-parallax :height="350">
			<template v-slot:media>
				<img
					src="https://images.pexels.com/photos/2096622/pexels-photo-2096622.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
				/>
			</template>
			<template v-slot:content="scope">
				<div
					class="row justify-center"
					:style="{
						// opacity: 0.45 + (1 - scope.percentScrolled) * 0.55,
						top: scope.percentScrolled * 60 + '%',
						left: 0,
						right: 0,
					}"
				>
					<template v-if="!isAuthenticated">
						<div class="col-12">
							<div class="text-h3 text-white text-center">
								Try Our Products For Free!
							</div>

							<div class="text-body2 text-white text-center">
								Get full access to our products by simply sign up
								<p></p>
							</div>
						</div>
						<SignupButton glossy style="background: white; color: black" />
					</template>
					<template v-if="isAuthenticated">
						<div class="col-12">
							<div class="text-h3 text-white text-center">
								Try Our Products For Free!
							</div>

							<div class="text-body2 text-white text-center">
								Click Tools on the menu bar to start using
							</div>
						</div>
					</template>
				</div>
			</template>
		</q-parallax>

		<div class="row content-2">
			<div class="left">
				<p class="title started">&nbsp;Dynimos’ Fashion Product Carbon Emission Calculator&nbsp;</p>
			</div>
			<div class="col-12 big-title text-left">Dynimos’ Industry’s First Fashion Product Carbon Emission Calculation Tool</div>

			<div class="col-12 detail">
				Dynimos’ Industry’s First Fashion Product Carbon Emission Calculation Tool 
				is now available to use. Key features include: lifecycle carbon emission calculation, 
				impact of country of origin, supply chain scenario comparison, material selection insights, 
				automated data visualization. <a href="https://calendly.com/dynimos/30min" target="_blank">Book an appointment</a> with us for learning more about this new 
				digital toolset.
			</div>
		</div>
		<div class="row justify-center carouselcontent">
			<q-carousel
				arrows
				animated
				control-color="black"
				v-model="slide1"
				class="col-10 text-center shadow-2 rounded-borders img"
			>
				<q-carousel-slide
					name="first"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1727187770/ynqcrijiqepkqcouwjoj.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Individual Stage</div>
						<!-- <div class="text-subtitle1">Select source to get started</div> -->
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="second"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1727187770/wrj4opgplsklo7csbg0w.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Life Cycle Stages</div>
						<!-- <div class="text-subtitle1">Select emission type</div> -->
					</div>
				</q-carousel-slide>
			</q-carousel>
		</div>

		<div class="row content-2">
			<div class="left">
				<p class="title started">&nbsp;Dynimos Carbon Accouting&nbsp;</p>
			</div>
			<div class="col-12 big-title text-left">Calculate Carbon Emission</div>

			<div class="col-12 detail">
				Dynimos Carbon Accounting provides automated digital tools that enable
				your business to track and calculate the carbon footprint associated
				with your operations.
				<a href="https://calendly.com/dynimos/30min" target="_blank">Book a demo appointment</a>
				today for details
			</div>
		</div>
		<div class="row justify-center carouselcontent">
			<q-carousel
				arrows
				animated
				control-color="black"
				v-model="slide1"
				class="col-10 text-center shadow-2 rounded-borders img"
			>
				<q-carousel-slide
					name="first"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690040192/t1_l17cnq.jpg"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Dynimos Carbon Accouting</div>
						<div class="text-subtitle1">Select source to get started</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="second"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690040192/t2_n0wtmm.jpg"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Dynimos Carbon Accouting</div>
						<div class="text-subtitle1">Select emission type</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="third"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1690040191/t3_jsx8qy.jpg"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Dynimos Carbon Accouting</div>
						<div class="text-subtitle1">
							Fill in detailed material data in your product, Carbon footprint
							for your product
						</div>
					</div>
				</q-carousel-slide>
			</q-carousel>
		</div>

		<q-separator />
		<div class="row content-2">
			<div class="left">
				<p class="title started">&nbsp; Dynimos Calulator &nbsp;</p>
			</div>
			<div class="col-12 big-title text-left">
				Textile Life Cycle Assessment
			</div>

			<div class="col-12 detail">
				Have you ever wondered how much CO2 is generated by the clothes you are
				wearing today? We have created a fun game to help you calculate it by
				using our Dynimos textile life cycle assessment calculator. Join us in
				this interactive journey to discover the CO2 emissions associated with
				your wardrobe choices!
			</div>
		</div>
		<div class="row justify-center carouselcontent">
			<q-carousel
				arrows
				animated
				control-color="black"
				v-model="slide3"
				class="col-10 text-center shadow-2 rounded-borders img"
			>
				<q-carousel-slide
					name="first"
					img-src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679784196/DYNIMOS%20DEFAULT%20PICS/pagepics/cal-1_vboodo.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="title">Apparel Carbon footprint calculator</div>
						<div class="detail">
							Fill in your product information to get started
						</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="second"
					img-src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679784546/DYNIMOS%20DEFAULT%20PICS/pagepics/cal-2_rfwgnj.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Apparel Carbon footprint calculator</div>
						<div class="text-subtitle1">
							Fill in detailed material data in your product
						</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="third"
					img-src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679784761/DYNIMOS%20DEFAULT%20PICS/pagepics/cal-3_aqa5b0.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h6">Apparel Carbon footprint calculator</div>
						<div class="text-subtitle1">Carbon footprint for your product</div>
					</div>
				</q-carousel-slide>
			</q-carousel>
			<div class="col-10 detail">
				<p></p>
				<span>
					Disclaimer: This simple calculator aims to understand how material
					composition and country of origin (in general) can affect the carbon
					footprint of apparel products. It uses emission factors developed from
					the research literature. The results shown here do not represent a
					specific product's actual footprint. The calculator should not be used
					in professional carbon footprint accounting or published LCA studies.
				</span>
			</div>
		</div>

		<q-separator />

		<div class="row content-2">
			<div class="left">
				<p class="title started">&nbsp; Dynimos ESG Report &nbsp;</p>
			</div>
			<div class="col-12 big-title text-left">
				Dynimos ESG Report makes it easy for you to spend your time in filing
				your sustainability report
			</div>
			<div class="col-12 detail text-left">
				When you sign up for Dynimos ESG Report, you can create and manage your
				own report instantly
				<q-btn push @click="downloadPdf">
					Click to download our sample ESG Report
				</q-btn>
			</div>
		</div>
		<div class="row justify-center carouselcontent">
			<q-carousel
				arrows
				animated
				control-color="black"
				v-model="slide2"
				class="col-10 text-center shadow-2 rounded-borders img"
			>
				<q-carousel-slide
					name="first"
					img-src="https://res.cloudinary.com/dtnpwkmla/image/upload/v1683295074/dynimos/ep4bms9mmlqmbulfemus.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="title">Step 1</div>
						<div class="detail">Select your template to get started</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="second"
					img-src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679783087/DYNIMOS%20DEFAULT%20PICS/pagepics/guilde_uthdof.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h4">Step 2</div>
						<div class="text-subtitle1">
							Follow our step-by-step guidance. <br />
							Choose from example answers we provide, or enter answers of your
							own.
						</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide
					name="third"
					img-src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679783806/DYNIMOS%20DEFAULT%20PICS/pagepics/report_energy_crafsh.png"
				>
					<div class="absolute-bottom custom-caption">
						<div class="text-h4">Step 3</div>
						<div class="text-subtitle1">
							Simple one click to get your final report
						</div>
					</div>
				</q-carousel-slide>
			</q-carousel>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import Toolbar from "@/components/tools/HomeToolBar.vue";
import SignupButton from "@/buttons/signup-button.vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
	name: "OurProductPage",
	components: {
		Toolbar,
		SignupButton,
	},

	setup() {
		const { isAuthenticated } = useAuth0();
		const slide1 = ref("first");
		const slide2 = ref("first");
		const slide3 = ref("first");

		return {
			isAuthenticated,
			slide1,
			slide2,
			slide3,
			// TODO: wrap this part as a component
			downloadPdf() {
				let url = encodeURI("/ESG_Report.pdf");
				let link = document.createElement("a");
				link.href = url;
				link.download = url;
				link.click();
			},
		};
	},
	methods: {
		track() {
			this.$gtag.query("event", "page_view", {
				page_title: "Product page",
				page_path: "/products",
			});
		},
	},
	// methods: {
	// jumpPrice() {
	// 	this.$router.push({ path: "/user/price" });
	// },
	// },
};
</script>
<style lang="less" scoped>
.leftButton {
	border-radius: 20px;
}
</style>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 1200px) {
	.container1 {
		font-family: PingFang SC;
		width: 100%;
		height: 500px;
	}
	.container {
		div {
			font-family: Calibri;
		}
		.carouselcontent {
			display: flex;
			padding: 30px 30px;
			justify-content: center;

			align-items: center;
			text-align: center;
			margin-left: 10px;
			.img {
				height: 450px;
				width: 1000px;
			}
			.custom-caption {
				text-align: center;
				padding: 12px;
				color: white;
				background-color: rgba(0, 0, 0, 0.3);
			}
		}

		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 28px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.left {
				.big {
					font-size: 2.5vw;
					font-weight: 700;
					font-family: "Roboto", sans-serif;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
					line-height: 2.9vw;
				}

				.small {
					margin-top: 20px;
					font-size: 20px;
					font-weight: 400;
					font-family: "Abel", sans-serif;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				img {
					width: 40vw;
					border-radius: 50px;
				}
			}
		}

		.content-intro {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.big-title {
				font-size: 3vw;
				font-weight: 700;
				font-family: "Roboto", sans-serif;
				color: #0d0c22;
				box-sizing: border-box;
				padding-right: 5vw;
				line-height: 2.9vw;
			}

			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
		}

		.content-2 {
			display: flex;
			justify-content: space-center;
			box-sizing: border-box;
			padding: 50px 8vw;

			.img {
				width: 300px;
				margin-top: 30px;
				box-sizing: border-box;
				border: 3px solid rgb(198, 197, 197);
			}
			.detaillistitem {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
			}
			.big-title {
				font-size: 3vw;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
			}
			.left {
				.title {
					font-size: 25px;
					font-weight: 700;
					// width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 30px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (min-width: 480px) and (max-width: 1200px) {
	.with-bg {
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
	.container {
		div {
			font-family: Calibri;
		}
		.carouselcontent {
			display: flex;
			padding: 30px 30px;
			justify-content: center;

			align-items: center;
			text-align: center;
			margin-left: 10px;
			.img {
				height: 350px;
				width: 800px;
			}
			.custom-caption {
				text-align: center;
				padding: 12px;
				color: white;
				background-color: rgba(0, 0, 0, 0.3);
			}
		}

		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 28px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.left {
				.big {
					font-size: 2.5vw;
					font-weight: 700;
					font-family: "Roboto", sans-serif;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
					line-height: 2.9vw;
				}

				.small {
					margin-top: 20px;
					font-size: 20px;
					font-weight: 400;
					font-family: "Abel", sans-serif;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				img {
					width: 40vw;
					border-radius: 50px;
				}
			}
		}

		.content-intro {
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 30px 8vw;
			margin-top: 30px;

			.big-title {
				font-size: 3vw;
				font-weight: 700;
				font-family: "Roboto", sans-serif;
				color: #0d0c22;
				box-sizing: border-box;
				padding-right: 5vw;
				line-height: 2.9vw;
			}

			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
		}

		.content-2 {
			display: flex;
			justify-content: space-center;
			box-sizing: border-box;
			padding: 20px 8vw;
			.img {
				width: 300px;
				margin-top: 30px;
				box-sizing: border-box;
				border: 3px solid rgb(198, 197, 197);
			}
			.detaillistitem {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
			}
			.big-title {
				font-size: 3vw;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
			}
			.left {
				width: 220px;
				.title {
					font-size: 23px;
					font-weight: 700;
					// width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 60px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
	.content-2 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 50px 8vw;
		margin-top: 30px;

		.left {
			margin-bottom: 30px;

			.title {
				font-size: 24px;
				font-weight: 700;
				width: 100% !important;
				color: rgb(13, 12, 34);
			}

			.detail {
				font-size: 18px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 50px;
				width: 100% !important;
				color: #3d3d4e;
			}
		}

		.right {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.item {
				width: 30vw !important;

				.detail {
					font-size: 16px;
					font-weight: 600;
					width: 20vw !important;
					height: 100px;
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (max-width: 480px) {
	.container {
		div {
			font-family: Calibri;
		}
		.carouselcontent {
			display: flex;
			padding: 30px 30px;
			justify-content: center;

			align-items: center;
			text-align: center;
			margin-left: 10px;
			.img {
				height: 150px;
				width: 400px;
			}
			.custom-caption {
				text-align: center;
				padding: 12px;
				color: white;
				background-color: rgba(0, 0, 0, 0.3);
				.title {
					font-size: 4vw;
					font-weight: 700;
					width: 300px;
					color: white;
				}
				.detail {
					font-size: 4vw;
					font-weight: 400;
					width: 300px;
					color: white;
				}
			}
		}
		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 18px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;

			.left {
				.big {
					font-size: 6vw;
					font-weight: 700;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
				}

				.small {
					margin-top: 10px;
					font-size: 18px;
					font-weight: 400;
					font-weight: normal;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				margin-top: 20px;

				img {
					width: 100%;
					border-radius: 50px;
				}
			}
		}

		.content-2 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 18px;

			.left {
				.title {
					font-size: 6vw;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				// display: flex;
				// justify-content: space-between;
				box-sizing: border-box;

				.item {
					margin-top: 50px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						margin-top: 20px;
					}

					.detail {
						margin-top: 20px;
						font-size: 16px;
						font-weight: 600;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
			.big-title {
				font-size: 20px;
				font-weight: 600;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 12px;
				margin-bottom: 8px;
			}
			.detail {
				margin-top: 16px;
				font-size: 18px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
			}
		}

		.content-3 {
			box-sizing: border-box;
			padding: 50px 8vw;

			.left {
				.left-title {
					font-size: 21px;
					font-weight: 800;
					margin-top: 20px;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
				}
			}

			.right {
				.title {
					text-align: center;
					font-size: 30px;
					font-weight: 500;
					color: rgb(79, 113, 190);
					margin-top: 30px;
				}

				.imglist {
					.item {
						display: flex;
						align-items: center;
						flex-direction: column;
						margin-top: 20px;

						img {
							width: 80vw;
						}

						.item-title {
							font-size: 25px;
							font-weight: 500;
							margin-top: 20px;
							margin-bottom: 20px;
						}

						.item-detail {
							font-size: 16px;
							font-weight: 500;
							width: 100%;
						}
					}
				}
			}
		}

		.content-4 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 30px;

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					white-space: nowrap;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					height: 50px;
					font-size: 18px;
					font-weight: 600;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				box-sizing: border-box;
				margin-top: 50px;

				.item {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					margin-top: 20px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 40px;
						margin-top: 20px;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
		}

		.footer {
			padding: 20px 10px;
			box-sizing: border-box;
			border-top: 1px solid rgb(231, 231, 233);
			color: #6e6d7a;
		}
	}
	.with-bg {
		border-radius: 5px;
		height: 50px;
		font-size: 18px;
		font-weight: 600;
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
}
</style>
