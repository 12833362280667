<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/wasteinventory')">
					<q-item-section avatar>
						<q-icon name="apartment" />
					</q-item-section>

					<q-item-section>
						<div>	Waste Inventory management</div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>
		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Waste Inventory Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>This section is designed to compile a detailed inventory of waste for each period of time. Click "Add new time period" then choose time period, and double-click the new row that appears. In the pop-up window, you can add all the waste types for the time period. Also, specify the treatment, amount, and the unit. </div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertWaste()">Add new time period</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
						</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						@cell-dblclick="cellDBLClickEvent"
						header-align="center"
						:auto-resize="true"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column type="checkbox" width="60"></vxe-column>
						<vxe-column field="timeperiod" title="Time Period" :span="12" :edit-render="{}">
							<template #default="{ row }">
								<span v-if="row.timeperiod.starttime !==0 || row.timeperiod.endtime !==0">{{ row.timeperiod.starttime }}{{ "-" }}{{ row.timeperiod.endtime }}</span>
							</template>
							<template #edit="{ row }">
								<div class="demo-date-picker">
									<div class="block">
										<el-date-picker
											v-model="row.timeperiod.starttime"
											type="date"
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
											placeholder="Start date"
											:size="small"
											style="width:50%"
											/>
										<el-date-picker
											v-model="row.timeperiod.endtime"
											type="date"
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
											placeholder="End date"
											:size="small"
											style="width:50%"
											/>
									</div>
								</div>
							</template>
						</vxe-column>
						<!-- <vxe-column field="wastetype" title="Waste type">
							<template #default="{ row }">
								<span>{{ row.wastetype }}</span>
							</template>
						</vxe-column> -->
						<vxe-column field="co2e" title="CO2e (kg)">
							<template #default="{ row }">
								<div>{{row.co2e}}</div>
							</template>
						</vxe-column>
					</vxe-table>
					<vxe-modal v-model="showEdit" :title="selectRow ? 'EDIT&SAVE' : 'ADD&SAVE'" width="800" min-width="600" min-height="300" :loading="submitLoading" resize destroy-on-close>
						<!-- <vxe-form :data="tableData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent"> -->
						<!-- <template #default> -->
							<vxe-toolbar>
								<template #buttons>
									<vxe-button icon="vxe-icon-square-plus" @click="insertWasteTreatment(-1)">Add Waste</vxe-button>
									<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
								</template>
							</vxe-toolbar>
							<vxe-table
								border
								:loading="loading"
								:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
								show-overflow
								:edit-config="{trigger: 'click', mode: 'cell'}"
								:column-config="{resizable: true}"
								:row-config="{isHover: true}"
								:data="tableData"
								empty-text="Empty Table"
								align="center"
								header-align="center"
								:auto-resize="true"
							>
								<!-- <vxe-column type="seq" width="60"></vxe-column> -->
								<vxe-column type="checkbox" width="60"></vxe-column>
								<vxe-column field="wastetype" title="Waste type">
									<template #default="{ data }">
										<vxe-select v-model="data.wastetype" transfer placeholder="Select Waste">
											<vxe-option v-for="item in Waste_option" :key="item.fueltype" :value="item.fueltype" :label="item.fueltype"></vxe-option>
										</vxe-select>
									</template>
								</vxe-column>
								<vxe-column field="treatment" title="Treatment">
									<template #default="{ data }">
										<vxe-select v-model="data.treatment" transfer placeholder="Select Treatment">
											<vxe-option v-for="item in getTreatment(data.wastetype)" :key="item" :value="item" :label="item"></vxe-option>
										</vxe-select>
									</template>
								</vxe-column>
								<vxe-column field="amount" title="Amount">
									<template #default="{ data }">
										<vxe-input v-model="data.amount" type="number" placeholder="Enter Amount"></vxe-input>
									</template>
								</vxe-column>
								<vxe-column field="unit" title="Units" :span="12" :item-render="{}">
									<template #default="{ data }">
										<vxe-input disabled="true" placeholder="Ton" v-model="data.unit"></vxe-input>
									</template>
								</vxe-column>
							</vxe-table>
							
								<!-- <vxe-form-item field="wastetype" title="Waste type" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-select v-model="data.wastetype" transfer placeholder="Select Wwaste">
										<vxe-option v-for="item in Waste_option" :key="item.fueltype" :value="item.fueltype" :label="item.fueltype"></vxe-option>
									</vxe-select>
									</template>
								</vxe-form-item>
								<vxe-form-item field="treatment" title="Treatment" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-select v-model="data.treatment" transfer placeholder="Select Wwaste">
										<vxe-option v-for="item in getTreatment(data.wastetype)" :key="item" :value="item" :label="item"></vxe-option>
									</vxe-select>
									</template>
								</vxe-form-item>

								<vxe-form-item field="amount" title="Amount" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-input v-model="data.amount" type="number" placeholder="Enter Amount"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item field="unit" title="Units" :span="12" :item-render="{}">
									<template #default="{ data }">
										<vxe-input disabled="true" placeholder="Ton" v-model="data.unit"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item align="center" title-align="center" :span="24">
									<template #default="{ data }">
									<vxe-button type="submit" @click="SaveFunction(data)">Save</vxe-button>
									<vxe-button type="reset">Clear</vxe-button>
									</template>
								</vxe-form-item> -->
							<!-- </vxe-form> -->
						<!-- </template> -->
					</vxe-modal>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
import stepFactor from "../carbondata/factortype.json"

import Guid from 'guid'

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			timeperiod:
			{
				starttime: 0,
				endtime: 0,
			},
			detail:[{
				wastetype: '',
				treatment:'',
				amount: 0,
				unit: '',
			}],
			co2e: 0,			
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const rowNum = ref();
		const Waste_option = ref([])
				
		function getWasteOption() {
			var source = stepSource.filter((x) => x.label === "Waste-generated");
			// console.log(source[0].children[0].suboptions)
			if (source.length >= 1) {
				return source[0].children[0].suboptions;
			} else {
				console.error("Failed to find the step",);
				return null;
			}
		}
		function getTreatment(fueltype) {
			var source = Waste_option.value.filter((x) => x.fueltype === fueltype);
			if (source.length >= 1) {
				return source[0].option;
			} else {
				console.error("Failed to find the step",);
				return null;
			}
		}
		
		const insertWaste = async (row) => {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			const $table = xTable.value
			const get_Guid = Guid.raw()
			if ($table) {
				const record = {
					transactionId: get_Guid,
					id: get_Guid,
					userId: userid,
					transactionType: "wasteinventory",
					scope: 0x31,
					timeperiod: {
						starttime: '',
						endtime: ''
					},
					detail:[{
						wastetype: '123456',
						treatment:'78946',
						amount: 0,
						unit: '',
					}],
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'wastetype')

				console.log("xTable",xTable.value.getTableData().visibleData)
				console.log("xTable",tableData)
				const headers = {};
				const body = {
					operatingData: record
				};
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
						record.transactionId,
						body,
					{ headers }
				);

				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
			}
		}

		const insertWasteTreatment = async (row) => {
			const $table = xTable.value
			
			if ($table) {
				const record = {
					wastetype: '',
					treatment: '',
					amount: '',
					unit: ''
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'wastetype')
				console.log(xTable.value.getTableData().visibleData)
				// const headers = {};
				// const body = {
				// 	operatingData: record
				// };
				// axios.post(
				// 	"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
				// 		record.transactionId,
				// 		body,
				// 	{ headers }
				// );

				// $q.notify({
				// 	color: "secondary",
				// 	textColor: "white",
				// 	icon: "done",
				// 	message: "Progress saved",
				// });
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		function remove_rowData(id)
		{
			const headers = {};
			let response = axios.delete(
				"https://testdynimosazure.azurewebsites.net/api/deleteCalculatorOperatingByGuid?id=" +
				id,
				// 	body,
				{ headers }
			);
			return response
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				const selectRecords = $table.getCheckboxRecords()
				for(let i=0;i<selectRecords.length;i++)
				{
					if (remove_rowData(selectRecords[i].id)) 
					{
						$table.removeCheckboxRow()
					}
				}
			}
		}
		const editEvent = (row,rowIndex) => {
			Object.assign(formData, row)
			selectRow.value = row
			showEdit.value = true
			rowNum.value = rowIndex
			// console.log("rowNum.value")
			// console.log(rowNum.value)
		}
		const cellDBLClickEvent = ({ row ,rowIndex}) => {
			editEvent(row,rowIndex)
		}
		const submitEvent = () => {
			submitLoading.value = true
				const $table = xTable.value
				if ($table) {
				submitLoading.value = false
				showEdit.value = false
				if (selectRow.value) {
					Object.assign(selectRow.value, formData)
				} else {
					$table.insert({ ...formData })
				}
				}

		}

		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {

				var	source = stepFactor.filter((x) => x.name === Ftype);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}

		function SaveFunction(data)
		{
			submitEvent()
			caclfunc(data)
		}

		function caclfunc(row)
		{
			var co2 = 0
			var temp_title

				temp_title = row.wastetype + " " + row.treatment
				// console.log("temp_title",temp_title)
				const temp_data = getFactor(temp_title)

				co2 = parseFloat(temp_data.value[0].co2);
				row.co2e = row.amount * co2;

				const headers = {};
				const body = {
					operatingData: row,
				};
				// console.log(row)
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
					row.id,
						body,
					{ headers }
				);
				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});			
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"wasteinventory"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}

		onMounted(async () => {
			const returnUser = await userHasData();
			Waste_option.value = getWasteOption()
			loading.value = false
			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			for(let i=0;i<databuf.length;i++){
				const table_arry=reactive([])
				table_arry.push({
					transactionId: databuf[i].transactionId,
					id: databuf[i].id,
					userId: databuf[i].userId,
					transactionType: databuf[i].transactionType,
					scope: databuf[i].scope,
					timeperiod:{
						starttime: databuf[i].timeperiod.starttime,
						endtime: databuf[i].timeperiod.endtime
					},
					wastetype: databuf[i].wastetype,
					treatment:databuf[i].treatment,
					amount: databuf[i].amount,
					unit: databuf[i].unit,
					co2e: databuf[i].co2e
				})					
				rowNum.value += 1 
				insertTable(-1,table_arry)					
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			loading,
			editEvent,
			cellDBLClickEvent,
			submitEvent,
			Waste_option,
			tableData,
			formRules,
			miniState: ref(true),
			caclfunc,
			SaveFunction,
			getWasteOption,
			getTreatment,
			removeSelectRowEvent,
			insertWaste,
			insertWasteTreatment
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>
.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>