<template>
	<q-header elevated class="glossy">
		<Toolbar />
	</q-header>
	<div>
		<flow-form v-on:complete="onComplete">
			<question
				v-for="(question, index) in questions"
				v-bind="question"
				v-bind:key="'m' + index"
				v-model="question.model"
			>
			</question>
			<template v-slot:complete>
				<div class="f-section-wrap">
					<div
						v-if="
							questions[0].model === 's_1_y' &&
							questions[1].model === 's_2_y' &&
							questions[2].model === 's_3_y'
						"
					>
						<!-- <span class="f-tagline"
							>This is page &gt; Calculation_Dual Report</span
						>
						<div>
							<span class="fh2"> This is Dual Report Calculation Page </span>
							<p class="f-description">
								<span> Thank You 😊. </span>
							</p>
						</div> -->
						{{ ClickByName('CalculaterDetailPage',getStep(),0,1) }}
					</div>
					<div
						v-if="
								questions[0].model === 's_1_n' && questions[2].model === 's_3_y'
							"
					>
						{{ ClickByName('CalculaterDetailPage',getStep(),0,1) }}
					</div>
					<div
						v-if="
							questions[0].model === 's_1_y' && questions[1].model === 's_2_n'
						"
					>
						<span class="f-tagline">
							The electricity (renewable or nonrenewable) used for you own
							operation is counted as scope 1 emission. The renewable energy (in
							Scope 1) you generated has an emission factor of 0 tCO2e/MWh.
						</span>
						<div>
							<span class="fh2">
								For scope 2, you only need to report the amount of electricity
								you use from the grid or other electricity supplier.
							</span>
							<p class="f-description">
								<q-btn @click="$router.go('/scope2')"> Start Over</q-btn>
							</p>
						</div>
					</div>
					<div
						v-if="
							(questions[0].model === 's_1_n' &&
								questions[2].model === 's_3_n') ||
							(questions[0].model === 's_1_y' &&
								questions[1].model === 's_2_y' &&
								questions[2].model === 's_3_n')
						"
					>
						<!-- <span class="f-tagline"
							>This is page &gt; Calculation_LocationBased
						</span>
						<div>
							<span class="fh2">This is LocationBased Calculation page</span>
							<p class="f-description">
								<span> Thank You 😊. </span>
							</p>
						</div> -->
						{{ ClickByName('CalculaterDetailPage',getStep(),0,0) }}
						<!-- <CalculaterDetail></CalculaterDetail> -->
					</div>
				</div>
			</template>

			<template v-slot:completeButton>
				<div class="f-submit">
					<!-- Leave empty to hide default submit button -->
				</div>
			</template>
		</flow-form>
	</div>
</template>

<script>
// Import necessary components and classes
import { FlowForm, Question } from "@ditdot-dev/vue-flow-form";
import Toolbar from "@/components/tools/HomeToolBar.vue";
// import CalculaterDetail from "@/components/calculator/CalculaterDetailPage.vue"
import {  useRouter } from "vue-router";
import stepSource from "../carbondata/sourcetype.json";

export default {
	components: {
		Toolbar,
		FlowForm,
		Question,
		// CalculaterDetail
	},

	methods: {
		/* eslint-disable-next-line no-unused-vars */
		onComplete(completed, questionList) {
			// This method is called whenever the "completed" status is changed.
			this.completed = completed;
			const data = this.getData();
			console.debug(data);
		},

		getData() {
			const data = {
				questions: [],
				answers: [],
			};

			this.questions.forEach((question) => {
				if (question.title) {
					let answer = question.model;
					if (Array.isArray(answer)) {
						answer = answer.join(", ");
					}

					data.questions.push(question.title);
					data.answers.push(answer);
				}
			});

			return data;
		},
		
	},
	setup() {
		function getStep() {

				// console.log(val);
				var source = stepSource.filter((x) => x.value === "SCOPE2-ELECTRICITY");
				if (source.length >= 1) {
					// console.log(source.length );
					console.log(source[0]);
					return source[0];
				} else {
					// console.error("Failed to find the step", "SCOPE2-ELECTRICITY");
					return null;
				}
		}
		function ClickByName(pagepath,id,index,dualflag) {
				const router = useRouter();
				console.log(pagepath);
				console.log(id);
				router.push({
					name: pagepath,
					params: {
						context: id,index,dualflag
					},
					state:{
						k1: JSON.stringify(id),
						k2: JSON.stringify(index),
						k3: JSON.stringify(dualflag),
					},
					// query: {
					// 	k1: JSON.stringify(id),
					// 	k2: JSON.stringify(index),
					// },
				});
		}
		return {
			getStep,
			ClickByName,
			completed: false,
			questions: [
				{
					type: "multiplechoice",
					id: "s_1",
					tagline:
						"Please use this one minute survey to decide the electricity scope 2 calculation process",
					title:
						"Has any electricity consumed from owned/operation generation?",
					multiple: false,
					required: true,
					helpTextShow: false,
					options: [
						{
							label: "Yes",
							value: "s_1_y",
						},
						{
							label: "No",
							value: "s_1_n",
						},
					],
					jump: {
						s_1_y: "s_2",
						s_1_n: "s_3",
					},
					model: "",
				},
				{
					type: "multiplechoice",
					id: "s_2",
					title:
						"Has any electricity been consumed from the grid or other third party electricity supplier?",
					multiple: false,
					required: true,
					helpTextShow: false,
					options: [
						{
							label: "Yes",
							value: "s_2_y",
						},
						{
							label: "No",
							value: "s_2_n",
						},
					],
					jump: {
						s_2_y: "s_3",
						_other: "_submit",
					},
					model: "",
				},
				{
					type: "multiplechoice",
					id: "s_3",
					title:
						"Are any of your energy-consuming facilities located in areas where grid customers can be provided with product or supplier-specific data in form of contractual instrument?",
					multiple: false,
					required: true,
					helpTextShow: false,
					options: [
						{
							label: "Yes",
							value: "s_3_y",
						},
						{
							label: "No",
							value: "s_3_n",
						},
					],
					jump: {
						s_3_y: "s_5",
						s_3_n: "s_6",
					},
					model: "",
				},
				{
					type: "sectionbreak",
					id: "s_5",
					title:
						"You will report two totals for electricity, one market based method and another location based method.",
					content: "Click continue to proceed.",
					jump: { _other: "_submit" },
					model: "",
				},
				{
					type: "sectionbreak",
					id: "s_6",
					title:
						"You will only report one scope 2 total for electricity, location based method.",
					content: "Click continue to proceed.",
					jump: { _other: "_submit" },
					model: "",
				},
			],
		};
	},
};
</script>

<style>
/* Import Vue Flow Form base CSS */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
/* Import one of the Vue Flow Form CSS themes (optional) */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css";
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
/* @import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css"; */
</style>
