<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-footer bordered class="bg-white text-black">
				<q-toolbar>
					<span class="text-right text-bold">
						Report period: {{ starttime }} - {{ endtime }}
					</span>

					<q-space />
					<span class="text-left text-bold"> POWERED BY DYNIMOS </span>
				</q-toolbar>
			</q-footer>

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row">
						<div class="col-12">
							<h1 class="text-h5">{{ companyname }}</h1>
							<q-separator />
							<h1 class="text-h6">SUSTAINABILITY DISCLOSURE 2023</h1>
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-12">
							<q-img
								src="https://images.pexels.com/photos/1230157/pexels-photo-1230157.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
								loading="lazy"
								spinner-color="white"
								style="fit"
							/>
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
export default {
	name: "CoverPage",
	components: {},

	setup() {
		const store = useStore();
		const companyname = store.state.companyname.toUpperCase();
		const starttime = store.state.report_start_date;
		const endtime = store.state.report_end_date;

		return { companyname, starttime, endtime };
	},
};
</script>

<style lang="less" scoped>
.slides_wrap {
	background-color: rgba(8, 7, 7, 0.3);
	// height: 65vh;
	// overflow-x: scroll;
	overflow-x: scroll;
	padding: 20px 0;
	// padding:  20px;
	display: flex;
	justify-content: center;
	.slides {
		max-width: 1320px;
	}
}
</style>
