<template>
	<q-header elevated class="glossy">
		<Toolbar />
	</q-header>
	<div class="content">
		<div class="row justify-center">
			<div class="col-12 big-title text-center text-capitalize">
				Select source to get started
			</div>
		</div>
		<div class="title text-left">Scope 1 (direct emissions)</div>
		<div class="title-explanation text-left">Emissions result from burning fuels in facilities you control or own.</div>
		<div class="row justify-left col-12">
			<div
				class="card"
				@click="ClickByName('CalculaterStationaryPage', 'SCOPE1-STATIONARY')"
			>
			
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/stationary.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;" />
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'fire']" size="2xl" style="color: #ffffff;" />
					</div>
					
					<div class="text">
						{{ getLabel("SCOPE1-STATIONARY") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE1-STATIONARY") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="ClickByName('CalculaterStationaryPage', 'SCOPE1-MOBILE')"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/mobile.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'truck-plane']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE1-MOBILE") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE1-MOBILE") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="
					ClickByName('CalculaterStationaryPage', 'SCOPE1-PROCESS EMISSION')
				"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/processemission.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon icon="fa-solid fa-industry" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE1-PROCESS EMISSION") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE1-PROCESS EMISSION") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="
					ClickByName('CalculaterStationaryPage', 'SCOPE1-FUGITIVE EMISSION')
				"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/fugitiveemission.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon icon="fa-solid fa-mask-ventilator" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE1-FUGITIVE EMISSION") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE1-FUGITIVE EMISSION") }}
					</div>
				</div>
			</div>
		</div>
		<br />
		<div class="title text-left">Scope 2 (indirect emissions from energy)</div>
		<div class="title-explanation text-left">Emissions result from purchased energy for facilities you own or control.</div>
		<div class="row justify-left">
			<div
				class="card"
				@click="ClickByName('Scope2', 'SCOPE2-ELECTRICITY')"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/electricity.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'tower-observation']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE2-ELECTRICITY") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE2-ELECTRICITY") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="
					ClickByName('CalculaterStationaryPage', 'SCOPE2-STEAM AND HEAT')
				"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/SteamHeat.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon icon="fa-solid fa-bars-staggered" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE2-STEAM AND HEAT") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE2-STEAM AND HEAT") }}
					</div>
				</div>
			</div>
		</div>
		<br />
		<div class="title text-left">Scope 3 (indirect emissions from upstream/downstream operations)</div>
		<div class="title-explanation text-left">Emissions result from your business activities, but from sources you don't own or control.</div>
		<div class="row justify-left">
			<div
				class="card"
				@click="SCOPE3_ClickByName('CalculaterDetailPage', getSource('SCOPE3-PURCHASED GOODS AND SERVICES'),0)"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/purchased.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'bell-concierge']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE3-PURCHASED GOODS AND SERVICES") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE3-PURCHASED GOODS AND SERVICES") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="SCOPE3_ClickByName('CalculaterDetailPage', getSource('SCOPE3-CAPITAL GOODS'),0)"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/capital.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'cart-shopping']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE3-CAPITAL GOODS") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE3-CAPITAL GOODS") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="ClickByName('CalculaterStationaryPage', 'SCOPE3-Business travel employee commuting')"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/travel.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'person-walking-luggage']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE3-Business travel employee commuting") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE3-Business travel employee commuting") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="ClickByName('CalculaterStationaryPage', 'SCOPE3-Upstream/Downstream Transportation and Distribution')"
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/distribution.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'truck-arrow-right']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE3-Upstream/Downstream Transportation and Distribution") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE3-Upstream/Downstream Transportation and Distribution") }}
					</div>
				</div>
			</div>
			<div
				class="card"
				@click="ClickByName('CalculaterStationaryPage', 'SCOPE3-Waste')"
				
			>
				<div class="card-front" :style="{'background-image':'url('+require('@/assets/waste.jpg')+')','background-size':'cover','background-position':'center','width':'100%','height':'100%'}">
					<div  style="background:rgba(48, 43, 43, 0.7);height:100%;border-radius: 15px;"/>
					<div class="u-icon">
						<font-awesome-icon :icon="['fas', 'trash-can-arrow-up']" size="2xl" style="color: #ffffff;" />
					</div>
					<div class="text">
						{{ getLabel("SCOPE3-Waste") }}
					</div>
				</div>
				<div class="card-back">
					<div class="text">
						{{ getExplanation("SCOPE3-Waste") }}
					</div>
				</div>
			</div>
		</div>
		<br />
		<br />
		<br />
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import stepSource from "../carbondata/sourcetype.json";
// import InfoBackgroundCard from "@/css/cards/InfoBackgroundCard.vue" 

export default {
	name: "CalculaterMainPage",
	components: {
		Toolbar,
	},

	setup() {
		const onShow = ref(0);
		const router = useRouter();
		// const route = useRoute();
		// const val = route.query.k;
		function ClickByName(pagepath, id) {
			console.log(pagepath);
			console.log(id);
			// if(pagepath==="SCOPE2-ELECTRICITY")
			// {
			// 	id = JSON.stringify(getStep('SCOPE2-ELECTRICITY').children[0]);
			// }
			router.push({
				name: pagepath,
				params: {
					context: id,
				},
				state:{

					k: id,
				},
				// query: {
				// 	k: id,
				// },
			});
		}

		function getStep(val) {
			if (val == null) {
				console.error("selected val is null");
			} else {
				console.log(val);
				var source = stepSource.filter((x) => x.value === val);
				if (source.length >= 1) {
					// console.log(source.length );
					console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", val);
					return null;
				}
			}
		}
		function getExplanation(val) {
			return getStep(val).explanation;
		}
		function getLabel(val) {
			return getStep(val).label;
		}

		function handleMouseEnter(val) {
			onShow.value = val;
			console.log("鼠标移入");
			console.log(onShow);
		}
		function handleMouseLeave() {
			onShow.value = 0;
			console.log("鼠标移出");
			console.log(onShow);
		}
		function getSource(val) {
			// console.log(val);
			var source = stepSource.filter((x) => x.value === val);
			if (source.length >= 1) {
				// console.log(source.length );
				console.log(source[0]);
				return source[0];
			} else {
				// console.error("Failed to find the step", "SCOPE2-ELECTRICITY");
				return null;
			}
		}
		function SCOPE3_ClickByName(pagepath,id,index) {
			// const router = useRouter();
			console.log(pagepath);
			console.log(id);
			router.push({
				name: pagepath,
				params: {
					context: id,index
				},
				state:{
					k1: JSON.stringify(id),
					k2: JSON.stringify(index),
				},
				// query: {
				// 	k1: JSON.stringify(id),
				// 	k2: JSON.stringify(index),
				// },
			});
		}
		return {
			stepSource,
			onShow,
			ClickByName,
			getStep,
			getExplanation,
			getLabel,
			handleMouseEnter,
			handleMouseLeave,
			getSource,
			SCOPE3_ClickByName,
		};
	},
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
.content {
	display: block;
	text-align:  center;
	margin: 0 13%;
	// margin-top: 20px;
	text-align: left;
	font-family: "Abel";
	border-radius: 5px;
	background-color: #ffffff;
	padding: 20px;
	width: 80%;

	.btn {
		font-size: 1vw;
		font-weight: 500;
		height: 80px;
		width: 15%;
		margin-left: 16px;
		margin-right: 16px;
	}
	.big-title {
		font-size: 3vw;
		font-weight: 400;
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 5px;
		margin-bottom: 40px;
	}
	.title {
		font-size: 2vw;
		font-weight: 700;
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 10px;
		// margin-bottom: 30px;
	}
	.title-explanation {
		font-size: 1.2vw;
		font-weight: 400;
		color: #0d0c22;
		box-sizing: border-box;
		// margin-top: 15px;
		margin-bottom: 20px;
	}
}

// Color variables (appears count calculates by raw css)
@color0: rgb(108, 154, 208); // Appears 3 times  #dfe7c3;
@color1: #ffffff; // Appears 3 times

// Width variables (appears count calculates by raw css)
@width1: 100%; // Appears 2 times

// Height variables (appears count calculates by raw css)
@height1: 100%; // Appears 2 times

body {
	background-color: #eeeeee;
	font-family: "Josefin Sans", sans-serif;
	margin: 0;
	padding: 0;
	a {
		color: @color1;
		text-decoration: none;
	}
}
.card {
	display: block;
	// outline: ridge #32a1ce;
	height: 260px;
	margin-right: 1vw;
	margin-bottom: 1vw;
	perspective: 600px;
	text-align: center;
	font-family: "Abel";
	cursor:pointer;
	// position: absolute;
	// top: 10%;
	// left: 10%;
	transform-style: preserve-3d;
	// transform: translate(-50%, -50%);
	transition: 0.5s;
	width: 23%;
	&:hover {
		.card-front {
			transform: rotateX(-180deg);
		}
		.card-back {
			transform: rotateX(0deg);
		}
	}
}
.card-front {
	display: block;
	backface-visibility: hidden;
	// background-color: @color0;
	background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
	background-position: 100% 100%;
	background-size: cover;
	border-radius: 15px;
	height: @height1;
	left: 0;
	position: absolute;
	align-items: center;
	top: 0;
	transform: rotateX(0deg);
	transition: 0.5s;
	width: @width1;

	.u-icon{
		position: absolute; 
		font-size: 1.5vw;
		left: 50%;
		top: 40%;
		transform: translate(-50%,-40%);
	} 
	
	.text {
		// display: inline-block;
		position: absolute;
		left: 50%;
		top: 80%;
		transform: translate(-50%,-80%);
		font-size: 1vw;
		color: #ffffff;
		font-weight: 700;
		text-align: center;
		// margin: -50% auto 35% auto;
	}
}
.card-back {
	display: block;
	backface-visibility: hidden;
	background-color: @color0;
	// background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
	color: @color1;
	height: @height1;
	border-radius: 15px;
	// left: 0;
	// position: absolute;
	// text-align: center;
	// align-items: center;
	// top: 0;
	transform: rotateX(180deg);
	transition: 0.5s;
	width: @width1;
	.text {
		font-size: 0.7vw;
		font-weight: 400;
		text-align: left; //justify;
		padding: 10px 10px 15px 10px;
		line-height: 1.6;
		// margin: 40% auto 35% auto;
	}
	h2 {
		font-size: 20px;
		margin: 10% auto 35% auto;
		span {
			font-size: 20px;
		}
	}
	a {
		border-radius: 4px;
		height: 20px;
		line-height: 20px;
		padding: 5px 5px;
		width: 20px;
		&:hover {
			background-color: @color1;
			color: @color0;
		}
	}
}
</style>
