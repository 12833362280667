<template>
	<q-footer bordered class="bg-white footer">
		<div class="company">
			DYNIMOS © 2024 All rights reserved
		</div>
		<!-- col-lg-8  -->
		<div class="icon-style">
			<a href="https://medium.com/@dynimosmedia/"><font-awesome-icon :icon="['fab', 'medium']" size="xl" style="color: #6e6d7a;"/></a>
			<a href="https://twitter.com/"><font-awesome-icon :icon="['fab', 'twitter']" size="xl" style="color: #6e6d7a;"/></a>
			<a href="https://www.youtube.com/@Dynimos"><font-awesome-icon :icon="['fab', 'youtube']" size="xl" style="color: #6e6d7a;"/></a>
			<a href="https://www.linkedin.com/company/dynimosusa/"><font-awesome-icon :icon="['fab', 'linkedin']" size="xl" style="color: #6e6d7a;"/></a>
		</div>
		<div class="term">
			<a
				href="https://www.google.com/url?q=https://app.termly.io/document/privacy-policy/998da42b-c21f-4f67-8a94-47058858990d&sa=D&source=docs&ust=1678433477070639&usg=AOvVaw2Z2feD0wivy-Zm3uZDAMbr"
				target="_blank"
			>
				Privacy
			</a>
			&
			<a
				href="https://www.google.com/url?q=https://app.termly.io/document/terms-and-conditions/fa0c95b3-1573-4eab-8396-7a20b026b8f7&sa=D&source=docs&ust=1678433477070789&usg=AOvVaw1pOBI0UBjC66Wfe_pjvuXn"
				target="_blank"
			>
				Terms
			</a>
		</div> 
	</q-footer>
</template>

<script>
export default {
	components: {
	},

	setup() {
		return {
		};
	},
};
</script>

<style lang="less" scoped>
@media (min-width: 600px) {
.footer {
	display: flex;
	font-size: 15px;
	.company {
		display: flex;
		color: #6e6d7a;
		flex: 1;
		justify-content: left;
	}
	.icon-style{
		display: flex;
		flex: 1;
		align-items: center;
		max-width: 7%;
		justify-content: space-between;
	}
	.term {
		display: flex;
		justify-content: right;
		align-items: right;
		flex: 1;
		color: #6e6d7a;
		a:link {
			color: #6e6d7a;
		}
		a:hover {
			color: blue;
		}
	}
}
}
@media (max-width: 600px) {
.footer {
	justify-content: left;
	font-size: 10px;
	.company {
		color: #6e6d7a;
		justify-content: left;
	}
	.icon-style{
		display: flex;
		max-width: 25%;
		justify-content: space-between;
	}
	.term {
		color: #6e6d7a;
		a:link {
			color: #6e6d7a;
		}
		a:hover {
			color: blue;
		}
	}
}
}
</style>
