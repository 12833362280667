const factor_data_country_states = {
    USA_AL: { carbon_factor_val: "0.4396" },
    USA_AK: { carbon_factor_val: "0.5055" },
    USA_AZ: { carbon_factor_val: "0.4320" },
    USA_AR: { carbon_factor_val: "0.3660" },
    USA_CA: { carbon_factor_val: "0.2056" },
    USA_CO: { carbon_factor_val: "0.5636" },
    USA_CT: { carbon_factor_val: "0.2218" },
    USA_DE: { carbon_factor_val: "0.3152" },
    USA_FL: { carbon_factor_val: "0.3905" },
    USA_GA: { carbon_factor_val: "0.4396" },
    USA_HI: { carbon_factor_val: "0.6532" },
    USA_ID: { carbon_factor_val: "0.3244" },
    USA_IL: { carbon_factor_val: "0.7187" },
    USA_IN: { carbon_factor_val: "0.4843" },
    USA_IA: { carbon_factor_val: "0.4982" },
    USA_KS: { carbon_factor_val: "0.4853" },
    USA_KY: { carbon_factor_val: "0.4308" },
    USA_LA: { carbon_factor_val: "0.3660" },
    USA_ME: { carbon_factor_val: "0.2218" },
    USA_MD: { carbon_factor_val: "0.3152" },
    USA_MA: { carbon_factor_val: "0.2218" },
    USA_MI: { carbon_factor_val: "0.5395" },
    USA_MN: { carbon_factor_val: "0.4982" },
    USA_MS: { carbon_factor_val: "0.3660" },
    USA_MO: { carbon_factor_val: "0.7187" },
    USA_MT: { carbon_factor_val: "0.3244" },
    USA_NE: { carbon_factor_val: "0.4982" },
    USA_NV: { carbon_factor_val: "0.3244" },
    USA_NH: { carbon_factor_val: "0.2218" },
    USA_NJ: { carbon_factor_val: "0.2512" },
    USA_NM: { carbon_factor_val: "0.4320" },
    USA_NY: { carbon_factor_val: "0.2512" },
    USA_NC: { carbon_factor_val: "0.3064" },
    USA_ND: { carbon_factor_val: "0.4982" },
    USA_OH: { carbon_factor_val: "0.4843" },
    USA_OK: { carbon_factor_val: "0.4545" },
    USA_OR: { carbon_factor_val: "0.3244" },
    USA_PA: { carbon_factor_val: "0.3152" },
    USA_RI: { carbon_factor_val: "0.2218" },
    USA_SC: { carbon_factor_val: "0.3064" },
    USA_SD: { carbon_factor_val: "0.4982" },
    USA_TN: { carbon_factor_val: "0.4308" },
    USA_TX: { carbon_factor_val: "0.3940" },
    USA_UT: { carbon_factor_val: "0.3244" },
    USA_VT: { carbon_factor_val: "0.2218" },
    USA_VA: { carbon_factor_val: "0.3064" },
    USA_WA: { carbon_factor_val: "0.3244" },
    USA_DC: { carbon_factor_val: "0.3152" },
    USA_WV: { carbon_factor_val: "0.4843" },
    USA_WI: { carbon_factor_val: "0.6816" },
    USA_WY: { carbon_factor_val: "0.5636" },
    CAN_NL: { carbon_factor_val: "0.025" },
    CAN_PE: { carbon_factor_val: "0.3" },
    CAN_NS: { carbon_factor_val: "0.68" },
    CAN_NB: { carbon_factor_val: "0.3" },
    CAN_QC: { carbon_factor_val: "0.0019" },
    CAN_ON: { carbon_factor_val: "0.028" },
    CAN_MB: { carbon_factor_val: "0.0012" },
    CAN_SK: { carbon_factor_val: "0.62" },
    CAN_AB: { carbon_factor_val: "0.64" },
    CAN_BC: { carbon_factor_val: "0.0078" },
    CAN_YT: { carbon_factor_val: "0.11" },
    CAN_NT: { carbon_factor_val: "0.18" },
    CAN_NU: { carbon_factor_val: "0.8" },
    CHN_BJ: { carbon_factor_val: "0.7119" },
    CHN_TJ: { carbon_factor_val: "0.7119" },
    CHN_HB: { carbon_factor_val: "0.7119" },
    CHN_SN: { carbon_factor_val: "0.7119" },
    CHN_SD: { carbon_factor_val: "0.7119" },
    CHN_NM: { carbon_factor_val: "0.7119" },
    CHN_LN: { carbon_factor_val: "0.6613" },
    CHN_JL: { carbon_factor_val: "0.6613" },
    CHN_HLJ: { carbon_factor_val: "0.6613" },
    CHN_SH: { carbon_factor_val: "0.5896" },
    CHN_JS: { carbon_factor_val: "0.5896" },
    CHN_ZJ: { carbon_factor_val: "0.5896" },
    CHN_AH: { carbon_factor_val: "0.5896" },
    CHN_FJ: { carbon_factor_val: "0.5896" },
    CHN_HE: { carbon_factor_val: "0.5721" },
    CHN_HN: { carbon_factor_val: "0.5721" },
    CHN_JX: { carbon_factor_val: "0.5721" },
    CHN_SC: { carbon_factor_val: "0.5721" },
    CHN_CQ: { carbon_factor_val: "0.5721" },
    CHN_SX: { carbon_factor_val: "0.6665" },
    CHN_QH: { carbon_factor_val: "0.6665" },
    CHN_NX: { carbon_factor_val: "0.6665" },
    CHN_XJ: { carbon_factor_val: "0.6665" },
    CHN_GD: { carbon_factor_val: "0.5089" },
    CHN_GX: { carbon_factor_val: "0.5089" },
    CHN_YN: { carbon_factor_val: "0.5089" },
    CHN_GZ: { carbon_factor_val: "0.5089" },
    CHN_HI: { carbon_factor_val: "0.5089" },
    BGD_default: { carbon_factor_val: "0.641" },
    BTN_default: { carbon_factor_val: "0.888" },
    KHM_default: { carbon_factor_val: "0.586" },
    CHN_default: { carbon_factor_val: "0.851" },
    PRK_default: { carbon_factor_val: "0.912" },
    IND_default: { carbon_factor_val: "0.905" },
    IDN_default: { carbon_factor_val: "0.778" },
    LAO_default: { carbon_factor_val: "0.574" },
    MYS_default: { carbon_factor_val: "0.670" },
    MNG_default: { carbon_factor_val: "1.130" },
    MMR_default: { carbon_factor_val: "0.395" },
    PAK_default: { carbon_factor_val: "0.545" },
    PHL_default: { carbon_factor_val: "0.512" },
    KOR_default: { carbon_factor_val: "0.625" },
    SGP_default: { carbon_factor_val: "0.486" },
    LKA_default: { carbon_factor_val: "0.708" },
    THA_default: { carbon_factor_val: "0.549" },
    VNM_default: { carbon_factor_val: "0.602" },
    ARG_default: { carbon_factor_val: "0.505" },
    BHS_default: { carbon_factor_val: "0.723" },
    BLZ_default: { carbon_factor_val: "0.152" },
    BOL_default: { carbon_factor_val: "0.589" },
    BRA_default: { carbon_factor_val: "0.294" },
    CHL_default: { carbon_factor_val: "0.600" },
    COL_default: { carbon_factor_val: "0.330" },
    CRI_default: { carbon_factor_val: "0.281" },
    CUB_default: { carbon_factor_val: "0.874" },
    DOM_default: { carbon_factor_val: "0.638" },
    ECU_default: { carbon_factor_val: "0.567" },
    SLV_default: { carbon_factor_val: "0.682" },
    GTM_default: { carbon_factor_val: "0.589" },
    GUY_default: { carbon_factor_val: "0.948" },
    HND_default: { carbon_factor_val: "0.661" },
    JAM_default: { carbon_factor_val: "0.783" },
    MEX_default: { carbon_factor_val: "0.529" },
    NIC_default: { carbon_factor_val: "0.631" },
    PAN_default: { carbon_factor_val: "0.579" },
    PER_default: { carbon_factor_val: "0.580" },
    URY_default: { carbon_factor_val: "0.533" },
    ALB_default: { carbon_factor_val: "0.312" },
    ARM_default: { carbon_factor_val: "0.436" },
    AZE_default: { carbon_factor_val: "0.590" },
    BIH_default: { carbon_factor_val: "0.973" },
    CYP_default: { carbon_factor_val: "0.798" },
    FJI_default: { carbon_factor_val: "0.597" },
    GEO_default: { carbon_factor_val: "0.402" },
    MNE_default: { carbon_factor_val: "0.984" },
    PNG_default: { carbon_factor_val: "0.679" },
    SRB_default: { carbon_factor_val: "1.099" },
    MKD_default: { carbon_factor_val: "0.861" },
    UZB_default: { carbon_factor_val: "0.585" },
    AGO_default: { carbon_factor_val: "0.841" },
    BFA_default: { carbon_factor_val: "0.368" },
    CMR_default: { carbon_factor_val: "0.390" },
    CPV_default: { carbon_factor_val: "0.655" },
    CIV_default: { carbon_factor_val: "0.634" },
    EGY_default: { carbon_factor_val: "0.533" },
    ETH_default: { carbon_factor_val: "0.000" },
    GHA_default: { carbon_factor_val: "0.479" },
    KEN_default: { carbon_factor_val: "0.583" },
    LBY_default: { carbon_factor_val: "0.794" },
    MDG_default: { carbon_factor_val: "0.601" },
    MLI_default: { carbon_factor_val: "0.614" },
    MUS_default: { carbon_factor_val: "0.969" },
    MAR_default: { carbon_factor_val: "0.647" },
    MOZ_default: { carbon_factor_val: "0.964" },
    NAM_default: { carbon_factor_val: "0.920" },
    NGA_default: { carbon_factor_val: "0.573" },
    RWA_default: { carbon_factor_val: "0.654" },
    SEN_default: { carbon_factor_val: "0.648" },
    SLE_default: { carbon_factor_val: "0.402" },
    ZAF_default: { carbon_factor_val: "0.951" },
    SDN_default: { carbon_factor_val: "0.305" },
    TUN_default: { carbon_factor_val: "0.554" },
    UGA_default: { carbon_factor_val: "0.487" },
    TZA_default: { carbon_factor_val: "0.498" },
    ZMB_default: { carbon_factor_val: "0.964" },
    IRN_default: { carbon_factor_val: "0.673" },
    ISR_default: { carbon_factor_val: "0.705" },
    JOR_default: { carbon_factor_val: "0.575" },
    KWT_default: { carbon_factor_val: "0.780" },
    LBN_default: { carbon_factor_val: "0.650" },
    SAU_default: { carbon_factor_val: "0.654" },
    ARE_default: { carbon_factor_val: "0.676" },
};
export default factor_data_country_states;