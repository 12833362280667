<template>
	<q-header elevated class="glossy">
		<HomeToolbar />
	</q-header>
	<div class="q-pa-md row justify-center">
		<div class="col-10">
			<q-banner
				rounded
				dense
				v-if="userHasData && showbanner"
				class="bg-warning text-white"
			>
				<q-avatar square text-color="white" icon="warning" />
				Dear customer, we identified you have pending work. Please click
				Continue to work on it. Otherwise click Dismiss to start from beginning.

				<template v-slot:action>
					<q-btn
						flat
						color="white"
						label="Dismiss"
						@click="showbanner = false"
					/>
					<q-btn flat color="white" label="Continue" @click="handleRedirect" />
				</template>
			</q-banner>
		</div>
		<div class="q-gutter-md">
			<div class="mb-4 big-title">ESG Reporting Generator</div>

			<q-form @submit="onSubmit" class="q-gutter-md">
				<q-select
					outlined
					v-model="select"
					:options="items"
					:option-label="label"
					:option-value="value"
					label="Please select a template to get started"
					emit-value
					map-options
					lazy-rules
					:rules="[(val) => (val !== null && val !== '') || '']"
				>
					<template v-slot:append>
						<q-avatar>
							<img
								src="https://icons.iconarchive.com/icons/double-j-design/super-mono-3d/128/button-dropdown-icon.png"
							/>
						</q-avatar>
					</template>
				</q-select>
				<q-input
					outlined
					v-model="companyname"
					label="Company Name On Report"
					counter
					maxlength="20"
					lazy-rules
					:rules="[
						(val) => (val && val.length > 0) || 'Please type company name',
					]"
				/>
				<q-input
					outlined
					v-model="startdate"
					mask="date"
					label="Report Start Date"
					:rules="['date']"
				>
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy
								cover
								transition-show="scale"
								transition-hide="scale"
							>
								<q-date v-model="startdate">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>
				<q-input
					outlined
					v-model="enddate"
					mask="date"
					label="Report End Date"
					:rules="['date']"
				>
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy
								cover
								transition-show="scale"
								transition-hide="scale"
							>
								<q-date v-model="enddate">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Close" color="primary" flat />
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>

				<div class="row">
					<div class="col-12 text-center">
						<q-btn
							style="background: #6c9ad0; color: white"
							icon-right="double_arrow"
							type="submit"
							label="Let's get started!"
						/>
					</div>
				</div>
			</q-form>
		</div>
	</div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import HomeToolbar from "@/components/tools/HomeToolBar.vue";

export default {
	name: "WelcomePage",
	components: {
		HomeToolbar,
	},

	setup() {
		const { user } = useAuth0();
		const store = useStore();
		const $q = useQuasar();

		const select = ref(null);
		let usertemplate = null;
		const showbanner = ref(true);

		const userHasData = ref(false);

		let companyname = computed({
			get() {
				return store.state.companyname;
			},
			set(value) {
				store.commit("updateSingleState", {
					label: "companyname",
					value: value,
				});
			},
		});
		let startdate = computed({
			get() {
				return store.state.report_start_date;
			},
			set(value) {
				store.commit("updateSingleState", {
					label: "report_start_date",
					value: value,
				});
			},
		});
		let enddate = computed({
			get() {
				return store.state.report_end_date;
			},
			set(value) {
				store.commit("updateSingleState", {
					label: "report_end_date",
					value: value,
				});
			},
		});

		onMounted(() => {
			isUserHasData();
		});

		async function isUserHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				// "https://formanswer.azurewebsites.net/api/getFormByUser?id=" + userid,
				"https://testdynimosazure.azurewebsites.net/api/getFormByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response && response.data.template) {
				usertemplate = response.data.template;
				const answers = response.data.answer;
				var allempty = true;

				// Scan all data fields from the answer to confirm user has unfinished work
				for (const key of Object.keys(answers)) {
					if (answers[key].ans.length > 0 || answers[key].img) {
						allempty = false;
						break;
					}
				}
				if (!allempty) {
					console.log("user has data");
					setbasicdata(response.data);
					userHasData.value = true;
					return;
				}
			}
			console.log("user has no data");
			userHasData.value = false;
		}

		function setbasicdata(data) {
			const template = data.template;
			const user = data.id;
			const companyname = data.companyname;
			const startdate = data.startdate;
			const enddate = data.enddate;

			store.commit("updateSingleState", {
				label: "template",
				value: template,
			});
			store.commit("updateSingleState", {
				label: "userid",
				value: user,
			});
			store.commit("updateSingleState", {
				label: "companyname",
				value: companyname,
			});
			store.commit("updateSingleState", {
				label: "report_start_date",
				value: startdate,
			});
			store.commit("updateSingleState", {
				label: "report_end_date",
				value: enddate,
			});
		}

		const router = useRouter();
		const route = useRoute();
		const path = route.query.redirect || "reportform";

		return {
			select,
			items: [
				// { label: "Craftman Template", value: "craft" },
				{ label: "Factory Template", value: "factory" },
				{ label: "Retail Template", value: "retail" },
				{ label: "General Template", value: "general" },
				// { label: "Resturant Template", value: "resturant" },
				// { label: "Logistics Template", value: "logistic" },
			],
			companyname,
			startdate,
			enddate,
			usertemplate,
			userHasData,
			showbanner,
			onSubmit() {
				if (startdate.value >= enddate.value) {
					$q.notify({
						color: "red-5",
						textColor: "white",
						icon: "warning",
						message: "Start date must be earlier than end date",
					});
				} else {
					store.commit("updateSingleState", {
						label: "template",
						value: select.value,
					});
					router.push(path + "/" + select.value);
				}
			},

			handleRedirect() {
				router.push(path + "/" + usertemplate);
			},
		};
	},
};
</script>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.big-title {
	font-size: 3.5vw;
	font-weight: 400;
	font-family: "Abel";
	color: #0d0c22;
	box-sizing: border-box;
	margin-top: 15px;
	margin-bottom: 10px;
	padding: 50px 8vw;
}
</style>
