<template>
	<q-layout view="lHh Lpr lFf">
		<q-page-container>
			<router-view />
		</q-page-container>

		<AppFooter/>
	</q-layout>
</template>

<script>
// import AppToolbar from "@/components/tools/AppToolBar.vue";
// import WelcomePage from './components/WelcomePage.vue';
import AppFooter from "@/components/tools/AppFooter.vue";

export default {
	name: "LayoutDefault",

	components: {AppFooter},

	setup() {},
};
</script>

<style lang="less" scoped>
.footer {
	padding: 20px 10px;
	box-sizing: border-box;
	border-top: 1px solid rgb(231, 231, 233);
	.company {
		align-items: left;
		color: #6e6d7a;
		padding-left: 20px;
	}
	.term {
		align-items: right;
		color: #6e6d7a;
		padding-right: 20px;
		a:link {
			color: #6e6d7a;
		}
		a:hover {
			color: blue;
		}
	}
}
</style>
