<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div> -->

	<!-- <p>[debug purpose, will remove] {{ step }}</p> -->
	<!-- <q-card>
		<q-card-section> -->
	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<!-- <div class="text-subtitle2">{{ question }}</div> -->
	<!-- </q-card-section>

		<q-separator color="blue-10" inset /> -->
	<!-- 
		<q-card-section> -->
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>
	<!-- </q-card-section>
	</q-card> -->

	<q-form @submit="onSubmit">
		<q-input
			v-model="answer"
			filled
			clearable
			clear-icon="close"
			placeholder="Please write your own or select example from our list below"
			autogrow
			counter
			:maxlength="charlimit"
			:rules="[
				(val) =>
					val.length < charlimit ||
					'Please use maximum ' + charlimit + ' characters',
			]"
			class="q-mt-md"
		/>

		<ImgUploader
			v-if="picturelabel"
			:step="step"
			:label="picturelabel"
			:ratio="pictureratio"
			class="q-mt-md q-pb-md"
		/>

		<q-card class="q-mt-lg">
			<q-card-section>
				<q-item-label class="text-caption">
					Click add button to use our examples
				</q-item-label>
				<!-- <q-item-label class="text-caption">Pick Examples</q-item-label> -->
				<!-- <div class="text-subtitle2">{{ question }}</div> -->
			</q-card-section>

			<q-list bordered padding v-for="(item, index) in items" :key="index">
				<q-item-label header>Exampe {{ index + 1 }}</q-item-label>
				<q-item>
					<q-item-section>
						<q-item-label class="text-body3 text-weight-medium">{{
							item.message
						}}</q-item-label>
					</q-item-section>

					<q-item-section top side>
						<div class="text-grey-8 q-gutter-xs">
							<q-btn
								class="gt-xs"
								color="primary"
								@click="updatemsg(item.message)"
								round
								icon="add"
							/>
						</div>
					</q-item-section>
				</q-item>
			</q-list>
		</q-card>
		<!-- <q-btn color="secondary" type="submit" label="I'm done!" /> -->
	</q-form>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ImgUploader from "../tools/ImgUploader.vue";

export default {
	props: [
		"label",
		"question",
		"guildline",
		"sampleanswers",
		"picturelabel",
		"pictureratio",
		"charlimit",
		"step",
	],

	components: { ImgUploader },

	setup(props) {
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));

		const answer = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set(value) {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: value,
				});
			},
		});

		const file = ref(null);

		const items = ref(
			props.sampleanswers.map((q) => {
				return {
					message: q,
					active: false,
				};
			})
		);

		return {
			items,
			answer,
			file,
			updatemsg(val) {
				// TODO: add rules here, i.e. val shall not be repeat added, size of selection
				if (!answer.value) {
					answer.value = val;
				} else {
					answer.value += "\n" + val;
				}
			},
			onSubmit() {
				console.log("clicked");
			},
		};
	},
};
</script>
