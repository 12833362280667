<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="container">
		<div>
			<!-- <q-parallax :height="500">
			<template v-slot:media> -->
			<img
				class="banner"
				src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679980888/DYNIMOS%20DEFAULT%20PICS/team_avdjla.png"
			/>
			<!-- </template>
		</q-parallax> -->
		</div>
		<div class="row content-2">
			<div class="col-12 big-title text-left">
				Life Cycle Assessment Consulting, Environmental Product Declaration, and
				Critical Review
			</div>

			<!-- src="https://www.oneclicklca.com/wp-content/uploads/2021/02/Asset-2@2x.png" -->
			<div class="col-5 text-center">
				<q-img
					src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1678169030/DYNIMOS%20DEFAULT%20PICS/LCA_figure_skam2p.png"
					style="max-width: 280px"
				/>
			</div>
			<div class="col-7 detail text-left">
				Life Cycle Assessment (LCA) is an analytical tool used to quantify and
				interpret flows to and from the environment over the entire life cycle
				of a product or service. By analyzing impacts throughout the product
				life cycle, LCA provides a comprehensive view of the product or process
				and an accurate picture of the true environmental trade-offs inherent in
				decisions and product selections. With more than ten years of experience
				in LCA studies, Dynimos experts have extensive knowledge and expertise
				to help you conduct a professional LCA on your product or service.
				<br /><br />
				We encourage you to try
				<router-link to="/calculater">Dynimos Calculator</router-link> at
				<strong>NO COST</strong> to assess greenhouse gas emissions in your
				everyday clothing's life cycle. Take a closer look at your clothes tags
				and conduct a fun experiment!
			</div>
		</div>

		<q-separator />

		<div class="row content-2 bg-grey-2">
			<div class="col-12 big-title text-left">
				GHG Inventory Establishment and Development
			</div>
			<div class="col-12 detail">
				According to GHG Protocol, multiple goals can be served by GHG
				inventories.
				<p></p>
				<li>Managing GHG risks and identifying reduction opportunitie</li>
				<li>Public reporting and participation in voluntary GHG programs</li>
				<li>Participating in mandatory reporting programs</li>
				<li>Participating in GHG markets</li>
				<li>Recognition for early voluntary action</li>

				<div class="col-12 detail">
					GHG emissions are classified into three scopes for reporting:
					<p></p>
					<li>
						Scope 1: Direct GHG emissions occur from sources owned or controlled
						by the company.
					</li>
					<li>
						Scope 2: Electricity indirect GHG emissions. Scope 2 accounts for
						GHG emissions from the generation of purchased electricity consumed
						by the company.
					</li>
					<li>
						Scope 3: Other indirect GHG emissions. Scope 3 is an optional
						reporting category that allows for treating all other indirect
						emissions. Scope 3 emissions are a consequence of the company's
						activities but occur from sources not owned or controlled by the
						company.
					</li>
				</div>

				<div class="col-12 detail">
					Dynimos experts can help you establish a customized ready-for-audit
					GHG inventory to monitor and control emissions.
				</div>
			</div>
		</div>
		<q-parallax :height="200">
			<template v-slot:media>
				<img
					src="https://images.pexels.com/photos/257775/pexels-photo-257775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
				/>
			</template>
		</q-parallax>
		<div class="row content-2 bg-grey-2">
			<div class="col-12 big-title text-left">GHG Reduction Projects</div>
			<div class="col-12 detail text-left">
				Dynimos provide consulting service on GHG reduction to help your
				organization meet GHG goals. We will work with you to review your
				company's emission sources and make a plan and suggestions to reduce GHG
				at each scope. Examples of GHG reduction practices are using
				energy-efficient light bulbs, fixing leaks, product redesign, etc.
				Dyninmos experts help you plan appropriately to meet GHG reduction
				goals.

				<p></p>
			</div>
		</div>

		<q-separator />
		<div class="row content-2">
			<div class="col-12 big-title text-left">
				Customized Sustainability Report
			</div>
			<div class="col-12 detail text-left">
				Introducing the Dynimos Report, a powerful tool designed to save your
				time while reporting responsible business practices. Our tool provides a
				pre-defined framework that is easy to use and supports up to five
				different business types. With the Dynimos Report, you can generate a
				downloadable PDF version of your report in just a few clicks. Best of
				all, our tool is 100% free to use. <br /><br />
				Need a more complex and customized report? Not to worry, our customized
				sustainability report is tailored to the specific needs of specific
				industry sectors (e.g., textiles and clothing, construction,
				agriculture, transports). Our team is equipped to assist you in creating
				a bespoke sustainability report based on the GRI framework, by gathering
				and organizing the necessary data into a polished sustainability
				document for reporting. <br />
				<br />
				Don't hesitate to <router-link to="/contact">Contact Us</router-link> to
				learn more.
			</div>
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
export default {
	name: "OurServicePage",
	components: {
		Toolbar,
	},
	data() {
		return {
			mailUrl: `mailto:support@dynimos.com?body=Hello, my friends,%0A%0AWe are glad you are here! %0APlease feel free to say hello and let us know if you are interested in telling your own sustainability stories. We would love to schedule a demo appointment with you. We suggest you return for our official product launch soon and bring your friends.%0A%0ADYNIMOS team `,
		};
	},
	methods: {
		track () {
		this.$gtag.query('event', 'page_view', {
			page_title: 'Service page',
			page_path:'/services'
		})
		}
	}
	// methods: {
	// jumpPrice() {
	// 	this.$router.push({ path: "/user/price" });
	// },
	// },
};
</script>
<style lang="less" scoped>
.leftButton {
	border-radius: 20px;
}
</style>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 1200px) {
	.container {
		div {
			font-family: Calibri;
		}
		.banner {
			height: 100%;
			width: 100%;
		}
		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 28px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-2 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 50px 8vw;
			.big-title {
				font-size: 3vw;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
				padding-left: 1vw;
			}
			.left {
				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 80px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 480px) and (max-width: 1200px) {
	.with-bg {
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
	.container {
		.banner {
			height: 100%;
			width: 100%;
		}
		.content-2 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 50px 8vw;
			.big-title {
				font-size: 3vw;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
				padding-left: 1vw;
			}
			.left {
				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					margin-top: 80px;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
				box-sizing: border-box;

				.item {
					width: 20vw;
					margin-top: 2vw;
					margin-bottom: 1.5vw;

					.template {
						height: 66px;

						img {
							height: 60px;
						}
					}

					.follow {
						height: 66px;

						img {
							height: 55px;
						}
					}

					.information {
						height: 66px;

						img {
							height: 66px;
						}
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 80px;
						margin-top: 20px;
						width: 15vw;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						width: 18vw;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding-right: 50px;
						cursor: pointer;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (max-width: 480px) {
	.container {
		div {
			font-family: Calibri;
		}
		.banner {
			height: 100%;
			width: 100%;
		}
		.header {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 20px 20px;
			justify-content: space-between;
			border-bottom: 1px solid rgb(231, 231, 233);

			.right {
				display: flex;
				align-items: center;
			}

			.left {
				margin-right: 50px;
			}

			.title {
				margin-left: 3vw;
				font-size: 16px;
				font-weight: 500;
				color: #6e6d7a;
			}

			.sign {
				font-weight: 400;
				font-size: 18px;
				color: #6e6d7a;
			}

			.logo {
				display: flex;
				align-items: center;

				.logo-img {
					width: 30px;
				}

				.logo-text {
					margin-left: 10px;
					font-size: 18px;
					font-weight: 900;
					color: rgb(108, 154, 208);
				}
			}
		}

		.content-1 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;

			.left {
				.big {
					font-size: 6vw;
					font-weight: 700;
					color: #0d0c22;
					box-sizing: border-box;
					padding-right: 5vw;
				}

				.small {
					margin-top: 10px;
					font-size: 18px;
					font-weight: 400;
					font-weight: normal;
					box-sizing: border-box;
					padding-right: 5vw;
				}
			}

			.right {
				margin-top: 20px;

				img {
					width: 100%;
					border-radius: 50px;
				}
			}
		}

		.content-2 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;
			.big-title {
				font-size: 5vw;
				font-weight: 500;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.detail {
				margin-top: 20px;
				font-size: 16px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				padding-right: 5vw;
				padding-left: 1vw;
			}
			.left {
				.title {
					font-size: 6vw;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				// display: flex;
				// justify-content: space-between;
				box-sizing: border-box;

				.item {
					margin-top: 50px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						margin-top: 20px;
					}

					.detail {
						margin-top: 20px;
						font-size: 16px;
						font-weight: 600;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
		}

		.content-3 {
			box-sizing: border-box;
			padding: 50px 8vw;

			.left {
				.left-title {
					font-size: 21px;
					font-weight: 800;
					margin-top: 20px;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
				}
			}

			.right {
				.title {
					text-align: center;
					font-size: 30px;
					font-weight: 500;
					color: rgb(79, 113, 190);
					margin-top: 30px;
				}

				.imglist {
					.item {
						display: flex;
						align-items: center;
						flex-direction: column;
						margin-top: 20px;

						img {
							width: 80vw;
						}

						.item-title {
							font-size: 25px;
							font-weight: 500;
							margin-top: 20px;
							margin-bottom: 20px;
						}

						.item-detail {
							font-size: 16px;
							font-weight: 500;
							width: 100%;
						}
					}
				}
			}
		}

		.content-4 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 30px;

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.title {
					font-size: 24px;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
					white-space: nowrap;
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					height: 50px;
					font-size: 18px;
					font-weight: 600;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				box-sizing: border-box;
				margin-top: 50px;

				.item {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					margin-top: 20px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						height: 40px;
						margin-top: 20px;
					}

					.detail {
						font-size: 16px;
						font-weight: 600;
						height: 100px;
					}

					.learnmore {
						color: rgb(238, 115, 165);
						font-size: 16px;
						font-weight: 600;
						margin-top: 30px;
					}
				}
			}
		}

		.footer {
			padding: 20px 10px;
			box-sizing: border-box;
			border-top: 1px solid rgb(231, 231, 233);
			color: #6e6d7a;
		}
	}
	.with-bg {
		border-radius: 5px;
		height: 50px;
		font-size: 18px;
		font-weight: 600;
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
}
</style>
