<template>
	<div>
		<iframe style="width: 100%; min-height: 100vh" src="/ESG_Report.pdf#/"></iframe>
	</div>
</template>

<script>


export default {
	components: {
	},

	setup() {
		return {
		};
	},
};
</script>
