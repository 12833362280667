<template>
	<div class="header">
		<div v-if="isAuthenticated && isLoading" class="page-layout">
			<q-circular-progress
				indeterminate
				rounded
				size="10px"
				color="prime"
				class="q-ma-md"
			/>
		</div>
		<q-toolbar>
			<div class="logo">
				<q-btn
					flat
					dense
					round
					@click="$router.push('/')"
					aria-label="Menu"
					icon="img:https://i.ibb.co/Cb7KGVX/logo.png"
				/>
				<div class="logo-text">DYNIMOS</div>
			</div>

			<q-space />
			<template v-if="!isAuthenticated">
				<q-separator dark vertical />
				<LoginButton />
				<q-separator dark vertical />
				<SignupButton />
				<q-separator dark vertical />
			</template>

			<template v-if="isFormPage()">
				<q-btn flat @click="generateReport">
					<q-icon left color="green" name="description" />
					<div class="menu">Generate Report</div>
				</q-btn>
				<q-btn flat dense @click="saveProgress()">
					<q-icon left color="green" name="archive" />
					<div class="menu">Save Progress</div>
				</q-btn>
				<q-btn flat class="menu" icon="construction" label="Tools">
					<q-menu>
						<q-list style="min-width: 100px">
							<q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon name="restart_alt" />
								</q-item-section>
								<q-item-section @click="resetData()"> Reset </q-item-section>
								<q-tooltip>
									Reset all input data to empty, you can still reload from last
									saved progress
								</q-tooltip>
							</q-item>
							<q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon name="sync" />
								</q-item-section>
								<q-item-section @click="loadData()">Reload</q-item-section>
								<q-tooltip> Reload from last saved progress </q-tooltip>
							</q-item>
							<!-- <q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon name="archive" />
								</q-item-section>
								<q-item-section @click="saveProgress()"
									>Save Progress</q-item-section
								>
								<q-tooltip>
									Click to save your work so that you can continue your work in
									future
								</q-tooltip>
							</q-item> -->
							<!-- <q-separator />
							<q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon color="green" name="double_arrow" />
								</q-item-section>
								<q-item-section @click="$router.push('/slides')">
									Generate Report
									</q-item-section>
								<q-tooltip>
									Ready on all steps? Click to generate your final ESG report.
								</q-tooltip>
							</q-item> -->
						</q-list>
					</q-menu>
				</q-btn>

				<!-- <q-btn flat round class="menu" icon="settings">
					<q-menu>
						<q-list style="min-width: 100px">
							<q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon name="person" />
								</q-item-section>
								<q-item-section @click="$router.push('/profile')"
									>User Profile</q-item-section
								>
							</q-item>
							<q-separator />
							<q-item clickable v-close-popup>
								<q-item-section avatar>
									<q-icon name="info" />
								</q-item-section>
								<q-item-section>Help &amp; Feedback</q-item-section>
							</q-item>
						</q-list>
					</q-menu>
				</q-btn> -->
			</template>

			<q-btn flat @click="$router.push('/esg')">
				<q-icon left color="black" name="exit_to_app" />
				<div class="menu">Exit App</div>
			</q-btn>
			<template v-if="isAuthenticated">
				<LogoutButton />
			</template>
		</q-toolbar>
	</div>
</template>
<script>
import { ref, watch, computed } from "vue";
import LoginButton from "@/buttons/login-button.vue";
import SignupButton from "@/buttons/signup-button.vue";
import LogoutButton from "@/buttons/logout-button.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { useRouter, useRoute } from "vue-router";
import default_facctory_states from "@/components/formdata/defaultfactorystate";
import default_retail_states from "@/components/formdata/defaultretailstate";
import default_general_states from "@/components/formdata/defaultgeneralstate";

export default {
	components: {
		LoginButton,
		LogoutButton,
		SignupButton,
	},

	setup() {
		const { isAuthenticated, user, isLoading } = useAuth0();
		const $q = useQuasar();
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		// a computed ref
		const routename = computed(() => route.name);

		function resetData() {
			$q.dialog({
				title: "Alert",
				message:
					"Please Note: All entered data will be cleared and progress will start over. You can still click reload button to reload from your last save. Click OK to proceed.",
				cancel: true,
				persistent: true,
			})
				.onOk(() => {
					// console.log('OK')
					store.commit("clearDataByTemplate", {
						template: store.state.template,
					});

					$q.notify({
						color: "secondary",
						textColor: "white",
						icon: "done",
						message: "All input data reset to empty",
					});
				})
				.onCancel(() => {
					// console.log('Cancel')
				})
				.onDismiss(() => {
					// console.log('I am triggered on both OK and Cancel')
				});
		}

		// TODO: add try catch to deal with error
		function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			console.log("Saving data for user: " + userid);
			store.commit("saveData", {
				template: store.state.template,
				userid: userid,
			});
			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		function loadData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			console.log("Loading data for user: " + userid);
			store.dispatch("loadItems", {
				template: store.state.template,
				userid: userid,
			});
		}

		function isFormPage() {
			return routename.value.includes("Form");
		}

		function generateReport() {
			let va = isAllComplete();
			// TODO: flip back after debugging
			va = true;
			if (va) {
				router.push({ name: "Slides" });
			} else {
				$q.dialog({
					title: "Incompleted Forms",
					message:
						"All forms must be completed before report generation. Please check your Progress Dashboard page for finding more details on pending items.",
				})
					.onOk(() => {
						// console.log('OK')
					})
					.onCancel(() => {
						// console.log('Cancel')
					})
					.onDismiss(() => {
						// console.log('I am triggered on both OK and Cancel')
					});
			}
		}

		function isAllComplete() {
			const template = store.state.template;
			console.log("Checking on completion status: " + template);
			let allkeys;
			let allvalues;
			let store_ans;
			let ret = true;

			if (template.toLowerCase() === "factory") {
				allkeys = Object.keys(default_facctory_states);
				allvalues = Object.values(default_facctory_states);
				store_ans = store.state[template];
			} else if (template.toLowerCase() === "retail") {
				allkeys = Object.keys(default_retail_states);
				allvalues = Object.values(default_retail_states);
				store_ans = store.state[template];
			} else {
				// } else if (template.toLowerCase() === "general") {
				allkeys = Object.keys(default_general_states);
				allvalues = Object.values(default_general_states);
				store_ans = store.state[template];
			}
			allvalues.forEach(function (item, index) {
				if (!ret) {
					return;
				}
				var curkey = allkeys[index];
				// console.log(allkeys[index] + ": " + store_ans);

				if (!store_ans[curkey].ans || store_ans[curkey].ans.length === 0) {
					console.log(
						allkeys[index] + ": " + store_ans[curkey].ans + " is empty"
					);
					ret = false;
				}
			});
			return ret;
		}

		watch(isLoading, (newLoading) => {
			console.log(`updatedLoading is ${newLoading}`);
			if (isAuthenticated && !newLoading) {
				const usercode = JSON.stringify(user.value, null, 2);
				const userid = JSON.parse(usercode)["sub"];
				store.commit("updateSingleState", {
					label: "userid",
					value: userid,
				});
				console.log("updated store userid: " + userid);
			}
		});

		return {
			routename,
			leftDrawerOpen: ref(false),
			isAuthenticated,
			resetData,
			saveProgress,
			loadData,
			isLoading,
			isFormPage,
			generateReport,
		};
	},
};
</script>

<style lang="less" scoped>
.header {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 10px 10px;
	justify-content: space-between;
	background-color: rgb(253, 253, 255);
	border-bottom: 1px solid rgb(231, 231, 233);

	.right {
		display: flex;
		align-items: center;
	}

	.left {
		margin-right: 50px;
	}

	.title {
		margin-left: 3vw;
		font-size: 16px;
		font-weight: 500;
		color: #6e6d7a;
	}
	.menu {
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-family: Arial;
		color: #333336;
	}

	.sign {
		font-weight: 400;
		font-size: 18px;
		color: #6e6d7a;
	}

	.logo {
		display: flex;
		align-items: center;

		.logo-img {
			width: 30px;
		}

		.logo-text {
			margin-left: 10px;
			font-size: 28px;
			font-weight: 900;
			color: rgb(108, 154, 208);
		}
	}
}
</style>
