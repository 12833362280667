<template>
	<q-header elevated class="glossy">
		<Toolbar />
	</q-header>

	<div class="row content-2 justify-center">
		<div class="col-12 big-title text-center">
			Carbon Emission Calculator for {{ val.label }}
		</div>

		<!-- <div style="width: 50%; font-size:14px"> -->
		<!-- <div class="c-form-container"> -->
			<q-breadcrumbs class="text-primary" style="width: 50%; font-size:14px">
				<template v-slot:separator>
					<q-icon size="0.8em" name="chevron_right" style="color:inherit;" />
				</template>

				<q-breadcrumbs-el label="Main" icon="home" to="/calculatermain" style="color:inherit;"/>
				<!-- <q-breadcrumbs-el v-if="sourcevalue.label === 'Electricity'" :label="sourcevalue.label" icon="widgets" :to="{path:'/scope2',state:{k:sourcevalue.value}}" style="color:inherit;"/>
				<q-breadcrumbs-el v-else :label="sourcevalue.label" icon="widgets" :to="{path:'/calculaterstationary',state:{k:sourcevalue.value}}" style="color:inherit;"/> -->
				<q-breadcrumbs-el v-if="sourcevalue.label !== 'Electricity' && sourcevalue.label !== 'Purchased goods and services' && sourcevalue.label !== 'Capital Goods' && sourcevalue.label !== 'Waste-generated'" :label="sourcevalue.label" icon="widgets" :to="{path:'/calculaterstationary',state:{k:sourcevalue.value}}" style="color:inherit;"/>
				<q-breadcrumbs-el :label="val.label" icon="widgets" />
			</q-breadcrumbs>
		<!-- </div> -->

		<div class="c-form-container" v-if="(sourcevalue.label !== 'Electricity' || DualReporting !== 1) && sourcevalue.label !== 'Purchased goods and services' && sourcevalue.label !== 'Capital Goods' && sourcevalue.label !== 'Business travel and employee commuting' && sourcevalue.label !== 'Upstream/Downstream Transportation and Distribution'&& sourcevalue.label !== 'Waste-generated'">
			<div class="q-gutter-md row justify-center" >
				<select v-model="fueltype" name="fueltype"  class="form">
					<option disabled  selected value="" v-if="sourcevalue.label === 'Electricity' && DualReporting === 0">
						<!-- Select {{ val.label }} Type -->
						Select eGRID Subregion
					</option>
					<option disabled  selected value="" v-else>
						<!-- Select {{ val.label }} Type -->
						Select fuel type
					</option>
					<option
						v-for="(item, index) in val.suboptions"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						<span v-if="sourcevalue.label === 'Electricity' && DualReporting === 0">{{ item.value }}</span> 
						<span v-else>{{ item.fueltype }}</span> 
					</option>
				</select>
				<select
					outlined
					class="form"
					v-model="units"
					name="units"
					v-if="
						(sourcevalue.label === 'Stationary' ||
						val.label === 'Non Mobile')&& fueltype!=''
					"
				>
					<option disabled selected value="0">
						Select Preferred Unit
					</option>
					<option value="1" style="color:black">						
						<div v-if="sourcevalue.label !== 'Mobile'">
						{{ "Thermal Unit(mmBtu)" }}
						</div>
						<div v-else>
							<div v-if="fueltype !== 'Compressed Natural Gas (CNG)'">
								{{ "Liquid Unit(gallon)" }}
							</div>
							<div v-else style="color:black">
								{{ "Volume Unit(scf)" }}
							</div>
						</div>
					</option>
					<option value="2" style="color:black" v-if="val.label!=='Biomass Fuels - Kraft Pulping Liquor' && val.label !== 'Non Mobile'">
						<div v-if="val.label==='Coal and Coke' || val.label==='Other Fuels - Solid' || val.label==='Biomass Fuels - Solid'">
						{{ "Weight Unit(short ton)" }}
						</div>
						<div v-if="val.label==='Natural Gas' || val.label==='Other Fuels - Gaseous' || val.label==='Biomass Fuels - Gaseous'">
						{{ "Volume Unit(scf)" }}
						</div>
						<div v-if="val.label==='Petroleum Products' || val.label==='Biomass Fuels - Liquid'">
						{{ "Liquid Unit(gallon)" }}
						</div>
					</option>
				</select>

				<input
					outlined
					class="form"
					v-model="years"
					type="number"
					min="1960"
					max="2022"
					placeholder="Please input year of manufacture"
					counter
					maxlength="4"
					@blur="verifyData()"
					:rules="{required: true,message: '名称必填', trigger: 'blur'}"	
					v-if="(val.label === 'Passenger Cars' || val.label === 'Light-Duty Trucks' || val.label === 'Medium-Duty Trucks' || val.label === 'Heavy-Duty Trucks' || val.label === 'Motorcycles') && 
					(fueltype === 'Gasoline' || fueltype === 'Diesel')"
				/>
				
				<input
					v-if="sourcevalue.label !== 'Steam and Heat'"
					outlined
					class="form"
					v-model.number="wegith"
					type="number"
					v-bind:placeholder="input_text"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
				<input
					v-else
					outlined
					class="form"
					v-model.number="wegith"
					type="number"
					placeholder="Thermal unit mmbtu"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
			</div>
			<div v-if="fueltype !== ''" class="ex_title text-center">
				{{getSuboptionsExplanation(fueltype)}}
			</div>
			<div class="title text-left" v-if="units === '2' && fueltype !== 'null' && val.label !== 'Non Mobile'">
				<!-- If weight unit is selected,show panel below: -->
				<br />
				The default Heat content (HHV) for {{ fueltype }} is <span style="font-weight: bold">{{ getHHV() }}</span> mmBtu per 
				<span v-if="val.label==='Coal and Coke' || val.label==='Other Fuels - Solid' || val.label==='Biomass Fuels - Solid'">
					short ton
				</span>
				<span v-if="val.label==='Natural Gas' || val.label==='Other Fuels - Gaseous' || val.label==='Biomass Fuels - Gaseous'">
					scf
				</span>
				<span v-if="val.label==='Petroleum Products' || val.label==='Biomass Fuels - Liquid'">
					gallon
				</span>
				.Please update the value in this box if you want to override the value
				<input
					v-model.number="ConvertValue"
					type="number"
					:min="0"
					@input="checkNegative"
					style="width: 100px; height: 30px; font-size: 1.2vw"
				/>
			</div>
			<div class="title text-left" v-if="fueltype !== '' && getFactor(fueltype) !== null && val.inputunits === 'Mile'">
				<!-- If weight unit is selected,show panel below: -->
				<br />
				The default mileage per gallon for {{ fueltype }} is <span style="font-weight: bold">{{ getMPG() }}</span>
				mpg.Please update the value in this box if you want to override the value
				<input
					v-model.number="ConvertValue"
					type="number"
					:min="0"
					@input="checkNegative"
					style="width: 100px; height: 30px; font-size: 1.2vw"
				/>
			</div>
		</div>
	<!-- </div> -->	

		<div class="c-form-container" v-if="((sourcevalue.label === 'Business travel and employee commuting' || sourcevalue.label === 'Upstream/Downstream Transportation and Distribution' ) && (val.label === 'Business travel' || val.label === 'Employee commuting' || val.label === 'Upstream' || val.label === 'Downstream') && calculate_type=== 0)||val.label === 'Distance based method' || val.label === 'Waste-generated'">
			<div class="q-gutter-md row justify-center" >
				<select v-model="fueltype" name="fueltype"  class="form" v-if="sourcevalue.label !== 'Upstream/Downstream Transportation and Distribution'">
					<option disabled  selected value="">

						<span v-if="sourcevalue.label !== 'Upstream/Downstream Transportation and Distribution' && val.label !== 'Waste-generated'">Select {{ val.label }} Mode</span>
						<span v-else-if="val.label !== 'Waste-generated'">Select Unit</span>
						<span v-else>Select Material</span>
						<!-- Select Transportation mode -->
					</option>
					<option
						v-for="(item, index) in val.suboptions"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						{{ item.fueltype }}
					</option>
				</select>
				<select v-model="fueltype" name="fueltype"  class="form" v-else>
					<option disabled  selected value="">
						<span>Select Unit</span>
					</option>
					<option
						v-for="(item, index) in val.distanceoptions"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						{{ item.fueltype }}
					</option>
				</select>
				
				<select v-model="vehicletype" name="vehicletype"  class="form" v-if="fueltype !== 'Bus'">
					<option disabled  selected value="">
						<span v-if="val.label !== 'Waste-generated'">Select Vehicle type</span>
						<span v-else>Select Treatment Method</span>
						<!-- Select {{ val.label }} Type -->
					</option>
					<option
						v-for="(item, index) in getVehicleType()"
						:key="index"
						:lable="item"
						style="color:black"
					>
						{{ item }}
					</option>
				</select>

				<input
					outlined
					class="form"
					v-model.number="wegith"
					type="number"
					v-bind:placeholder="distance_text"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
				
			</div>
			<!-- <div v-if="fueltype !== ''" class="ex_title text-center">
				{{getSuboptionsExplanation()}}
			</div> -->
		</div>
		<div class="c-form-container" v-if="((sourcevalue.label === 'Business travel and employee commuting' || sourcevalue.label === 'Upstream/Downstream Transportation and Distribution' ) && calculate_type=== 1)">
			<div class="q-gutter-md row justify-center" >
				<select
					v-if="sourcevalue.label === 'Upstream/Downstream Transportation and Distribution'"
					outlined
					class="form"
					v-model="fueltype"
					name="fueltype"
				>
					<option disabled selected  value="">
						Select Vehicle Type
					</option>
					<option style="color:black" v-if="sourcevalue.label !== 'Upstream/Downstream Transportation and Distribution'">						
						<div v-if="val.label === 'Business travel' || val.label === 'Employee commuting'">
						{{ "Passenger ground transport" }}
						</div>
						<div v-else-if="val.label === 'Hotels'">
						{{ "Hotels" }}
						</div>
					</option>
					<option
						v-else
						v-for="(item, index) in val.spendsuboptions"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						{{ item.fueltype }}
					</option>
				</select>
				<div v-if="sourcevalue.label !== 'Upstream/Downstream Transportation and Distribution'" style="margin-top: 40px;">
					<span 
					style="font-size: 1vw;"
					>Enter total spending on employee commuting(USD): </span>
					
					<input
						outlined 
						class="input-underline"
						v-model.number="wegith"
						type="number"
						maxlength="20"
						lazy-rules
						:min="0"
						@input="checkNegative"
					/>
				</div>
				<input
					v-if="sourcevalue.label === 'Upstream/Downstream Transportation and Distribution'"
					outlined 
					class="form"
					v-model.number="wegith"
					type="number"
					placeholder="Total spend(USD)"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
			</div>
		</div>

		<div class="guidelinedetail" style="white-space: pre-wrap" v-if="(sourcevalue.label === 'Electricity' && DualReporting === 1) || sourcevalue.label === 'Purchased goods and services' ||  sourcevalue.label === 'Capital Goods' || (val.label === 'Hotels' && calculate_type=== 0)">
			<q-card
				flat
				bordered
				class="my-card"
				:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
			>
				<q-card-section>
					<div class="row items-center no-wrap">
						<div class="col">
							<div style="font-size:2vw">Guideline</div>
						</div>
					</div>
				</q-card-section>

				<q-separator />

				<q-card-section style="font-size: 0.8vw" v-if="sourcevalue.label === 'Electricity' && DualReporting === 1">
					<div> 
					<span style="font-weight: 700">Total usage:</span> The total amount of electricity consumed at a specific geographical location, which includes electricity obtained from the local e-Grid, self-generated electricity, and electricity obtained from other suppliers.<br/>
					<span style="font-weight: 700">e-Grid amount:</span> The quantity of electricity received from the local e-Grid, which is the specific electricity grid serving the given location.<br/>
					<span style="font-weight: 700">e-Grid:</span> Refers to a specific electricity grid serving a particular location. To determine your local e-Grid, please visit the website:<a href = https://www.epa.gov/egrid/power-profiler#/ target=_blank>https://www.epa.gov/egrid/power-profiler#/</a><br/>
					<span style="font-weight: 700">Supplier energy amount:</span> The amount of energy obtained from an electricity product supplier other than the e-Grid. If there are multiple electricity product suppliers (excluding the e-Grid), you can click on "Add Supplier" to include their respective energy amounts.<br/>
					<span style="font-weight: 700">Emission factors:</span> The emission factors provided by a specific supplier, encompassing the emissions of CO2, CH4, and N2O whenever possible. If only a CO2e (carbon dioxide equivalent) emission factor is provided, please use the CO2e value exclusively.<br/>
					<span style="font-weight: 700">REC:</span> Renewable Energy Certificate (REC) represents the environmental attributes of renewable electricity. The energy amount associated with REC results in zero CO2e emissions. 
					</div>
				</q-card-section>
				<q-card-section style="font-size: 0.8vw" v-if="sourcevalue.label === 'Purchased goods and services' ||  sourcevalue.label === 'Capital Goods'">
					<div> 
					Explore our comprehensive list of purchased goods and services. For accurate emission results, please provide the amount of spend for each category you select to get the emission results. 
					</div>
				</q-card-section>
				<q-card-section style="font-size: 0.8vw" v-if="val.label === 'Hotels' && calculate_type=== 0">
					<div> 
					<span style="font-weight: 700">Find e-Grid with hotel location::</span> Refers to a specific electricity grid serving a particular location. To determine your local e-Grid, please visit the website:<a href = https://www.epa.gov/egrid/power-profiler#/ target=_blank>https://www.epa.gov/egrid/power-profiler#/</a><br/>
					</div>
				</q-card-section>
			</q-card>
			<br />
		</div>
		<div class="c-form-container" v-if="sourcevalue.label === 'Electricity' && DualReporting === 1"> 
			<vxe-toolbar>
			<template #buttons>
				<!-- icon="vxe-icon-square-plus" -->
				<vxe-button @click="insertEvent(-1)">Add Supplier</vxe-button>  
				<vxe-button @click="$refs.xTable.removeCheckboxRow()">Delete Supplier</vxe-button>
				<!-- <vxe-radio label="1" content="Detailed factor" v-model="detailedFactor" :strict="false" style="margin-left: 16px"></vxe-radio> -->
				<!-- <vxe-button @click="egirdCalculate()">Calculate</vxe-button>   -->
			</template>
			</vxe-toolbar>

			<vxe-table
				border
				show-overflow
				keep-source
				ref="xTable"
				align="center"
				size="mini"
				max-height="600"
				empty-text="Empty Table"
				:span-method="mergeRowMethod"
				:merge-cells="mergeCells"
				@edit-closed="updateData"
				:edit-rules="validRules"
				:edit-config="{trigger: 'click', mode: 'cell'}"
			>
				<vxe-column type="checkbox" width="60"></vxe-column>
				<!-- <vxe-column type="seq" v-model="tablerowseq"  width="60" ></vxe-column> -->

				<vxe-column field="Totalusage" title="Total usage(MWh)" :edit-render="{}" :title-prefix="{ message: 'The total amount of electricity consumed at a specific geographical location, which includes electricity obtained from the local e-Grid, self-generated electricity, and electricity obtained from other suppliers.', icon: 'vxe-icon-question-circle-fill' }" >
					<template #edit="{ row }">
					<vxe-input 
						v-model="row.Totalusage" 
						type="number" 
						min="0"
						placeholder="Please enter"
						@change="$refs.xTable.updateStatus(row.Totalusage)"
					></vxe-input>
					</template>
				</vxe-column>

				<vxe-column field="eGirdamount" title="e-Gird amount" :edit-render="{}" :title-prefix="{ message: 'The quantity of electricity received from the local e-Grid, which is the specific electricity grid serving the given location.', icon: 'vxe-icon-question-circle-fill' }"> 
					<template #edit="{ row }">
					<vxe-input 
						v-model="row.eGirdamount" 
						type="number" 
						min="0" 
						placeholder="Please enter"
					></vxe-input>
					</template>
				</vxe-column>
				<!-- defaultValue: 'select e-Gird type' -->                      
				<vxe-column field="eGird" title="e-Gird" :edit-render="{}" :title-prefix="{ useHTML: true,  content: 'Refers to a specific electricity grid serving a particular location. To determine your local e-Grid, please visit the website: <a href = https://www.epa.gov/egrid/power-profiler#/ target=_blank>https://www.epa.gov/egrid/power-profiler#/</a> ', icon: 'vxe-icon-question-circle-fill' }">
					<template #default="{ row }">
					<span>{{ row.eGird }}</span>
					</template>
					<template #edit="{ row }">
					<vxe-select v-model="row.eGird" transfer placeholder="Please select"> 
						<vxe-option v-for="item in val.suboptions" :key="item.value" :value="item.value" :label="item.value"></vxe-option>
					</vxe-select>
					</template>
				</vxe-column>

				<vxe-column field="energyamount" title="Supplier energy amount (MWh)" :edit-render="{defaultValue: ''}" :title-prefix="{ message: 'The amount of energy obtained from an electricity product supplier other than the e-Grid. If there are multiple electricity product suppliers (excluding the e-Grid), you can click on <Add Supplier> to include their respective energy amounts.', icon: 'vxe-icon-question-circle-fill' }">
					<template #edit="{ row }">
					<vxe-input 
					v-model="row.energyamount" 
					type="number" 
					placeholder="Please enter"
					transfer min="0" 
					></vxe-input>
					</template>
				</vxe-column>

				<vxe-colgroup title="Supplier emission faction" :title-prefix="{ message: 'The emission factors provided by a specific supplier, encompassing the emissions of CO2, CH4, and N2O whenever possible. If only a CO2e (carbon dioxide equivalent) emission factor is provided, please use the CO2e value exclusively.', icon: 'vxe-icon-question-circle-fill' }">
					<vxe-column field="lbCO2amount" title="lbCO2/MWh" :edit-render="{defaultValue: ''}">
						<template #edit="{ row ,rowIndex}">
						<vxe-input 
							v-model="row.lbCO2amount" 
							type="number" 
							transfer 
							min="0" 
							placeholder="Please enter"
							:disabled="lbCO2IsDisable[rowIndex]"
						></vxe-input>
						</template>
					</vxe-column>

					<vxe-column field="lbCH4amount" title="lbCH4/MWh" :edit-render="{}">
						<template #edit="{ row}">
						<vxe-input 
							v-model="row.lbCH4amount" 
							type="number" 
							transfer 
							min="0" 
							placeholder="Please enter"
							:disabled="lbCO2IsDisable[rowIndex]"
						></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="lbN2Oamount" title="lbN2O/MWh" :edit-render="{}">
						<template #edit="{ row ,rowIndex}">
						<vxe-input 
							v-model="row.lbN2Oamount" 
							type="number" 
							transfer 
							min="0" 
							placeholder="Please enter"
							:disabled="lbCO2IsDisable[rowIndex]
						"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="lbCO2eamount" title="lbCO2e/MWh" :edit-render="{}">
						<template #edit="{ row ,rowIndex}">
						<vxe-input 
							v-model="row.lbCO2eamount" 
							type="number" 
							transfer 
							min="0" 
							placeholder="Please enter"
							:disabled="lbCO2eIsDisable[rowIndex]"
						></vxe-input>
						</template>
					</vxe-column>
				</vxe-colgroup>
				
				<vxe-column field="recamount" title="REC amount(MWh)" :edit-render="{}" :title-prefix="{ message: 'Renewable Energy Certificate (REC) represents the environmental attributes of renewable electricity. The energy amount associated with REC results in zero CO2e emissions.', icon: 'vxe-icon-question-circle-fill' }">
					<template #edit="{ row }">
					<vxe-input 
						v-model="row.recamount" 
						type="number" 
						transfer 
						min="0" 
						placeholder="Please enter"
					></vxe-input>
					</template>
				</vxe-column>

				<!-- <vxe-column title="操作" width="500">
					<template #default="{ row }">
					<vxe-button type="text" status="primary" @click="insertRow(row, 'bottom')">尾部插入子节点</vxe-button>
					<vxe-button type="text" status="primary" @click="removeRow(row)">删除节点</vxe-button>
					</template>
				</vxe-column> -->
			</vxe-table>
		</div>

		<div class="c-form-container" v-if="sourcevalue.label === 'Purchased goods and services' ||  sourcevalue.label === 'Capital Goods'">
			<div class="q-gutter-md row justify-center" >
				<q-select
					v-if="sourcevalue.label === 'Purchased goods and services'"
					filled 
					style="width:35%; "	
					hide-dropdown-icon
					v-model="fueltype"
					use-input	
					input-debounce="0"
					hint='Please select the purchased goods or services'
					:options="Goodsoption"
					@filter="filterFn"
				>
					<template v-slot:no-option>
					<q-item>
						<q-item-section class="text-grey">
						No results
						</q-item-section>
					</q-item>
					</template>
				</q-select>
				<q-select
					v-if="sourcevalue.label === 'Capital Goods'"
					filled 
					style="width:35%; "	
					hide-dropdown-icon
					v-model="fueltype"
					use-input	
					input-debounce="0"
					hint='Please select the capital goods'
					:options="Goodsoption"
					@filter="filterFn"
				>
					<template v-slot:no-option>
					<q-item>
						<q-item-section class="text-grey">
						No results
						</q-item-section>
					</q-item>
					</template>
				</q-select>
				<q-input
					filled
					style="width:35%"
					v-model.number="wegith"
					type="number"
					hint='Please enter the amount of spend(USD)'
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
			</div>
		</div>
		<div class="c-form-container" v-if="sourcevalue.label === 'Business travel and employee commuting' && val.label === 'Hotels' && calculate_type=== 0">
			<div class="q-gutter-md row justify-center" >
				<select v-model="fueltype" name="fueltype"  class="form">
					<option disabled  selected value="">
						<!-- Select {{ val.label }} Type -->
						Select choose egrid
					</option>
					<option
						v-for="(item, index) in geteGridType('Electricity')"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						{{ item.value }}
					</option>
				</select>
				<!-- <q-badge color="secondary" multi-line>
					Model: "{{ vehicletype }}"
				</q-badge> -->
				<select v-model="vehicletype" name="vehicletype"  class="form">
					<option disabled  selected value="">
						<!-- Select {{ val.label }} Type -->
						Select Hotel type
					</option>
					<option
						v-for="(item, index) in val.suboptions"
						:key="index"
						:lable="item.fueltype"
						style="color:black"
					>
						{{ item.fueltype }}
					</option>
				</select>

				<input
					outlined
					class="form"
					v-model.number="wegith"
					type="number"
					placeholder="Number of nights stayed"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>

				<input
					outlined
					class="form"
					v-model.number="rooms"
					type="number"
					placeholder="Number of rooms stayed"
					counter
					maxlength="20"
					lazy-rules
					:min="0"
					@input="checkNegative"
				/>
				
			</div>
			<div v-if="vehicletype !== ''" class="ex_title text-center">
			<span style="font-weight:bold">{{vehicletype}} : </span>{{getSuboptionsExplanation(vehicletype)}}
			</div>
		</div>
        <div class="card" v-if="calculate > 0 || testoutput > 0 || wegith > 0">
          <div class="card-header">
            <h5 style="margin-top: 1px;margin-bottom: 5px;font-weight: 700;">Report</h5>
			<div class="title text-left" v-if="sourcevalue.label === 'Purchased goods and services' ||  sourcevalue.label === 'Capital Goods' || sourcevalue.label === 'Business travel and employee commuting' || sourcevalue.label === 'Upstream/Downstream Transportation and Distribution' || sourcevalue.label === 'Waste-generated'">
				The total Scope 3 GHG emission for your {{ val.label }} is <span style="font-weight: bold">{{ rows[0].kgCO2e }}</span> 
				<span v-if="sourcevalue.label === 'Waste-generated'"> ton CO2e.</span> 
				<span v-if="sourcevalue.label !== 'Waste-generated'"> kg CO2e.See below for details:</span>
			</div>
			<div class="title text-left" v-else-if="sourcevalue.label !== 'Electricity' && sourcevalue.label !== 'Steam and Heat'">
				The total Scope 1 GHG emission for your {{ val.label }} is <span style="font-weight: bold">{{ rows[0].kgCO2e }}</span> kg CO2e. See below for details:
			</div>
			<div class="title text-left" v-else>
				The total Scope 2 GHG emission for your {{ val.label }} is <span style="font-weight: bold">{{ rows[0].kgCO2e }}</span> 
				<span v-if="sourcevalue.label !== 'Steam and Heat'"> lb CO2e</span>
				<span v-else> kg CO2e</span>
				. See below for details:
			</div>
          </div>
          <div class="table-responsive" v-if="sourcevalue.label !== 'Waste-generated'">
            <table style="border-top: 4mm ridge rgba(205, 206, 196, 0.3);width: 100%;">
              <thead >
                <tr v-if="sourcevalue.label !== 'Electricity'">
					<th class="text-secondary" style="width: 16%;">Output</th>
					<th class="text-secondary" style="width: 16%;">kg CO2e</th>
					<th class="text-secondary" style="width: 16%;" v-if="sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">kg CO2</th>
					<th class="text-secondary" style="width: 16%;" v-if="val.label !== 'Non Mobile' && sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">g CH4</th>
					<th class="text-secondary" style="width: 16%;" v-if="val.label !== 'Non Mobile' && sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">g N2O</th>
					<th class="text-secondary" style="width: 16%;" v-if="(sourcevalue.label === 'Business travel and employee commuting' && calculate_type=== 1)||val.label === 'Spend based method'">Other kg CO2e</th>
                </tr>
				<tr v-else>
					<th class="text-secondary" style="width: 16%;">output</th>
					<th class="text-secondary" style="width: 16%;">lb CO2e</th>
					<th class="text-secondary" style="width: 16%;">lb CO2</th>
					<th class="text-secondary" style="width: 16%;">lb CH4</th>
					<th class="text-secondary" style="width: 16%;">lb N2O</th>
				</tr>
              </thead>
              <tbody style="text-align:center">
                <tr style="text-align:center">
					<td style="font-weight: 700" v-if="sourcevalue.label !== 'Electricity'">Value</td>	
					<td style="font-weight: 700" v-else>Location based</td>
					<td>{{ rows[0].kgCO2e }}</td>
					<td v-if="sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">{{ rows[0].kgCO2 }}</td>
					<td v-if="val.label !== 'Non Mobile' && sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">{{ rows[0].gCH4 }}</td>
					<td v-if="val.label !== 'Non Mobile' && sourcevalue.label !== 'Process Emission' && sourcevalue.label !== 'Fugitive Emission'">{{ rows[0].gN2O }}</td>
					<td v-if="(sourcevalue.label === 'Business travel and employee commuting' && calculate_type=== 1)||val.label === 'Spend based method'">{{ rows[0].otherCo2e }}</td>
					
                </tr>
                <tr style="text-align:center" v-if="DualReporting===1">
					<td style="font-weight: 700">Market based</td>
					<td>{{ rows[1].kgCO2e }}</td>
					<td>{{ rows[1].kgCO2 }}</td>
					<td>{{ rows[1].gCH4 }}</td>
					<td>{{ rows[1].gN2O }}</td>
				</tr>
              </tbody>
            </table>
          </div>
        </div>
		<div class="detail" style="font-size: x-small">
			<br>
			<br>
			<router-link to="/contact">Contact Us</router-link> for our calculation
			methodology
		</div>
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
import { computed, ref } from "vue";
import { VXETable ,VxeTableInstance } from 'vxe-table';
// import { useRoute } from "vue-router";
import { useQuasar } from 'quasar'
import stepSource from "../carbondata/sourcetype.json";
import stepFactor from "../carbondata/factortype.json";
import stepGoodsFactor from "../carbondata/GoodsServicesEmission.json";
// import stepSource from "../carbondata/sourcetype.json";

export default {
	// name: "CO2Calculator",
	components: {
		Toolbar,
	},

	setup() {
		const name = ref("");
		const fueltype = ref("");
		const vehicletype = ref("");
		const wegith = ref("");
		const rooms = ref("");
		const units = ref("0");
		const years = ref("");
		const ConvertValue = ref("");
		const selectUnit = [
			{
				label :'Thermal Unit (mmBtu)',
				value : '1'
			},
			{
				label :'Weight Unit (short ton)',
				value : '2'
			}];

		const columns = [
			{
				name: "name",
				required: true,
				label: "output",
				align: "left",
				sortable: true,
			},
			{ name: "kgCO2e", align: "center", label: "kg CO2e", field: "kgCO2e" },
			{ name: "kgCO2", align: "center", label: "kg CO2", field: "kgCO2" },
			{ name: "gCH4", align: "center", label: "g CH4", field: "gCH4" },
			{ name: "gN2O", align: "center", label: "g N2O", field: "gN2O" },
		];
		const rows = [
			{
				name: "value",
				kgCO2e: 0,
				kgCO2: 0,
				gCH4: 0,
				gN2O: 0,
				otherCo2e:0
			},
			{
				name: "value",
				kgCO2e: 0,
				kgCO2: 0,
				gCH4: 0,
				gN2O: 0,
			},
		];
		var input_text = "Please input usage amount";
		var distance_text = "Total distance traveled";
		var kg_CO2e = 0,
			kg_CO2 = 0,
			g_CH4 = 0,
			g_N2O = 0,
			other_Co2e; //,HHV = 0;
		const calculate_type = ref("0");
		const DualReporting = ref("0");
		const detailedFactor = ref("1");
		const xTable = ref(VxeTableInstance);
		// let xTabledata = ref("");
		const testoutput =  ref("0");
		var lbCO2IsDisable = [];
		var	lbCO2eIsDisable = [];
		
		const stringOptions = []  //
		for (var i = 0; i < stepGoodsFactor.length; i++) {
			var arrindex=stringOptions.findIndex((item) => {return item.name === stepGoodsFactor[i].name})//获取下标
			if(arrindex==-1){//不存在盘符数据的添加盘符数据
				stringOptions.push(stepGoodsFactor[i].name);
			}else{//有盘符则往盘符数据中添加
				stringOptions[arrindex].data.push(stepGoodsFactor[i].data)
			}
			//console.log(arrindex);
		}
				
		const Goodsoption = ref(stringOptions);
		// const route = useRoute();
		// const val = JSON.parse(route.query.k1);
		const index = history.state.k2;//route.query.k2;
		const sourcevalue = JSON.parse(history.state.k1);
		var val =  sourcevalue.children[index];
		// var dualflag = JSON.parse(history.state.k3);

		const validRules = ref({
              Totalusage: [
                { required: true, message: 'mandatory field' }              ],
            })

		function getTable() {
			if(sourcevalue.label === "Purchased goods and services" ||  sourcevalue.label === "Capital Goods" || sourcevalue.label === "Business travel and employee commuting" || sourcevalue.label === "Upstream/Downstream Transportation and Distribution")
			{
				rows[0].kgCO2e = kg_CO2e.toFixed(3);
				rows[0].kgCO2 = kg_CO2.toFixed(3);
				rows[0].gCH4 = g_CH4.toFixed(3);
				rows[0].gN2O = g_N2O.toFixed(3);
				if(calculate_type.value === 1 || val.label === 'Spend based method')
				{
					rows[0].otherCo2e = other_Co2e.toFixed(3);
				}
			}else{
				rows[0].kgCO2e = kg_CO2e.toFixed(2);
				rows[0].kgCO2 = kg_CO2.toFixed(2);
				rows[0].gCH4 = g_CH4.toFixed(2);
				rows[0].gN2O = g_N2O.toFixed(2);
			}
		}
		function confirmunit(){
			if(val.label === "Biomass Fuels - Kraft Pulping Liquor" || val.label === "Non Mobile")
			{
				units.value = 1;
			}
		}
		function egirdCalculate(){
			let factor = 0;
			let co2 = 0,
				ch4 = 0,
				n2o = 0; 
			let temp_Totalusage = 0,
				temp_eGirdamount = 0,
				temp_energyamount = 0,
				temp_lbCO2eamount = 0,
				temp_lbCO2amount = 0,
				temp_lbCH4amount = 0,
				temp_lbN2Oamount = 0,
				ekg_CO2e = 0,
				ekg_CO2 = 0,
				eg_CH4 = 0,
				eg_N2O = 0;

			kg_CO2e = 0;
			kg_CO2 = 0;
			g_CH4 = 0;
			g_N2O = 0;
			let xTabledata = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData));
			// console.log("xTabledata=====");
			// console.log(xTabledata);
			// console.log(xTabledata.length);

			for(let i=0;i<xTabledata.length;i++){
				// console.log(xTabledata[i].lbCO2eamount)
				// console.log(xTabledata[i].lbCO2amount)
				// console.log(xTabledata[i].lbCH4amount)
				// console.log(xTabledata[i].lbN2Oamount)
				if(xTabledata[i].lbCO2eamount > 0)
				{
					lbCO2IsDisable[i] = true;
				}else{
					console.log(xTabledata[i].lbCO2eamount)
					lbCO2IsDisable[i] = false;
				}
				if(xTabledata[i].lbCO2amount > 0 || xTabledata[i].lbCH4amount > 0 || xTabledata[i].lbN2Oamount > 0)
				{
					lbCO2eIsDisable[i] = true;
				}else{
					lbCO2eIsDisable[i] = false;
				}
				// console.log("disable")
				// console.log(lbCO2IsDisable[i])
				// console.log(lbCO2eIsDisable[i])
			}
	

			factor = getFactor(xTabledata[0].eGird)
			co2 = parseFloat(factor.value[0].co2);
			ch4 = parseFloat(factor.value[1].ch4);
			n2o = parseFloat(factor.value[2].n2o);
			temp_Totalusage = parseFloat(xTabledata[0].Totalusage);
			temp_eGirdamount = parseFloat(xTabledata[0].eGirdamount);
			kg_CO2e += temp_Totalusage * (co2 + ch4 * 25 + n2o * 298);
			kg_CO2 += temp_Totalusage * co2;
			g_CH4 += temp_Totalusage * ch4;
			g_N2O += temp_Totalusage * n2o;

			ekg_CO2e += temp_eGirdamount * (co2 + ch4 * 25 + n2o * 298);
			ekg_CO2 += temp_eGirdamount  * co2;
			eg_CH4 += temp_eGirdamount  * ch4;
			eg_N2O += temp_eGirdamount * n2o;
			for(let i=0;i<xTabledata.length;i++){
				temp_energyamount = parseFloat(xTabledata[i].energyamount);
				temp_lbCO2eamount = parseFloat(xTabledata[i].lbCO2eamount)
				temp_lbCO2amount = parseFloat(xTabledata[i].lbCO2amount)
				temp_lbCH4amount = parseFloat(xTabledata[i].lbCH4amount)
				temp_lbN2Oamount = parseFloat(xTabledata[i].lbN2Oamount)
				// console.log("lbCO2eIsDisable[i]")
				// console.log(lbCO2eIsDisable[i])
				if(lbCO2eIsDisable[i]=== true){
					
					ekg_CO2e += temp_energyamount * (temp_lbCO2amount + temp_lbCH4amount * 25 + temp_lbN2Oamount * 298);
					ekg_CO2 += temp_energyamount * temp_lbCO2amount;
					eg_CH4 += temp_energyamount * temp_lbCH4amount;
					eg_N2O += temp_energyamount  * temp_lbN2Oamount;
				}else{
					console.log("detailedFactor.value")
					ekg_CO2e += temp_energyamount * temp_lbCO2eamount;
					// ekg_CO2 += temp_eGirdamount  * co2;
					// eg_CH4 += temp_eGirdamount  * ch4;
					// eg_N2O += temp_eGirdamount * n2o;
								// console.log(ekg_CO2e)
				}
				
			}
			rows[0].kgCO2e = kg_CO2e.toFixed(2);
			rows[0].kgCO2 = kg_CO2.toFixed(2);
			rows[0].gCH4 = g_CH4.toFixed(2);
			rows[0].gN2O = g_N2O.toFixed(2);
			rows[1].kgCO2e = ekg_CO2e.toFixed(2);
			rows[1].kgCO2 = ekg_CO2.toFixed(2);
			rows[1].gCH4 = eg_CH4.toFixed(2);
			rows[1].gN2O = eg_N2O.toFixed(2);

			testoutput.value = (kg_CO2e ? kg_CO2e : 0) +
								(kg_CO2 ? kg_CO2 : 0) +
								(g_CH4 ? g_CH4 : 0) +
								(g_N2O ? g_N2O : 0) +
								(ekg_CO2e ? ekg_CO2e : 0) +
								(ekg_CO2 ? ekg_CO2 : 0) +
								(eg_CH4 ? eg_CH4 : 0) +
								(eg_N2O ? eg_N2O : 0);
								console.log("testoutput.value")
			console.log(testoutput.value)
		}
		let calculate = computed(() => {
			var temp_data = 0, temp_data1 = 0, temp_data2 = 0;
			var cov = 1;
			var co2 = 0,
				ch4 = 0,
				n2o = 0,
				co2_1 = 0,
				ch4_1 = 0,
				n2o_1 = 0,
				otherco2e = 0,
				electricity_night = 0,
				gas_night = 0; //,hhv = 0;
			var CO2_MotorGasoline = 8.78,
				CO2_Diesel = 10.21,
				CO2_Methanol = 4.1,
				CO2_Ethanol = 5.75,
				CO2_CNG = 0.05444,
				CO2_LPG = 5.68,
				CO2_LNG = 4.50,
				CO2_Biodiesel = 9.45,
				CO2_ResidualFuelOil = 11.27,
				CO2_AviationGasoline = 8.31,
				CO2_JetFuel = 9.75;
				var temp_mpg = 0;
			confirmunit();
			console.log("val.label")
			console.log(calculate_type.value)

			if(val.label === 'Hotels' && calculate_type.value=== 1)// || val.label === 'Spend based method')
			{
				temp_data = getFactor("Hotels");
				// fueltype.value = 'Hotel';
			}else if((val.label === "Business travel" || val.label === "Employee commuting") && calculate_type.value=== 1){
				// fueltype.value = 'Passenger ground transport';
				temp_data = getFactor("Passenger ground transport");
			}else
				temp_data = getFactor(fueltype.value);

			if (temp_data === null) {
				console.log("出錯退出");
				return 0;
			} else {
				// console.log("正確");
				// console.log(sourcevalue);
				// if (val.label === "Non Mobile") {
				// 	kg_CO2e = wegith.value * parseFloat(temp_data.value);
				// 	kg_CO2 = kg_CO2e;
				// } else 
				if (sourcevalue.label === "Mobile") {

					ch4 = parseFloat(temp_data.value[0].ch4);
					n2o = parseFloat(temp_data.value[1].n2o);

					g_CH4 = wegith.value * temp_data.value[0].ch4;
					g_N2O = wegith.value * temp_data.value[1].n2o;

					if (val.inputunits === "Mile") {
						switch(fueltype.value)
						{
							case "Gasoline":
								temp_mpg = CO2_MotorGasoline;
							break;
							case "Diesel":
								temp_mpg = CO2_Diesel;
							break;
							case "Methanol":
								temp_mpg = CO2_Methanol;
							break;
							case "CNG":
								temp_mpg = CO2_CNG;
							break;
							case "Ethanol":
								temp_mpg = CO2_Ethanol;
							break;
							case "LPG":
								temp_mpg = CO2_LPG;
							break;
							case "LNG":
								temp_mpg = CO2_LNG;
							break;
							case "Biodiesel":
								temp_mpg = CO2_Biodiesel;
							break;
							default:
								temp_mpg = CO2_MotorGasoline;
							break;
						}
						cov = ConvertValue.value > 0 ? ConvertValue.value : getMPG();
						kg_CO2e = (g_CH4 * 25) / 1000 + (g_N2O * 298) / 1000 + temp_mpg * wegith.value / cov;
						kg_CO2 = temp_mpg * wegith.value / cov;
					}else if(val.inputunits === "Gallon"){
						if(fueltype.value === 'Diesel' || fueltype.value === 'Diesel Equipment' || fueltype.value === 'Diesel Off-Road Trucks')
						{
							temp_mpg = CO2_Diesel;
						}
						else if(fueltype.value === 'Residual Fuel Oil')
						{
							temp_mpg = CO2_ResidualFuelOil;
						}
						else if(fueltype.value === 'Aviation Gasoline')
						{
							temp_mpg = CO2_AviationGasoline;
						}
						else if(fueltype.value === 'LPG')
						{
							temp_mpg = CO2_LPG;
						}
						else if(fueltype.value === 'Jet Fuel')
						{
							temp_mpg = CO2_JetFuel;
						}
						else   //Gasoline、Gasoline (2 stroke)、Gasoline (4 stroke)、Gasoline Off-Road Trucks ...
						{
							temp_mpg = CO2_MotorGasoline;
						}
						kg_CO2 = temp_mpg * wegith.value;
						kg_CO2e = (g_CH4 * 25) / 1000 + (g_N2O * 298) / 1000 + kg_CO2;
					}
					
				} 
				else if(sourcevalue.label === "Purchased goods and services" || sourcevalue.label === "Capital Goods"){
					console.log("Purchased goods and services")
					co2 = parseFloat(temp_data.value[0].co2);
					ch4 = parseFloat(temp_data.value[1].ch4);
					n2o = parseFloat(temp_data.value[2].n2o);
					otherco2e = parseFloat(temp_data.value[3].otherco2e);
					
					kg_CO2e = wegith.value * (co2 + ch4 * 25 + n2o * 298 + otherco2e);
					kg_CO2 = wegith.value * co2;
					g_CH4 = wegith.value * ch4;
					g_N2O = wegith.value * n2o;
				}else if (
					val.label === "Emissions" ||
					val.label === "Blended Refrigerants"
				) {
					kg_CO2e = wegith.value * parseFloat(temp_data.value);
					// kg_CO2  = kg_CO2e;
				} else if (val.label === "Electricity") {
					// console.log("eGRID")
					co2 = parseFloat(temp_data.value[0].co2);
					ch4 = parseFloat(temp_data.value[1].ch4);
					n2o = parseFloat(temp_data.value[2].n2o);
					// console.log(co2)
					// console.log(ch4)
					// console.log(n2o)
					kg_CO2e = wegith.value * (co2 + ch4 * 25 + n2o * 298);
					kg_CO2 = wegith.value * co2;
					g_CH4 = wegith.value * ch4;
					g_N2O = wegith.value * n2o;
					// console.log(kg_CO2e)
					// console.log(kg_CO2)
					// console.log(g_CH4)
					// console.log(g_N2O)
				}else if(val.label === "Business travel" || val.label === "Employee commuting" || val.label === "Hotels" || (sourcevalue.label === "Upstream/Downstream Transportation and Distribution" && calculate_type.value === 1)){
					co2 = parseFloat(temp_data.value[0].co2);
					ch4 = parseFloat(temp_data.value[1].ch4);
					n2o = parseFloat(temp_data.value[2].n2o);
					if(val.label === "Hotels" && calculate_type.value === 0)
					{
						
						temp_data1 = getFactor(vehicletype.value);
						if(temp_data1 === null)
						{
							return;
						}
						electricity_night = parseFloat(temp_data1.value[0].co2);
						temp_data2 = getFactor("Natural Gas Fuel");
						co2_1 = parseFloat(temp_data2.value[0].co2);
						ch4_1 = parseFloat(temp_data2.value[1].ch4);
						n2o_1 = parseFloat(temp_data2.value[2].n2o);
						gas_night = parseFloat(temp_data1.value[1].ch4);
					}
					
					if(calculate_type.value === 1)
					{
						otherco2e = parseFloat(temp_data.value[3].otherco2e);
						other_Co2e = wegith.value * otherco2e;
						kg_CO2e = wegith.value * (co2 + ch4 * 25/1000 + n2o * 298/1000 + otherco2e);
						kg_CO2 = wegith.value * co2;
						g_CH4 = wegith.value * ch4;
						g_N2O = wegith.value * n2o;
					}else{
						console.log("进来")
						if(val.label === "Business travel" || val.label === "Employee commuting")
						{
							kg_CO2e = wegith.value * (co2 + ch4 * 0.021/1000 + n2o * 0.31/1000);
							kg_CO2 = wegith.value * co2;
							g_CH4 = wegith.value * ch4;
							g_N2O = wegith.value * n2o;
						}else{
							kg_CO2e = wegith.value * ((co2 + ch4 * 25 + n2o * 298) * electricity_night * 0.453592 + (co2_1 + ch4_1 * 25/1000 + n2o_1 * 298/1000) * gas_night) * rooms.value;
							kg_CO2 = wegith.value * (co2*electricity_night * 0.453592 + co2_1*gas_night) * rooms.value;
							g_CH4 = wegith.value * (ch4*electricity_night * 1000 * 0.453592 + ch4_1*gas_night) * rooms.value;
							g_N2O = wegith.value * (n2o*electricity_night * 1000 * 0.453592 + n2o_1*gas_night) * rooms.value;
						}
						
					}
				}
				else if(sourcevalue.label === "Upstream/Downstream Transportation and Distribution" && calculate_type.value === 0)
				{
					co2 = parseFloat(temp_data.value[0].co2);
					ch4 = parseFloat(temp_data.value[1].ch4);
					n2o = parseFloat(temp_data.value[2].n2o);
					kg_CO2e = wegith.value * (co2 + ch4 * 0.021/1000 + n2o * 298/1000);
					kg_CO2 = wegith.value * co2;
					g_CH4 = wegith.value * ch4;
					g_N2O = wegith.value * n2o;
				}
				else if(val.label === "Waste-generated"){
					co2 = parseFloat(temp_data.value[0].co2);
					kg_CO2e = wegith.value * co2;
				}
				else{// if (val.label === "Coal and Coke") 
		
					if (units.value === "2") {
						cov = (ConvertValue.value > 0 ? ConvertValue.value : getHHV() );
					}
					if(sourcevalue.label === "Steam and Heat"){
						co2 = parseFloat(temp_data.value[0].co2);
						ch4 = parseFloat(temp_data.value[1].ch4);
						n2o = parseFloat(temp_data.value[2].n2o);
					}else{
						co2 = parseFloat(temp_data.value[1].co2);
						ch4 = parseFloat(temp_data.value[2].ch4);
						n2o = parseFloat(temp_data.value[3].n2o);
					}

					kg_CO2e = cov * wegith.value * (co2 + ch4 * 25/1000  + n2o * 298/1000);
					kg_CO2 = cov * wegith.value * co2;
					g_CH4 = cov * wegith.value * ch4;
					g_N2O = cov * wegith.value * n2o;

					// console.log(val.label)
					// console.log(ConvertValue.value)
					// console.log(units.value)
					// console.log(cov)
					// console.log(kg_CO2e)
					// console.log(kg_CO2)
					// console.log(g_CH4)
					// console.log(g_N2O)
				} 
				getTable();
			}
			return kg_CO2e+kg_CO2+g_CH4+g_N2O;//kg_CO2e.toFixed(2);
		});

		function getHHV() {
			return parseFloat(getFactor(fueltype.value).value[0].hhv);
		}

		function getMPG() {
			return parseFloat(getFactor(fueltype.value).value[2].mpg);
		}

		function getFactor(Ftype) {
			let temp_Factor;
			
			// console.log("fueltype.value");
			// console.log(Ftype);
			// console.log(row.eGird);
			if (Ftype === "") {
				// console.log(fueltype.value);
				console.error("selected val is null");
				return null;
			} else {
				// console.log(val.label);
				if(sourcevalue.label === "Mobile")
				{
					if ((val.label === "Passenger Cars" || val.label === "Light-Duty Trucks" || val.label === "Medium-Duty Trucks" || val.label === "Heavy-Duty Trucks" || val.label === "Motorcycles") && 
					(Ftype === "Gasoline"||Ftype === "Diesel")
					) {
						if(val.label === "Medium-Duty Trucks" || val.label === "Heavy-Duty Trucks")
						{
							if(Ftype === "Gasoline" || val.label === "Heavy-Duty Trucks")
							{
								if(years.value < 1980)
									temp_Factor = "Heavy-Duty Trucks" + " " + Ftype + " " + "1980";
								else
									temp_Factor = "Heavy-Duty Trucks" + " " + Ftype + " " + years.value;
							}else{
								temp_Factor = "Medium and Heavy-Duty Vehicles" + " " + Ftype + " " + years.value;
							}
							
						}else{
							temp_Factor = val.label + " " + Ftype + " " + years.value;
						}
						
						// console.log(temp_Factor);
					}else{
						temp_Factor = val.label + " " + Ftype;
					}

				}
				else if(val.label === "Business travel" || val.label === "Employee commuting" || val.label === "Upstream" || val.label === "Downstream" || val.label === "Waste-generated")
				{					
					if((Ftype === "Bus" || Ftype === "Passenger ground transport" || Ftype === "Hotels")|| ((val.label === "Upstream" || val.label === "Downstream")&&calculate_type.value === 1)) temp_Factor = Ftype;
					else temp_Factor = Ftype + " " + vehicletype.value;
				}
				else{
					temp_Factor = Ftype;
				}
				var source;
				if(sourcevalue.label !== "Purchased goods and services" && sourcevalue.label !== "Capital Goods")
				{
					source = stepFactor.filter((x) => x.name === temp_Factor);
				}else{
					source = stepGoodsFactor.filter((x) => x.name === temp_Factor);
				}
				// console.log(source);
				// console.log(source[0].value['hhv']);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].value[0].hhv);
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}

		
		// function getStep() {
			
		// 	if (val == null) {
		// 		// console.log(JSON.parse(route.query.id));
		// 		console.error("selected val is null");
		// 	} else {
		// 		// console.log(val);
		// 		console.log("this.$route.query.k2");
		// 		console.log(this.$route.query.k2);
		// 		var source = val.children[index].suboptions.filter((x) => x.value === val);
		// 		if (source.length >= 1) {
		// 			// console.log(source.length );
		// 			// console.log(source[0]);
		// 			return source[0];
		// 		} else {
		// 			console.error("getStep--Failed to find the step", val);
		// 			return null;
		// 		}
		// 	}
		// }

		// function getExplanation() {
		// 	// console.log("getStep().children.length");
		// 	// console.log(getStep().children.length);
		// 	// console.log(getStep().children[0].explanation);
		// 	return getStep().children[0].explanation;
		// }
		// function getLabel() {
		// 	console.log(getStep().label);
		// 	return getStep().label;
		// }
		function getSuboptionsExplanation(paramtype) {
			console.log("val");
			console.log(val);
			console.log(paramtype)
			var source = val.suboptions.filter((x) => x.value === paramtype);
			if (source.length >= 1) {
				// console.log(source.length );
				console.log(source[0]);
				return source[0].explanation;
			} else {
				console.error("getStep--Failed to find the step", val);
				return null;
			}
		}

		const insertEvent = async (row) => {
			const $table = xTable.value
			const record = {
			}
			const { row: newRow } = await $table.insertAt(record, row)
			await $table.setEditCell(newRow, 'name')
			console.log(xTable.value.getTableData().visibleData)
		}
		function getInsertEvent () {
			const $table = xTable.value
			const insertRecords = $table.getInsertRecords()
			VXETable.modal.alert(insertRecords.length)
		}
		const $q = useQuasar();
		function verifyData(){
			let value = this.years;
			if(value){
				if(value < 1960 || value > 2020) {
					$q.notify(
					{
						message: "year>=1960 & year<=2020",
						// position: "center",
						position: 'top',
						color: 'red',
						timeout: 1500
					})
					return;
				}
           }
		}

		function getVehicleType() {
			console.log(val);
			var source;
			if(sourcevalue.label === "Upstream/Downstream Transportation and Distribution")
			{
				source = val.distanceoptions.filter((x) => x.fueltype === fueltype.value);
			}else{
				source = val.suboptions.filter((x) => x.fueltype === fueltype.value);
			}
			
			if (source.length >= 1) {
				// console.log(source.length );
				console.log(source[0]);
				return source[0].option;
			} else {
				console.error("getStep--Failed to find the step", val);
				return null;
			}
		}
		function geteGridType(val) {
			if (val == null) {
				console.error("selected val is null");
			} else {
				console.log(val);
				var source = stepSource.filter((x) => x.label === val);
				if (source.length >= 1) {
					// console.log(source.length );
					console.log(source[0]);
					return source[0].children[0].suboptions;
				} else {
					console.error("Failed to find the step", val);
					return null;
				}
			}
		}
		return {
			verifyData,
			name,
			wegith,
			rooms,
			units,
			years,
			fueltype,
			vehicletype,
			ConvertValue,
			selectUnit,
			columns,
			rows,
			xTable,
			input_text,
			distance_text,
			// dualflag,
			DualReporting,
			calculate_type,
			sourcevalue,
			calculate,
			getHHV,
			getMPG,
			getTable,
			val,
			index,
			getSuboptionsExplanation,
			getFactor,
			getInsertEvent,
			insertEvent,
			egirdCalculate,
			testoutput,
			detailedFactor,
			tableData: [
			],
			eGirdList: [
			],
			lbCO2IsDisable,
			lbCO2eIsDisable,
			validRules,
			stepGoodsFactor,
			stringOptions,
			Goodsoption,
			filterFn(val, update){
				console.log("fueltype=")
				console.log(fueltype)
				update(() => {
					const needle = val.toLowerCase()
					Goodsoption.value = stringOptions.filter(v => v.toLowerCase().indexOf(needle) > -1)
				})
				// if(this.fueltype==='')
				// {
				// 	const needle = val.toLowerCase()
				// 	this.Goodsoption.value = this.stringOptions.filter(v => v.toLowerCase().indexOf(needle) > -1)
				// 	// for(var i=0;i<stepGoodsFactor.length;i++)
				// 	// {
				// 	// 	this.Goodsoption.value[i].push(i);
				// 	// }
					
				// 	// // this.Goodsoption.push("DD")
				// 	// console.log(this.Goodsoption.value[0])
				// }

			},
			geteGridType,
			getVehicleType,
		};
	},
	watch: {
		fueltype: function () {
			if(this.sourcevalue.label === "Mobile")
			{
				if (this.val.inputunits === "Mile") {
					this.input_text = 'Please input mileage'; 
				}else if(this.val.inputunits === "Gallon"){
					this.input_text = 'Please input gallonage';
				}
			}else if(this.sourcevalue.label === "Process Emission" || this.sourcevalue.label === "Fugitive Emission"){
				this.input_text = "Please input usage amount(kg)";
			}else if(this.sourcevalue.label === "Electricity"){
				this.input_text = "Please input usage amount(MWh)";
			}

			if(this.val.label === "Distance based method")
			{
				this.distance_text = 'Total distance traveled(' + this.fueltype + ')'
			}
			else if(this.sourcevalue.label === "Waste-generated")
			{
				this.distance_text = 'Input weight(ton)'
			}
			// else if(this.sourcevalue.label === "Purchased goods and services"){
			// 	this.input_text = "Please input usage Spend($)";
			// }
			

		}
	},
	mounted(){
		if(this.sourcevalue.label === "Electricity")
		{
			this.DualReporting = JSON.parse(history.state.k3);//this.dualflag;
			// console.log(this.DualReporting)
			// console.log("this.sourcevalue.children[this.index]")
		}
		else if(this.sourcevalue.label === "Business travel and employee commuting" || this.sourcevalue.label === "Upstream/Downstream Transportation and Distribution")
		{
			this.calculate_type = JSON.parse(history.state.k3);
			// console.log(this.calculate_type)
			// console.log("this.calculate_type")
		}
		else{
			this.DualReporting = 0;
			// console.log(this.index)
			// console.log("this.sourcevalue.children[this.index]11111")
		}
		// console.log(this.val);   


	},
	methods: {
		updateData ({row}) {
			// 后台更新接口接受一条数据，所以传row即可
			this.egirdCalculate();
			console.log(row);
		},
		// 合并行
        // mergeRowMethod({ row, columnIndex }){
        //     // columnIndex 代表列数，从0开始计数,我们要合并的字段属于第一列，取0
        //     if(columnIndex === 1 ||columnIndex === 2|| columnIndex === 3 || columnIndex === 4|| columnIndex === 5){
        //         return {
        //             rowspan: row.rowNum, // 待合并行数 -- 合并的行数长度就等于之前赋值的子数据的长度；未赋值的即表示0，不显示
        //             colspan: row.rowNum > 0 ? 1 : 0  // 待合并列数 -- 合并的列数自身占一列，被合并的要返回0，表示不显示
        //         };
        //     }
        // },
		mergeRowMethod({ _rowIndex, columnIndex }){
           if(columnIndex === 1 || columnIndex === 2 ||columnIndex === 3)
			{
				if(_rowIndex === 0){
					return {rowspan:200,colspan:1}
				}else{
					return {rowspan:0,colspan:0}
				}
			}
        },
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	}
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.card {
	position: relative;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	min-height: 160px; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
	//   height: null !default;
	word-wrap: break-word;
	background-color:  rgba(241, 241, 241, 0.747) ;
	background-clip: border-box;
	border-radius: 10px;
	width: 60%;
	font-family: "Abel";

	.card-header{
		flex-wrap: inherit;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
		font-weight: 500;
		font-size: 16px;
		.title {
			font-size: 1.2vw;
			font-weight: 400;
			// font-family: "Abel";
			color: #0d0c22;
			box-sizing: border-box;
			margin-top: 5px;
		}
	}
	.table-responsive{
		// margin-left: 10px;
		// padding-left:12%;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
}
.container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: center;

}

.content-2 {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 50px 8vw;

	.big-title {
		font-size: 3vw;
		font-weight: 400;
		font-family: "Abel";
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 15px;
		margin-bottom: 10px;
	}
	.detail {
		margin-top: 20px;
		margin-left: 16px;
		font-size: 28px;
		font-weight: 400;
		font-family: "Abel", sans-serif;
		text-align: center;
		box-sizing: border-box;
		width: 60%;
	}
	.guidelinedetail {
		margin-top: 14px; //20px
		font-size: 16px;
		font-weight: 400;
		font-family: "Abel", sans-serif;
		box-sizing: border-box;
		text-align: left;
		width:60%;
	}
	.c-form-container {
		display: block;
		// margin: -3em auto;
		margin-top: 10px;
		// margin-left: 130px;
		justify-content: center;
		text-align: center;
		// border-radius: 5px;
		background-color: #ffffff;
		padding: 10px;
		width: 70%;
		.detail {
			margin-top: 20px;
			font-size: 16px;
			font-weight: 200;
			font-family: "Abel", sans-serif;
			box-sizing: border-box;
			text-align: center;
		}
		.title {
			font-size: 1.2vw;
			font-weight: 400;
			font-family: "Abel";
			color: #0d0c22;
			box-sizing: border-box;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		.ex_title {
			font-size: 1vw;
			font-weight: 100;
			font-family: "Abel";
			color: #0d0c22;
			box-sizing: border-box;
			// margin-top: 1px;
			margin-bottom: 20px;
		}
		.form {
			font-size: 0.8vw;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 5px;
			margin-right: 5px;
			width: 23%;
			height: 40px;
			color: gray;
			//background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
			border-radius: 6px;
			border: solid 1.5px;
			// border-top: 1px solid rgb(231, 231, 233);
		}
		.selectform {

			// outline:auto;
			font-size: 0.8vw;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 10px;
			margin-right: 10px;
			padding: 0px;
			width: 30%;
			max-height: 10px;
			// color: gray;
			//background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
			border-radius: 6px;
			// border: solid auto;
			// border-top: 1px solid rgb(231, 231, 233);
		}
		.table {
			margin-top: 20px;
			margin-bottom: 35px;
			border-top: 1px solid rgb(231, 231, 233);
		}
		.chart {
			margin-top: 5px;
			margin-bottom: 30px;
			box-sizing: border-box;
			border-radius: 30px;
			// border-top: 0px solid rgb(231, 231, 233);
			color: #6e6d7a;
		}
	}
}
.input-underline {
    border-bottom: 1px solid #000000;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
	font-size: 20px;
	width: 20%;
	height: 30px;
	
}
</style>
