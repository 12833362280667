<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/vehicleprofile')">
					<q-item-section avatar>
						<q-icon name="apartment" />
					</q-item-section>

					<q-item-section>
						<div > Vehicle Management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/vehiclecacl')">
					<q-item-section avatar>
						<q-icon name="inventory"/>
					</q-item-section>

					<q-item-section>
						<div >	Vehicle Usage Management</div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>
		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Vehicle Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>In this section, you will create profiles for each vehicle or piece of motorized equipment that your company owns or leases long-term. These profiles will be incorporated into the "Vehicle Usage Management" system. After entering the details for a new item, make sure to click "Save" to record the new information.</div>
							<div style="margin-top: 15px;">Note: Brand, Model, and VIN are not required. </div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
						<template #buttons>
							<vxe-button icon="vxe-icon-square-plus" @click="insertBuilding()">Add Vehicle</vxe-button>
							<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
							<vxe-button @click="saveProgress()">SAVE</vxe-button>
						</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						header-align="center"
						:auto-resize="true"
						:edit-rules="formRules"
					>
					<vxe-column type="seq" width="60"></vxe-column>
					<vxe-column type="checkbox" width="60"></vxe-column>
					<vxe-column field="license_plate" title="License Plate (e.g. WA 1234567) " :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.license_plate" type="text" transfer placeholder="Enter Plate"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="vehicle_type" title="Vehicle Type" :edit-render="{}">
						<template #default="{ row }">
							<span>{{row.vehicle_type}}</span>
						</template>
						<template #edit="{ row }">
							<vxe-select
								v-model="row.vehicle_type"
								transfer
								placeholder="Select State"
								@change="row.fule_type=''"							
							>
							<vxe-option  :key="1" value="Passenger Cars" label="Passenger Cars"></vxe-option>
							<vxe-option  :key="2" value="Trucks" label="Trucks"></vxe-option>
							<vxe-option  :key="3" value="Buses" label="Buses"></vxe-option>
							<vxe-option  :key="4" value="Motorcycles" label="Motorcycles"></vxe-option>
							<vxe-option  :key="5" value="Ship and Boats" label="Ship and Boats"></vxe-option>
							<vxe-option  :key="6" value="Aircraft" label="Aircraft"></vxe-option>
								<!-- <vxe-option
									v-for="item in getVehicle()"
									:key="item.label"
									:value="item.label"
									:label="item.label"
								>
								</vxe-option>		 -->
							</vxe-select>
						</template>
					</vxe-column>
					<vxe-column field="gvwr" title="GVWR(ton)" :span="12" :edit-render="{name: 'input'}">
						<template #edit="{ row }">
						<vxe-input v-model="row.gvwr" type="number" placeholder="Enter GVWR" @change="row.fule_type=''"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="fule_type" title="Fuel Type" :edit-render="{}">
						<template #default="{ row }">
							<span>{{row.fule_type}}</span>
						</template>
						<template #edit="{ row }">
							<div style="display: Flex;">
								<vxe-select
									v-model="row.fule_type"
									v-if="row.vehicle_type !== ''"
									transfer
									placeholder="Select State"
								>
									<vxe-option
										v-for="item in getFuelType(row)"
										:key="item.fueltype"
										:value="item.fueltype"
										:label="item.fueltype"
									>
									</vxe-option>		
								</vxe-select>
								<vxe-select
									v-else
									placeholder="Select City"
								>
									<vxe-option>null</vxe-option>		
								</vxe-select>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="year" title="Year" :span="12" :edit-render="{}">
						<template #edit="{ row }">
						<vxe-input v-model="row.year" type="number" placeholder="Enter Year"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="mpg" title="MPG" :span="12" :edit-render="{name: 'input'}">
						<template #edit="{ row }">
						<vxe-input v-model="row.mpg" placeholder="Enter MPG"></vxe-input>
						</template>
					</vxe-column>	
					<vxe-column field="cost" title="cost" :span="12" :edit-render="{}">
						<template #edit="{ row }">
						<vxe-input v-model="row.cost" type="number" placeholder="Enter COST"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="brand" title="Brand" :span="12" :edit-render="{name: 'input'}">
						<template #edit="{ row }">
						<vxe-input v-model="row.brand" placeholder="Enter brand"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="model" title="Model" :span="12" :edit-render="{name: 'input'}">
						<template #edit="{ row }">
						<vxe-input v-model="row.model" placeholder="Enter model"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="vin" title="VIN" :span="12" :edit-render="{name: 'input'}">
						<template #edit="{ row }">
						<vxe-input v-model="row.vin" placeholder="Enter VIN"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="ownership" title="Ownership" :edit-render="{}">
						<template #default="{ row }">
							<span>{{row.ownership}}</span>
						</template>
						<template #edit="{ row }">
						<vxe-select v-model="row.ownership" transfer placeholder="Please select">
							<vxe-option  :key="1" value="Rent" label="Rent"></vxe-option>
							<vxe-option  :key="2" value="Own" label="Own"></vxe-option>
						</vxe-select>
						</template>
					</vxe-column>
				</vxe-table>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import AllConuntryStates from "@/components/formdata/allcountries.json";
import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
// import USAZipcode from "@/components/formdata/zipcode.json";


export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			name:'',
			fueltype: '',
			fuelcategory: '',
			cost: '',
			location: ''
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = ref({
			zipcode: [
				{ required: true, message: 'Enter zipcode' }
			]
		})
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		const AllCityStates = ref()
		const rowNum = ref();
		// var temp_city,temp_country
		// const ConvertValue = ref("")	

		const countryChangeEvent = (row) => {
				// Update State list and iso3
				AllConuntryStates.map((item) => {
					// console.log(row.location_county)
					// console.log(item.name)
					if (row.location_county === item.name) {
						console.log("Found selected country from all countries json");
						row.iso3 = item.iso3;
						if (!item.states.length) {
							console.log(
								"No status or province in country:" +
									item.name +
									" Using country as state"
							);
							const tmpState = {
								name: item.name,
								state_code: item.iso3,
							};
							row.state = tmpState;
							row.allStates.push(tmpState);
						} else {
							// row.state = Object.create(item.states[0]);
							// console.log(item.states)
							row.location_city = ''
							AllCityStates.value = item.states//Object.create(item.states);
							// console.log(AllCityStates)
							return
						}
					}
				});
		}
		
		const cityChangeEvent = (row) => {
			var source = AllConuntryStates.filter((x) => x.name === row.location_county);
			var source1 = source[0].states.filter((x) => x.name === row.location_city);
			row.location = source[0].iso3 + "_" + source1[0].state_code
		}
		
		// function cityBycode(code){
		// 	// console.log("cityBycode")
		// 	var ncode = code.split('_')
		// 	var source = AllConuntryStates.filter((x) => x.iso3 === ncode[0]);
		// 	// console.log(source)
		// 	if(source.length !== 0){
		// 		temp_country = source[0].name
		// 		if(source[0].states.length !== 0){
		// 			var source1 = source[0].states.filter((x) => x.state_code === ncode[1]);
		// 			temp_city = source1[0].name
		// 		}
		// 	}
		// }
				
		function getCategory(categoryStr) {
			if (categoryStr == null || categoryStr == "" ) {
				console.error("selected val is null");
			} else {
				// console.log(categoryStr)
				// console.log(stepSource)
				var source = stepSource[0].children.filter((x) => x.label === categoryStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0].suboptions);
					return source[0].suboptions;
				} else {
					console.error("Failed to find the step", categoryStr);
					return null;
				}
			}
		}

		const insertBuilding = async (row) => {
			// console.log("insertEvent",row)
			const $table = xTable.value

			if ($table) {
				const record = {
					vehicle_type: '',
					license_plate: '',
					fule_type: '',
					year: ''
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'name')
			}
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			if ($table) {
				// console.log("$table")
				// const selectRecords = $table.getCheckboxRecords()
				// console.log(selectRecords)
				$table.removeCheckboxRow()
				// itemsValue()
				saveProgress()
			}
		}

		function getVehicle() {
			var source = stepSource.filter((x) => x.value === "SCOPE1-MOBILE");
			if (source.length >= 1) {
				// console.log(source.length );
				// console.log(source[0]);
				return source[0].children;
			} else {
				console.error("Failed to find the step");
				return null;
			}
		}

		function getFuelType(row) {
			var temp_vehicle_type 
			if(row.vehicle_type === "Trucks")
			{
				if(row.gvwr < 6.4)
				{
					temp_vehicle_type = "Light-Duty Trucks"
				}
				else if(row.gvwr < 11.8)
				{
					temp_vehicle_type = "Medium-Duty Trucks"
				}
				else
				{
					temp_vehicle_type = "Heavy-Duty Trucks"
				}
				console.log(row.gvwr)
				console.log(temp_vehicle_type)
			}else temp_vehicle_type = row.vehicle_type
			var source = getVehicle().filter((x) => x.label === temp_vehicle_type);
			if (source.length >= 1) {
				// console.log(source.length );
				// console.log(source[0]);
				return source[0].suboptions;
			} else {
				console.error("Failed to find the step");
				return null;
			}
		}

		// function caclfunc(temp_building,temp_buildingprofile,row)
		// {
		// 	var co2 = 0,
		// 		ch4 = 0,
		// 		n2o = 0
		// 	if(row.buildingprofile_name != "")
		// 	{
		// 		// const buildingprofile = getBuildingConfig(row.buildingprofile_name)
		// 		// const building = getBuilding(buildingprofile.building);
		// 		// console.log("test...................",buildingprofile,building)

		// 		// console.log("test1...................",buildingprofile,tempFactor)
		// 		if(temp_buildingprofile.powersource_config == "Electricity grid")
		// 		{
		// 			const tempFactor = getFactor(temp_building.zipcode)
		// 			if(tempFactor == null)
		// 			{
		// 				row.co2e = 0
		// 				row.co2 = 0
		// 				row.ch4 = 0
		// 				row.n2o = 0
		// 				// return
		// 			}else{
		// 				if(temp_buildingprofile.powersource_config == "yes")
		// 				{
		// 					console.log("test...................",temp_buildingprofile,tempFactor)
		// 				}
		// 				co2 = parseFloat(tempFactor.value[0].co2);
		// 				ch4 = parseFloat(tempFactor.value[1].ch4);
		// 				n2o = parseFloat(tempFactor.value[2].n2o);
		// 				// console.log(co2)
		// 				// console.log(ch4)
		// 				// console.log(n2o)
		// 				row.co2e = (row.amount * (co2 + ch4*25/1000 + n2o*298/1000)).toFixed(2);
		// 				row.co2 = (row.amount * co2).toFixed(2);
		// 				row.ch4 = (row.amount * ch4).toFixed(2);
		// 				row.n2o = (row.amount * n2o).toFixed(2);
		// 			}
		// 		}
		// 		else if(temp_buildingprofile.powersource_config == "Market energy" || temp_buildingprofile.powersource_config == "Self generation")
		// 		{
		// 			// console.log("test...................",buildingprofile,tempFactor)
		// 			if(temp_buildingprofile.factor_co2 != "")
		// 			{
		// 				row.co2 = (row.amount * temp_buildingprofile.factor_co2).toFixed(2);
		// 			}else row.co2 = 0;
		// 			if(temp_buildingprofile.factor_ch4 != "")
		// 			{
		// 				row.ch4 = (row.amount * temp_buildingprofile.factor_ch4).toFixed(2);
		// 			}else row.ch4 = 0;
		// 			if(temp_buildingprofile.factor_n2o != "")
		// 			{
		// 				row.n2o = (row.amount * temp_buildingprofile.factor_n2o).toFixed(2);
		// 			}else row.n2o = 0;

		// 			row.co2e = Number(row.co2) + Number(row.ch4) + Number(row.n2o);
		// 		}else if(temp_buildingprofile.powersource_config == "Heat and steam"){
		// 			// const buildingprofile = getBuildingConfig(row.buildingprofile_name)
		// 			const tempFactor = getHeatandsteamFactor("Natural Gas Fuel")
		// 			// console.log("test...................",buildingprofile,tempFactor)
		// 			co2 = parseFloat(tempFactor.value[0].co2);
		// 			ch4 = parseFloat(tempFactor.value[1].ch4);
		// 			n2o = parseFloat(tempFactor.value[2].n2o);
		// 			// console.log(co2)
		// 			// console.log(ch4)
		// 			// console.log(n2o)
		// 			row.co2e = (row.amount * (co2 + ch4*25/1000 + n2o*298/1000)).toFixed(2);
		// 			row.co2 = (row.amount * co2).toFixed(2);
		// 			row.ch4 = (row.amount * ch4).toFixed(2);
		// 			row.n2o = (row.amount * n2o).toFixed(2);
		// 		}
		// 		// console.log("test...................",buildingprofile,tempFactor)

		// 		// saveProgress()
		// 	}

		// }

		async function saveProgress() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];

			if(xTable.value.getTableData().visibleData === null) return
			// const building = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
			// const returnUser = await getBuildingConfigData()
			// if(returnUser.data.buildingprofile === undefined) return
			// const oldbuildingprofile = JSON.parse(JSON.stringify(returnUser.data.buildingprofile))

			// const returnElectricity = await getElectricityData()	
			// const returnSteamAndHeat = await getSteamAndHeatData()
			// const oldElectricity = JSON.parse(JSON.stringify(returnElectricity.data.electricity_items))
			// const oldSteamAndHeat = JSON.parse(JSON.stringify(returnSteamAndHeat.data.steamheat_items))
			// // console.log("olditems",olditems)
			// const newbuildingprofile=reactive([])
			// const newElectricity=reactive([])
			// const newSteamAndHeat=reactive([])
			// let j=0;
			// for(let i=0;i<oldbuildingprofile.length;i++){
			// 	let rst = 0;
			// 	j=0;
			// 	for(j=0;j<building.length;j++)
			// 	{
			// 		if(building[j].name === oldbuildingprofile[i].building)
			// 		{
			// 			rst = 1
			// 			break
			// 		}
			// 	}
			// 	if(rst)
			// 	{
			// 		if(typeof(oldbuildingprofile[i].buildingprofile_name) !== "undefined") {	
			// 			newbuildingprofile.push(oldbuildingprofile[i])

			// 			for(let k=0;k<oldElectricity.length;k++){
			// 				console.log("oldElectricity",oldElectricity[k].buildingprofile_name)		
			// 				if(oldElectricity[k].buildingprofile_name == oldbuildingprofile[i].buildingprofile_name)
			// 				{
			// 					caclfunc(building[j],oldbuildingprofile[i],oldElectricity[k])
			// 					newElectricity.push(oldElectricity[k])
			// 				}
			// 			}
			// 			for(let k=0;k<oldSteamAndHeat.length;k++){
			// 				if(oldSteamAndHeat[k].buildingprofile_name == oldbuildingprofile[i].buildingprofile_name)
			// 				{
			// 					caclfunc(building[j],oldbuildingprofile[i],oldSteamAndHeat[k])
			// 					newSteamAndHeat.push(oldSteamAndHeat[k])
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			const headers = {};
			const body = {
				vehicle_config: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData)),
			};

			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
		}

		// const router = useRouter();
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorVehicleProfileByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		
		onMounted(async () => {
			const returnUser = await userHasData();
			loading.value = false
			console.log(returnUser.data)

			if(returnUser.data.vehicle_config === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.vehicle_config))
			console.log(databuf)
			for(let i=0;i<databuf.length;i++){
				if(typeof(databuf[i].license_plate) !== "undefined") {
					const table_arry=reactive([])
					// cityBycode(databuf[i].location)
					table_arry.push({
						cost: databuf[i].cost,
						gvwr: databuf[i].gvwr,
						vehicle_type: databuf[i].vehicle_type,
						fule_type: databuf[i].fule_type,
						model: databuf[i].model,
						vin: databuf[i].vin,
						mpg: databuf[i].mpg,
						ownership: databuf[i].ownership,
						license_plate: databuf[i].license_plate,
						brand: databuf[i].brand,
						year: databuf[i].year
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			showEdit,
			selectRow,
			loading,
			tableData,
			formRules,
			miniState: ref(true),
			AllConuntryStates,
			countryChangeEvent,
			cityChangeEvent,
			getFuelType,
			getCategory,
			getVehicle,
			removeSelectRowEvent,
			insertBuilding,
			saveProgress,
			AllCityStates,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>

.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>