/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import WelcomePage from "@/components/WelcomePage.vue";
import FactoryForm from "@/components/FactoryForm.vue";
import RetailForm from "@/components/RetailForm.vue";
import GeneralForm from "@/components/GeneralForm.vue";
import HomePage from "@/views/HomePage.vue";
import OurServicePage from "@/views/OurServicePage.vue";
import OurProductPage from "@/views/OurProductPage.vue";
import BlogPage from "@/views/BlogPage.vue";
import BlogDetailPage from "@/views/BlogDetailPage.vue";
import MethodPage from "@/views/OurMethod.vue";
import Profile from "@/views/ProfilePage.vue";
import Settings from "@/views/SettingsPage.vue";
import Contact from "@/views/ContactForm.vue";
import About from "@/views/AboutPage.vue";
import Slides from "@/components/slides/MainSlide";
import Chart from "@/components/charts/PieChart.vue";
import Col from "@/components/charts/ColChart.vue";

import FlipCardVue from "@/components/sections/card/FlipCard.vue";

import CalculaterPage from "@/components/CalculaterPage.vue";
import CalculaterMainPage from "@/components/calculator/CalculaterMainPage.vue";
import CalculaterStationaryPage from "@/components/calculator/CalculaterStationaryPage.vue";
import CalculaterDetailPage from "@/components/calculator/CalculaterDetailPage.vue";
import ScopeSample from "@/components/calculator/ScopeTwoSample.vue";
import previewPDF from "@/components/tools/previewPDF.vue";

import EquipmentPage from "@/components/calculatorcache/EquipmentPage.vue";
import InventoryPage from "@/components/calculatorcache/InventoryPage.vue";
import MethodologyPage from "@/components/calculatorcache/MethodologyPage.vue";

import BuildingPage from "@/components/calculatorcache/BuildingPage.vue";
import BuildingConfigPage from "@/components/calculatorcache/BuildingConfigPage.vue";
import ElectricityUsagePage from "@/components/calculatorcache/ElectricityUsagePage.vue";
import SteamAndHeatUsagePage from "@/components/calculatorcache/SteamAndHeatUsagePage.vue";

import VehicleProfilePage from "@/components/calculatorcache/VehicleProfilePage.vue";
import VehicleCaclPage from "@/components/calculatorcache/VehicleCaclPage.vue";
import WasteInventoryPage from "@/components/calculatorcache/WasteInventoryPage.vue";

import DashboardMainPage from "@/components/calculatorcache/DashboardMainPage.vue";
import DashboardEquipmentPage from "@/components/calculatorcache/DashboardEquipmentPage.vue";
import DashboardVehiclePage from "@/components/calculatorcache/DashboardVehiclePage.vue";
import DashboardBuildingPage from "@/components/calculatorcache/DashboardBuildingPage.vue";

// import WasteInventoryPage from "@/components/calculatorcache/WasteInventoryPage.vue";


const routes = [
	{ path: "/", component: HomePage },
	{
		path: "/reportform/factory",
		name: "FactoryForm",
		component: FactoryForm,
		text: "factoryform",
		beforeEnter: authGuard,
	},
	{
		path: "/reportform/retail",
		name: "RetailForm",
		component: RetailForm,
		text: "retailform",
		beforeEnter: authGuard,
	},
	{
		path: "/reportform/general",
		name: "GeneralForm",
		component: GeneralForm,
		text: "generalForm",
		beforeEnter: authGuard,
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		text: "profile",
		beforeEnter: authGuard,
	},
	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		text: "Settings",
		beforeEnter: authGuard,
	},
	{
		path: "/esg",
		name: "WelcomePage",
		component: WelcomePage,
		text: "WelcomePage",
		beforeEnter: authGuard,
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact,
		text: "Contact",
	},
	{
		path: "/about",
		name: "About",
		component: About,
		text: "About",
	},
	{
		path: "/slides",
		name: "Slides",
		component: Slides,
		text: "slides",
		beforeEnter: authGuard,
	},
	{
		path: "/chart",
		name: "Chart",
		component: Chart,
		text: "waterchart",
	},
	{
		path: "/col",
		name: "Col",
		component: Col,
		text: "colchart",
	},
	{
		path: "/home",
		name: "HomePage",
		component: HomePage,
		text: "HomePage",
	},
	{
		path: "/services",
		name: "OurServicePage",
		component: OurServicePage,
		text: "OurServicePage",
	},
	{
		path: "/products",
		name: "OurProductPage",
		component: OurProductPage,
		text: "OurProductPage",
	},
	{
		path: "/wasteinventory",
		name: "WasteInventoryPage",
		component: WasteInventoryPage,
		text: "WasteInventoryPage",
	},
	{
		path: "/vehicleprofile",
		name: "VehicleProfilePage",
		component: VehicleProfilePage,
		text: "VehicleProfilePage",
	},
	{
		path: "/vehiclecacl",
		name: "VehicleCaclPage",
		component: VehicleCaclPage,
		text: "VehicleCaclPage",
	},
	{
		path: "/building",
		name: "BuildingPage",
		component: BuildingPage,
		text: "BuildingPage",
	},
	{
		path: "/buildingconfig",
		name: "BuildingConfigPage",
		component: BuildingConfigPage,
		text: "BuildingConfigPage",
	},
	{
		path: "/electricityusage",
		name: "ElectricityUsagePage",
		component: ElectricityUsagePage,
		text: "ElectricityUsagePage",
	},
	{
		path: "/steamandheat",
		name: "SteamAndHeatUsagePage",
		component: SteamAndHeatUsagePage,
		text: "SteamAndHeatUsagePage",
	},
	{
		path: "/equipment",
		name: "EquipmentPage",
		component: EquipmentPage,
		text: "EquipmentPage",
	},
	{
		path: "/inventory",
		name: "InventoryPage",
		component: InventoryPage,
		text: "InventoryPage",
	},
	{
		path: "/dashboardmain",
		name: "DashboardMainPage",
		component: DashboardMainPage,
		text: "DashboardMainPage",
	},
	{
		path: "/dashboardequipment",
		name: "DashboardEquipmentPage",
		component: DashboardEquipmentPage,
		text: "DashboardEquipmentPage",
	},
	{
		path: "/dashboardvehicle",
		name: "DashboardVehiclePage",
		component: DashboardVehiclePage,
		text: "DashboardVehiclePage",
	},
	{
		path: "/dashboardbuilding",
		name: "DashboardBuildingPage",
		component: DashboardBuildingPage,
		text: "DashboardBuildingPage",
	},
	{
		path: "/methodology",
		name: "MethodologyPage",
		component: MethodologyPage,
		text: "MethodologyPage",
	},
	{
		path: "/blog",
		name: "BlogPage",
		component: BlogPage,
		text: "BlogPage",
	},
	{
		path: "/blogdetail",
		name: "BlogDetailPage",
		component: BlogDetailPage,
		text: "BlogDetailPage",
	},
	{
		path: "/method",
		name: "MethodPage",
		component: MethodPage,
		text: "MethodPage",
	},
	{
		path: "/calculater",
		name: "CalculaterPage",
		component: CalculaterPage,
		test: "CalculaterPage",
	},
	{
		path: "/calculatermain",
		name: "CalculaterMainPage",
		component: CalculaterMainPage,
		test: "CalculaterMainPage",
		// beforeEnter: authGuard,
	},
	{
		path: "/calculaterstationary",
		name: "CalculaterStationaryPage",
		component: CalculaterStationaryPage,
		test: "CalculaterStationaryPage",
		// beforeEnter: authGuard,
	},
	{
		path: "/calculaterdetail",
		name: "CalculaterDetailPage",
		component: CalculaterDetailPage,
		test: "CalculaterDetailPage",
		// beforeEnter: authGuard,
	},
	{
		path: "/scope2",
		name: "Scope2",
		component: ScopeSample,
	},
	{
		path: "/previewpdf",
		name: "previewPDF",
		component: previewPDF,
	},
];
const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 };
	},
});
export default router;
