<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			v-model="drawer"
			show-if-above

			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay

			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
		<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/equipment')">
					<q-item-section avatar>
						<q-icon name="account_tree" />
					</q-item-section>

					<q-item-section>
						<div>	Equipment management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/inventory')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Inventory management</div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="$router.push('/methodology')">
					<q-item-section avatar>
						<q-icon name="description" />
					</q-item-section>

					<q-item-section>
						<div>	Methodology </div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>

		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Methodology</h3>
				<h6> The following emission factors are used in the calculation.</h6>
				<div style="width: 80%; margin:0 auto">
					<vxe-table
						stripe
						align="center"
						header-align="center"
						ref="xTable1"
						:data="part1tableData">
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column field="fuelcategory" title="Emission factors"></vxe-column>
						<vxe-column field="CO2e_factors" title="CO2e"></vxe-column>
						<vxe-column field="CO2_factors" title="CO2"></vxe-column>
						<vxe-column field="N2O_factors" title="N2O"></vxe-column>
						<vxe-column field="CH4_factors" title="CH4"></vxe-column>
						<vxe-column field="location" title="Source" show-overflow></vxe-column>
						<vxe-column title="Link" show-overflow>
							<a href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub" target="_blank">Link</a>
						</vxe-column>
					</vxe-table>
				</div>
				<h6> Here are details about the data types used in the inventory.</h6>
				<div style="width: 80%; margin:0 auto">
					<vxe-table
						stripe
						align="center"
						header-align="center"
						ref="xTable2"
						:data="part2tableData">
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column field="operation" title="Operation"></vxe-column>
						<vxe-column field="location" title="Location"></vxe-column>
						<vxe-column field="timeperiod" title="Time Period"></vxe-column>
						<vxe-column field="fuelcategory" title="Fuel Category" show-overflow></vxe-column>
						<vxe-column field="datatype" title="Data Type" show-overflow></vxe-column>
					</vxe-table>
				</div>
				<h6> The following notes were made in the data management.</h6>
				<div style="width: 80%; margin:0 auto">
					<vxe-table
						stripe
						align="center"
						header-align="center"
						ref="xTable3"
						:data="part3tableData">
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column field="operation" title="Operation"></vxe-column>
						<vxe-column field="location" title="Location"></vxe-column>
						<vxe-column field="timeperiod" title="Time Period"></vxe-column>
						<vxe-column field="fuelcategory" title="Fuel Category" show-overflow></vxe-column>
						<vxe-column field="note" title="Note" show-overflow></vxe-column>
					</vxe-table>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import {  VxeTableInstance } from 'vxe-table'
import axios from "axios";
// import AllConuntryStates from "@/components/formdata/allcountries.json";
// import stepSource from "../carbondata/sourcetype.json";
import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";

export default {
	components: {
		ToolBar,
	},
	setup () {
		const xTable1 = ref(VxeTableInstance)
		const xTable2 = ref(VxeTableInstance)
		const xTable3 = ref(VxeTableInstance)
		// const store = useStore()
		const { user } = useAuth0()
		// var watch_first = true
		const part1tableData = ref([])
		const part3tableData = ref([])
		const part2tableData = ref([])
		const equipment_option = []
						
		function getFuelHHVValue(FuelTypeStr){
			if (FuelTypeStr == null) {
				console.error("selected val is null");
			} else {
				var source = stepFactor.filter((x) => x.name === FuelTypeStr);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log("source[0]",source[0].value[0].hhv);
					return source[0].value[0].hhv;
				} else {
					console.error("Failed to find the step", FuelTypeStr);
					return null;
				}
			}
		}

		const insertTable = async (row,Tdata) => {
			const $table1 = xTable1.value
			if ($table1) {
				const { row: newRow } = await $table1.insertAt(Tdata, row)
				await $table1.setEditCell(newRow, 'operation')
			}
			const $table2 = xTable2.value
			if ($table2) {
				const { row: newRow } = await $table2.insertAt(Tdata, row)
				await $table2.setEditCell(newRow, 'operation')
			}
			const $table3 = xTable3.value
			if ($table3) {
				const { row: newRow } = await $table3.insertAt(Tdata, row)
				await $table3.setEditCell(newRow, 'operation')
			}
			// console.log("xTable.value",xTable2.value)
		}	

		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {
				var equipment_source = equipment_option.filter((x) => x.name === Ftype);
				console.log(equipment_source)
				// temp_Factor = Ftype;
				if(equipment_source.length > 0){
					var	source = stepFactor.filter((x) => x.name === equipment_source[0].fuelcategory);
					if (source.length >= 1) {
						return source[0];
					} else {
						console.error("Failed to find the step", Ftype);
						return null;
					}
				}else{
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}
		function getLocation(equipment) {
			if (equipment === "") {
				console.error("selected val is null");
				return null;
			} else {
				var	source = equipment_option.filter((x) => x.name === equipment);
				console.log(source)
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", equipment);
					return null;
				}
			}
		}
		async function getEquipmentData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		async function userHasData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"equipment"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		onMounted(async () => {
			const returnUser = await userHasData();
			const tempequipment = await getEquipmentData();
			// console.log("tempequipment",tempequipment.data.equipment)
			for(let i=0;i<tempequipment.data.equipment.length;i++)
				equipment_option.push(tempequipment.data.equipment[i])
			
			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			console.log(databuf)
				for(let i=0;i<databuf.length;i++){
					
					const table_arry=reactive([])
					var temp_data = 0;
					var cov = 1;
					var co2e = 0,
						co2 = 0,
						ch4 = 0,
						n2o = 0; 
					if(databuf[i].equipment !== undefined)
					{
						temp_data = getFactor(databuf[i].equipment);
						if(temp_data === null) return
						if(databuf[i].unit === "1")
						{
							temp_unit = "mmBtu"
						}
						else if(databuf[i].unit === "2")
						{
							var temp_unit = ""
							if(databuf[i].type==="Coal and Coke"||databuf[i].type==="Other Fuels - Solid"||databuf[i].type==="Biomass Fuels - Solid")
							{
								temp_unit = "kg"
							}
							else if(databuf[i].type==="Natural Gas"||databuf[i].type==="Other Fuels - Gaseous"||databuf[i].type==="Biomass Fuels - Gaseous")
							{
								temp_unit = "scf"
							}
							else if(databuf[i].type==="Petroleum Products"||databuf[i].type==="Biomass Fuels - Liquid")
							{
								temp_unit = "gallon"
							}
							cov = temp_data.value[0].hhv
						}
						co2 = (cov*temp_data.value[1].co2).toFixed(2) + "kgCO2/" + temp_unit
						ch4 = (cov*temp_data.value[2].ch4).toFixed(2) + "gCH4/" + temp_unit
						n2o = (cov*temp_data.value[3].n2o).toFixed(2) + "gN2O/" + temp_unit
						co2e = (cov*temp_data.value[1].co2+ 
								cov*temp_data.value[2].ch4*25/1000 +
								cov*temp_data.value[3].n2o*298/1000).toFixed(2) + "kgCO2e/" + temp_unit
								const equipment = getLocation(databuf[i].equipment)
						table_arry.push({
							transactionId: databuf[i].transactionId,
							id: databuf[i].id,
							userId: databuf[i].userId,
							transactionType: databuf[i].transactionType,
							fuelcategory: equipment.fuelcategory,
							datatype: databuf[i].datatype,
							note: databuf[i].note,
							// unit: "kgCO2e/kg",//databuf[i].unit,
							timeperiod:databuf[i].timeperiod.starttime+'-'+databuf[i].timeperiod.endtime,
							// timeperiod: databuf[i].timeperiod,
							location: equipment.location,
							operation: databuf[i].operation,
							CO2e_factors: co2e,
							CO2_factors: co2,
							CH4_factors: ch4,
							N2O_factors: n2o
						})
						insertTable(-1,table_arry)		
					}			
				}
		});
		
		return { 
			xTable1,
			xTable2,
			xTable3,
			part1tableData,
			part2tableData,
			part3tableData,
			miniState: ref(true),
			getFuelHHVValue,
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
	},
}
</script>



