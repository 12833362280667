<template>
	<div class="q-pa-md">
		<q-header reveal>
			<div class="header">
				<div class="logo">
					<q-btn
						flat
						dense
						round
						@click="$router.push('/')"
						aria-label="Menu"
						icon="img:https://i.ibb.co/Cb7KGVX/logo.png"
					/>
					<div class="logo-text">ESG REPORT</div>
				</div>
				<q-space />
				<q-btn
					flat
					class="menu"
					icon="download"
					label="Download Sample PDF"
					@click="exportToPDF"
				/>
				<q-btn
					flat
					class="menu"
					icon="undo"
					label="Go back"
					@click="backToForm"
				/>
			</div>
		</q-header>

		<q-page-container>
			<q-page>
				<div id="canvas">
					<CoverPage />
					<OurCompanyPage />
					<EconomyPage />
					<EnviromentSlideEngery />
					<EnviromentSlideWater />
					<EnviromentSlideMaterial />
					<WorkplaceSlide />
					<CommunitySlide />
					<SafetySlide />
					<EndPage />
				</div>
				<q-page-sticky position="bottom-right" :offset="[50, 18]">
					<q-fab
						label="Get Help"
						direction="up"
						icon="keyboard_arrow_up"
						color="amber"
					>
						<q-fab-action
							@click="openInNewTabApp"
							color="amber"
							icon="event"
							label="Make Appointment"
						/>
						<q-fab-action
							@click="openInNewTabContact"
							color="amber"
							icon="front_hand"
							label="Customization Request"
						/>
					</q-fab>
				</q-page-sticky>
			</q-page>
		</q-page-container>
	</div>
</template>

<script>
import CoverPage from "./CoverPage.vue";
import OurCompanyPage from "./OurCompanySlide.vue";
import EconomyPage from "./EconomySlide.vue";
import EnviromentSlideEngery from "./EnviromentSlideEngery.vue";
import EnviromentSlideWater from "./EnviromentSlideWater.vue";
import EnviromentSlideMaterial from "./EnviromentSlideMaterial.vue";
import WorkplaceSlide from "./WorkplaceSlide.vue";
import CommunitySlide from "./CommunitySlide.vue";
import SafetySlide from "./SafetySlide.vue";
import EndPage from "./EndPage.vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";

import JsPDF from "jspdf";
import domtoimage from "dom-to-image";

export default {
	name: "MainSlides",
	components: {
		CoverPage,
		OurCompanyPage,
		EconomyPage,
		EnviromentSlideEngery,
		EnviromentSlideWater,
		EnviromentSlideMaterial,
		WorkplaceSlide,
		CommunitySlide,
		SafetySlide,
		EndPage,
	},

	setup() {
		const $q = useQuasar();

		const store = useStore();
		const router = useRouter();
		const template = store.state.template;
		const companyname = store.state.companyname;

		function openInNewTabApp() {
			window.open("https://calendly.com/dynimos/30min", "_blank", "noreferrer");
		}
		function openInNewTabContact() {
			const contacturl = router.resolve({
				name: "Contact",
			});
			window.open("#" + contacturl.path, "_blank", "noreferrer");
		}

		function backToForm() {
			console.log(template);
			if (template.toUpperCase() === "FACTORY") {
				router.push("/reportform/factory");
			} else if (template.toUpperCase() === "RETAIL") {
				router.push("/reportform/retail");
			} else if (template.toUpperCase() === "GENERAL") {
				router.push("/reportform/general");
			} else {
				console.error("No template found, do nothing");
				router.push({ name: "WelcomePage" });
			}
		}

		//https://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html
		//https://github.com/tsayen/dom-to-image
		function exportToPDF() {
			$q.loading.show({
				message: "Preparing your pdf file. Please hang on...",
			});
			const canvasHeight = 12800; // 10 pages of single page height
			const canvasWidth = 1485;
			const options = {
				background: "white",
				height: canvasHeight,
				width: canvasWidth,
			};

			let pageHeight = 1250;
			let pageWidth = 1455;
			let imgWidth = 1455;
			let imgHeight = (canvasHeight * imgWidth) / canvasWidth;
			let doc = new JsPDF({
				orientation: "landscape",
				unit: "px",
				format: [pageHeight, pageWidth],
				floatPrecision: 16,
			});
			domtoimage
				.toPng(document.getElementById("canvas"), options)
				.then((dataUrl) => {
					let pos = 0;
					let idx = 0;
					while (idx < 10) {
						doc.addImage(dataUrl, "PNG", 0, pos, imgWidth, imgHeight);

						if (idx !== 9) {
							doc.addPage();
						}
						pos = pos - 1256;
						idx++;
					}
					setTimeout(function () {
						$q.loading.hide();
						let filename = companyname + "_" + "ESG_Report.pdf";
						doc.save(filename);
					}, 3000);
				});
		}
		return {
			openInNewTabApp,
			openInNewTabContact,
			backToForm,
			exportToPDF,
		};
	},
};
</script>

<style lang="less" scoped>
.header {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 10px 10px;
	justify-content: space-between;
	background-color: rgb(253, 253, 255);
	border-bottom: 1px solid rgb(231, 231, 233);

	.right {
		display: flex;
		align-items: center;
	}

	.left {
		margin-right: 50px;
	}

	.title {
		margin-left: 3vw;
		font-size: 16px;
		font-weight: 500;
		color: #6e6d7a;
	}
	.menu {
		margin-left: 1vw;
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-family: Arial;
		color: #333336;
	}

	.sign {
		font-weight: 400;
		font-size: 18px;
		color: #6e6d7a;
	}

	.logo {
		display: flex;
		align-items: center;

		.logo-img {
			width: 30px;
		}

		.logo-text {
			margin-left: 10px;
			font-size: 28px;
			font-weight: 900;
			color: rgb(108, 154, 208);
		}
	}
	.slides_wrap {
		background-color: rgba(8, 7, 7, 0.3);
		// height: 65vh;
		// overflow-x: scroll;
		overflow-x: scroll;
		padding: 20px 0;
		// padding:  20px;
		display: flex;
		justify-content: center;
		.slides {
			max-width: 1320px;
		}
	}
}
</style>
