<template>
	<!-- <button class="button__sign-up" @click="handleSignUp">Sign Up</button> -->
	<q-btn flat class="menu" @click="handleSignUp">Sign Up</q-btn>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithRedirect } = useAuth0();

const handleSignUp = () => {
	loginWithRedirect({
		appState: {
			target: "/profile",
		},
		screen_hint: "signup",
	});
};
</script>
<style lang="less" scoped>
.menu {
	margin-left: 3vw;
	font-size: 16px;
	font-weight: bold;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	color: #333336;
}
</style>
