<template>
	<q-footer bordered class="bg-white text-black">
		<q-toolbar>
			<q-toolbar-title class="text-center">
				{{ companyname }} REPORT 2023
			</q-toolbar-title>
		</q-toolbar>
		<div class="text-center">POWERED BY DYNIMOS</div>
	</q-footer>
</template>

<script>
import { useStore } from "vuex";
export default {
	name: "DefaultFooter",
	components: {},

	setup() {
		const store = useStore();
		const companyname = store.state.companyname.toUpperCase();
		return { companyname };
	},
};
</script>
