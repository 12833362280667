<template>
	<q-card flat bordered class="my-card">
		<q-card-section>
			<div class="text-h6">Our Company</div>
		</q-card-section>
		<q-separator inset />

		<q-card-section>
			The first section gives an overview of your company. The goal is to let
			readers understand your business and operations background, governance,
			and principles and set a foundation for the remaining sections. You will
			be asked to provide the following information: An introduction to the
			business, Goals and/or commitment to sustainability in one to two
			sentences, Short descriptions of the major suppliers, customers, and
			stakeholders and how you engage with them, A feature story or a
			testimonial related to how you engage with stakeholders. In addition, we
			will invite you to upload pictures in some sections to make your report
			look visually pleasing. Examples and templates in this section demonstrate
			how the content can be presented and how it can be a starting point where
			you edit the example based on your company's situation. Whenever possible,
			please use your content and avoid adopting the examples directly.
		</q-card-section>
	</q-card>
</template>
<script>
export default {
	name: "OurCompany",
};
</script>
