<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1230px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-footer bordered class="bg-white text-black">
				<q-toolbar>
					<q-toolbar-title class="text-center">
						{{ companyname }} REPORT 2023
					</q-toolbar-title>
				</q-toolbar>
			</q-footer>

			<div class="row">
				<div class="col-12">
					<h3 class="text-h5 text-center">{{ companyname }}</h3>
				</div>
			</div>

			<q-separator />
			<p></p>
			<div class="row justify-center">
				<div class="col-11">
					<q-img
						src="https://images.pexels.com/photos/970594/pexels-photo-970594.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
						loading="lazy"
						spinner-color="white"
						style="fit"
					/>
				</div>
			</div>
			<div class="row justify-evenly content">
				<div class="col-11">
					<span class="text-bold">
						The reporting framework was created by DYNIMOS scientists, who took
						into account standard reporting metrics from a range of sources,
						including GRI and SASB
					</span>
					<q-list dense padding>
						<q-item v-ripple>
							<q-item-section>
								1. Global Reporting Initiatives. (2023). GRI - Standards.
								Retrieved October 25, 2022, from
								https://www.globalreporting.org/standards/
							</q-item-section>
						</q-item>

						<q-item v-ripple>
							<q-item-section>
								2. SASB Standards. (2023). Standards Overview. SASB. Retrieved
								October 25, 2022, from https://www.sasb.org/standards/
							</q-item-section>
						</q-item>

						<q-item v-ripple>
							<q-item-section>
								3. DYNIMOS. (2023). Methodology. https://www.dynimos.com/methods
							</q-item-section>
						</q-item>
					</q-list>
				</div>
			</div>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
export default {
	name: "CoverPage",
	components: {},

	setup() {
		const store = useStore();
		const companyname = store.state.companyname.toUpperCase();

		return { companyname };
	},
};
</script>

<style lang="less" scoped>
.content {
	display: flex;
	justify-content: space-between;
	align-items: left;
	box-sizing: border-box;
	padding: 30px 4.2vw;
	margin-top: 30px;

	font-family: "Abel", sans-serif;
}
</style>
