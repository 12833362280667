<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" class="text-black">
						<b> ECONOMIC IMPACT</b>
					</q-tab>
					<q-tab name="enviro" label="ENVIRONMENTAL IMPACT" />
					<q-tab name="social" label="SOCIAL IMPACT" />
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container>
				<q-page class="q-pa-md">
					<div class="row justify-evenly items-center">
						<div class="col-3">
							<div class="title text-capitalize">Business Activity</div>
							<div class="text-content">
								{{ businessactivity }}
							</div>
							<q-img :src="businessactivityimg" style="fit" />
						</div>
						<div class="col-3">
							<div class="title text-capitalize">Business Opportunities</div>
							<div class="text-content">
								{{ businessoppo }}
							</div>
							<q-img :src="businessoppoimg" style="fit" />
						</div>
						<div class="col-4">
							<q-img :src="businesshighlightsimg" style="fit" />
						</div>
					</div>

					<div class="row justify-evenly testrow bg-light-blue-1">
						<div class="col-3">
							<div class="title text-capitalize">Business Ethos</div>
							<div class="text-content">
								{{ businessethos }}
							</div>
						</div>
						<div class="col-3">
							<div class="title text-capitalize">Business Highlight</div>
							<div class="text-content">
								{{ featurestory }}
							</div>
						</div>
						<div class="col-4">
							<q-img :src="featurestoryimg" style="fit" />
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
// import HeaderTab from "./components/HeaderTab.vue";
import SlideFooter from "./components/DefaultFooter.vue";

export default {
	name: "EconomyPage",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;
		
		const businessactivity =
			store.state[template][template+"_economy_activities"].ans;
		let businessactivityimg =
			store.state[template][template+"_economy_activities"].img;
		if (!businessactivityimg) {
			businessactivityimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677929696/DYNIMOS%20DEFAULT%20PICS/pexels-pixabay-357440_jcbdad.jpg";
		}
		const businessoppo =
			store.state[template][template+"_economy_opportunities"].ans;
		let businessoppoimg =
			store.state[template][template+"_economy_opportunities"].img;
		if (!businessoppoimg) {
			businessoppoimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202720/DYNIMOS%20DEFAULT%20PICS/BUSINESS_OPPORTUNITY_ajkbk8.jpg";
		}
		const businessethos =
			store.state[template][template+"_economy_practices"].ans;
		let businessethosimg =
			store.state[template][template+"_economy_practices"].img;
		if (!businessethosimg) {
			businessethosimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/c_scale,h_3824,w_3705/v1677930600/DYNIMOS%20DEFAULT%20PICS/pexels-polina-zimmerman-3782134_cbdhwr.jpg";
		}
		const featurestory =
			store.state[template][template+"_economy_featurestory"].ans;
		let featurestoryimg =
			store.state[template][template+"_economy_featurestory"].img;
		if (!featurestoryimg) {
			featurestoryimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677929689/DYNIMOS%20DEFAULT%20PICS/pexels-curioso-photography-343696_d68xfj.jpg";
		}

		const businesshighlights =
			store.state[template][template+"_economy_highlights"].ans;
		let businesshighlightsimg =
			store.state[template][template+"_economy_highlights"].img;
		if (!businesshighlightsimg) {
			businesshighlightsimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677929689/DYNIMOS%20DEFAULT%20PICS/pexels-curioso-photography-343696_d68xfj.jpg";
		}
		return {
			businessactivity,
			businessactivityimg,
			businessoppo,
			businessoppoimg,
			businessethos,
			businessethosimg,
			featurestory,
			featurestoryimg,
			businesshighlights,
			businesshighlightsimg,
		};
	},
};
</script>

<!-- <style lang="sass" scoped>
.row > div
  padding: 10px 15px
  background: rgba(86,61,124,.15)
  border: 1px solid rgba(86,61,124,.2)
.row + .row
  margin-top: 1rem
.row
  background: rgba(255,0,0,.0)
</style> -->

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.testrow {
	padding: 10px 15px;
	background: rgba(86, 61, 124, 0.15);
	border: 1px solid rgba(86, 61, 124, 0.2);
}
.row {
	margin-top: 1rem;
}
.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
