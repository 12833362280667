import { createStore } from "vuex";
import axios from "axios";
import default_facctory_states from "@/components/formdata/defaultfactorystate";
import default_retail_states from "@/components/formdata/defaultretailstate";
import default_general_states from "@/components/formdata/defaultgeneralstate";
import default_stationary_states from "@/components/formdata/calculaterdata/defaultstationarystate";

const initial_facctory_states = JSON.parse(JSON.stringify(default_facctory_states));
const initial_retail_states = JSON.parse(JSON.stringify(default_retail_states));
const initial_general_states = JSON.parse(JSON.stringify(default_general_states));
const initial_stationary_states = JSON.parse(JSON.stringify(default_stationary_states));

const store = createStore({
	state() {
		return {
			userid: "",
			companyname: "",
			report_start_date: "",
			report_end_date: "",
			template: "",
			factory: initial_facctory_states,
			retail: initial_retail_states,
			general: initial_general_states,
			stationary: initial_stationary_states,
		};
	},
	mutations: {
		// Payload structure example:
		// {label: 'companyname', value: 'dynimos' }
		updateSingleState(state, arg) {
			state[arg.label] = arg.value;
			console.debug("Updated " + arg.label);
		},
		// Payload structure example:
		// {template: 'factory', step: 'factory_ourcompany_intro', key: 'value', value: textareaModel }
		updateData(state, payload) {
			state[payload.template][payload.step][payload.key] = payload.value;
			console.debug("Updated answer for " + payload.step);
		},

		updateCalcData(state, payload) {
			state[payload.template] = payload.data
			// state[payload.template].push("1234567890-=")
			// state[payload.template][payload.date_label] = payload.date_value
			// state[payload.template][payload.location_label] = payload.location_value
			// state[payload.template][payload.fuel_label] = payload.fuel_value
			// state[payload.template][payload.operation_label] = payload.operation_value
			console.log(state[payload.template])
			console.debug("Updated answer for " + payload.key);
		},
		// Payload structure example:
		// {template: 'factory'}
		clearDataByTemplate(state, payload) {
			console.log("Resetting store state for template: " + payload.template);
			if (payload.template === "factory") {
				const new_state = JSON.parse(JSON.stringify(default_facctory_states));
				state[payload.template] = new_state;
			} else if (payload.template === "retail") {
				const new_state = JSON.parse(JSON.stringify(default_retail_states));
				state[payload.template] = new_state;
			} else {
				console.error("Wrong template " + payload.template);
			}
			console.log("All form answers reset to empty");
		},
		// Payload structure example:
		// {template: 'factory', userid: 'abcd123'}
		saveData(state, payload) {
			console.log(
				"Saving store state for template: " +
					payload.template +
					", user: " +
					payload.userid
			);

			const headers = {};
			const body = {
				template: state.template,
				companyname: state.companyname,
				report_start_date: state.report_start_date,
				report_end_date: state.report_end_date,
				answer: state[payload.template],
			};

			console.log(body);
			const userid = payload.userid;
			axios.post(
				// "http://localhost:7071/api/postFormByUser?userid=" +
				// "https://formanswer.azurewebsites.net/api/postformbyuser?userid=" +
				"https://testdynimosazure.azurewebsites.net/api/postformbyuser?userid=" +
					userid,
				body,
				{ headers }
			);
			console.log("All form answers saved");
		},
		saveCalcData(state, payload) {
			// const currentTime = ref(new Date())
			console.log(
				"Saving store state for template: " +
					payload.template +
					", user: " +
					payload.userid
			);

			const headers = {};
			const body = {
				items : state[payload.template],
				// answer: state[payload.template],
			};

			console.log(body);
			const userid = payload.userid;
			axios.post(
				// "http://localhost:7071/api/postFormByUser?userid=" +
				// "https://formanswer.azurewebsites.net/api/postformbyuser?userid=" +
				"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?userid=" +
					userid,
				body,
				{ headers }
			);
			console.log("All form items saved");
		},
	},
	actions: {
		loadItems({ commit }, payload) {
			axios
				.get(
					// "http://localhost:7071/api/getFormByUser?id=" +
					// "https://formanswer.azurewebsites.net/api/getFormByUser?id=" +
					"https://testdynimosazure.azurewebsites.net/api/getFormByUser?id=" +
						payload.userid,
					{
						headers: {},
					}
				)
				.then((response) => response.data)
				.then((items) => {
					for (const [key, value] of Object.entries(items.answer)) {
						// Only update non-empty values
						if (value.ans || value.ans.length) {
							commit("updateData", {
								template: items.template,
								step: key,
								key: "ans",
								value: value.ans,
							});
						}
						// if (value.img || value.ans.length) {
						// 	commit("updateData", {
						// 		template: items.template,
						// 		step: key,
						// 		key: "img",
						// 		value: value.img,
						// 	});
						// }
					}
					// commit("updateSingleState", {
					// 	label: items.template,
					// 	value: items.answer,
					// });
					console.log("Successfully reloaded form");
				});
		},
		loadCalcItems({ commit }, payload) {
			axios
				.get(
					// "http://localhost:7071/api/getFormByUser?id=" +
					// "https://formanswer.azurewebsites.net/api/getFormByUser?id=" +
					"https://testdynimosazure.azurewebsites.net/api/getCalculatorDataByUser?id=" +
						payload.userid,
					{
						headers: {},
					}
				)
				.then((response) => response.data)
				.then((items) => {
					
					for (const [value] of Object.entries(items)) {
						console.log(items)
						// Only update non-empty values
						if (value) {
							commit("updateCalcData", {
								template: "stationary",
								data: items.items
							});
						}
					}
					console.log("Successfully reloaded form");
				});
		},
	},
});

export default store;
