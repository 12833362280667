<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" class="text-black">
						<b> ENVIRONMENTAL IMPACT</b>
					</q-tab>
					<q-tab name="social" label="SOCIAL IMPACT" />
				</q-tabs>
			</q-header>

			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-6">
							<div class="title text-capitalize">
								Water Reduction Commttment
							</div>
							<div class="text-content">{{ watertreduction }}</div>
						</div>
						<div class="col-5">
							<div class="title text-capitalize">Waste Water Treatment</div>
							<div class="text-content">
								{{ watertreatment }}
							</div>
						</div>
					</div>
					<div class="row justify-evenly testrow bg-blue-1">
						<div class="col-6">
							<div class="title text-capitalize">Water Usage</div>
							<q-list>
								<q-item dense v-for="obj in dataArray" :key="obj.operation">
									<q-item-section side>
										<q-icon name="arrow_right" color="black" />
									</q-item-section>
									<q-item-section>
										<q-item-label>
											{{ obj.operation }} usage amount: {{ obj.amount }}
											{{ obj.unit }}
										</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>
						</div>
						<div class="col-5">
							<PieChart />
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-6 pic">
							<q-img :src="pic1" style="fit" />
						</div>
						<div class="col-6 pic">
							<q-img :src="pic2" style="fit" />
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
import PieChart from "@/components/charts/PieChart.vue";
import SlideFooter from "./components/DefaultFooter.vue";

export default {
	name: "EnviromentEngery",
	components: { PieChart, SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;

		const water = store.state[template][template + "_environment_strategy"].ans;
		const watertreduction =
			store.state[template][template + "_environment_waterreduction"].ans;
		const watertreatment =
			store.state[template][template + "_environment_wastewater"].ans;
		let pic1 =
			store.state[template][template + "_environment_waterreduction"].img;
		if (!pic1) {
			pic1 =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677932313/DYNIMOS%20DEFAULT%20PICS/pexels-dominika-roseclay-4289045_itbj5d.jpg";
		}
		const pic2 =
			"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677932175/DYNIMOS%20DEFAULT%20PICS/pexels-%E8%94%A1%E5%AE%97%E7%BF%B0-4345855_nyhl32.jpg";

		var watertable =
			store.state[template][template + "_environment_watertable"].ans;
		const dataArray = JSON.parse(JSON.stringify(watertable));

		return {
			template,
			water,
			watertreduction,
			watertreatment,
			pic1,
			pic2,
			dataArray,
		};
	},
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.testrow {
	padding: 10px 15px;
	background: rgba(86, 61, 124, 0.15);
	border: 1px solid rgba(86, 61, 124, 0.2);
}
.row {
	margin-top: 1rem;
}
.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
.pic {
	padding: 10px 15px 10px 10px;
}
</style>
