<template>
	<div id="chart">
		<apexchart
			type="bar"
			height="350"
			:options="chartOptions"
			:series="series"
		></apexchart>
	</div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

import factordata from "@/components/formdata/allfactorsbycountry.js";
import { useStore } from "vuex";

const factor_data_by_states = JSON.parse(JSON.stringify(factordata));

export default {
	components: {
		apexchart: VueApexCharts,
	},
	setup() {
		const store = useStore();
		const template = store.state.template;
		var storedata =
			store.state[template][template+"_environment_energytable"].ans;
		const dataArray = JSON.parse(JSON.stringify(storedata));
		var mydata = [];
		var mycategories = [];
		for (let i = 0; i < dataArray.length; i++) {
			let obj = dataArray[i];
			const factor = getfactor(obj.iso3, obj.state.state_code);
			var amount = parseFloat(obj.amount) * 0.001 * factor;
			mydata.push(amount.toFixed(2));
			mycategories.push(obj.operation);
		}
		const series = [
				{
					name: "Engergy Usage",
					data: mydata,
				},
			],
			chartOptions = {
				chart: {
					height: 350,
					type: "bar",
				},
				plotOptions: {
					bar: {
						columnWidth: "65%",
						distributed: true,
					},
				},
				dataLabels: {
					enabled: true,
				},

				grid: {
					row: {
						colors: ["#fff", "#f2f2f2"],
					},
				},
				xaxis: {
					labels: {
						rotate: -45,
					},
					categories: mycategories,
					// tickPlacement: "on",
				},
				yaxis: {
					decimalsInFloat: 2,
					title: {
						text: "tCO2e/MWh",
					},
				},
				fill: {
					type: "solid",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},

				title: {
					text: "Energy Usage",
					align: "center",
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: "20px",
						fontWeight: "bold",
						fontFamily: "Nunito",
						color: "#263238",
					},
				},
			};

		function getfactor(country, state) {
			const key = country + "_" + state;
			const defaultkey = country + "_default";
			const carbonkey = "carbon_factor_val";
			if (key in factor_data_by_states) {
				return factor_data_by_states[key][carbonkey];
			} else if (defaultkey in factor_data_by_states) {
				return factor_data_by_states[defaultkey][carbonkey];
			} else {
				// emmission factor not defined, returning 0.5402 as default
				return 0.5402;
			}
		}

		return {
			series,
			chartOptions,
		};
	},
};
</script>
