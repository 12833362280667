<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" label="ENVIRONMENTAL IMPACT" />
					<q-tab name="social" class="text-black">
						<b>SOCIAL IMPACT</b>
					</q-tab>
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-around">
						<div class="col-5">
							<div class="testrow">
								<div class="title text-capitalize">
									Diversity, Equity, and Inclusion
								</div>
								<div class="text-content">
									{{ diversity }}
								</div>
							</div>

							<div><p></p></div>

							<div
								class="testrow2 bg-light-blue-1"
								v-if="template !== 'retail'"
							>
								<div class="title text-capitalize">Employee Training</div>
								<div class="text-content">
									{{ employees }}
								</div>
							</div>
							<div
								class="testrow2 bg-light-blue-1"
								v-else-if="template === 'retail'"
							>
								<div class="title text-capitalize">Training and Education</div>
								<div class="text-content">
									{{ employees }}
								</div>
							</div>
						</div>
						<div class="col-5">
							<q-img :src="diversityimg" style="fit" />
						</div>
					</div>
					<div class="row justify-evenly">
						<div class="col-11" v-if="template !== 'retail'">
							<div class="title text-capitalize">Regulatory Compliance</div>
							<div class="text-content">
								{{ compliance }}
							</div>
						</div>
						<div class="col-11" v-else-if="template === 'retail'">
							<div class="title text-capitalize">Employees Growth</div>
							<div class="text-content">
								{{ compliance }}
							</div>
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-5">
							<q-img :src="employeesimg" style="fit"	/>
						</div>

						<div class="col-3 testrow2 bg-blue-grey-1">
							<div class="title text-capitalize">
								Feature Story
							</div>
							<div class="text-content">
								{{ featurestorydiversity }}
							</div>
						</div>

						<div class="col-3 testrow2 bg-pink-1">
							<div class="title text-capitalize">
								Feature Story
							</div>
							<div class="text-content">
								{{ featurestorytraining }}
							</div>
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
// import HeaderTab from "./components/HeaderTab.vue";
import SlideFooter from "./components/DefaultFooter.vue";

export default {
	name: "WorkplacePage",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;
		let employees = ref("");
		let compliance = ref("");
		let employeesimg = ref("");

		const diversity = store.state[template][template + "_social_diversity"].ans;
		let diversityimg =
			store.state[template][template + "_social_diversity"].img;
		if (!diversityimg) {
			diversityimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202706/DYNIMOS%20DEFAULT%20PICS/WORKPLACE_WELLBEING_ze7mz2.jpg";
		}
		if (template !== "retail") {
			employees = store.state[template][template + "_social_employees"].ans;
			compliance = store.state[template][template + "_social_compliance"].ans;
		} else {
			employees =
				store.state[template][template + "_social_employees_education"].ans;
			employeesimg.value =
				store.state[template][template + "_social_employees_education"].img;
			if(!employeesimg.value){
				employeesimg.value = 
					"https://res.cloudinary.com/ddkphzsvp/image/upload/c_crop,h_858,w_1600/v1677202704/DYNIMOS%20DEFAULT%20PICS/WELLBEING_q1cwqa.webp"
			}
			compliance =
				store.state[template][template + "_social_employees_growth"].ans;
		}

		const featurestorydiversity =
			store.state[template][template + "_social_featurestory_diversity"].ans;
		const featurestorytraining =
			store.state[template][template + "_social_featurestory_training"].ans;

		return {
			template,
			diversity,
			diversityimg,
			employees,
			employeesimg,
			compliance,
			featurestorydiversity,
			featurestorytraining,
		};
	},
};
</script>

<style lang="less" scoped>
.testrow {
	padding: 10px 15px;
	border: 5px solid rgba(86, 61, 124, 0.2);
}
.content {
	height: 1250px;
}
.testrow2 {
	padding: 10px 15px;
}
.testrow3 {
	padding: 10px 15px;
	border: 1px solid rgba(86, 61, 124, 0.2);
}
// .row + .row
//   margin-top: 1rem
.row {
	background: rgba(255, 0, 0, 0);
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
