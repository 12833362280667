<template>
	<q-btn flat icon="logout" class="menu" @click="handleLogout"> Log Out </q-btn>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { logout } = useAuth0();

const handleLogout = () =>
	logout({
		returnTo: window.location.origin,
	});
</script>

<style lang="less" scoped>
.menu {
	//margin-left: 3vw;
	
	
	
	font-family: Arial;
	color: #333336;
}
</style>
