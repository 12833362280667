<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
  <div style="width: 80%;margin: 0 auto">
    <div  style="font-weight: bolder;text-align: center;font-size: 3vw;margin-top: 50px;margin-bottom: 20px;">
      {{ val.title }}
    </div>
    <div style="text-align: center;margin-bottom: 50px;">
      <div style="font-size: 1vw;">{{ val.author }} {{"  "}}{{ val.pubDate}}</div>
    </div>
    <!-- style="text-align: left;font-size: 1.2vw;" -->
    <!-- <div  > -->
    <div v-html="val.description"></div>
    <!-- </div> -->
    <div 
      class="ellipse"
      v-for="(item,index) in val.categories"
      :key="index"
    >
      <div class="title">{{item}}</div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";

export default {
	name: "BlogDetailPage",
	components: {
		Toolbar,
	},
	
	data() {
    const delect_hs = (history.state.k).replaceAll('<h3><strong>','<h4 style=\\"display:none\\"><strong>')
    const delect_hshs = (delect_hs).replaceAll('</strong></h3>','</strong></h4>')
    const replace_h3 = delect_hshs.replaceAll('<h3>','<p style=\\"font-weight:700;font-size:1.4vw\\">')
    const replace_h3h3 = replace_h3.replaceAll('</h3>','</p>')
    const replace_p = replace_h3h3.replaceAll('<p>','<p style=\\"font-size:1.4vw\\">')
    const replace_figure = replace_p.replaceAll('<figure>','<figure style=\\"margin: 0 auto\\">')
    const replace_figcaption = replace_figure.replaceAll('<figcaption>','<figcaption style=\\"text-align:center;\\">')
    const delete_wh1 = replace_figcaption.replaceAll('width=\\"1\\" height=\\"1\\"','')
    const replace_img = delete_wh1.replaceAll('<img','<img style=\\"display: flex;margin: 10px auto;width:auto;height:auto;max-width:100%\\"')
    const val = JSON.parse(replace_img)
    // console.log(val)
		return {
			val,
		};
	},
	methods: {
		
	}
};
</script>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
// @media (min-width: 1200px) {
div {
  font-family: "Abel";
}

.ellipse {
  font-size: 1vw;
  text-align: center;
  display: inline-block;
  // width: auto; height: auto;
  margin: auto 10px;
  // border-top: 50px solid #ddd;
  background-color: #ccc;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  .title{
    margin: auto 10px;
  }
}

.figure_img{
    display: flex;
     width: 20;
  .img{
    margin: 0 auto ;
  }
}

// }
</style>




