<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div>
	<p>{{ step }}</p>
	<p>{{ question }}</p>
	<p>{{ guildline }}</p> -->

	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>
	<vxe-toolbar>
		<template #buttons>
			<vxe-button @click="insertEvent(-1)">Add Row</vxe-button>
			<vxe-button @click="$refs.xTable.removeCheckboxRow()"
				>Delete Selected</vxe-button
			>
			<vxe-button @click="saveEvent">Save</vxe-button>
		</template>
	</vxe-toolbar>

	<vxe-table
		border
		show-overflow
		keep-source
		ref="xTable"
		max-height="400"
		empty-text="Empty Table"
		:data="tableData"
		:edit-config="{ trigger: 'click', mode: 'cell', showStatus: true }"
	>
		<vxe-column type="checkbox" width="60"></vxe-column>

		<vxe-column field="operation" title="Operation" :edit-render="{}">
			<template #edit="{ row }">
				<vxe-input
					v-model="row.operation"
					type="text"
					placeholder="Input Operation"
					maxlength="20"
				>
				</vxe-input>
			</template>
		</vxe-column>

		<vxe-column field="amount" title="Annual Amount" :edit-render="{}">
			<template #edit="{ row }">
				<vxe-input
					v-model.number="row.amount"
					placeholder="Input Annual Amount"
					type="number"
					maxlength="9"
				>
				</vxe-input>
			</template>
		</vxe-column>

		<vxe-column field="unit" title="Unit" :edit-render="{}">
			<template #default="{ row }">
				<span>{{ row.unit }}</span>
			</template>
			<template #edit="{ row }">
				<vxe-select v-model="row.unit" placeholder="Select Unit Type" transfer>
					<vxe-option
						v-for="item in unitList"
						:key="item"
						:value="item"
						:label="item"
					></vxe-option>
				</vxe-select>
			</template>
		</vxe-column>
	</vxe-table>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { VxeTableInstance } from "vxe-table";

export default {
	props: ["label", "question", "guildline", "step"],
	components: {},

	setup(props) {
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));
		const xTable = ref(VxeTableInstance);
		let add_count = 0;

		var tableData = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set() {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: xTable.value.getTableData().tableData,
				});
			},
		});

		const unitList = ["Gallon", "Liter"];
        
		const insertEvent = async (row) => {
			
			const $table = xTable.value;
			const record = {
				operation: "",
				amount: "",
				unit: "",
			};
			if(++add_count > 3) return;
			const { row: newRow } = await $table.insertAt(record, row);
			await $table.setEditCell(newRow, "operation");
		};

		const saveEvent = () => {
			const $table = xTable.value;
			store.commit("updateData", {
				template: curTemplate,
				step: curStep,
				key: "ans",
				value: $table.getTableData().tableData,
			});
		};

		return {
			xTable,
			tableData,
			insertEvent,
			saveEvent,
			unitList,
		};
	},
};
</script>
