<template>
	<!-- <button class="button__login" @click="handleLogin">Log In</button> -->
	<q-btn flat class="menu" @click="handleLogin">Log In</q-btn>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
export default {
	setup() {
		const { loginWithRedirect } = useAuth0();

		function handleLogin() {
			loginWithRedirect({
				appState: {
					target: "/",
				},
			});
		}
		return {
			handleLogin,
		};
	},
};
</script>

<style lang="less" scoped>
.menu {
	//margin-left: 3vw;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	font-family: Arial;
	color: #333336;
}
</style>
