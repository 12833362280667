<template>
	<div class="q-pa-md row justify-center">
		<div class="col-12">
			<q-card flat>
				<q-card-section>
					<div class="text-h4 text-center">Progress Status Dashboard</div>
				</q-card-section>
				<q-card-section>
					This page shows the progress status for your report. You can see how
					many questions are left until completion from the chart below. You can
					access the detailed question forms from the list panel at the bottom
					of this page. Please note that you can always save your data and come
					back later to continue your work. <br />
					Don't forget to save your work by clicking the 'Save Progress' button
					on the menu bar.
				</q-card-section>
			</q-card>
		</div>
	</div>
	<StatusBarChart
		:ourcompnay="getallpendingourcompany().length"
		:economy="getallpendingeconomy().length"
		:environment="getallpendingoenvironment().length"
		:social="getallpendingosocial().length"
	/>
	<div class="row">
		<div class="col-3">
			<q-card flat bordered class="bg-grey-1 my-card">
				<q-card-section>
					<div class="text-h6">Our Compnay</div>
					<div class="text-subtitle2">
						{{ getallpendingourcompany().length }} pending
					</div>
				</q-card-section>

				<q-separator inset />
				<q-card-section>
					<q-list
						dense
						padding
						class="rounded-borders"
						v-for="(item, index) in getallpendingourcompany()"
						:key="index"
					>
						<q-item v-ripple>
							<q-item-section side>
								<q-icon name="arrow_right" />
							</q-item-section>
							<q-item-section> {{ item[1] }} </q-item-section>
						</q-item>
					</q-list>
				</q-card-section>
			</q-card>
		</div>

		<div class="col-3">
			<q-card flat bordered class="bg-grey-1 my-card">
				<q-card-section>
					<div class="text-h6">Economy</div>
					<div class="text-subtitle2">
						{{ getallpendingeconomy().length }} pending
					</div>
				</q-card-section>

				<q-separator inset />

				<q-card-section>
					<q-list
						dense
						padding
						class="rounded-borders"
						v-for="(item, index) in getallpendingeconomy()"
						:key="index"
					>
						<q-item v-ripple>
							<q-item-section side>
								<q-icon name="arrow_right" />
							</q-item-section>
							<q-item-section> {{ item[1] }} </q-item-section>
						</q-item>
					</q-list>
				</q-card-section>
			</q-card>
		</div>

		<div class="col-3">
			<q-card flat bordered class="bg-grey-1 my-card">
				<q-card-section>
					<div class="text-h6">Environment</div>

					<div class="text-subtitle2">
						{{ getallpendingoenvironment().length }} pending
					</div>
				</q-card-section>

				<q-separator inset />

				<q-card-section>
					<q-list
						dense
						padding
						class="rounded-borders"
						v-for="(item, index) in getallpendingoenvironment()"
						:key="index"
					>
						<q-item v-ripple>
							<q-item-section side>
								<q-icon name="arrow_right" />
							</q-item-section>
							<q-item-section> {{ item[1] }} </q-item-section>
						</q-item>
					</q-list>
				</q-card-section>
			</q-card>
		</div>

		<div class="col-3">
			<q-card flat bordered class="bg-grey-1 my-card">
				<q-card-section>
					<div class="text-h6">Social Responsibility</div>

					<div class="text-subtitle2">
						{{ getallpendingosocial().length }} pending
					</div>
				</q-card-section>

				<q-separator inset />

				<q-card-section>
					<q-list
						dense
						padding
						class="rounded-borders"
						v-for="(item, index) in getallpendingosocial()"
						:key="index"
					>
						<q-item v-ripple>
							<q-item-section side>
								<q-icon name="arrow_right" />
							</q-item-section>
							<q-item-section> {{ item[1] }} </q-item-section>
						</q-item>
					</q-list>
				</q-card-section>
			</q-card>
		</div>
	</div>
</template>
<script>
import { onMounted } from "vue";
import StatusBarChart from "../charts/StatusBarChart.vue";
import { useStore } from "vuex";
import stepQuestionsFactory from "../formdata/factoryquestions.json";
import stepQuestionsRetail from "../formdata/retailquestions.json";
import stepQuestionsGeneral from "../formdata/generalquestions.json";
import default_facctory_states from "@/components/formdata/defaultfactorystate";
import default_retail_states from "@/components/formdata/defaultretailstate";
import default_general_states from "@/components/formdata/defaultgeneralstate";

export default {
	name: "StatusTab",
	components: { StatusBarChart },

	setup() {
		const store = useStore();
		const template = store.state.template;
		let allpending = [];
		function labelbyvalue(value) {
			if (template.toLowerCase() === "factory") {
				let entry = stepQuestionsFactory.find((el) => el.value === value);
				return entry.label;
			} else if (template.toLowerCase() === "retail") {
				let entry = stepQuestionsRetail.find((el) => el.value === value);
				return entry.label;
			} else {
				let entry = stepQuestionsGeneral.find((el) => el.value === value);
				return entry.label;
			}
		}

		onMounted(() => {
			allpending = getallpending();
		});

		function getallpending() {
			if (allpending && allpending.length > 0) {
				return;
			}
			if (!template) {
				console.info("empty template");
			}
			if (!allpending) {
				allpending = [];
			}
			if (template.toLowerCase() === "factory") {
				const allkeys = Object.keys(default_facctory_states);
				const allvalues = Object.values(default_facctory_states);
				const store_ans = store.state[template];

				allvalues.forEach(function (item, index) {
					var curkey = allkeys[index];
					if (!store_ans[curkey].ans || store_ans[curkey].ans.length === 0) {
						let rawname = allkeys[index].replaceAll("_", "-");
						allpending.push([rawname, labelbyvalue(rawname)]);
					}
					//  else {
					// 	let rawname = allkeys[index].replaceAll("_", "-");
					// 	console.log("Found completed" + labelbyvalue(rawname));
					// }
				});
			} else if (template.toLowerCase() === "retail") {
				const allkeys = Object.keys(default_retail_states);
				const allvalues = Object.values(default_retail_states);
				const store_ans = store.state[template];

				allvalues.forEach(function (item, index) {
					var curkey = allkeys[index];
					// console.log(allkeys[index] + ": " + store_ans);
					if (!store_ans[curkey].ans || store_ans[curkey].ans.length === 0) {
						let rawname = allkeys[index].replaceAll("_", "-");
						allpending.push([rawname, labelbyvalue(rawname)]);
					} else {
						let rawname = allkeys[index].replaceAll("_", "-");
						console.log("Found completed" + labelbyvalue(rawname));
					}
				});
			} else if (template.toLowerCase() === "general") {
				const allkeys = Object.keys(default_general_states);
				const allvalues = Object.values(default_general_states);
				const store_ans = store.state[template];

				allvalues.forEach(function (item, index) {
					var curkey = allkeys[index];
					// console.log(allkeys[index] + ": " + store_ans);
					if (!store_ans[curkey].ans || store_ans[curkey].ans.length === 0) {
						let rawname = allkeys[index].replaceAll("_", "-");
						allpending.push([rawname, labelbyvalue(rawname)]);
					} else {
						let rawname = allkeys[index].replaceAll("_", "-");
						console.log("Found completed" + labelbyvalue(rawname));
					}
				});
			}
			return allpending;
		}

		function getallpendingourcompany() {
			let ret = [];
			if (!allpending || allpending.length === 0) {
				allpending = getallpending();
			}
			allpending.forEach(function (item) {
				if (item[0].includes("ourcompany")) {
					ret.push(item);
				}
			});
			return ret;
		}

		function getallpendingeconomy() {
			let ret = [];
			if (!allpending || allpending.length === 0) {
				allpending = getallpending();
			}
			allpending.forEach(function (item) {
				if (item[0].includes("economy")) {
					ret.push(item);
				}
			});
			return ret;
		}

		function getallpendingoenvironment() {
			let ret = [];
			if (!allpending || allpending.length === 0) {
				allpending = getallpending();
			}
			allpending.forEach(function (item) {
				if (item[0].includes("environment")) {
					ret.push(item);
				}
			});
			return ret;
		}
		function getallpendingosocial() {
			let ret = [];
			if (!allpending || allpending.length === 0) {
				allpending = getallpending();
			}
			allpending.forEach(function (item) {
				if (item[0].includes("social")) {
					ret.push(item);
				}
			});
			return ret;
		}

		return {
			getallpendingourcompany,
			getallpendingeconomy,
			getallpendingoenvironment,
			getallpendingosocial,
			allpending,
			template,
		};
	},
};
</script>
<style lang="less" scoped>
.my-card {
	border-radius: 5px;
	margin-top: 20px;
}
</style>
