<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div>
	<p>{{ step }}</p>
	<p>{{ question }}</p>
	<p>{{ guildline }}</p> -->
	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>
	<q-form @submit="onSubmit" class="q-gutter-md">
		<q-input
			v-model="textareaModel"
			filled
			clearable
			autogrow
			color="green-8"
			label="Click to use our template"
			hint="Press TAB to autocomplete suggested value or ESC to cancel suggestion"
			counter
			:maxlength="charlimit"
			:rules="[
				(val) =>
					val.length < charlimit ||
					'Please use maximum ' + charlimit + ' characters',
			]"
			:shadow-text="textareaShadowText"
			@keydown="processTextareaFill"
			@focus="processTextareaFill"
		/>

		<ImgUploader
			v-if="picturelabel"
			:step="step"
			:label="picturelabel"
			:ratio="pictureratio"
			class="q-mt-md q-pb-md"
		/>
		<!-- <q-btn color="secondary" type="submit" label="I'm done!" /> -->
	</q-form>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { event } from "quasar";
import ImgUploader from "../tools/ImgUploader.vue";

const { stopAndPrevent } = event;

export default {
	props: [
		"label",
		"question",
		"guildline",
		"sampleanswer",
		"picturelabel",
		"pictureratio",
		"charlimit",
		"step",
	],
	components: { ImgUploader },

	setup(props) {
		const store = useStore();

		// TODO: fix me gracely
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));

		const textareaModel = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set(value) {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: value,
				});
			},
		});

		const textareaFillCancelled = ref(false);

		const textareaShadowText = computed(() => {
			if (textareaFillCancelled.value === true) {
				return "";
			}
			const t = props.sampleanswer[0];
			let empty =
				typeof textareaModel.value !== "string" ||
				textareaModel.value.length === 0;

			if (empty === true) {
				return t.split("\n")[0];
			} else if (t.indexOf(textareaModel.value) !== 0) {
				return "";
			}

			return t
				.split(textareaModel.value)
				.slice(1)
				.join(textareaModel.value)
				.split("\n")[0];
		});

		return {
			textareaModel,
			textareaFillCancelled,
			textareaShadowText,

			processTextareaFill(e) {
				if (e === void 0) {
					return;
				}

				if (e.keyCode === 27) {
					if (textareaFillCancelled.value !== true) {
						textareaFillCancelled.value = true;
					}
				} else if (e.keyCode === 9) {
					if (
						textareaFillCancelled.value !== true &&
						textareaShadowText.value.length > 0
					) {
						stopAndPrevent(e);
						textareaModel.value =
							(typeof textareaModel.value === "string"
								? textareaModel.value
								: "") + textareaShadowText.value;
					}
				} else if (textareaFillCancelled.value === true) {
					textareaFillCancelled.value = false;
				}
			},
			onSubmit() {
				console.log("clicked");
			},
		};
	},
};
</script>
