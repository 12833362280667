<template>
	<q-header elevated>
		<Toolbar />
	</q-header>
	<div class="container">
		<div class="row content-2 justify-center">
			<div class="col-10 title text-center text-capitalize">
				The Philosophy Behind Dynimos
			</div>
			<div class="col-10 detail">
				We believe that creativity and respect for boundaries can achieve authentic sustainability.
			</div>
			<div class="col-10 title text-center text-capitalize">
				The Mission of Dynimos
			</div>
			<div class="col-10 detail">
				Dynimos' mission is to create clearly defined and transferable assets that contribute positively to society. 
				We strive to develop innovative solutions that harmonize the demands of people, the environment, and successful business operations.
			</div>
			<div class="col-10 title text-center text-capitalize">
				What does Dynimos do?
			</div>
			<div class="col-10 detail">
				Dynimos offers an AI-driven software solution that assists in quantifying, tracking, 
				and managing your business's carbon emissions. Our digital platform for ESG reporting 
				streamline and personalize your sustainability and ESG reporting procedures.
			</div>
			<div class="col-10 title text-center text-capitalize">
				Why choose Dynimos?
			</div>
			<div class="col-10 detail">
				With over a decade of scientific expertise in sustainability and life cycle assessment (LCA), 
				our team stands ready to help you effectively share your corporate sustainability efforts with stakeholders. 
				We're committed to ensuring those efforts are recorded and reported with both clarity and accuracy.
			</div>
		</div>
		<q-separator inset />

		<!-- <div class="row content-2 justify-center">
			<div class="col-10 title text-center text-capitalize">
				Dynimos scientists and researchers
			</div>
			<div class="col-4 img">
				<q-img
					src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679370975/DYNIMOS%20DEFAULT%20PICS/banner/hao_gnjqhy.png"
				/>
			</div>
			<div class="col-10 detail">
				<span class="subtitle"> Hao Zhang, Ph.D <br /></span>
				<span>
					Dr. Hao Zhang is an accomplished expert in life cycle engineering and
					sustainability assessment, with over a decade of experience in the
					field. He obtained his PhD from Oregon State University and completed
					postdoctoral training at the University of Alberta before joining
					James Madison University, where he is currently an associate professor
					teaching courses in Sustainable Manufacturing, Industrial Ecology, and
					Industrial Sustainable Systems. <br /><br />Dr. Zhang's research and
					expertise is focused on industrial sustainability assessment and
					improvement, and he has extensive experience in conducting life cycle
					assessments, sustainability assessments, and carbon emission reduction
					projects in a diverse range of industries, including metal equipment,
					energy, food, and waste treatment. He has also conducted life cycle
					and sustainability assessments on various products and industrial
					systems in US, Canada, and China.<br /><br />
					Dr. Zhang's work has been published in respected peer-reviewed
					journals such as the International Journal of Life Cycle Assessment,
					Journal of Industrial Ecology, and Journal of Cleaner Production, as
					well as in conference proceedings and book chapters (according to
					Google Scholar). Additionally, his contributions have been recognized
					in industrial news and professional media.
				</span>
			</div>
			<div class="col-10 detail">
				<span class="subtitle">Ruirui(Raerae) Zhang, Ph.D <br /></span>
				<span>
					Dr. Ruirui Zhang is an expert in sustainable consumption and consumer
					behavior, whose multifaceted career spans academia, business, and the
					arts. <br /><br />With a PhD under her belt, Dr. Zhang has been
					recognized by the International Textile and Apparel Association, where
					she received the Best PhD Paper Award. Her work has been published in
					Sustainable Production and Consumption, Sustainability, Fashion
					Design, Education, and Technology, etc. Currently, Dr. Zhang is
					working on a highly anticipated book, "The Business of Fashion (7th
					Edition)", which delves into the ever-evolving world of fashion
					business and sustainability. <br /><br />Beyond her academic
					achievements, she is an internationally awarded filmmaker and ballet
					dancer.
				</span>
			</div>

			<div class="col-5 img">
				<q-img
					src="https://res.cloudinary.com/ddkphzsvp/image/upload/v1679371620/DYNIMOS%20DEFAULT%20PICS/banner/rui_zlfeyx.png"
				/>
			</div> 
		</div> -->
	</div>
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
export default {
	name: "AboutPage",
	components: {
		Toolbar,
	},
};
</script>
<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
@media (min-width: 480px) {
	.container {
		.content-2 {
			display: flex;
			justify-content: center;
			box-sizing: border-box;
			padding: 50px 8vw;

			.title {
				font-size: 3vw;
				font-weight: 400;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
				margin-bottom: 30px;
			}
			.detail-btn {
				font-size: 24px;
				font-weight: 700;
				font-family: "Abel", sans-serif;
				color: rgb(108, 154, 208);
				margin-top: 80px;
			}
			.img {
				padding: 50px 20px 20px 20px;
			}
			.detail {
				margin-top: 20px;
				font-size: 20px;
				font-weight: 400;
				font-family: "Abel", sans-serif;
				box-sizing: border-box;
				margin-bottom: 60px;
			}
			.subtitle {
				font-size: 2vw;
				font-weight: 700;
				font-family: "Abel";
				color: #0d0c22;
				box-sizing: border-box;
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (min-width: 480px) and (max-width: 1200px) {
	.with-bg {
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
	.content-2 {
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		box-sizing: border-box;
		padding: 50px 8vw;
		margin-top: 30px;

		.left {
			margin-bottom: 30px;

			.title {
				font-size: 24px;
				font-weight: 700;
				width: 100% !important;
				color: rgb(13, 12, 34);
			}

			.detail {
				font-size: 18px;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 50px;
				width: 100% !important;
				color: #3d3d4e;
			}
		}

		.right {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.item {
				width: 30vw !important;

				.detail {
					font-size: 16px;
					font-weight: 600;
					width: 20vw !important;
					height: 100px;
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
@media (max-width: 480px) {
	.container {
		div {
			font-family: Calibri;
		}

		.content-2 {
			box-sizing: border-box;
			padding: 20px 8vw;
			margin-top: 10px;
			.title {
				font-size: 21px;
				font-weight: 700;
				margin-top: 20px;
			}

			.left {
				.title {
					font-size: 6vw;
					font-weight: 700;
					width: 300px;
					color: rgba(0, 0, 0, 0.65);
				}

				.detail {
					font-size: 18px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 50px;
					color: #3d3d4e;
				}

				.started {
					border-radius: 5px;
					background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
				}
			}

			.right {
				// display: flex;
				// justify-content: space-between;
				box-sizing: border-box;

				.item {
					margin-top: 50px;

					img {
						width: 50px;
					}

					.title {
						font-size: 21px;
						font-weight: 700;
						margin-top: 20px;
					}

					.detail {
						margin-top: 20px;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}
	}
	.with-bg {
		border-radius: 5px;
		height: 50px;
		font-size: 18px;
		font-weight: 600;
		background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
	}
}
</style>
