const default_general_states = {
    general_ourcompany_intro: {
        ans: "",
        img: "",
    },
    general_ourcompany_suppliers: {
        ans: "",
        img: "",
    },
    general_ourcompany_stakeholders: {
        ans: "",
        img: "",
    },
    general_ourcompany_commitment: {
        ans: "",
        img: "",
    },
    general_ourcompany_featurestory: {
        ans: "",
        img: "",
    },
    general_economy_activities: {
        ans: "",
        img: "",
    },
    general_economy_opportunities: {
        ans: "",
        img: "",
    },
    general_economy_practices: {
        ans: "",
        img: "",
    },
    general_economy_featurestory: {
        ans: "",
        img: "",
    },
    general_economy_highlights: {
        ans: "",
        img: "",
    },
    general_environment_strategy: {
        ans: "",
    },
    general_environment_suppliers: {
        ans: "",
        img: "",
    },
    general_environment_carbon: {
        ans: "",
        img: "",
    },
    general_environment_energyreduction: {
        ans: "",
        img: "",
    },
    general_environment_wastewater: {
        ans: "",
        img: "",
    },
    general_environment_waterreduction: {
        ans: "",
    },
    general_environment_wastetreatment: {
        ans: "",
        img: "",
    },
    general_environment_materialreduction: {
        ans: "",
        img: "",
    },
    general_social_diversity: {
        ans: "",
        img: "",
    },
    general_social_employees: {
        ans: "",
    },
    general_social_compliance: {
        ans: "",
    },
    general_social_featurestory_diversity: {
        ans: "",
    },
    general_social_featurestory_training: {
        ans: "",
    },
    general_social_community: {
        ans: "",
    },
    general_social_culturalheritage: {
        ans: "",
    },
    general_social_featurestory_community: {
        ans: "",
    },
    general_social_partner: {
        ans: "",
    },
    general_social_featurestory_commitment: {
        ans: "",
    },
    general_social_safetymeasures: {
        ans: "",
    },
    general_social_safety: {
        ans: "",
    },
    general_social_wellbeing: {
        ans: "",
    },
    general_social_featurestory_safety: {
        ans: "",
        img: "",
    },
    // Table data starts
    general_environment_energytable: {
        ans: [],
    },
    general_environment_watertable: {
        ans: [],
    },
    general_environment_materialtable: {
        ans: [],
    },
    general_environment_wastetable: {
        ans: [],
    },
    // Table data ends
};
export default default_general_states;