<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<q-layout view="hHh Lpr lff" container style="position: absolute;top: 70px;height: 86%" class="shadow-2 rounded-borders">  
		<q-drawer
			show-if-above
			:mini="miniState"
			@mouseover="miniState = false"
			@mouseout="miniState = true"
			mini-to-overlay
			:width="250"
			:breakpoint="500"
			bordered
			class="bg-grey-3"
		>
			<q-scroll-area class="fit" style="padding-top:50px ;" :horizontal-thumb-style="{ opacity: 0 }">
			<q-list padding>
				<q-item clickable v-ripple @click="$router.push('/equipment')">
					<q-item-section avatar>
						<q-icon name="account_tree" />
					</q-item-section>

					<q-item-section>
						<div>	Equipment management</div>
					</q-item-section>
				</q-item>
				
				<q-item clickable v-ripple @click="$router.push('/inventory')">
					<q-item-section avatar>
						<q-icon name="inventory" />
					</q-item-section>

					<q-item-section>
						<div>	Inventory management</div>
					</q-item-section>
				</q-item>

				<q-item clickable v-ripple @click="$router.push('/methodology')">
					<q-item-section avatar>
						<q-icon name="description" />
					</q-item-section>

					<q-item-section>
						<div>	Methodology </div>
					</q-item-section>
				</q-item>

				<q-separator />

			</q-list>
			</q-scroll-area>
		</q-drawer>

		<q-page-container style="width: 90%;margin: 0 auto;">
			<q-page padding>
				<h3> Inventory Management</h3>
				<div class="detail" style="white-space: pre-wrap">
					<q-card
						flat
						bordered
						class="my-card"
						:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
					>
						<q-card-section>
							<div class="row items-center no-wrap">
								<div class="col">
									<div style="font-size:1.4vw">Guideline</div>
								</div>
							</div>
						</q-card-section>
						<q-separator />
		
						<q-card-section style="font-size: 1.0vw">
							<div>This section is designed to compile a detailed inventory of fuel usage for each piece of equipment over specific periods.</div>
							<div style="margin-top:10px;">Please record the fuel consumption of your equipment for a specified period. To begin, click "Add Operation," then double-click the new row that appears. In the pop-up window, you can enter details such as the operation name (the task for which the equipment was used), the time period, and select the equipment from the list created in the "Equipment Management" page. Also, specify the units of measurement, the amount of fuel used, and the data source.</div>
						<div style="margin-top:10px;">Data types to consider:</div>
						<div style="margin-left:15px;">
							<div style="font-weight: 700;">Primary data: </div><div>This is directly collected from the specific activity, providing firsthand insights.</div>	
							<div style="font-weight: 700;">Secondary data:</div><div>This includes industry-average figures obtained from published databases, government statistics, literature studies, and industry associations. It may also encompass financial data, proxy data, and other generic data. Proxy data, used to estimate emissions for one part of the value chain based on specific data from another, is classified as secondary because it is not directly related to the specific activity being measured.</div>	
							<div style="font-weight: 700;">Assumptions:</div><div>When neither primary nor secondary data are available, assumptions may be used to estimate the activity information.</div>
						</div>
						<div style="margin-top:10px;font-weight: 700;">Note:</div><div> Ensure you describe how the data was collected. For secondary data, specify the source. Also, outline any assumptions made during the data collection process.
						</div>
						</q-card-section>
					</q-card>
					<br />
				</div>
				<div>
					<vxe-toolbar>
					<template #buttons>
						<vxe-button icon="vxe-icon-square-plus" @click="insertEvent()">Add Operation</vxe-button>
						<!-- <vxe-button icon="vxe-icon-square-plus" @click="insertOperation(-1)">Add Operation</vxe-button> -->
						<vxe-button icon="vxe-icon-square-plus" @click="insertPortion('Process')" v-if="disableProcess==='true'">Add Process</vxe-button>
						<vxe-button icon="vxe-icon-square-minus" @click="removeSelectRowEvent">Delete Checked</vxe-button>
						<vxe-checkbox icon="vxe-icon-square-plus" v-model="disableProcess" checked-value="true" unchecked-value="false" style="margin-left: 10px;">process</vxe-checkbox>
					</template>
					</vxe-toolbar>
					<vxe-table
						border
						:loading="loading"
						:loading-config="{icon: 'vxe-icon-indicator roll', text: 'loading...'}"
						show-overflow
						ref="xTable"
						:span-method="rowspanMethod"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:column-config="{resizable: true}"
						:row-config="{isHover: true}"
						:data="tableData"
						empty-text="Empty Table"
						align="center"
						header-align="center"
						:cell-style="cellStyle"
						:auto-resize="true"
						@cell-dblclick="cellDBLClickEvent"
						:menu-config="menuConfig"
						@menu-click="contextMenuClickEvent"
					>
						<vxe-column type="seq" width="60"></vxe-column>
						<vxe-column type="checkbox" width="60"></vxe-column>
						<vxe-column field="operation" title="Operation">
							<template #default="{ row }">
								<span>{{ row.operation }}</span>
							</template>
						</vxe-column>
						<vxe-column field="process" title="Process" v-if="disableProcess==='true'">
							<template #default="{ row }">
								<span>{{ row.process }}</span>
							</template>
						</vxe-column>
						<vxe-column field="timeperiod" title="Time Period">
							<template #default="{ row }">
								<span v-if="row.timeperiod.starttime !==0 || row.timeperiod.endtime !==0">{{ row.timeperiod.starttime }}{{ "-" }}{{ row.timeperiod.endtime }}</span>
							</template>
						</vxe-column>
						<vxe-column field="equipment" title="Equipment">
							<template #default="{ row }">
								<span>{{ row.equipment }}</span>
							</template>
						</vxe-column>
						<vxe-colgroup title="GHG Emissions">
								<vxe-column field="co2" title="CO2 (kg)">
									<template #default="{ row }">
										<div>{{row.co2}}</div>
									</template>
								</vxe-column>
								<vxe-column field="ch4" title="CH4 (g)">
									<template #default="{ row }">
										<div>{{row.ch4}}</div>
									</template>
								</vxe-column>
								<vxe-column field="n2o" title="N2O (g)">
									<template #default="{ row }">
										<div>{{row.n2o}}</div>
									</template>
								</vxe-column>
								<vxe-column field="co2e" title="CO2e (kg)">
									<template #default="{ row }">
										<div>{{row.co2e}}</div>
									</template>
								</vxe-column>
						</vxe-colgroup>
					</vxe-table>
					<vxe-modal v-model="showEdit" :title="selectRow ? 'EDIT&SAVE' : 'ADD&SAVE'" width="800" min-width="600" min-height="300" :loading="submitLoading" resize destroy-on-close>
						<template #default>
							<vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">
								<!-- <vxe-form-item :title="title_text" :titleAlign="center" :title-width="400" :span="24"></vxe-form-item> -->
								<vxe-form-item field="operation" title="Operation" :span="12" :edit-render="{}">
									<template #default="{ data }">
									<vxe-input v-model="data.operation" type="text" transfer placeholder="Enter Operation"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item field="process" title="Process" :span="12" :edit-render="{}" v-if="disableProcess==='true'">
									<template #default="{ data }">
									<vxe-input v-model="data.process" type="text" transfer placeholder="Enter Process"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item field="timeperiod" title="Time Period" :span="12" :edit-render="{}">
									<!-- <template #default="{ data }">
										<span v-if="data.timeperiod.starttime !=='' || data.timeperiod.endtime !==''">{{ data.timeperiod.starttime }}{{ "-" }}{{ data.timeperiod.endtime }}</span>
									</template> -->
									<template #default="{ data }">
										<div class="demo-date-picker">
											<div class="block">
												<el-date-picker
													v-model="data.timeperiod.starttime"
													type="date"
													format="YYYY/MM/DD"
													value-format="YYYY-MM-DD"
													placeholder="Start date"
													:size="small"
													style="width:50%"
													/>
												<el-date-picker
													v-model="data.timeperiod.endtime"
													type="date"
													format="YYYY/MM/DD"
													value-format="YYYY-MM-DD"
													placeholder="End date"
													:size="small"
													style="width:50%"
													/>
											</div>
										</div>
									</template>
								</vxe-form-item>
								<!-- <vxe-form-item :span="12"></vxe-form-item>							 -->
								<vxe-form-item field="equipment" title="Equipment" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-select v-model="data.equipment" transfer placeholder="Please select">
										<vxe-option v-for="item in equipment_option" :key="item.name" :value="item.name" :label="item.name"></vxe-option>
									</vxe-select>
									</template>
								</vxe-form-item>
								<vxe-form-item field="unit" title="Units" :span="12" :item-render="{}" placeholder="Please select">
									<template #default="{ data }">
									<vxe-select v-model="data.unit" transfer  placeholder="Please select" @click="getFueltype(data.equipment)">
										<vxe-option value="1" label="Thermal Unit(mmBtu)"></vxe-option>
										<vxe-option value="2" label="Weight Unit(short ton)" v-if="cur_fueltype==='Coal and Coke' || cur_fueltype=='Other Fuels - Solid' || cur_fueltype==='Biomass Fuels - Solid'">
										</vxe-option>
										<vxe-option value="2" label="Volume Unit(scf)" v-if="cur_fueltype==='Natural Gas' || cur_fueltype==='Other Fuels - Gaseous' || cur_fueltype==='Biomass Fuels - Gaseous'">
										</vxe-option>
										<vxe-option value="2" label="Liquid Unit(gallon)" v-if="cur_fueltype==='Petroleum Products' || cur_fueltype==='Biomass Fuels - Liquid'">
										</vxe-option>
									</vxe-select>
									</template>
								</vxe-form-item>
								<vxe-form-item field="amount" title="Amount" :span="12" :item-render="{}">
									<template #default="{ data }">
									<vxe-input v-model="data.amount" type="number" placeholder="Enter Amount"></vxe-input>
									</template>
								</vxe-form-item>
								<vxe-form-item field="datatype" title="Data type" :span="16" :item-render="{}">
									<template #default="{ data }">
										<!-- <vxe-input v-model="data.datatype" type="number" placeholder="Select data type"></vxe-input> -->
										<vxe-radio-group v-model="data.datatype" size="medium" :strict="false">
											<vxe-radio-button label="Primary data" content="Primary data"></vxe-radio-button>
											<vxe-radio-button label="Secondary data" content="Secondary data"></vxe-radio-button>
											<vxe-radio-button label="Assumptions" content="Assumptions"></vxe-radio-button>
										</vxe-radio-group>	
									</template>
								</vxe-form-item>
								<vxe-form-item field="note" title="Note" :span="24" :item-render="{}">
									<template #default="{ data }">
									<vxe-textarea v-model="data.note" :autosize="{minRows: 2, maxRows: 4}" placeholder="Enter Note"></vxe-textarea>
									</template>
								</vxe-form-item>
								<vxe-form-item align="center" title-align="center" :span="24">
									<template #default="{ data }">
									<vxe-button type="submit" @click="SaveFunction(data)">Save</vxe-button>
									<vxe-button type="reset">Clear</vxe-button>
									</template>
								</vxe-form-item>
							</vxe-form>
						</template>
					</vxe-modal>
				</div>
				<br>
				<br>
				<br>
			</q-page>
		</q-page-container>
	</q-layout>

</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
// import { useRouter } from "vue-router";
import { VxeTableInstance } from 'vxe-table'
import axios from "axios";
import AllConuntryStates from "@/components/formdata/allcountries.json";
// import stepSource from "../carbondata/sourcetype.json";
import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";

import Guid from 'guid'

export default {
	components: {
		ToolBar,
	},
	setup () {
		const disableProcess = ref()
		const xTable = ref(VxeTableInstance)
		const formData = reactive({
			equipment: '',
			unit: '',
			amount: 0,
			co2e: 0,
			co2: 0,
			ch4: 0,
			n2o: 0,
			datatype: '',
			note: '',
			timeperiod:
			{
				starttime: 0,
				endtime: 0,
			},
			operation: '',
			process: ''
		})
		const submitLoading = ref(false)
		const showEdit = ref(false)
		const selectRow = ref()
		const loading = ref(true)
		const tableData = ref([])
		const formRules = reactive({
			name: [
				{ required: true, message: '请输入名称' },
				{ min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			nickname: [
				{ required: true, message: '请输入昵称' }
			],
			amount: [
				// { required: true, message: '请选择性别' },
				{ min: 0,  message: 'no less than 0' }
			]
		})
		const ConvertValue = ref("")
		var kg_CO2e = [],
			kg_CO2 = [],
			g_CH4 = [],
			g_N2O = [];
		// const store = useStore()
		const { user } = useAuth0()
		const $q = useQuasar()
		// var watch_first = true
		const AllCityStates = ref()
		const rowNum = ref()
		const equipment_option = []
		const cur_fueltype = ref()
		const usercode = JSON.stringify(user.value, null, 2);
		const userid = JSON.parse(usercode)["sub"];
				
		const cellStyle = ({  column }) => {
			if (['CO2', 'CH4', 'N2O','CO2e'].includes(column.field)) {
				return {
					backgroundColor: '#d6d6d6'
				}
			}
		}

		const insertEvent = async (row) => {
			// console.log("insertEvent",row)
			const $table = xTable.value
			const get_Guid = Guid.raw()
			if ($table) {
				const record = {
					transactionId: get_Guid,
					id: get_Guid,
					userId: userid,
					transactionType: "equipment",
					scope: 0x11,
					equipment: '',
					unit: '',
					amount: 0,
					datatype: '',
					note: '',
					timeperiod:{
						starttime: '',
						endtime: ''
					},
					operation: '',
					process: ''
				}
				const { row: newRow } = await $table.insertAt(record, row)
				await $table.setEditCell(newRow, 'operation')

				console.log(record.transactionId)
				const headers = {};
				const body = {
					operatingData: record
				};
				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
						record.id,
						body,
					{ headers }
				);

				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
			}
		}

		const insertPortion = async (type) => {
			const $table = xTable.value
			// const xTabledata = ref()
			// xTabledata.value = JSON.parse(JSON.stringify(selectRecords))
			if ($table) {
				const selectRecords = $table.getCheckboxRecords()
				// console.log(selectRecords)
				const get_Guid = Guid.raw()
				if(selectRecords.length === 1){
					var temp_data = selectRecords[0].process
					if(type === 'Fuel')
					{
						temp_data = ''
					}else if(type === 'Process'){
						// disableProcess.value = false
						console.log("disableProcess",disableProcess.value)
					}
					const record = {
						transactionId: get_Guid,
						id: get_Guid,
						userId: userid,
						transactionType: "equipment",
						scope: 0x11,
						equipment: '',
						unit: '',
						amount: 0,
						datatype: '',
						note: '',
						timeperiod:{
							starttime: '',
							endtime: ''
						},
						// timeperiod: xTabledata.value[xTabledata.value.length-1].timeperiod,
						operation: selectRecords[0].operation,
						process: temp_data,
					}
					const { selectRecords: newRow } = await $table.insertAt(record, selectRecords[0])
					await $table.setEditCell(newRow, 'operation')

					// console.log(record.transactionId)
					const headers = {};
					const body = {
						operatingData: record
					};
					axios.post(
						"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
							record.transactionId,
							body,
						{ headers }
					);

					$q.notify({
						color: "secondary",
						textColor: "white",
						icon: "done",
						message: "Progress saved",
					});
				}else{
					$q.notify({
						color: "red",
						textColor: "white",
						icon: "error",
						message: "Please select only one 'operation'",
					});
				}
			}
		}

		function remove_rowData(id)
		{
			const headers = {};
			let response = axios.delete(
				"https://testdynimosazure.azurewebsites.net/api/deleteCalculatorOperatingByGuid?id=" +
				id,
				// 	body,
				{ headers }
			);
			return response
		}

		const removeSelectRowEvent = () => {
			const $table = xTable.value
			
			if ($table) {
				const selectRecords = $table.getCheckboxRecords()
				for(let i=0;i<selectRecords.length;i++)
				{
					if (remove_rowData(selectRecords[i].id)) {
						$table.removeCheckboxRow()
					}
				}
				// itemsValue()
				// saveProgress()
			}
		}
		
		const editEvent = (row,rowIndex) => {
			Object.assign(formData, row)
			selectRow.value = row
			showEdit.value = true
			rowNum.value = rowIndex
			// console.log("rowNum.value")
			// console.log(rowNum.value)
		}
		const cellDBLClickEvent = ({ row ,rowIndex}) => {
			editEvent(row,rowIndex)
		}

		const submitEvent = () => {
			submitLoading.value = true
			// setTimeout(() => {
				const $table = xTable.value
				if ($table) {
				submitLoading.value = false
				showEdit.value = false
				if (selectRow.value) {
					// VXETable.modal.message({ content: 'Save successfully', status: 'success' })
					Object.assign(selectRow.value, formData)
					// console.log("submitEvent")
					// console.log(JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData)))
				} else {
					// VXETable.modal.message({ content: 'Successfully added', status: 'success' })
					$table.insert({ ...formData })
				}
				}
			// }, 500)
		}
		// const rowspanMethod = ({ row, _rowIndex, column, visibleData }) => {
		// 	const fields = ['operation','process','location']//,'timeperiod'
		// 	const cellValue = row[column.field]
		// 	if (cellValue && fields.includes(column.field)) {
		// 		const prevRow = visibleData[_rowIndex - 1]
		// 		let nextRow = visibleData[_rowIndex + 1]
		// 		if (prevRow && prevRow[column.field] === cellValue) {
		// 			return { rowspan: 0, colspan: 0 }
		// 		} else {
		// 			let countRowspan = 1
		// 			while (nextRow && nextRow[column.field] === cellValue) {
		// 				nextRow = visibleData[++countRowspan + _rowIndex]
		// 			}
		// 			if (countRowspan > 1) {
		// 				return { rowspan: countRowspan, colspan: 1 }
		// 			}
		// 		}
		// 	}
		// }

		const menuConfig = reactive({
			header: {
				options: [
				[
					{ code: 'hideColumn', name: '隐藏列', disabled: false },
					{ code: 'showAllColumn', name: '取消所有隐藏列', disabled: false }
				]
				]
			},
			body: {
				options: [
				[
					// { code: 'copy', name: '复制', prefixIcon: 'vxe-icon-copy', disabled: false },
					// { code: 'reload', name: '刷新', disabled: false },
					// { code: 'insertAtOperation', name: 'insert operation', prefixIcon: 'vxe-icon-add', disabled: false },
					// { code: 'insertAtProcess', name: 'insert process', prefixIcon: 'vxe-icon-add', disabled: false },
					// { code: 'insertAtFuel', name: 'insert fuel', prefixIcon: 'vxe-icon-add' ,disabled: false },
					{ code: 'remove', name: 'delete', prefixIcon: 'vxe-icon-delete', disabled: false },
					// { code: 'save', name: '保存', prefixIcon: 'vxe-icon-save', disabled: false }
				]
				]
			},
			visibleMethod ({ options, column }) {
				const isDisabled = !column
				options.forEach(list => {
				list.forEach(item => {
					item.disabled = isDisabled
				})
				})
				return true
			}				
		})

		const contextMenuClickEvent = ({ menu, row, column }) => {
			const $table = xTable.value
			if ($table) {
				switch (menu.code) {
				case 'hideColumn':
					$table.hideColumn(column)
					break
				case 'showAllColumn':
					$table.resetColumn(true)
					break
				case 'insertAtOperation':
					insertEvent(row)
					break
				case 'insertAtProcess':
					insertEvent(row)
					break
				case 'insertAtFuel':
					insertEvent(row)
					break
				case 'remove':
					var i = 0,j = 0,k = 0
					var xTabledata
					xTabledata = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
					console.log(xTabledata)
					console.log("column",row._X_ROW_KEY,column.field)
					if(column.field === "operation")
					{
						for(i=0;i<xTabledata.length;i++)
						{
							if(row._X_ROW_KEY === xTabledata[i]._X_ROW_KEY)
							{
								break;
							}
						}
						if(i!=0)
						{
							for(j=i;j>=0;j--)
							{
								if(xTabledata[j].operation===row.operation)
								{
									if(remove_rowData(xTabledata[j].id))
									{
										$table.remove(xTabledata[j])
									}
								}else{
									break
								}
							}
						}
						if(i<xTabledata.length-1)
						{
							for(k=i;k<xTabledata.length;k++)
							{
								if(xTabledata[k].operation===row.operation)
								{
									if(remove_rowData(xTabledata[k].id))
									{
										$table.remove(xTabledata[k])
									}
								}else{
									break
								}
							}
						}
						
					}
					else if(column.field === "process")
					{
						for(i=0;i<xTabledata.length;i++)
						{
							if(row._X_ROW_KEY === xTabledata[i]._X_ROW_KEY)
							{
								break;
							}
						}
						if(i!=0)
						{
							for(j=i;j>=0;j--)
							{
								if(xTabledata[j].process===row.process && row.process !== '')
								{
									if(remove_rowData(xTabledata[j].id))
									{
										$table.remove(xTabledata[j])
									}
								}else{
									break
								}
							}
						}
						if(i<xTabledata.length-1)
						{
							for(k=i;k<xTabledata.length;k++)
							{
								if(xTabledata[k].process===row.process && row.process !== '')
								{
									$table.remove(xTabledata[k])
								}else{
									break
								}
							}
						}
						
					}
					if(remove_rowData(row.id))
					{
						$table.remove(row)
					}
				// itemsValue()
				// 	saveProgress()	
					break
				}
			}
		}

		// function itemsValue()
		// {
		// 	let i = 0
		// 	let starttimevalue, endtimevalue,location,operation,process
		// 	const item_arry=reactive([])
		// 	const fuel_arry=reactive([])
		// 	const xTabledata = ref()
		// 	xTabledata.value = JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
		// 	// console.log(xTabledata.value)
		// 	for(i=0;i<xTabledata.value.length;i++)
		// 	{
		// 		if(xTabledata.value[i].equipment !== '' && 	xTabledata.value[i].unit !== '' &&
		// 			xTabledata.value[i].operation !== '' && xTabledata.value[i].starttime !== '' && 
		// 			xTabledata.value[i].endtime !== '')
		// 		{
		// 			fuel_arry.push({
		// 				category: JSON.parse(JSON.stringify(xTabledata.value[i].fuelcategory)),
		// 				type: JSON.parse(JSON.stringify(xTabledata.value[i].fueltype)),
		// 				unit: JSON.parse(JSON.stringify(xTabledata.value[i].unit)),
		// 				Amount: JSON.parse(JSON.stringify(xTabledata.value[i].amount)),
		// 				datatype: JSON.parse(JSON.stringify(xTabledata.value[i].datatype)),
		// 				Note: JSON.parse(JSON.stringify(xTabledata.value[i].note))
		// 			})
		// 			starttimevalue = xTabledata.value[i].timeperiod.starttime;
		// 			endtimevalue = xTabledata.value[i].timeperiod.endtime;
		// 			location = xTabledata.value[i].location;
		// 			operation = xTabledata.value[i].operation;
		// 			process = xTabledata.value[i].process;
		// 			if(i<xTabledata.value.length-1){
		// 				if((xTabledata.value[i].operation !== xTabledata.value[i+1].operation) || 
		// 					(xTabledata.value[i].location !== xTabledata.value[i+1].location) || 
		// 					(xTabledata.value[i].timeperiod !== xTabledata.value[i+1].timeperiod)){
		// 					item_arry.push({
		// 						timeperiod:{
		// 							starttime: starttimevalue,
		// 							endtime: endtimevalue
		// 						},
		// 						location: location,
		// 						fuel: JSON.parse(JSON.stringify(fuel_arry)),
		// 						operation: operation,
		// 						process: process
		// 					})

		// 					fuel_arry.length = 0 
		// 				}
		// 			}else{
		// 				item_arry.push({
		// 					timeperiod:{
		// 						starttime: starttimevalue,
		// 						endtime: endtimevalue
		// 					},
		// 					location: location,
		// 					fuel: fuel_arry,
		// 					operation: operation,
		// 					process: process
		// 				})
		// 			}
		// 		}
		// 	}
		// 	// console.log(item_arry)
		// 	//time 一样放在同一个数据文件中
		// 	//---->operation/location任一不一样则在items增加
		// 	//---->operation、location都一样，则在fuel中增加

		// 	store.commit("updateCalcData", {
		// 		template: "stationary",
		// 		data: item_arry
		// 	});

		// 	store.commit("updateCalcData", {
		// 		template: "stationary",
		// 		data: item_arry
		// 	});
		// }

		// function saveProgress() {
		// 	// const usercode = JSON.stringify(user.value, null, 2);
		// 	// const userid = JSON.parse(usercode)["sub"];
		// 	console.log("Saving data for user: " + userid + store.state.template);
		// 	const headers = {};
		// 	const body = {
		// 		items: JSON.parse(JSON.stringify(xTable.value.getTableData().visibleData))
		// 	};

		// 	axios.post(
		// 		"https://testdynimosazure.azurewebsites.net/api/postCalculatorDataByUser?id=" +
		// 			userid,
		// 			body,
		// 		{ headers }
		// 	);

		// 	$q.notify({
		// 		color: "secondary",
		// 		textColor: "white",
		// 		icon: "done",
		// 		message: "Progress saved",
		// 	});
		// }

		function getFueltype(equipment){
			if (equipment === "") {
				console.error("equipment val is null");
				return null;
			} else {
				console.log(equipment)

				var source = equipment_option.filter((x) => x.name === equipment);
				console.log(source[0].fueltype)
				// temp_Factor = Ftype;
				// var	source = stepFactor.filter((x) => x.name === equipment_source[0].fuelcategory);
				if (source.length >= 1) {
					cur_fueltype.value = source[0].fueltype
					return source[0]
				} else {
					console.error("Failed to find the step", equipment);
					return null
				}
			}
		}
		function getFactor(Ftype) {
			// let temp_Factor;
			if (Ftype === "") {
				console.error("selected val is null");
				return null;
			} else {
				var equipment_source = equipment_option.filter((x) => x.name === Ftype);
				// console.log(equipment_source)
				// temp_Factor = Ftype;
				var	source = stepFactor.filter((x) => x.name === equipment_source[0].fuelcategory);
				if (source.length >= 1) {
					return source[0];
				} else {
					console.error("Failed to find the step", Ftype);
					return null;
				}
			}
		}
		// function getHHV(fueltype_t) {
		// 	return parseFloat(getFactor(fueltype_t).value[0].hhv);
		// }
		function calculate(data){
			var temp_data = 0;
			var cov = 1;
			var co2 = 0,
				ch4 = 0,
				n2o = 0; 
			temp_data = getFactor(data.equipment);

			if (temp_data === null) {
				console.log("-------->error");
				return 0;
			} else {
				if (data.unit === "2") {
					console.log("data",data)
					cov = (ConvertValue.value > 0 ? ConvertValue.value : temp_data.value[0].hhv);
				}

				co2 = parseFloat(temp_data.value[1].co2);
				ch4 = parseFloat(temp_data.value[2].ch4);
				n2o = parseFloat(temp_data.value[3].n2o);

				data.co2e = (cov * data.amount * (co2 + ch4 * 25/1000  + n2o * 298/1000)).toFixed(2);
				data.co2 = (cov * data.amount * co2).toFixed(2);
				data.ch4 = (cov * data.amount * ch4).toFixed(2);
				data.n2o = (cov * data.amount * n2o).toFixed(2);
				data.scope = 0x11
				// console.log(data)
				const headers = {};
				const body = {
					operatingData: data,
				};

				axios.post(
					"https://testdynimosazure.azurewebsites.net/api/postCalculatorOperatingByGuid?id=" +
					data.id,
						body,
					{ headers }
				);
				$q.notify({
					color: "secondary",
					textColor: "white",
					icon: "done",
					message: "Progress saved",
				});
			}
			return kg_CO2e+kg_CO2+g_CH4+g_N2O;//kg_CO2e.toFixed(2);
		}
		function SaveFunction(data)
		{
			console.log(data)
			submitEvent()
			calculate(data)
			// itemsValue()
			// setTimeout(()=>{
			// 	saveProgress()
			// },1000)
		}
		// const router = useRouter();

		async function userHasData() {
			// const usercode = JSON.stringify(user.value, null, 2);
			// const userid = JSON.parse(usercode)["sub"];
			setTimeout(()=>{
				// console.log("setTimeout")
				if(loading.value === true)
				{
					//router.push('/home')
				}
				
			},10000)
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"equipment"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			
			return null;
		}
		async function getEquipmentData() {
            // const usercode = JSON.stringify(user.value, null, 2);
            // const userid = JSON.parse(usercode)["sub"];
            setTimeout(()=>{
                // console.log("setTimeout")
                if(loading.value === true)
                {
                    //router.push('/home')
                }
                
            },10000)
            let response = await axios.get(
                "https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUser?id=" + userid,
                {
                    headers: {},
                }
            );
            if (response) {
                return response;
            }
            
            return null;
        }
		onMounted(async () => {
			const returnUser = await userHasData();
			const tempequipment = await getEquipmentData();
			console.log(tempequipment.data.equipment)
            for(let i=0;i<tempequipment.data.equipment.length;i++)
            {
                equipment_option.push(tempequipment.data.equipment[i])
            }
			console.log(returnUser.data)
			loading.value = false
			if(returnUser.data.operatingData === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.operatingData))
			for(let i=0;i<databuf.length;i++){
				if(typeof(databuf[i].operation) !== "undefined") 
				{
					const table_arry=reactive([])
					// cityBycode(databuf[i].location)
					table_arry.push({
						transactionId: databuf[i].transactionId,
						id: databuf[i].id,
						userId: databuf[i].userId,
						transactionType: databuf[i].transactionType,
						operation: databuf[i].operation,
						process: databuf[i].process,
						equipment: databuf[i].equipment,
						scope: databuf[i].scope,
						co2e: databuf[i].co2e,
						co2: databuf[i].co2,
						ch4: databuf[i].ch4,
						n2o: databuf[i].n2o,
						timeperiod:{
							starttime: databuf[i].timeperiod.starttime,
							endtime: databuf[i].timeperiod.endtime
						},
						amount: databuf[i].amount,
						unit: databuf[i].unit,
						datatype: databuf[i].datatype,
						note: databuf[i].note
					})					
					rowNum.value += 1 
					insertTable(-1,table_arry)					
				}
			}
		});
		const insertTable = async (row,Tdata) => {
			const $table = xTable.value
			if ($table) {
				const { row: newRow } = await $table.insertAt(Tdata, row)
				await $table.setEditCell(newRow, 'operation')
			}
		}	    
		
		return { 
			disableProcess,
			xTable,
			formData,
			submitLoading,
			menuConfig,
			contextMenuClickEvent,
			showEdit,
			selectRow,
			loading,
			tableData,
			formRules,
			kg_CO2e,
			kg_CO2,
			g_CH4,
			g_N2O,
			ConvertValue,
			miniState: ref(true),
			AllConuntryStates,
			cellStyle,
			removeSelectRowEvent,
			insertEvent,
			insertPortion,
			editEvent,
			cellDBLClickEvent,
			submitEvent,
			// rowspanMethod,
			SaveFunction,
			AllCityStates,
			equipment_option,
			getFueltype,
			cur_fueltype
		}
	},
	methods: {
		go(selector) {
			document.querySelector(selector).scrollIntoView({
				// 不想要滚动动画,则设置为"instant"
				behavior: 'smooth', // 滚动动画
				block: 'center'
			});
		},
		checkNegative() {
			this.wegith = Math.max(0, parseInt(this.wegith));
			this.ConvertValue = Math.max(0, parseInt(this.ConvertValue));
		},
	},
}
</script>

<style lang="less" scoped>

.detail {
	margin-top: 14px; //20px
	font-size: 16px;
	font-weight: 400;
	font-family: "Abel", sans-serif;
	box-sizing: border-box;
	width: 100%;
}
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
</style>