const hasOwn = (target, key) => Object.prototype.hasOwnProperty.call(target, key);
function reactive(data) {
    if (typeof data !== 'object') { 
        return data;
    }

    const proxy = new Proxy(data, {
      get(target, key) {
        // console.log('get: key is ${key}');
        // console.log(`get: key is ${key}`);
        const val = Reflect.get(target, key); // 等价于target[key]
        if (typeof val === 'object') {
          return reactive(val);
        }
        return val;
      },
      set(target, key, value) {
        const oldValue = target[key]; 
        const res  = Reflect.set(target, key, value); 

        //新增
        if (!hasOwn(target, key)) { 
            // console.log('新增属性set: key is ${key}, value is ${value}');
            return true;
        } 


        //排除数组修改length回调
        if (Array.isArray(target) && key === 'length') {
            return true;
        }

        //设置值相同不回调
        if (value !== oldValue) {
            // console.log(`修改属性set: key is ${key}, value is ${value}`);
            return true;
        }

        return res ;
      },
      deleteProperty(target, key) {
        console.log(`delete: key is ${key}`);
        return true;
      },
    });
    return proxy;
  }
  export default reactive;