<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<div class="container-1">      
		<div style="display: inline-block;width:50%">
			<div class="item1" style="border-radius:10px;background-color:rgb(241, 241, 241);">
				<img class="photo" src="@/assets/img/anime3.png" alt="Profile Photo" />
				<div style="display: flex;justify-content: center;font-weight:bolder;">
					<div style="padding-left:20px;font-size:xx-large">{{ formData.FirstName }}{{ ' ' }}{{ formData.LastName}}</div>
					<!-- <div style="width: 10%;font-size:large;padding-left:35px;"></div> -->
				</div>
			</div>
			<div class="item2" style="border-radius:10px;background-color:rgb(241, 241, 241);">
				<div id="basic-info" class="card mt-4">
					<div class="card-header">
						<h5>Basic Info</h5>
					</div>
					<vxe-form
						vertical
						style="border-radius:10px;background-color:rgb(241, 241, 241);"
						ref="formRef"
						:data="formData"
						:rules="formRules"
						:loading="loading"
						@submit="submitEvent"
						@reset="resetEvent"
					>
						<vxe-form-item title="FirstName" field="FirstName" span="12">
							<template #default="params">
							<vxe-input v-model="params.data.FirstName" placeholder="" clearable @change="changeEvent(params)"></vxe-input>
							</template>
						</vxe-form-item>
						<vxe-form-item title="LastName" field="LastName" span="12">
							<template #default="params">
							<vxe-input v-model="params.data.LastName" placeholder="" clearable @change="changeEvent(params)"></vxe-input>
							</template>
						</vxe-form-item>
						<vxe-form-item title="Occupation" field="Occupation" span="24">
							<template #default="params">
							<vxe-input v-model="params.data.Occupation" placeholder="" clearable @change="changeEvent(params)"></vxe-input>
							</template>
						</vxe-form-item>
						<vxe-form-item title="CompanyName" field="CompanyName" span="24">
							<template #default="params">
							<vxe-input v-model="params.data.CompanyName" placeholder="" clearable @change="changeEvent(params)"></vxe-input>
							</template>
						</vxe-form-item>
						<vxe-form-item title="CompanySize" field="CompanySize" span="24" :item-render="{}" title-overflow>
							<template #default="params">
							<vxe-select v-model="params.data.CompanySize" placeholder="" clearable @change="changeEvent(params)">
								<vxe-option value="1" label="1-50 employees"></vxe-option>
								<vxe-option value="2" label="50-200 employees"></vxe-option>
								<vxe-option value="3" label="200-1000 employees"></vxe-option>
								<vxe-option value="4" label="1000+ employees"></vxe-option>
							</vxe-select>
							</template>
						</vxe-form-item>
						<vxe-form-item title="Country" field="Country" span="24" :item-render="{}" title-overflow>
							<template #default="params">
							<vxe-select v-model="params.data.Country" placeholder="" clearable @change="changeEvent(params)">
								<vxe-option
										v-for="item in AllConuntryStates"
										:key="item.iso3"
										:value="item.name"
										:label="item.name"
									>
								</vxe-option>
							</vxe-select>
							</template>
						</vxe-form-item>
						<vxe-form-item title="Email" field="Email" span="24">
							<template #default="params">
							<vxe-input v-model="params.data.Email" @input="validateEmail" clearable @change="changeEvent(params)"></vxe-input>
							<span v-if="!isValidEmail" style="color: red;">Invalid email format</span>	
						</template>
						</vxe-form-item>
						<vxe-form-item title="Phone" field="Phone" span="24">
							<template #default="params">
							<vxe-input v-model.number="params.data.Phone" type="number" clearable @change="changeEvent(params)"></vxe-input>
							</template>
						</vxe-form-item>
						<vxe-form-item align="center" span="24">
							<template #default>
							<vxe-button type="submit" status="primary" content="submit"></vxe-button>
							<vxe-button type="reset" content="reset"></vxe-button>
							</template>
						</vxe-form-item>
					</vxe-form>
				</div>
							
			</div>
		</div>
	</div>
</template>

<script>
import { ref , onMounted} from 'vue'
import { useAuth0 } from "@auth0/auth0-vue";
import { useQuasar } from "quasar";
import axios from "axios";
import { VXETable } from 'vxe-table'
import ToolBar from "@/components/tools/HomeToolBar.vue";
import AllConuntryStates from "@/components/formdata/allcountries.json";

export default {
	components: {
		ToolBar,
	},
	setup () {
		const { user } = useAuth0()
		const collapseStatus = ref(true)
		const usercode = JSON.stringify(user.value, null, 2);
		const userid = JSON.parse(usercode)["sub"];
		const $q = useQuasar()
		const formData = ref({
			id:userid,
			userId: userid,
			submitFlag: true,
			FirstName: '',
			LastName: '',
			Occupation: '',
			CompanyName: '',
			CompanySize: '',
			Country: '',
			Email: '',
			Phone: ''
		})
		const formRef = ref()
		const loading = ref(false)
		const formRules = ref({
			FirstName: [
				{ required: true, message: 'Please enter firstname' },
				// { min: 3, max: 5, message: '长度在 3 到 5 个字符' }
			],
			LastName: [
				{ required: true, message: 'Please enter lastname' }
			],
			Occupation: [
				{ required: true, message: 'Please enter Occupation' }
			],
			CompanyName: [
				{ required: false, message: 'Please enter Company Name' }
			],
			CompanySize: [
				{ required: false, message: 'Please select Company Size' }
			],
			Country: [
				{ required: true, message: 'Please select Country' }
			],
			Email: [
				{ required: true, message: 'Please enter Email' }
			],
			Phone: [
				{ required: true, message: 'Please enter Phone' }
			]
		})
		const changeEvent = (params) => {
			const $form = formRef.value
			if ($form) {
				$form.updateStatus(params)
			}
		}
		const submitEvent = () => {

			loading.value = true
			const headers = {};
			const body = {
				form:formData.value
			};
			axios.post(
				"https://testdynimosazure.azurewebsites.net/api/postUserDataByUser?id=" +
					userid,
					body,
				{ headers }
			);

			$q.notify({
				color: "secondary",
				textColor: "white",
				icon: "done",
				message: "Progress saved",
			});
			setTimeout(() => {
				loading.value = false
				// VXETable.modal.message({ content: 'Save successfully', status: 'success' })
			}, 500)
		}
		const resetEvent = () => {
			formData.value.FirstName = ''
			formData.value.LastName = ''
			formData.value.Occupation = ''
			formData.value.CompanyName = ''
			formData.value.CompanySize = ''
			formData.value.Country = ''
			formData.value.Email =  ''
			formData.value.Phone = ''
			VXETable.modal.message({ content: 'reset event', status: 'info' })
		}

		async function userHasData() {
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getUserDataByUser?id=" + userid,
				{
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		onMounted(async () => {
			const returnUser = await userHasData();
// console.log(returnUser)
			if(returnUser.data.items === undefined) return
			const databuf = JSON.parse(JSON.stringify(returnUser.data.items))
			// console.log(databuf)
			if(databuf.id){
				formData.value.id = databuf.id
				formData.value.userId = databuf.userId
				formData.value.submitFlag = true//databuf.submitFlag
				formData.value.FirstName = databuf.FirstName
				formData.value.LastName = databuf.LastName
				formData.value.Occupation = databuf.Occupation
				formData.value.CompanyName = databuf.CompanyName
				formData.value.CompanySize = databuf.CompanySize
				formData.value.Country = databuf.Country
				formData.value.Email = databuf.Email
				formData.value.Phone = databuf.Phone
			}
		});

		return { 
			collapseStatus,
			formData,
			formRules,
			changeEvent,
			submitEvent,
			resetEvent,
			AllConuntryStates,
			isValidEmail: true
		}
	},
	methods: {
		validateEmail() {
			// 正则表达式用来验证邮箱格式
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			this.isValidEmail = emailRegex.test(this.formData.Email);
		}
	}
}
</script>

<style  lang="less" scoped>

.container-1 {
	text-align: center;
	justify-content: center; /* 水平居中 */
	padding-top: 5%;
}
.container-2 {
	display: flex;
	justify-content: center; /* 水平居中 */
	padding-top: 2%;
}

.item1 {
	display: flex;
	align-items: center;
	height:100px;
	padding-left: 20px;
	text-align:center;

	padding-left:10px
}
.item2 {
	text-align:left;

	padding-left:10px
}
.photo {
	display: inline-block;
	
	height: 70px;
	width: 60px;
	border-radius: 10px;

}
.item3 {
	width: 15%;
	height:300px;
	margin-right: 10px;
	text-align:center
}
.item4 {
	width: 46%;
	height:300px;
	text-align:center
}

.form-label {
	margin-bottom: .5rem ;

	font-style: null;
	font-weight: 700;
	color:#344767;
  }
</style>
