<template>
	<q-header elevated class="glossy">
		<Toolbar />
	</q-header>
	<div class="content">
		<div class="row justify-center">
			<div class="col-12 big-title text-center">{{ getLabel() }}</div>
		</div>
		<div class="q-pa-md q-gutter-sm">
			<q-breadcrumbs class="text-primary" style="font-size: 1.5vw;">
				<template v-slot:separator>
					<q-icon size="1.5em" name="chevron_right" style="color:inherit;"/>
				</template>

				<q-breadcrumbs-el label="Main" icon="home" to="/calculatermain" style="color:inherit;"/>
				<q-breadcrumbs-el :label="getLabel()" icon="widgets" />
			</q-breadcrumbs>
		</div>
		<div class="detail" style="white-space: pre-wrap" v-if="getLabel() !== 'Business travel and employee commuting'">
			<q-card
				flat
				bordered
				class="my-card"
				:class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-2'"
			>
				<q-card-section>
					<div class="row items-center no-wrap">
						<div class="col">
							<div style="font-size:2vw">Guideline</div>
						</div>
					</div>
				</q-card-section>

				<q-separator />

				<q-card-section style="font-size: 0.8vw">
					<div>{{ getStep().guildlilne }}</div>
				</q-card-section>
			</q-card>
			<br />
		</div>

		<div class="row justify-left" v-if="getLabel() !== 'Business travel and employee commuting'&&getLabel() !== 'Upstream/Downstream Transportation and Distribution'">
			<div
				class="card"
				v-for="(tab, index) in getStep().children"
				:key="index"
				@click="ClickByName('CalculaterDetailPage', getStep(), index)"
			>
				<div class="card-front1" v-if="tab.explanation === ''">
					<div class="text justify-left">
						{{ tab.label }}
					</div>
				</div>

				<div class="card-front" v-if="tab.explanation !== ''">
					<div class="text justify-left">
						{{ tab.label }}
					</div>
				</div>
				<div class="card-back" v-if="tab.explanation !== ''">
					<div class="text">
						{{ tab.explanation }}
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-left" v-else>
			<div
				class="card"
				v-for="(tab, index) in getStep().children"
				:key="index"
				@click="getIndex(index)"
			>
				<div class="card-front1" v-if="tab.explanation === ''">
					<div class="text justify-left">
						{{ tab.label }}
					</div>
				</div>

				<div class="card-front" v-if="tab.explanation !== ''">
					<div class="text justify-left">
						{{ tab.label }}
					</div>
				</div>
				<div class="card-back" v-if="tab.explanation !== ''">
					<div class="text">
						{{ tab.explanation }}
					</div>
				</div>
			</div>
		</div>

		<div style="width:40%;height:auto;position:absolute;left:30%;top:30%; border-radius:10px;justify-center;background-color:rgb(129, 149, 230)"  v-if="showAlert === true">
			<div style="position:absolute;font-weight:700;font-size:1.8vw;margin-top:1%;margin-left: 94%;" @click="showAlert=false">
				<font-awesome-icon :icon="['fas', 'xmark']" />
			</div>
			<div style="text-align:center;font-weight:700;font-size:1.8vw;margin-top:2%;">
				Please select a calculation method
			</div>
			<div style="text-align:left;font-size:1vw;margin:2%;">
				<div v-if="sourceindex < 2">
					<span style="font-weight:bold">Distance based method:</span>  
					The distance based method is a relatively accurate calculation approach used to estimate GHG emissions based on the distance traveled. To use this method, please gather data on the distances covered for each mode of transportation used during business trips.
				</div>
				<div v-if="sourceindex === 2">
					<span style="font-weight:bold">Duration based method:</span>  
					The duration-based method for hotels is a calculation approach used to estimate GHG emissions based on the total number of nights stayed at hotels during business trips. To use this method, kindly select the name of the hotel and provide us with the total number of nights spent during business trips.
				</div>
				<div>
					<span style="font-weight:bold">Spend based method:</span>  
					The spend-based method is a practical alternative used to estimate GHG emissions based on the amount of money spent on business travel and hotels. While it may be less precise compared to distance based methods, it can be used when detailed data on distances traveled and dates stayed are not readily available. To use this approach, please collect the total expenditure for business travel and hotels. 		
				</div>
			</div>
			<div style="text-align:center;">
				<button  style="text-align:center;font-size:1.2vw;border-radius:10px" @click="ClickByName('CalculaterDetailPage', getStep(), sourceindex, 0)">
					<span v-if="sourceindex < 2">Distance Based Method</span>
					<span v-if="sourceindex === 2">Hotel Stay Duration based</span>
				</button>
				<button style="text-align:center;font-size:1.2vw;margin-top:5%;margin-bottom:5%;margin-left:8%;border-radius:10px" @click="ClickByName('CalculaterDetailPage', getStep(), sourceindex, 1)">
					<span v-if="sourceindex < 2">Spend Based Method</span>
					<span v-if="sourceindex === 2">Hotel Spent based</span>
				</button>
			</div>
		</div>
	</div>
	<br />
	<br />
	<br />
	<br />
</template>

<script>
import Toolbar from "@/components/tools/HomeToolBar.vue";
// import stepFactor from "./carbondata/factortype.json";
import stepSource from "../carbondata/sourcetype.json";
import { useRouter } from "vue-router";
import { ref } from 'vue';
// import { faL } from "@fortawesome/free-solid-svg-icons";
// import { computed, ref } from "vue";

export default {
	name: "CalculaterStationaryPage",
	components: {
		Toolbar,
	},

	setup() {
		// const router = useRouter();
		// const route = useRoute();
		// const val = route.query.k;
		const sourceindex = ref("");
		const showAlert = ref("");
		const val = history.state.k;

		const router = useRouter();
		function ClickByName(pagepath, id, index, dualflag) {
			console.log(pagepath);
			console.log(id);
			router.push({
				name: pagepath,
				params: {
					context: id,index,dualflag
				},
				state: {
					k1: JSON.stringify(id),
					k2: JSON.stringify(index),
					k3:	JSON.stringify(dualflag)
				},
				// query: {
				// 	k1: JSON.stringify(id),
				// 	k2: JSON.stringify(index),
				// },
			});
		}

		function getStep() {
			if (val == null) {
				console.error("selected val is null");
			} else {
				var source = stepSource.filter((x) => x.value === val);
				if (source.length >= 1) {
					// console.log(source.length );
					// console.log(source[0]);
					return source[0];
				} else {
					console.error("Failed to find the step", val);
					return null;
				}
			}
		}
		function getExplanation() {
			console.log("getStep().children.length");
			console.log(getStep().children.length);
			console.log(getStep().children[0].explanation);
			return getStep().children[0].explanation;
		}
		function getLabel() {
			console.log(getStep().label);
			return getStep().label;
		}

		function getIndex(index_value){
			sourceindex.value = index_value;
			showAlert.value=true;
		}   

		return {
			sourceindex,
			showAlert,
			ClickByName,
			getStep,
			getExplanation,
			getLabel,
			// c_method,
			getIndex,
		};
	},
	mounted() {
		// console.log("1234564879:")
		// const style = document.createElement('style')
		// style.innerText = '.card{ height:500px }'
		// document.head.appendChild(style)
		// document.styleSheets[0].deleteRule(0);
	},
};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");

.content {
	display: block;
	margin: -3em auto;
	margin-top: 20px;
	text-align: left;
	font-family: "Abel";
	border-radius: 5px;
	background-color: #ffffff;
	padding: 20px;
	width: 60%;

	.btn {
		outline: solid;
		border-width: 200px;
		font-size: 1.2vw;
		font-weight: 500;
		height: 80px;
		width: 15%;
		margin-left: 16px;
		margin-right: 16px;
	}
	.big-title {
		font-size: 3vw;
		font-weight: 400;
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 30px;
		margin-bottom: 50px;
	}
	.detail {
		margin-top: 14px; //20px
		font-size: 16px;
		font-weight: 400;
		font-family: "Abel", sans-serif;
		box-sizing: border-box;
		width: 100%;
	}
	.title {
		font-size: 2vw;
		font-weight: 400;
		color: #0d0c22;
		box-sizing: border-box;
		margin-top: 15px;
		margin-bottom: 30px;
	}
}
// Color variables (appears count calculates by raw css)
@color0: #5b9fdf; // Appears 3 times  #dfe7c3;
@color1: #ffffff; // Appears 3 times

// Width variables (appears count calculates by raw css)
@width1: 100%; // Appears 2 times

// Height variables (appears count calculates by raw css)
@height1: 100%; // Appears 2 times

body {
	background-color: #eeeeee;
	font-family: "Josefin Sans", sans-serif;
	margin: 0;
	padding: 0;
	a {
		color: @color1;
		text-decoration: none;
	}
}
.display {
	pointer-events: none;
}
.card {
	display: block;

	// outline: ridge #32a1ce;
	height: 140px;
	margin-right: 0.8vw;
	margin-left: 0.8vw;
	margin-top: 1.5vw;
	perspective: 600px;
	text-align: center;
	font-family: "Abel";
	cursor: pointer;
	// position: absolute;
	// top: 10%;
	// left: 10%;
	transform-style: preserve-3d;
	// transform: translate(-50%, -50%);
	transition: 0.5s;
	width: 22%;
	// .a{
	// display: none;
	&:hover {
		.card-front {
			transform: rotateX(-180deg);
		}
		.card-back {
			transform: rotateX(0deg);
		}
	}
	// }
	.card-front1 {
		display: block;
		backface-visibility: hidden;
		background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
		background-position: 100% 100%;
		background-size: auto;
		height: @height1;
		left: 0;
		position: absolute;
		align-items: center;
		top: 0;
		width: @width1;
		border-radius: 10px;
		.text {
			font-size: 1.4vw;
			font-weight: 700;
			text-align: center;
			margin: 10% 2%;
			// margin: 18% auto 35% auto;
		}
	}
}
.card-front {
	display: block;
	backface-visibility: hidden;
	background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
	background-position: 100% 100%;
	background-size: auto;
	height: @height1;
	left: 0;
	position: absolute;
	align-items: center;
	top: 0;
	transform: rotateX(0deg);
	transition: 0.5s;
	width: @width1;
	border-radius: 10px;
	.text {
		font-size: 1.4vw;
		font-weight: 700;
		text-align: center;
		margin: 20% 2%;
	}
}
.card-back {
	display: block;
	// pointer-events: none;
	backface-visibility: hidden;
	background-color: @color0;
	color: @color1;
	height: @height1;
	border-radius: 15px;
	// left: 0;
	// position: absolute;
	// text-align: center;
	// align-items: center;
	// top: 0;
	transform: rotateX(180deg);
	transition: 0.5s;
	width: @width1;
	.text {
		font-size: 0.6vw;
		font-weight: 400;
		text-align: left; //justify;
		padding: 2% 4% 15px 4%;
		line-height: 1.6;
		// margin: 40% auto 35% auto;
	}
	h2 {
		font-size: 20px;
		margin: 10% auto 35% auto;
		span {
			font-size: 20px;
		}
	}
	a {
		border-radius: 4px;
		height: 20px;
		line-height: 20px;
		padding: 5px 5px;
		width: 20px;
		&:hover {
			background-color: @color1;
			color: @color0;
		}
	}
}

.card2 {
	display: block;

	// outline: ridge #32a1ce;
	height: 400px;
	// margin-right: 0.8vw;
	// margin-left: 0.8vw;
	// margin-top: 1.5vw;
	perspective: 600px;
	text-align: center;
	font-family: "Abel";
	cursor: pointer;
	transform-style: preserve-3d;
	transition: 0.5s;
	width: 45%;
	
	&:hover {
		.card-front {
			transform: rotateX(-180deg);
		}
		.card-back {
			transform: rotateX(0deg);
		}
	}
	
	.card-front1 {
		display: block;
		backface-visibility: hidden;
		background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
		background-position: 100% 100%;
		background-size: auto;
		height: @height1;
		left: 0;
		position: absolute;
		align-items: center;
		top: 0;
		width: @width1;
		border-radius: 10px;
		.text {
			font-size: 1.4vw;
			font-weight: 700;
			text-align: center;
			margin: 20% 2%;
			// margin: 18% auto 35% auto;
		}
	}
}

</style>
