<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" class="text-black">
						<b> COMPANY SUSTAINABILITY </b>
					</q-tab>
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" label="ENVIRONMENTAL IMPACT" />
					<q-tab name="social" label="SOCIAL IMPACT" />
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container class="content">
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-3">
							<div class="title text-capitalize">About the Business</div>
							<div class="text-content">
								{{ aboutbusiness }}
							</div>
							<q-img :src="aboutbusinessimg" style="fit" />
						</div>
						<div class="col-4 testrow">
							<div class="title text-capitalize">
								Commitment to Sustainability
							</div>
							<div class="text-content">
								{{ commitment }}
							</div>
							<q-img :src="commitmentimg" style="fit" />
						</div>
						<div class="col-4">
							<div class="title text-capitalize">Stakeholders Engagement</div>
							<div class="text-content">
								{{ stackholders }}
							</div>
							<q-img :src="stackholdersimg" style="fit" />
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-7 testrow">
							<div class="title text-capitalize" v-if="template!=='retail'">Customer and Suppliers</div>
							<div class="title text-capitalize" v-else-if="template==='retail'">Mission</div>
							<div class="text-content">
								{{ suppliers_mission }}
							</div>
							<div class="title text-capitalize">Feature Story</div>
							<div class="text-content">
								{{ featurestory }}
							</div>
						</div>
						<div class="col-4">
							<q-img :src="featurestoryimg" :ratio="3 / 3" />
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from "vuex";
import SlideFooter from "./components/DefaultFooter.vue";

export default {
	name: "OurCompanyPage",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;
	
		const aboutbusiness =
			store.state[template][template+"_ourcompany_intro"].ans;
		let aboutbusinessimg =
			store.state[template][template+"_ourcompany_intro"].img;
		
		if (!aboutbusinessimg) {
			aboutbusinessimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202735/DYNIMOS%20DEFAULT%20PICS/Our_company_gwrkgf.jpg";
		}
		const commitment =
			store.state[template][template+"_ourcompany_commitment"].ans;
		let commitmentimg =
			store.state[template][template+"_ourcompany_commitment"].img;
		if (!commitmentimg) {
			commitmentimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202719/DYNIMOS%20DEFAULT%20PICS/COMMITEMENT_TO_SUSTAINABILITY_t46qlj.webp";
		}
		var suppliers_mission = ref("");
		if(template!=="retail"){
			suppliers_mission =
				store.state[template][template+"_ourcompany_suppliers"].ans;
		}else{
			suppliers_mission =
				store.state[template][template+"_ourcompany_mission"].ans;
		}
		const stackholders = 
			store.state[template][template+"_ourcompany_stakeholders"].ans;
		let stackholdersimg = 
			store.state[template][template+"_ourcompany_stakeholders"].img;
		if (!stackholdersimg) {
			stackholdersimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677202687/DYNIMOS%20DEFAULT%20PICS/EMPLOYEE_GROWTH_df2hqm.webp";
		}

		const featurestory =
			store.state[template][template+"_ourcompany_featurestory"].ans;
		let featurestoryimg =
			store.state[template][template+"_ourcompany_featurestory"].img;
		if (!featurestoryimg) {
			featurestoryimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/c_crop,g_east,h_4000,w_4059,x_552/v1677928022/DYNIMOS%20DEFAULT%20PICS/pexels-fauxels-3184291_lntyiy.jpg";
		}

		return {
			template,
			aboutbusiness,
			aboutbusinessimg,
			commitment,
			commitmentimg,
			suppliers_mission,
			stackholders,
			stackholdersimg,
			featurestory,
			featurestoryimg,
		};
	},

};
</script>

<style lang="less" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto&family=Taviraj&display=swap");
.testrow {
	padding: 10px 15px;
	background: rgba(86, 61, 124, 0.15);
	border: 1px solid rgba(86, 61, 124, 0.2);
}
.row {
	margin-top: 1rem;
}
.row {
	background: rgba(255, 0, 0, 0);
}
.content {
	height: 1250px;
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
