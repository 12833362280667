<template>
	<q-header elevated>
		<ToolBar/>
	</q-header>
	<div style="display: flex;overflow: auto;">
		<div style="position: fixed;align-items: center;margin-left:10px;margin-top:12px;width:12%;height:80vh;border-radius:10px;background-color:rgb(61, 56, 56);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
			<div style="width:80%;font-size:1.6vw;margin-left:7%;color: aliceblue;">
				<div class="box" style="margin-top:30%;border-radius:10px;background-color:rgba(0, 255, 0, 0.552);" @click="$router.push('/dashboardmain')">Dashboards</div>
				<div>
					<div class="box" @click="$router.push('/dashboardequipment')">Equipment</div>
					<div class="box" @click="$router.push('/dashboardvehicle')">Vehicle</div>
					<div class="box" @click="$router.push('/dashboardbuilding')">Building</div>
					<!-- <div class="box" @click="$router.push('/dashboardequipment')">Waste</div> -->
				</div>
			</div>
		</div>
		<div style="position: static;margin-left:14%;margin-top:30px;width:84%;">
			<!-- <div style="font-size:3.0vw;padding-bottom:2%">Inventory</div> -->
			<div style="width: 80%;padding:10px;border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
				<div>
					<div style="font-size:large;margin-bottom:10px">Dropdown button to select time range start -> end, all charts in this dashboard are based on the time range selected</div>
					<div class="p-3 pb-0 card-header">
						<div class="demo-date-picker">
							<div class="block">
								<el-date-picker
									v-model="startTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="Start date"
									transfer
									:size="small"
									@change="dateRules"
									style="width:120px;"
									/>
								<el-date-picker
									v-model="endTime"
									type="date"
									format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
									placeholder="End date"
									transfer
									:size="small"
									@change="dateRules"
									style="width:120px;margin-left:20px"
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6" style="margin: 20px auto;">
				<div class="card z-index-2">
					<div class="p-3 card-body"  style="border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);"> 
						<div style="font-size: medium;font-weight:600;margin:10px">Total emmision (co2e only) by time</div>
						<div style="display: flex;width:25%;margin-left:65%;justify-content: space-between;">
							<button style="width:70px;border-radius:10px;" id="button0" onclick="changeColor(this,0)" checked>Total</button>
							<button style="width:70px;border-radius:10px;" id="button1" onclick="changeColor(this,1)">Scope 1</button>
							<button style="width:70px;border-radius:10px;" id="button2" onclick="changeColor(this,2)">Scope 2</button>
							<button style="width:70px;border-radius:10px;" id="button3" onclick="changeColor(this,3)">Scope 3</button>
						</div>
						<div class="chart" style="width: 96%;padding-left:2%" :key="refresh_indexs_0">	
						<default-line-chart
							id="line-chart"
							title="Total emmision (co2e only) by time"
							:chart="{
								labels: CO2e_title,//['123','234','345'],//
								datasets: [
									{
										label: line_label,
										data: CO2e_value//[200,300,100]//
									}
								]
							}"
						/>
						</div>
					</div>
				</div>
			</div>

			<div style="margin-top:10px;">
				<div class="card" style="border-radius:10px;background-color:rgb(255, 255, 255);box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);">
					<div class="p-3 card-body" :key="refresh_indexs">
						<div class="row" style="display: flex;justify-content: space-between;margin:10px;">
							<div>
								<div></div>
								<div style="font-size: medium;font-weight:600;margin:10px">Scope 1 by category</div>
								<doughnut-chart
									id="doughnut-chart"
									class="w-50"
									:chart="{
										labels: scope1_category_titie,
										datasets: {
											label: 'Projects',
											data: scope1_category_value
										}
									}"
								/>
							</div>
							<div >
								<div style="font-size: medium;font-weight:600;margin:10px">Scope 2 by category</div>
								<doughnut-chart
									id="doughnut-chart2"
									class="w-51"
									:chart="{
										labels: scope2_category_titie,
										datasets: {
											label: 'Projects1',
											data: scope2_category_value
										}
									}"
								/>
							</div>
							<div>
								<div style="font-size: medium;font-weight:600;margin:10px">Scope 3 by category</div>
								<doughnut-chart
									id="doughnut-chart3"
									class="w-52"
									:chart="{
										labels: scope3_category_titie,
										datasets: {
											label: 'Projects2',
											data: scope3_category_value
										}
									}"
								/>
							</div>
						</div>
					</div>
				</div>
	
			</div>
			<br>
			<br>
			<br>
		</div>
	</div>

</template>

<script>
import { reactive, ref, onMounted } from 'vue'
// import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";
// import { useQuasar } from "quasar";
// import {  VxeTableInstance } from 'vxe-table'
import axios from "axios";
// import AllConuntryStates from "@/components/formdata/allcountries.json";
// import stepSource from "../carbondata/sourcetype.json";
// import stepFactor from "../carbondata/factortype.json";
import ToolBar from "@/components/tools/HomeToolBar.vue";
// import LineChart from "./components/LineChart.vue";
import DefaultLineChart from "./components/DefaultLineChart.vue";
import DoughnutChart from "./components/DoughnutChart.vue";
// import BarChart from "./components/BarChart.vue";
// import BarChartHorizontal from "./components/BarChartHorizontal.vue";
// import VueApexCharts from "vue3-apexcharts";
import reactive1 from "./reactive.js"

export default {
	components: {
		ToolBar,
		// LineChart,
		DefaultLineChart,
		DoughnutChart,

	},
	setup () {
		// const xTable = ref(VxeTableInstance)
		const tableData = []
		var kg_CO2e = [];
		// const store = useStore()
		const { user } = useAuth0()

		const startTime = ref()
		const endTime = ref()

		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
		const currentDay = String(today.getDate()).padStart(2, '0');
		endTime.value  = `${currentYear}-${currentMonth}-${currentDay}`;

		const oneYearAgo = new Date();
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
		const oneYearAgoYear = oneYearAgo.getFullYear();
		const oneYearAgoMonth = String(oneYearAgo.getMonth() + 1).padStart(2, '0');
		const oneYearAgoDay = String(oneYearAgo.getDate()).padStart(2, '0');
		startTime.value = `${oneYearAgoYear}-${oneYearAgoMonth}-${oneYearAgoDay}`;

		
		const refresh_indexs = ref(0)
		const refresh_indexs_0 = ref(0)

		let line_label = ref("Total")
		let CO2e_value = ref([])  //123,321,232,323,444,333,222
		let CO2e_title = ref([])  //'五', '六', '七', '八', '九', '十'
		let total_co2e = reactive1([])
		let total_title = reactive1([])
		let scope1_co2e = reactive1([])
		let scope2_co2e = reactive1([])
		let scope3_co2e = reactive1([])
		let scope1_title = reactive1([])
		let scope2_title = reactive1([])
		let scope3_title = reactive1([])

		let scope1_category_titie = reactive1([])
		let scope2_category_titie = reactive1([])
		let scope3_category_titie = reactive1([])
		let scope1_category_value = reactive1([])
		let scope2_category_value = reactive1([])
		let scope3_category_value = reactive1([])

		window.changeColor = function(button,index) {
			// 首先移除所有按钮的样式
			var buttons = document.querySelectorAll('button');
			// console.log(index)
			buttons.forEach(function(btn) {
					btn.classList.remove('selected');
			});
			// console.log(scope1_co2e,scope1_title)
			if(index === 0){
				line_label.value = "Total"
				CO2e_value.value = [...total_co2e];
				CO2e_title.value = [...total_title];
			}else if(index === 1){
				line_label.value = "Scope 1"
				CO2e_value.value = [...scope1_co2e];
				CO2e_title.value = [...scope1_title];
			}else if(index === 2){
				line_label.value = "Scope 2"
				CO2e_value.value = [...scope2_co2e];
				CO2e_title.value = [...scope2_title];
			}else if(index === 3){
				line_label.value = "Scope 3"
				CO2e_value.value = [...scope3_co2e];
				CO2e_title.value = [...scope3_title];
			}
			// console.log(CO2e_value,CO2e_title)
			// 将被点击的按钮添加选中样式
			button.classList.add('selected');
			setTimeout(()=>{
				refresh_indexs_0.value ++
				// console.log(refresh_indexs_0.value)
			},20)
		}

		async function getEquipmentData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"equipment"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		async function getVehicleData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"vehicleusage"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		async function getElectricityData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"electricity"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}
		async function getSteamandHeatData() {
			const usercode = JSON.stringify(user.value, null, 2);
			const userid = JSON.parse(usercode)["sub"];
			let response = await axios.get(
				"https://testdynimosazure.azurewebsites.net/api/getCalculatorEquipmentByUserOperating?id=" + userid,
				{
					params:{
						type:"steamandheat"
					},
					headers: {},
				}
			);
			if (response) {
				return response;
			}
			return null;
		}

		function getData(Data)
		{
			var temp_str ,scope
			for(let i=0;i<Data.length;i++)
			{
				if((Data[i].scope & 0xF0) === 0x10)
				{
					scope = 1;
					if((Data[i].scope & 0x0F) == 0x01)
						temp_str = "equipment"
					else if((Data[i].scope & 0x0F) == 0x02)
						temp_str = "vehicle"
				}
				else if((Data[i].scope & 0xF0) === 0x20){
					scope = 2;
					if((Data[i].scope & 0x0F) == 0x01)
						temp_str = "electricity"  //Data[i].buildingprofile_name
					else if((Data[i].scope & 0x0F) == 0x02)
						temp_str = "steam and heat"//Data[i].buildingprofile_name
				} 
				else if((Data[i].scope & 0xF0) === 0x30){
					scope = 3
					if((Data[i].scope & 0x0F) == 0x01)
						temp_str = "vehicle"
					else if((Data[i].scope & 0x0F) == 0x02)
						temp_str = "waste"
				} 
				if(Data[i].co2e !== 0 && typeof(Data[i].co2e) !== "undefined") {
					const table_arry=reactive([])
					table_arry.push({
						scope: scope,  //Scope 1
						category: temp_str,
						timeperiod:{
							starttime: Data[i].timeperiod.starttime,
							endtime: Data[i].timeperiod.endtime
						},
						co2e: Data[i].co2e,
					})							
					tableData.push(table_arry[0])
				}
				
			}
		}
		
		onMounted(async () => {
			// const returnUser = await userHasData();
			const equipmentData = await getEquipmentData();
			const vehicleData = await getVehicleData();
			const electricityData = await getElectricityData();
			const steamandHeatData = await getSteamandHeatData();

			if(equipmentData.data.operatingData != undefined)
			{
				const databuf = JSON.parse(JSON.stringify(equipmentData.data.operatingData))
				getData(databuf)
			}
			if(vehicleData.data.operatingData != undefined)
			{
				const databuf1 = JSON.parse(JSON.stringify(vehicleData.data.operatingData))
				getData(databuf1)
			}
			if(electricityData.data.operatingData != undefined)
			{
				const databuf2= JSON.parse(JSON.stringify(electricityData.data.operatingData))
				getData(databuf2)
			}
			if(steamandHeatData.data.operatingData != undefined)
			{
				const databuf3 = JSON.parse(JSON.stringify(steamandHeatData.data.operatingData))
				getData(databuf3)
			}
			// console.log("get---tableData",tableData)
			charts_calc(startTime.value ,endTime.value)
			setTimeout(()=>{
				var button = document.getElementById('button0');
				button.click();
			},20)
		});

		function charts_calc(start,end){
			if(start < end){
				total_co2e.length = 0
				total_title.length = 0
				scope1_title.length = 0
				scope2_title.length = 0
				scope3_title.length = 0
				scope1_co2e.length = 0
				scope2_co2e.length = 0
				scope3_co2e.length = 0
				scope1_category_titie.length = 0
				scope2_category_titie.length = 0
				scope3_category_titie.length = 0
				scope1_category_value.length = 0
				scope2_category_value.length = 0
				scope3_category_value.length = 0
				let i=0,j=0,k=0,n=0,m=0
				let month_len = 0
				var xTabledata = tableData//reactive()
				// console.log("------------>charts_calc start",xTabledata)
				let input_start_year = parseInt(start.substring(0,4))
				let input_start_month = parseInt(start.substring(5,7))
				let input_end_year = parseInt(end.substring(0,4))
				let input_end_month = parseInt(end.substring(5,7))
				for(i=0;i<xTabledata.length;i++)
				{
					
					// console.log(xTabledata[i].timeperiod.starttime,xTabledata[i].timeperiod.endtime)
					if(xTabledata[i].timeperiod.starttime!="" && xTabledata[i].timeperiod.endtime!="")
					{							
						let table_start_year = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(0,4))
						let table_start_month = parseInt(xTabledata[i].timeperiod.starttime.toString().substring(5,7))
						let table_end_year = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(0,4))
						let table_end_month = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(5,7))
						
						// let month_len = parseInt(xTabledata[i].timeperiod.endtime.toString().substring(0,7)) - parseInt(xTabledata[i].timeperiod.starttime.toString().substring(0,7)) + 1
						if(table_end_year > table_start_year)
						{
							month_len = (table_end_year - table_start_year - 1)*12 + (12 + table_end_month - table_start_month) + 1    //防止小数月份减大数月份
						}else{
							month_len = (table_end_year - table_start_year) * 12 + (table_end_month - table_start_month) + 1
						}
						
						// console.log("month_len=%d",month_len,table_start_year,table_start_month,table_end_year,table_end_month)
						if(isNaN(month_len)){
							return
						}
						
						for(j=0;j<month_len;j++)   //当前跨度几个月
						{
							let comp_yeas = parseInt(table_start_year)
							let comp_month = parseInt(table_start_month+j)
							if(comp_month>12) {
								comp_month -= 12
								comp_yeas +=1
							}
							if((comp_yeas > input_start_year && comp_yeas < input_end_year)||
								(comp_yeas === input_start_year && comp_yeas < input_end_year && comp_month >= input_start_month)||
								(comp_yeas === input_start_year && comp_yeas === input_end_year && comp_month >= input_start_month && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas > input_start_year && comp_month <= input_end_month)||
								(comp_yeas === input_end_year && comp_yeas === input_start_year && comp_month >= input_start_month && comp_month <= input_end_month))
							{			
								var comp_category = xTabledata[i].category
								let title_lenght,category_titie
								if(xTabledata[i].scope === 1){
									title_lenght = scope1_category_titie.length
									category_titie = [...scope1_category_titie]
								}else if(xTabledata[i].scope === 2){
									title_lenght = scope2_category_titie.length
									category_titie = [...scope2_category_titie]
								}else if(xTabledata[i].scope === 3){
									title_lenght = scope3_category_titie.length
									category_titie = [...scope3_category_titie]
								}

								for(n=0;n<title_lenght;n++)
								{
									if(comp_category === category_titie[n])
									{
										if(xTabledata[i].scope === 1)
											scope1_category_value[n] += parseInt(xTabledata[i].co2e/month_len)
										else if(xTabledata[i].scope === 2)
											scope2_category_value[n] += parseInt(xTabledata[i].co2e/month_len)
										else if(xTabledata[i].scope === 3)
											scope3_category_value[n] += parseInt(xTabledata[i].co2e/month_len)
										break
									}
								}
								if(n === title_lenght){
									if(xTabledata[i].scope === 1){
										scope1_category_value.splice(title_lenght,0,parseInt(xTabledata[i].co2e/month_len))
										scope1_category_titie.splice(title_lenght,0,comp_category)	
									}else if(xTabledata[i].scope === 2){
										scope2_category_value.splice(title_lenght,0,parseInt(xTabledata[i].co2e/month_len))
										scope2_category_titie.splice(title_lenght,0,comp_category)	
									}else if(xTabledata[i].scope === 3){
										scope3_category_value.splice(title_lenght,0,parseInt(xTabledata[i].co2e/month_len))
										scope3_category_titie.splice(title_lenght,0,comp_category)	
									}
								}


								var comp_title = comp_yeas.toString() + "/" + comp_month.toString()	
								// console.log(comp_title)
								let index = 0,index_1 = 0
								if(xTabledata[i].scope === 1)
									index = scope1_title.length
								else if(xTabledata[i].scope === 2)
									index = scope2_title.length
								else if(xTabledata[i].scope === 3)
									index = scope3_title.length
								let min_time_flag = 0		
								for(k=0;k<index;k++){
									let temp_title
									if(xTabledata[i].scope === 1)
										temp_title = scope1_title[k]
									else if(xTabledata[i].scope === 2)
										temp_title = scope2_title[k]
									else if(xTabledata[i].scope === 3)
										temp_title = scope3_title[k]
									let title_year = parseInt(temp_title.substring(0,4))
									let title_month = parseInt(temp_title.substring(5,7))
									
									if(temp_title === comp_title)  
									{
										if(xTabledata[i].scope === 1)
											scope1_co2e[k] += parseInt(xTabledata[i].co2e/month_len)
										else if(xTabledata[i].scope === 2)
											scope2_co2e[k] += parseInt(xTabledata[i].co2e/month_len)
										else if(xTabledata[i].scope === 3)
											scope3_co2e[k] += parseInt(xTabledata[i].co2e/month_len)
										break
									}else if((comp_yeas<title_year || (comp_yeas===title_year && comp_month<=title_month)) && min_time_flag===0){
										index = k
										min_time_flag = 1
										break
									}
								} 
								if(k===index){
									if(xTabledata[i].scope === 1){
										scope1_co2e.splice(index,0,parseInt(xTabledata[i].co2e/month_len))
										scope1_title.splice(index,0,comp_title)	
									}else if(xTabledata[i].scope === 2){
										scope2_co2e.splice(index,0,parseInt(xTabledata[i].co2e/month_len))
										scope2_title.splice(index,0,comp_title)	
									}else if(xTabledata[i].scope === 3){
										scope3_co2e.splice(index,0,parseInt(xTabledata[i].co2e/month_len))
										scope3_title.splice(index,0,comp_title)		
									}	
								}		
								index_1 = total_title.length
								min_time_flag = 0
								for(m=0;m<index_1;m++)
								{
									let temp_title = total_title[m]
									let title_year = parseInt(temp_title.substring(0,4))
									let title_month = parseInt(temp_title.substring(5,7))
									// console.log(comp_yeas,comp_month,title_year,title_month)
									if(temp_title === comp_title)  
									{
										total_co2e[m] += parseInt(xTabledata[i].co2e/month_len)
										break
									}else if((comp_yeas<title_year || (comp_yeas===title_year && comp_month<=title_month)) && min_time_flag===0){
										// console.log("------>",m,comp_yeas,comp_month,title_year,title_month)
										index_1 = m
										min_time_flag = 1
										break
									}
								}

								// console.log(comp_yeas,comp_month,"--->",m,index_1)
								if(m===index_1){
									// console.log("------------>",comp_yeas,comp_month)
									total_co2e.splice(index_1,0,parseInt(xTabledata[i].co2e/month_len))
									total_title.splice(index_1,0,comp_title)	
								}					
							}
						}
						CO2e_value.value = [...total_co2e];
						CO2e_title.value = [...total_title];
						// console.log(scope1_co2e,scope1_title)
						// console.log(scope2_co2e,scope2_title)
						// console.log(scope3_co2e,scope3_title)
					}
				}
				if(refresh_indexs_0.value > 0)refresh_indexs_0.value ++
				refresh_indexs.value ++
			}			
		}
		return { 
			tableData,
			line_label,
			// changeColor,
			kg_CO2e,
			startTime,
			endTime,
			CO2e_value,
			CO2e_title,
			total_co2e,
			total_title,
			scope1_category_titie,
			scope2_category_titie,
			scope3_category_titie,
			scope1_category_value,
			scope2_category_value,
			scope3_category_value,
			charts_calc,
			refresh_indexs,
			refresh_indexs_0,
		}
	},

	methods: {
		dateRules(){
			// console.log(this.startTime,this.endTime)
			if(this.startTime > this.endTime) {

				this.$q.notify({
					color: "red-5",
					textColor: "white",
					icon: "warning",
					message: "Start date must be earlier than end date",
				});
				
			}else{
				this.charts_calc(this.startTime,this.endTime);
			}
		},
	},
	

}
</script>

<style scoped>
.selected {
	background-color: rgb(238, 255, 0); /* 设置选中时的背景色 */
  }
  
.box {
	display: flex;
	height: 40px;
	font-size:1.4vw;
	padding-left:7%;
	align-items: center;
	margin-top: 3px;
	border-radius:10px;
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */
  }

  .box:hover {
    box-shadow: 0px 0px 10px rgba(214, 213, 213, 0.678); /* 鼠标移入时添加阴影效果 */
  }
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 0px 0;
  text-align: left;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 0px;
}
.el-date-editor {
    --el-date-editor-daterange-width: 275px;
}
.delete-line {
	text-decoration: line-through;
  }
</style>
