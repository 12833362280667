<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div> -->
	<!-- <p>{{ step }}</p> -->
	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>
	<q-form @submit="onSubmit" class="q-gutter-md">
		<q-input
			v-model="answer"
			filled
			clearable
			clear-icon="close"
			placeholder="Please write your own or add our tempalte from list"
			autogrow
			counter
			:maxlength="charlimit"
			:rules="[
				(val) =>
					val.length < charlimit ||
					'Please use maximum ' + charlimit + ' characters',
			]"
		/>
		<ImgUploader
			v-if="picturelabel"
			:step="step"
			:label="picturelabel"
			:ratio="pictureratio"
			class="q-mt-md q-pb-md"
		/>

		<q-card class="q-mt-lg">
			<q-card-section>
				<q-item-label class="text-caption">
					Click add button to use our examples
				</q-item-label>
				<!-- <q-item-label class="text-caption">Pick Examples</q-item-label> -->
				<!-- <div class="text-subtitle2">{{ question }}</div> -->
			</q-card-section>
			<q-list bordered v-for="(item, index) in items" :key="index">
				<q-item v-ripple>
					<q-item-section>
						<q-item-label>{{ item.message }}</q-item-label>

						<q-item-label lines="10">
							<q-chip
								v-for="(option, optionindex) in item.optionlist"
								:key="optionindex"
								v-model:selected="option.active"
								color="indigo"
								text-color="white"
							>
								{{ option.label }}
							</q-chip>
						</q-item-label>
					</q-item-section>

					<q-item-section top side>
						<div class="text-grey-8 q-gutter-xs">
							<q-btn
								class="gt-xs"
								color="primary"
								@click="updatemsg(item)"
								round
								icon="add"
							/>
						</div>
					</q-item-section>
				</q-item>
			</q-list>
		</q-card>
	</q-form>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

import ImgUploader from "../tools/ImgUploader.vue";

export default {
	props: [
		"label",
		"question",
		"guildline",
		"sampleanswers",
		"picturelabel",
		"pictureratio",
		"charlimit",
		"step",
	],

	components: { ImgUploader },

	setup(props) {
		const $q = useQuasar();
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));

		let answer = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set(value) {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: value,
				});
			},
		});
		const file = ref(null);

		const items = ref(
			props.sampleanswers.map((q) => {
				return {
					message: q.value,
					optionlist: q.options.map((o) => {
						return { label: o, active: false };
					}),
				};
			})
		);

		return {
			items,
			answer,
			file,
			updatemsg(val) {
				// TODO: add rules here, i.e. val shall not be repeat added, size of selection
				let newmessage = val.message;
				const selectedLables = val.optionlist.filter((o) => o.active == true);
				const pendingReplaceCount = (val.message.match(/__/g) || []).length;
				if (selectedLables.length !== pendingReplaceCount) {
					$q.notify({
						color: "red-5",
						textColor: "white",
						icon: "warning",
						message: "Please select correct number of chips",
					});
				} else {
					for (let i = 0; i < pendingReplaceCount; i++) {
						newmessage = newmessage.replace("__", selectedLables[i].label);
					}

					if (!answer.value) {
						answer.value = newmessage;
					} else {
						answer.value += "\n" + newmessage;
					}
				}
			},

			onSubmit() {
				console.log("clicked");
			},
		};
	},
};
</script>
