<template>
	<div class="q-pa-md">
		<q-layout
			view="lHh lpr lFf"
			container
			style="height: 1250px; width: 1455px"
			class="shadow-2 rounded-borders"
		>
			<q-header bordered class="bg-white text-primary">
				<q-tabs
					no-caps
					active-color="primary"
					class="text-grey"
					align="justify"
				>
					<q-tab name="company" label="COMPANY SUSTAINABILITY" />
					<q-tab name="eco" label="ECONOMIC IMPACT" />
					<q-tab name="enviro" label="ENVIRONMENTAL IMPACT" />
					<q-tab name="social" class="text-black">
						<b>SOCIAL IMPACT</b>
					</q-tab>
				</q-tabs>
			</q-header>
			<SlideFooter />

			<q-page-container>
				<q-page class="q-pa-md">
					<div class="row justify-evenly">
						<div class="col-11 paddingrow bg-pink-1">
							<div class="title text-capitalize text-center" v-if="template==='factory'">Measures</div>
							<div class="title text-capitalize text-center" v-if="template==='general'">Safety Measures</div>
							<div class="title text-capitalize text-center" v-if="template==='retail'">Regulatory Compliance</div>
							<div class="text-content">
								{{ measures }}
							</div>
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-6">
							<div class="title text-capitalize" v-if="template==='factory'">Safety</div>
							<div class="title text-capitalize" v-else-if="template!=='factory'">Workplace Safty</div>
							<div class="text-content">
								{{ safety }}
							</div>
							<q-img :src="safetyimg" style="fit" />
						</div>
						<div class="col-5">
							<div class="title text-capitalize" v-if="template==='factory'">Wellness</div>
							<div class="title text-capitalize" v-else-if="template!=='factory'">Wellbeing</div>
							<div class="text-content">
								{{ wellbeing }}
							</div>
							<q-img :src="testimonialimg" style="fit" />
						</div>
					</div>

					<div class="row justify-evenly">
						<div class="col-11 paddingrow bg-grey-2">
							<div class="title text-capitalize">Testimonial</div>
							<div class="text-content">
								{{ testimonial }}
							</div>
						</div>
					</div>
				</q-page>
			</q-page-container>
		</q-layout>
	</div>
</template>

<script>
import { useStore } from "vuex";
// import HeaderTab from "./components/HeaderTab.vue";
import SlideFooter from "./components/DefaultFooter.vue";
export default {
	name: "SafetyePage",
	components: { SlideFooter },

	setup() {
		const store = useStore();
		const template = store.state.template;

		const measures =
			store.state[template][template+"_social_safetymeasures"].ans;
		const safety = store.state[template][template+"_social_safety"].ans;
		let safetyimg = store.state[template][template+"_social_safety"].img;

		if (!safetyimg) {
			safetyimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/v1677928021/DYNIMOS%20DEFAULT%20PICS/pexels-alexander-suhorucov-6457563_k3dsro.jpg";
		}
		const wellbeing = store.state[template][template+"_social_wellbeing"].ans;
		const testimonial =
			store.state[template][template+"_social_featurestory_safety"].ans;
		let testimonialimg =
			store.state[template][template+"_social_featurestory_safety"].img;
		if (!testimonialimg) {
			testimonialimg =
				"https://res.cloudinary.com/ddkphzsvp/image/upload/c_crop,h_1650,w_1600/v1677202696/DYNIMOS%20DEFAULT%20PICS/SAFTY_zynsby.jpg";
		}

		return {
			template,
			measures,
			safety,
			safetyimg,
			wellbeing,
			testimonial,
			testimonialimg,
		};
	},
};
</script>

<style lang="less" scoped>
.testrow {
	padding: 10px 15px;
	border: 5px solid rgba(86, 61, 124, 0.2);
}

.paddingrow {
	margin-top: 15px;
	margin-bottom: 10px;
	padding: 15px 15px;
}
// .row + .row
//   margin-top: 1rem
.row {
	background: rgba(255, 0, 0, 0);
}
.text-content {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	font-family: "Abel", sans-serif;
	color: #000000;
}
.title {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 30px;
	font-weight: 500;
	font-family: "Taviraj", sans-serif;
	color: #000000;
}
</style>
