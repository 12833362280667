<template>
	<!-- <div class="text-h4 q-mb-md">{{ label }}</div>
	<p>{{ step }}</p>
	<p>{{ question }}</p>
	<p>{{ guildline }}</p> -->
	<div class="text-h5 text-weight-bold">{{ question }}</div>
	<q-separator />
	<div class="text-caption">Guideline</div>
	<div class="text-body3 text-weight-thin">{{ guildline }}</div>
	<vxe-toolbar>
		<template #buttons>
			<vxe-button @click="insertEvent(-1)">Add Row</vxe-button>
			<vxe-button @click="$refs.xTable.removeCheckboxRow()"
				>Delete Selected</vxe-button
			>
			<vxe-button @click="saveEvent">Save</vxe-button>
		</template>
	</vxe-toolbar>

	<vxe-table
		border
		show-overflow
		keep-source
		ref="xTable"
		max-height="400"
		empty-text="Empty Table"
		:data="tableData"
		:edit-config="{ trigger: 'click', mode: 'cell', showStatus: true }"
	>
		<vxe-column type="checkbox" width="60"></vxe-column>
		<vxe-column
			field="country"
			title="Country"
			sortable
			:edit-render="{
				autofocus: '.vxe-input--inner',
				defaultValue: 'Click to select Country',
			}"
		>
			<template #default="{ row }">
				<span>{{ row.country }}</span>
			</template>
			<template #edit="{ row }">
				<vxe-select
					v-model="row.country"
					placeholder="Select Country"
					@change="countryChangeEvent(row)"
					transfer
				>
					<vxe-option
						v-for="item in AllConuntryStates"
						:key="item.iso3"
						:value="item.name"
						:label="item.name"
					></vxe-option>
				</vxe-select>
			</template>
		</vxe-column>

		<vxe-column field="state" title="Province/State" :edit-render="{}">
			<template #default="{ row }">
				<span>{{ row.state.name || "Please select country" }}</span>
			</template>
			<template #edit="{ row }">
				<vxe-select v-model="row.state.name" v-if="row.state.name" transfer>
					<vxe-option
						v-for="s in row.allStates"
						:key="s.state_code"
						:value="s.name"
						:label="s.name"
					></vxe-option>
				</vxe-select>
			</template>
		</vxe-column>

		<vxe-column field="operation" title="Operation" :edit-render="{}">
			<template #edit="{ row }">
				<vxe-input
					v-model="row.operation"
					type="text"
					placeholder="Input Operation"
					maxlength="14"
				>
				</vxe-input>
			</template>
		</vxe-column>

		<vxe-column field="energytype" title="Energy Type" :edit-render="{}">
			<template #default="{ row }">
				<span>{{ row.engerytype.label }}</span>
			</template>
			<template #edit="{ row }">
				<vxe-select
					v-model="row.engerytype.label"
					placeholder="Select Engery Type"
					@change="energyChangeEvent(row)"
					transfer
				>
					<vxe-option
						v-for="item in engerytypes"
						:key="item.value"
						:value="item.label"
						:label="item.label"
					></vxe-option>
				</vxe-select>
			</template>
		</vxe-column>

		<vxe-column field="amount" title="Annual Amount" :edit-render="{}">
			<template #edit="{ row }">
				<vxe-input
					v-model.number="row.amount"
					placeholder="Input Annual Amount"
					type="number"
					maxlength="7"
				>
				</vxe-input>
			</template>
		</vxe-column>

		<vxe-column field="unit" title="Unit" :edit-render="{}">
			<template #default="{ row }">
				<span>{{ row.unit || "Please select energy type" }}</span>
			</template>
			<template #edit="{ row }">
				<vxe-select v-model="row.unit" v-if="row.unit" transfer>
					<vxe-option
						v-for="item in row.allUnit"
						:key="item"
						:value="item"
						:label="item"
					></vxe-option>
				</vxe-select>
			</template>
		</vxe-column>
	</vxe-table>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { VxeTableInstance } from "vxe-table";
import AllConuntryStates from "@/components/formdata/allcountries.json";

export default {
	props: ["label", "question", "guildline", "step"],
	components: {},

	setup(props) {
		const store = useStore();
		const curStep = props.step.replaceAll("-", "_");
		const curTemplate = curStep.substr(0, curStep.indexOf("_"));
		const xTable = ref(VxeTableInstance);

		// var tableData = ref([]);
		var tableData = computed({
			get() {
				return store.state[curTemplate][curStep].ans;
			},
			set(value) {
				store.commit("updateData", {
					template: curTemplate,
					step: curStep,
					key: "ans",
					value: value,
				});
			},
		});

		const engerytypes = [
			{ label: "Electricity", value: "electricity", unit: ["KWh"] },
			// NOT Supported yet
			// { label: "Natural Gas", value: "gas", unit: ["MMBtu"] },
			// { label: "Gasoline", value: "gasoline", unit: ["Gallon", "Liter"] },
		];

		const insertEvent = async (row) => {
			const $table = xTable.value;
			const record = {
				country: "",
				iso3: "",
				state: {
					name: "",
					state_code: "",
				},
				operation: "",
				engerytype: { label: "", value: "" },
				amount: "",
				unit: "",
				allStates: [],
				allUnit: [],
			};
			const { row: newRow } = await $table.insertAt(record, row);
			await $table.setEditCell(newRow, "country");
		};

		const saveEvent = () => {
			const $table = xTable.value;
			store.commit("updateData", {
				template: curTemplate,
				step: curStep,
				key: "ans",
				value: $table.getTableData().tableData,
			});
		};

		const countryChangeEvent = (row) => {
			// Update State list and iso3
			AllConuntryStates.map((item) => {
				if (row.country === item.name) {
					console.log("Found selected country from all countries json");
					row.iso3 = item.iso3;
					if (!item.states.length) {
						console.log(
							"No status or province in country:" +
								item.name +
								" Using country as state"
						);
						const tmpState = {
							name: item.name,
							state_code: item.iso3,
						};
						row.state = tmpState;
						row.allStates.push(tmpState);
					} else {
						row.state = Object.create(item.states[0]);
						row.allStates = Object.create(item.states);
					}
				}
			});
		};

		const energyChangeEvent = (row) => {
			// Update energy list and iso3
			engerytypes.map((item) => {
				if (row.engerytype.label === item.label) {
					row.engerytype.value = item.value;
					row.unit = item.unit[0];
					row.allUnit = item.unit;
				}
			});
		};

		return {
			xTable,
			tableData,
			insertEvent,
			saveEvent,
			AllConuntryStates,
			countryChangeEvent,
			energyChangeEvent,
			engerytypes,
		};
	},
};
</script>
